import React from 'react'
import image from '../../../assets/products/resiWebsite.png';
import { createStyles, Group, rem, Button, Title, Box, Container, Text, Image, Paper, SimpleGrid } from '@mantine/core';
import { Icon24Hours, IconDiamond, IconMedal, IconPhone } from '@tabler/icons-react';
import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme) => ({
  title: {
    [theme.fn.smallerThan('sm')]: {
      fontSize: rem(24),
      maxWidth: '100%',
    },
    maxWidth: '66%',
  },

  sectionTitle: {
    fontFamily: 'Inter, sans-serif',
    textAlign: 'left',
  },

  sectionDescription: {
    fontFamily: 'Inter, sans-serif',
    color: '#6B7280',
    textAlign: 'left',
    maxWidth: 600,
  },

  featureCard: {
    backgroundColor: '#EDFBF4',
  },

  iconWrapper: {
    backgroundColor: '#003D51',
    borderRadius: '8px',
    width: 'fit-content',
    padding: '8px',
    marginBottom: '5rem',
  },

  featureTitle: {
    fontFamily: 'Inter, sans-serif',
    textAlign: 'left',
  },

  featureText: {
    fontFamily: 'Inter, sans-serif',
    color: '#4B5563',
    textAlign: 'left',
  },
}));

export default function ProductPage({ language }) {

  const { classes, theme } = useStyles();
  const { t } = useTranslation();

  const content = {
    '/': {
      'mainTitle': "Explore additional features",
      'groupTitle': 'Why ResiWebsite?',
      'enhanceText': "Enhance your website with valuable features that improve guest experience, build trust, and make your property stand out.", // ✅ Added
      'description': "Show your hidden gems.",
      'text': 'Add thoughtfull suggestions regarding food, activities, museum at much more to your website based on your experience as a host.',
      'description2': 'Highlight your booking reviews on other booking platforms.',
      'text2': "Show your guests that you're a trusted host by highlighting your reviews from other booking platforms.",
      'description3': 'Mobile friendly.',
      'text3': "Your website will be mobile friendly and will look great on all devices.",
    },
    '/nl': {
      'mainTitle': "Ontdek extra functies",
      'groupTitle': 'Waarom ResiWebsite?',
      'enhanceText': "Verbeter uw website met waardevolle functies die de gastervaring verbeteren, vertrouwen opbouwen en uw woning laten opvallen.", // ✅ Added
      'description': "Laat je verborgen pareltjes zien.",
      'text': 'Voeg op basis van uw ervaring als gastheer/gastvrouw doordachte suggesties toe over eten, activiteiten, musea en nog veel meer op uw website.',
      'description2': 'Toon uw boekingsbeoordelingen op andere boekingsplatforms.',
      'text2': "Laat uw gasten zien dat u een betrouwbare gastheer bent door uw beoordelingen van andere boekingsplatforms te benadrukken.",
      'description3': 'Mobiel vriendelijk.',
      'text3': "Uw website is geschikt voor mobiele apparaten en ziet er op alle apparaten goed uit.",
    },
    '/de': {
      'mainTitle': "Entdecken Sie weitere Funktionen",
      'groupTitle': 'Warum ResiWebsite?',
      'enhanceText': "Verbessern Sie Ihre Website mit wertvollen Funktionen, die die Gästeerfahrung verbessern, Vertrauen aufbauen und Ihre Unterkunft hervorheben.", // ✅ Added
      'description': "Zeigen Sie Ihre verborgenen Schätze.",
      'text': 'Fügen Sie durch Ihre Erfahrung als Gastgeber durchdachte Empfehlungen für Essen, Aktivitäten, Museen und vieles mehr zu Ihrer Website hinzu.',
      'description2': 'Heben Sie Ihre Buchungsbewertungen von anderen Buchungsplattformen hervor.',
      'text2': "Zeigen Sie Ihren Gästen, dass Sie ein vertrauenswürdiger Gastgeber sind, indem Sie Ihre Bewertungen von anderen Buchungsplattformen hervorheben.",
      'description3': 'Mobilfreundlich.',
      'text3': "Ihre Website wird mobilfreundlich sein und auf allen Geräten großartig aussehen.",
    },
  };

  const languagePrefix = window.location.pathname.startsWith('/nl') ? '/nl' : window.location.pathname.startsWith('/de') ? '/de' : '/';

  return (
    <Box>
      <Container size="lg" py={100}>
        <Title order={2} className={classes.sectionTitle}>
          {content[languagePrefix].mainTitle}
        </Title>

        <Text mt="sm" mb="xl" className={classes.sectionDescription}>
          {content[languagePrefix].enhanceText}
        </Text>

        <SimpleGrid
          cols={3}
          spacing="lg"
          breakpoints={[
            { maxWidth: 1024, cols: 2 },
            { maxWidth: 768, cols: 1 },
          ]}
        >
          {/* Card 1 */}
          <Paper radius="md" shadow="sm" p="lg" className={classes.featureCard}>
            <Box className={classes.iconWrapper}>
              <IconDiamond size={20} color="white" />
            </Box>
            <Title order={5} mb="xs" className={classes.featureTitle}>
              {content[languagePrefix].description}
            </Title>
            <Text size="sm" className={classes.featureText}>
              {content[languagePrefix].text}
            </Text>
          </Paper>

          {/* Card 2 */}
          <Paper radius="md" shadow="sm" p="lg" className={classes.featureCard}>
            <Box className={classes.iconWrapper}>
              <IconMedal size={20} color="white" />
            </Box>
            <Title order={5} mb="xs" className={classes.featureTitle}>
              {content[languagePrefix].description2}
            </Title>
            <Text size="sm" className={classes.featureText}>
              {content[languagePrefix].text2}
            </Text>
          </Paper>

          {/* Card 3 */}
          <Paper radius="md" shadow="sm" p="lg" className={classes.featureCard}>
            <Box className={classes.iconWrapper}>
              <IconPhone size={20} color="white" />
            </Box>
            <Title order={5} mb="xs" className={classes.featureTitle}>
              {content[languagePrefix].description3}
            </Title>
            <Text size="sm" className={classes.featureText}>
              {content[languagePrefix].text3}
            </Text>
          </Paper>
        </SimpleGrid>
      </Container>

      {/* <Box align="right">
                        <Text component='a' href={imageButton[currentImage].link} order={1} weight={400} color="dimmed" mt="md">
                            {imageButton[currentImage].name}
                        </Text>
                    </Box> */}
    </Box >
  )
}

import React from 'react'

export default function About({ language }) {
    return (
        <div>
            About kdflmdff
            {language}
        </div>
    )
}

import React from 'react'
import image from '../../../assets/products/Colors.png';
import { createStyles, rem, Title, Box, Container, Text, Image, Paper, SimpleGrid } from '@mantine/core';

const useStyles = createStyles((theme) => ({
    title: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(24),
            maxWidth: '100%',
            display: 'block',
        },
        // maxwidth 2/3 of the screen
        maxWidth: '66%',
        margin: 'auto',
    },

    paperProduct: {
        [theme.fn.smallerThan('sm')]: {
            width: '100%',
            marginTop: rem(30),
        },
        alignItems: 'center',
        width: '80%',
    },

    description: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(16),
            maxWidth: '100%',
            display: 'block',
        },
        // maxwidth 2/3 of the screen
        maxWidth: '66%',
        margin: 'auto',
        fontWeight: 400,
    },

    control: {
        '&:hover': {
            border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.resirentPink[5] : theme.colors.resirentPink[8]}`,
            backgroundColor: theme.white,
            color: theme.colors.resirentPink[5],
        },

        '&:active': {
            variant: 'filled',
            color: 'resirentPink',
        },

        textAlign: 'center', // Center-align the contents
    },

    card: {
        border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.resirent[8]}`,
        '&:hover': {
            transform: 'scale(1.01)',
            boxShadow: theme.shadows.md,
        },
        textAlign: 'center', // Center-align the contents
    },
}));

export default function MainProductSecond({ language }) {

    const { classes } = useStyles();

    const content = {
        '/': {
            'groupTitle': 'SIMPLE LAYOUT EDITOR',
            'mainTitle': 'Discover the Perfect Authentic Look',
            'description': 'Customize colors and fonts to give your website an authentic and unique appearance.',
        },
        '/nl': {
            'groupTitle': 'EENVOUDIGE LAYOUT EDITOR',
            'mainTitle': 'Ontdek de Perfecte Authentieke Uitstraling',
            'description': 'Pas kleuren en lettertypen aan om je website een authentieke en unieke uitstraling te geven.',
        },
        '/de': {
            'groupTitle': 'EINFACHER LAYOUT EDITOR',
            'mainTitle': 'Entdecken Sie das perfekte, authentische Erscheinungsbild',
            'description': 'Passen Sie Farben und Schriftarten an, um Ihrer Website ein authentisches und einzigartiges Erscheinungsbild zu verleihen.',
        },
    };

    const languagePrefix = window.location.pathname.startsWith('/nl') ? '/nl' : window.location.pathname.startsWith('/de') ? '/de' : '/';

    return (
        <Box>
          <Container size="lg">
            <Paper
              radius="xl"
              shadow="xl"
              p="xl"
              style={{
                backgroundColor: '#003D51',
                minHeight: '420px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <SimpleGrid
                cols={2}
                spacing="xl"
                breakpoints={[{ maxWidth: 768, cols: 1 }]}
                style={{ width: '100%' }}
              >
                <Box
                  display="flex"
                  justify="center"
                  align="center"
                  style={{ padding: '1rem' }}
                >
                  <Paper
                    shadow="md"
                    radius="md"
                    p="xs"
                    withBorder
                    style={{
                      backgroundColor: 'white',
                      padding: '1rem',
                      borderRadius: '16px',
                    }}
                  >
                    <Image src={image} alt="Website Editing" radius="md" />
                  </Paper>
                </Box>
      
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    paddingLeft: '1rem',
                  }}
                >
                  <Text
                    fw={600}
                    size="sm"
                    mb={6}
                    style={{ color: '#D7FEDB' }}
                    tt="uppercase"
                  >
                    {content[languagePrefix].groupTitle}
                  </Text>
      
                  <Title
                    order={2}
                    my="sm"
                    c="white"
                    style={{
                      whiteSpace: 'pre-line',
                    }}
                  >
                    {content[languagePrefix].mainTitle.split(' ').slice(0, 3).join(' ') + '\n' + content[languagePrefix].mainTitle.split(' ').slice(3).join(' ')}
                  </Title>
      
                  <Text size="md" mt="md" style={{ color: '#E9D7FE' }} maw={400}>
                    {content[languagePrefix].description}
                  </Text>
                </Box>
              </SimpleGrid>
            </Paper>
          </Container>
        </Box>
      );      
}

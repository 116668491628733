import {
  createStyles,
  SimpleGrid,
  Card,
  Image,
  Text,
  Container,
  AspectRatio,
  Title,
  Button,
  Group,
  rem
} from "@mantine/core";
import { postsDE, postsEn, postsNL } from "../../languages/blogPages";
import { useEffect, useState } from "react";
import axios from "../../api/axios";
import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme) => ({
  card: {
    transition: "transform 150ms ease, box-shadow 150ms ease",
    width: "100%",
    "&:hover": {
      transform: "scale(1.01)",
      boxShadow: theme.shadows.md
    }
  },
  title: {
    textAlign: "center",
    fontSize: rem(28),
    fontWeight: 700,
    fontFamily: "Inter, sans-serif",
    color: "#111827",
    [theme.fn.smallerThan('sm')]: {
      fontSize: rem(20),
      textAlign: 'left',
    },
  },
  description: {
    textAlign: "left",
    color: theme.colors.gray[6],
    marginBottom: "2rem",
    [theme.fn.smallerThan('sm')]: {
      fontSize: rem(13),
    },
  },
  viewAllButton: {
    display: "flex",
    justifyContent: "flex-end",
  },
  container: {
    maxWidth: "1200px",
    margin: "0 auto"
  },
  blogDescription: {
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    [theme.fn.smallerThan('sm')]: {
      fontSize: rem(13),
    },
  },
}));

const translations = {
  '/': {
    "Our Blog": "Our Blog",
    "Latest blog posts": "Latest blog posts",
    "Tools and strategies modern teams need to help their companies grow.": "",
    "View all posts": "View all posts",
  },
  '/nl': {
    "Our Blog": "Onze blog",
    "Latest blog posts": "Laatste blogberichten",
    "Tools and strategies modern teams need to help their companies grow.": "Hulpmiddelen en strategieën die moderne teams nodig hebben om hun bedrijven te laten groeien.",
    "View all posts": "Bekijk alle berichten",
  },
  '/de': {
    "Our Blog": "Unser Blog",
    "Latest blog posts": "Neueste Blogbeiträge",
    "Tools and strategies modern teams need to help their companies grow.": "Werkzeuge und Strategien, die moderne Teams benötigen, um ihre Unternehmen zu vergrößern.",
    "View all posts": "Alle Beiträge anzeigen",
  },
};

export function BlogHomePage() {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const languagePrefix = window.location.pathname.startsWith('/nl') ? '/nl' : window.location.pathname.startsWith('/de') ? '/de' : '/';
  // const languagePrefix = window.location.pathname.startsWith("/nl")
  //   ? "/nl"
  //   : window.location.pathname.startsWith("/de")
  //   ? "/de"
  //   : "";
  // const posts =
  //   languagePrefix === "/nl"
  //     ? postsNL
  //     : languagePrefix === "/de"
  //     ? postsDE
  //     : postsEn;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "short",
      year: "numeric"
    });
  };

  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    axios
      .get("/blog")
      .then((res) => setBlogs(res.data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const filtered_blogs = blogs.filter((blog) =>
    languagePrefix === "/nl"
      ? blog.language === "NL"
      : languagePrefix === "/de"
        ? blog.language === "DE"
        : blog.language === "EN"
  );

  return (
    <Container className={classes.container} py={80}>
      <Text size="sm" color="#003D51" weight={700} textAlign="left">
        {translations[languagePrefix]["Our Blog"] || translations["/"]["Our Blog"]}
      </Text>
      <Group className={classes.viewAllButton}>
        <Button component="a" href="/blog" sx={{ backgroundColor: "#003D51", color: "white" }}>
          {translations[languagePrefix]["View all posts"] || translations["/"]["View all posts"]}
        </Button>
      </Group>
      <Title className={classes.title}>
        {translations[languagePrefix]["Latest blog posts"] || translations["/"]["Latest blog posts"]}
      </Title>
      <Text className={classes.description}>
      {translations[languagePrefix]["Tools and strategies modern teams need to help their companies grow."] || translations["/"]["Tools and strategies modern teams need to help their comapnies grow."]}
      </Text>
      <SimpleGrid
        cols={3}
        spacing="lg"
        breakpoints={[
          { maxWidth: "xs", cols: 1 },
          { maxWidth: "md", cols: 2 }
        ]}
      >
        {filtered_blogs.map((article) => (
          <Card
            key={article.title}
            p="md"
            radius="md"
            component="a"
            href={`${languagePrefix}/blog/${article.id}`}
            className={classes.card}
          >
            <AspectRatio ratio={16 / 9}>
              <Image src={article.imageUrl} alt={article.title} />
            </AspectRatio>
            <Text size="xs" mt="md" color="gray">
              {article.date && formatDate(article.date)}
            </Text>
            <Title order={4} mt={5} className={classes.title}>{article.title}</Title>
            <Text size="sm" color="dimmed" mt={5} className={classes.blogDescription}>
              {article.description}
            </Text>
          </Card>
        ))}
      </SimpleGrid>
    </Container>
  );
}

import React, { useState, useEffect } from 'react';
import {
    TextInput, NumberInput, Paper, Textarea, Select, createStyles, rem,
    Group, Title, SimpleGrid, Card, Button, MultiSelect, Container, Image,
    Box,
    Grid
} from '@mantine/core';
import axios from '../../../api/axios';
import { useParams } from 'react-router-dom';
import CalendarTouristView from './CalendarTouristView';
import accommodationImage from '../../../assets/team/Anja_profile.jpeg';
import hostImage from '../../../assets/hosts/zilverdauw.jpg';
import topImage from '../../../assets/hosts/zilverdauw.jpg';

const WEBSITE_URL = '/websitejson';

const useStyles = createStyles((theme) => ({
    title: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(24),
            maxWidth: '100%',
            display: 'block',
            padding: `${rem(8)} ${rem(16)}`,
        },
        maxWidth: '100%',
        margin: 'auto',
        paddingTop: rem(48),
        paddingBottom: rem(32),
        fontSize: rem(48), // Adjust the font size as needed
    },

    titleCard: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(24),
            maxWidth: '100%',
            display: 'block',
        },
        margin: 'auto',
    },

    description: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(16),
            maxWidth: '100%',
            textAlign: 'center',
            display: 'block',
        },
        maxWidth: '66%',
        margin: 'auto',
        fontWeight: 400,
    },

    descriptionCard: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(16),
            maxWidth: '100%',
            textAlign: 'center',
            display: 'block',
        },
        margin: 'auto',
        fontWeight: 400,
    },

    base: {
        transition: 'transform 150ms ease, box-shadow 150ms ease',
        '&:hover': {
            transform: 'scale(1.01)',
            boxShadow: theme.shadows.md,
        },
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.blue[2] : theme.colors.blue[2],
    },

    low: {
        transition: 'transform 150ms ease, box-shadow 150ms ease',
        '&:hover': {
            transform: 'scale(1.01)',
            boxShadow: theme.shadows.md,
        },
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.green[2] : theme.colors.green[2],
    },

    high: {
        transition: 'transform 150ms ease, box-shadow 150ms ease',
        '&:hover': {
            transform: 'scale(1.01)',
            boxShadow: theme.shadows.md,
        },
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.red[2] : theme.colors.red[2],
    },

    gray: {
        transition: 'transform 150ms ease, box-shadow 150ms ease',
        '&:hover': {
            transform: 'scale(1.01)',
            boxShadow: theme.shadows.md,
        },
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.gray[2] : theme.colors.gray[2],
    },

    control: {
        '&:hover': {
            color: theme.white,
            backgroundColor: theme.colors.resirentPink[5],
        },
        border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.resirentPink[5] : theme.colors.resirentPink[8]}`,
        backgroundColor: theme.white,
        color: theme.colors.resirentPink[5],
        textAlign: 'center',
    },

    fullWidthImage: {
        width: '100%',
        height: 'auto',
        marginBottom: theme.spacing.md,
    },

    controlSecond: {
        '&:hover': {
            border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.resirentPink[5] : theme.colors.resirentPink[8]}`,
            backgroundColor: theme.white,
            color: theme.colors.resirentPink[5],
        },
        color: theme.white,
        backgroundColor: theme.colors.resirentPink[5],
        textAlign: 'center',
    },
    paper: {
        padding: '16px', // default padding for larger screens
        [theme.fn.smallerThan('md')]: {
            width: '100%',
            marginTop: rem(30),
            padding: '1px', // smaller padding for smaller screens
        },
        alignItems: 'center',
    },
}));

const WebsiteCalendarTourist = () => {
    const { classes } = useStyles();
    const { url_website_host } = useParams();
    const username = url_website_host;
    const accommodationName = username.split('.').slice(0, -1).join('.');
    const [currentImageURL, setCurrentImageURL] = useState(null);
    const [currentHostImageURL, setCurrentHostImageURL] = useState(null);
    const [currentAccommodationImageURL, setCurrentAccommodationImageURL] = useState(null);
    const IMAGE_URL = '/image_url';

    const [formData, setFormData] = useState({
        pricing: {
            basePrice: {
                amount: 0,
                maxPeoplePrice: 0,
                extraCostPerPerson: 0,
                weekendExtraCost: 0,
            },
            highSeason: {
                amount: 0,
                maxPeoplePrice: 0,
                extraCostPerPerson: 0,
                weekendExtraCost: 0,
            },
            lowSeason: {
                amount: 0,
                maxPeoplePrice: 0,
                extraCostPerPerson: 0,
                weekendExtraCost: 0,
            },
            cleaningFee: {
                included: false,
                amount: 0,
            },
            cityTax: {
                included: false,
                amount: 0,
            },
            dogFee: {
                included: false,
                amount: 0,
            },
            breakfastFee: {
                included: false,
                amount: 0,
            },
            discountMonthly: {
                included: false,
                amount: 0,
            },
            discountWeek: {
                included: false,
                amount: 0,
            },
            parking: {
                included: false,
                amount: 0,
            }
        },
        minNights: 1,
        checkInDays: [{ value: 7, label: 'All' }],
        cityTaxAge: 0,
        welcomeSentence: '',
        cancellationPolicy: '',
        houseRules: '',
    });

    useEffect(() => {
        const title = "contact"
        const getContent = async () => {
            try {
                const response = await axios.get(`${IMAGE_URL}`, {
                    params: { username, title },
                }).catch((err) => {
                    if (err.response.status === 404) {
                        // setCurrentImageURL(notFound);
                    }
                }
                );
                if (response.data) {
                    setCurrentHostImageURL(response.data);
                }
            } catch (err) {
                console.error(err);
            }
        };
        getContent();
    }, [username]);

    useEffect(() => {
        const title = "accommodation"
        const getContent = async () => {
            try {
                const response = await axios.get(`${IMAGE_URL}`, {
                    params: { username, title },
                }).catch((err) => {
                    if (err.response.status === 404) {
                        // setCurrentImageURL(notFound);
                    }
                }
                );
                if (response.data) {
                    setCurrentAccommodationImageURL(response.data);
                }
            } catch (err) {
                console.error(err);
            }
        };
        getContent();
    }, [username]);

    useEffect(() => {
        const title = "home"
        const getContent = async () => {
            try {
                const response = await axios.get(`${IMAGE_URL}`, {
                    params: { username, title },
                }).catch((err) => {
                    if (err.response.status === 404) {
                        // setCurrentImageURL(notFound);
                    }
                }
                );
                if (response.data) {
                    setCurrentImageURL(response.data);
                }
            } catch (err) {
                console.error(err);
            }
        };
        getContent();
    }, [username]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${WEBSITE_URL}`, {
                    params: { username, art: 'en', title: 'calendar-settings' },
                });
                setFormData(JSON.parse(response.data.entry.content));
            } catch (err) {
                console.log(err);
            }
        };
        fetchData();
    }, [username]);

    return (
        <Box style={{ maxWidth: '100%', alignItems: "center" }}>

            {/* <Image src={topImage} alt="Top banner" className={classes.fullWidthImage} /> */}

            <Group>
                <Title order={1} className={classes.title} my="sm" display="block">
                    Calendar
                    <span style={{ color: '#F06595'}}>  {accommodationName} </span> 
                </Title>
            </Group>

            <Box display={{ base: 'none', sm: 'block' }}>
                <Grid columns={12} p="md">
                    <Grid.Col span={4}>
                        <Image src={currentImageURL} alt="Host" radius="xl" mx="auto" mb="md" />
                        <Image src={currentAccommodationImageURL} alt="Accommodation" radius="xl" mx="auto" mb="md" />
                        <Image src={currentHostImageURL} alt="Host" radius="xl" mx="auto" mb="md" />
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <Paper
                            className={classes.paper}
                            sx={(theme) => ({
                                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
                            })}
                        >
                            <Box style={{ maxWidth: '100%' }}>
                                {CalendarTouristView(formData)}
                            </Box>
                        </Paper>
                    </Grid.Col>
                </Grid>
            </Box>

            {/* Layout for medium devices (single image at the top and calendar view underneath) */}
            <Box display={{ base: 'block', sm: 'none' }}>
                <Image src={currentImageURL} alt="Host" className={classes.fullWidthImage} />
                <Paper
                    className={classes.paper}
                    sx={(theme) => ({
                        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
                    })}
                >
                    <Box style={{ maxWidth: '100%' }}>
                        {CalendarTouristView(formData)}
                    </Box>
                </Paper>
            </Box>

        </Box>
    );
};

export default WebsiteCalendarTourist;

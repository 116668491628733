import React, { useEffect, useState } from 'react';
import { Container, Paper, Stepper, Text, Button, Box, SimpleGrid, Group, rem, TextInput, Textarea, Title, Center, Checkbox, Image } from '@mantine/core';
import { createStyles } from '@mantine/core';
import axios from '../../../api/axios';
import { CalendarMonthViewTourist } from './CalendarMonthViewTourist';
import { DatePickerInput } from '@mantine/dates';
import BookingSummary from './BookingSummary';
import { useParams } from 'react-router-dom';
import { IconHome } from '@tabler/icons-react';
import accommodationImage from '../../../assets/team/Anja_profile.jpeg';
import hostImage from '../../../assets/hosts/zilverdauw.jpg';

const BOOKING_URL = '/booking';
const USER_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const useStyles = createStyles((theme) => ({
    datepicker: {
        mt: "md"
    },
    paper: {
        m: "md",
        radius: "md",
        align: "center"
    },
    box: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        padding: theme.spacing.xs,
        borderRadius: theme.radius.md,
        cursor: 'pointer',
        align: "center",
        '&:hover': {
            backgroundColor:
                theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1],
        },
    },
    input: {
        backgroundColor: theme.white,
        borderColor: theme.colors.gray[4],
        color: theme.black,
        '&::placeholder': {
            color: theme.colors.gray[5],
        },
    },
    inputLabel: {
        color: theme.black,
    },
    control: {
        backgroundColor: theme.colorScheme.resirentPink,
    },
    controlSecond: {
        '&:hover': {
            border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.resirentPink[5] : theme.colors.resirentPink[8]}`,
            backgroundColor: theme.white,
            color: theme.colors.resirentPink[5],
        },
        color: theme.white,
        backgroundColor: theme.colors.resirentPink[5],
        textAlign: 'center', // Center-align the contents
    },
    control: {
        '&:hover': {
            border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.resirentPink[5] : theme.colors.resirentPink[8]}`,
            backgroundColor: theme.white,
            color: theme.colors.resirentPink[5],
        },
        color: theme.white,
        backgroundColor: theme.colors.resirentPink[5],
        textAlign: 'center', // Center-align the contents
    },
}));

const CalendarTouristView = (inputForm) => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const { classes } = useStyles();
    const [selectedRange, setSelectedRange] = useState({ start: null, end: null });
    const [dateStates, setDateStates] = useState('');
    const { url_website_host } = useParams();
    const username = url_website_host;
    const WEBSITE_URL = '/websitejson';
    const [saved, setSaved] = useState(false);
    const [isBookable, setIsBookable] = useState(false);
    const [checkInWarning, setCheckInWarning] = useState(false);
    const [dateWarning, setDateWarning] = useState(false);
    const [minNightsWarning, setMinNightsWarning] = useState(false);
    const [active, setActive] = useState(0);
    const [summaryData, setSummaryData] = useState(null);
    const nextStep = () => setActive((current) => (current < 3 ? current + 1 : current));
    const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));
    const [error, setError] = useState(null);

    const art = "en";
    const title = "calendar-bookings";
    const [errMsg, setErrMsg] = useState('');

    const [bookingDetailsForm, setBookingDetailsForm] = useState({
        name: '',
        email: '',
        street: '',
        number: '',
        postalCode: '',
        city: '',
        phone: '',
        country: '',
        numberOfGuests: 0,
        numberOfDogs: 0,
        breakfast: false,
        underCityTaxAge: 0,
        message: '',
    });

    const canBeBooked = (dateStates, selectedRange) => {
        let canBeBooked = true;
        let currentDate = new Date(selectedRange.start);
        let numberOfNightsCounter = 0;

        // Check if the start date is a valid check-in day
        let currentDay = currentDate.getDay();
        if (!inputForm.checkInDays.includes(currentDay)) {
            setCheckInWarning(true);
            return false;
        }

        const endDate = new Date(selectedRange.end);

        // Loop until the currentDate reaches the end date and count the number of nights
        while (currentDate <= endDate) {
            const dateKey = currentDate.toISOString().slice(0, 10);
            numberOfNightsCounter++;

            for (const rangeKey in dateStates) {
                const range = dateStates[rangeKey];
                const rangeStart = new Date(range.start);
                const rangeEnd = new Date(range.end);

                if (
                    (currentDate > rangeStart && currentDate < rangeEnd) ||
                    (currentDate.toDateString() === rangeEnd.toDateString() && currentDate.toDateString() === rangeStart.toDateString() && range.booked === 'booked')
                ) {
                    if (range.booked === 'booked' || range.booked === 'inProcess') {
                        setDateWarning(true);
                        return false;
                    }
                }

                if (currentDate >= rangeStart && currentDate <= rangeEnd) {
                    if (range.booked === 'closed') {
                        setDateWarning(true);
                        return false;
                    }
                }
            }

            currentDate.setDate(currentDate.getDate() + 1);
        }

        // Check if the number of nights is at least the minimum required
        if (numberOfNightsCounter <= inputForm.minNights) {
            setMinNightsWarning(true);
            return false;
        }

        return canBeBooked;
    };

    useEffect(() => {
        setCheckInWarning(false);
        setDateWarning(false);
        setMinNightsWarning(false);
        if (selectedRange.end && selectedRange.start) {
            setIsBookable(canBeBooked(dateStates, selectedRange));
        }
    }, [selectedRange]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${WEBSITE_URL}`, {
                    params: { username, art, title },
                });
                setDateStates(JSON.parse(response.data.entry.content));
            } catch (err) {
                console.log(err);
            }
        };
        fetchData();
    }, [username, art, title]);

    const handleSelectEndDateInput = (date) => {

        if (date === null) {
            return;
        }

        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDate();

        const dateKey = `${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;

        if (date !== null) {
            setSelectedRange({ start: selectedRange.start, end: dateKey });
        } else {
            setSelectedRange({ start: selectedRange.start, end: null });
        }
    };

    const handleSelectStartDateInput = (date) => {
        if (date === null) {
            return;
        }

        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDate();

        const dateKey = `${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;

        if (date !== null) {
            setSelectedRange({ start: dateKey, end: selectedRange.end });
            setCurrentDate(new Date(date.getTime()));
        } else {
            setSelectedRange({ start: null, end: selectedRange.end });
        }
    };

    const handleSummaryCalculated = (data) => {
        setSummaryData(data);
    };

    const isFormFilled = () => {
        return (
            bookingDetailsForm.name.trim() !== '' &&
            bookingDetailsForm.email.trim() !== '' &&
            bookingDetailsForm.numberOfGuests.toString().trim() !== '' &&
            bookingDetailsForm.message.trim() !== ''
        );
    };

    const handleFinalRequest = () => {
        const newDateStates = { ...dateStates };
        const startDate = new Date(selectedRange.start);
        const endDate = new Date(selectedRange.end);

        const newRangeKey = `${selectedRange.start}-${selectedRange.end}`;
        newDateStates[newRangeKey] = {
            start: selectedRange.start,
            end: selectedRange.end,
            booked: 'inProcess',
            label: bookingDetailsForm.name,
            formDate: bookingDetailsForm,
        };

        saveFormData(newDateStates);
    };

    const sendMail = () => {

        const handleSubmit = async () => {
            const v1 = USER_REGEX.test(bookingDetailsForm.email);
            const user = bookingDetailsForm.email;
            const startDate = selectedRange.start;
            const endDate = selectedRange.end;

            if (!v1) {
                setErrMsg("Invalid email address");
                return;
            }

            const response = await axios.post(BOOKING_URL, JSON.stringify({ user, bookingDetailsForm, username, startDate, endDate, summaryData }), {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true,
            });
        };
        handleSubmit();
    };

    const saveFormData = (dateStates) => {
        const update = async () => {
            try {
                await axios.put(WEBSITE_URL, {
                    username,
                    art,
                    title,
                    content: dateStates,
                });
                setSaved(true);
                setTimeout(() => {
                    setSaved(false);
                }, 5000);
            } catch (err) {
                console.log(err);
            }
        };
        update();
        sendMail();
        nextStep();
    };

    const handlePrevMonth = () => {
        const newDate = new Date(currentDate);
        newDate.setMonth(newDate.getMonth() - 1);
        setCurrentDate(newDate);
    };

    const handleNextMonth = () => {
        const newDate = new Date(currentDate);
        newDate.setMonth(newDate.getMonth() + 1);
        setCurrentDate(newDate);
    };

    const handleInputChange = (e, fieldName) => {
        const { value } = e.target;
        setBookingDetailsForm(prevState => ({
            ...prevState,
            [fieldName]: value,
        }));
    };

    return (
        <>

            <Stepper color="resirentPink" active={active} onStepClick={setActive}>
                <Stepper.Step label="First step" description="Pick your dates">
                    <Container size="xl" m="xl" mt="xl">
                        {/* <Title order={2} align="center">1. Select your dates and make a non-binding request</Title> */}

                        <Box itemsAlign="center">

                            <SimpleGrid cols={2} spacing="md" verticalSpacing="md" mt="md">
                                <DatePickerInput
                                    className={classes.datepicker}
                                    clearable
                                    label="Check-in: "
                                    value={selectedRange.start ? new Date(selectedRange.start) : null}
                                    placeholder="Pick date"
                                    description="pick days first, then edit booking details"
                                    onChange={(e) => handleSelectStartDateInput(e)}
                                    maw={400}
                                />
                                <DatePickerInput
                                    className={classes.datepicker}
                                    clearable
                                    label="Check-out: "
                                    value={selectedRange.end ? new Date(selectedRange.end) : null}
                                    placeholder="Pick date"
                                    description="pick days first, then edit booking details"
                                    onChange={(e) => handleSelectEndDateInput(e)}
                                    maw={400}
                                />
                            </SimpleGrid>
                        </Box>

                        <Box p="md" mt="md">
                            <Center>
                                {checkInWarning && <Text color="red">Check-in not possible on selected day</Text>}
                                {dateWarning && <Text color="red">Date already booked</Text>}
                                {minNightsWarning && <Text color="red">Minimum nights not reached! Minimum nights are {inputForm.minNights}</Text>}
                            </Center>
                        </Box>
                        <Group position="center" mt="xl">
                            <Button color="resirentPink" variant="white" radius="lg" className={classes.control} onClick={nextStep} disabled={!isBookable}>
                                Select Dates
                            </Button>
                        </Group>
                    </Container>
                    <Paper
                        className={classes.paper}
                        m="md"
                        sx={(theme) => ({
                            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[2],
                        })}
                    >
                        <SimpleGrid cols={1} spacing="md" verticalSpacing="md" breakpoints={[{ maxWidth: 'xs', cols: 1 }, { maxWidth: 'md', cols: 1 }]}>
                            <Container size="xl" m="md" mt="xl">
                                <SimpleGrid cols={3} spacing="md" verticalSpacing="md">
                                    <Button variant="outline" color="resirent" onClick={handlePrevMonth}>
                                        Previous
                                    </Button>
                                    <Text size="md" weight={500} align="center">
                                        {currentDate.toLocaleString('default', { month: 'long' })} {currentDate.getFullYear()}
                                    </Text>
                                    <Button variant="outline" color="resirent" onClick={handleNextMonth}>
                                        Next
                                    </Button>
                                </SimpleGrid>
                                <SimpleGrid cols={7} spacing="xxs" verticalSpacing="xs" mt="xs">
                                    {CalendarMonthViewTourist(inputForm, currentDate, dateStates, selectedRange, setSelectedRange, 'Booked')}
                                </SimpleGrid>
                                <Box>
                                    Check-in days are: {inputForm.checkInDays.map((day) => new Date(0, 0, day).toLocaleString('default', { weekday: 'long' })).join(', ')} shown with the icon:
                                    <IconHome size={16} color="green" className={classes.iconRight} />.
                                </Box>
                                Blue days are available for booking, red days are already booked.
                            </Container>
                        </SimpleGrid>
                    </Paper>
                </Stepper.Step>

                <Stepper.Step label="Second step" description="Enter your contact details">
                    <Container size="xl" m="md" mt="xl">
                        {/* <Title order={2} align="center">2. Enter your contact details</Title> */}

                        <SimpleGrid breakpoints={[
                            { maxWidth: 700, cols: 1 },
                        ]} spacing="md" verticalSpacing="md" mt="md">
                            <TextInput
                                label="Name"
                                placeholder="Enter your name"
                                required
                                value={bookingDetailsForm.name}
                                onChange={(e) => handleInputChange(e, 'name')}
                                error={!bookingDetailsForm.name.trim() ? 'Name is required' : null}
                            />
                            <TextInput
                                label="Email"
                                placeholder="Enter your email"
                                required
                                value={bookingDetailsForm.email}
                                onChange={(e) => handleInputChange(e, 'email')}
                                error={!USER_REGEX.test(bookingDetailsForm.email) ? 'Invalid email address' : null}
                            />
                            <TextInput
                                label="Number of guests"
                                placeholder={`Enter number of guests (max ${inputForm.maxGuest})`}
                                type="number"
                                required
                                value={bookingDetailsForm.numberOfGuests}
                                onChange={(e) => handleInputChange(e, 'numberOfGuests')}
                                error={bookingDetailsForm.numberOfGuests > inputForm.maxGuest ? `Number of guests cannot exceed ${inputForm.maxGuest}` : (!bookingDetailsForm.numberOfGuests ? 'Number of guests is required' : null)}
                            />
                            <TextInput
                                label={`Number of guests above the age of ${inputForm.cityTaxAge} (city tax)`}
                                placeholder={`Enter number above the age of ${inputForm.cityTaxAge}`}
                                type="number"
                                required
                                value={bookingDetailsForm.underCityTaxAge}
                                onChange={(e) => {
                                    const value = parseInt(e.target.value, 10);

                                    // Validation logic
                                    if (isNaN(value) || value < 0 || value > bookingDetailsForm.numberOfGuests) {
                                        setError(`Please enter a number between 0 and ${bookingDetailsForm.numberOfGuests}`);
                                    } else {
                                        setError(null);
                                    }

                                    // Update form state
                                    setBookingDetailsForm({
                                        ...bookingDetailsForm,
                                        underCityTaxAge: e.target.value,
                                    });
                                }}
                                error={error || (!bookingDetailsForm.underCityTaxAge && 'Number of guests above city tax age is required')}
                            />
                            <TextInput
                                label="Street"
                                placeholder="Enter your street"
                                required
                                value={bookingDetailsForm.street}
                                onChange={(e) => handleInputChange(e, 'street')}
                                error={!bookingDetailsForm.street.trim() ? 'Street is required' : null}
                            />
                            <TextInput
                                label="House Number"
                                placeholder="Enter your house number"
                                required
                                value={bookingDetailsForm.number}
                                onChange={(e) => handleInputChange(e, 'number')}
                                error={!bookingDetailsForm.number.trim() ? 'Number is required' : null}
                            />
                            <TextInput
                                label="Postal Code"
                                placeholder="Enter your postal code"
                                required
                                value={bookingDetailsForm.postalCode}
                                onChange={(e) => handleInputChange(e, 'postalCode')}
                                error={!bookingDetailsForm.postalCode.trim() ? 'Postal code is required' : null}
                            />
                            <TextInput
                                label="City"
                                placeholder="Enter your city"
                                required
                                value={bookingDetailsForm.city}
                                onChange={(e) => handleInputChange(e, 'city')}
                                error={!bookingDetailsForm.city.trim() ? 'City is required' : null}
                            />
                            <TextInput
                                label="Country"
                                placeholder="Enter your country"
                                required
                                value={bookingDetailsForm.country}
                                onChange={(e) => handleInputChange(e, 'country')}
                                error={!bookingDetailsForm.country.trim() ? 'Country is required' : null}
                            />
                            <TextInput
                                label="Phone"
                                placeholder="Enter your phone number"
                                required
                                type="tel"
                                value={bookingDetailsForm.phone}
                                onChange={(e) => handleInputChange(e, 'phone')}
                                error={!bookingDetailsForm.phone.trim() ? 'Phone number is required' : null}
                            />
                            {inputForm.pricing.dogFee.included ? (
                                <TextInput
                                    label="Number of dogs"
                                    placeholder="Enter number of dogs"
                                    type="number"
                                    required
                                    value={bookingDetailsForm.numberOfDogs}
                                    onChange={(e) => handleInputChange(e, 'numberOfDogs')}
                                    error={!bookingDetailsForm.numberOfDogs && bookingDetailsForm.numberOfDogs !== 0 ? 'Number of dogs is required' : null}
                                />
                            ) : (
                                <TextInput
                                    label="No dogs allowed"
                                    placeholder="No dogs allowed"
                                    type="number"
                                    required
                                    disabled
                                    value={bookingDetailsForm.numberOfDogs}
                                    onChange={(e) => handleInputChange(e, 'numberOfDogs')}
                                />
                            )}
                            {inputForm.pricing.breakfastFee.included ? (

                                <Checkbox
                                    label="Breakfast"
                                    placeholder="Do you want a breakfast?"
                                    type="checkbox"
                                    required
                                    checked={bookingDetailsForm.breakfast}
                                    onChange={(e) => {
                                        const { checked } = e.target;
                                        setBookingDetailsForm({
                                            ...bookingDetailsForm,
                                            breakfast: checked,
                                        });
                                    }}
                                />
                            ) : (
                                <TextInput
                                    label="No breakfast"
                                    placeholder="No breakfast"
                                    type="checkbox"
                                    required
                                    disabled
                                    value={bookingDetailsForm.breakfast}
                                    onChange={(e) => handleInputChange(e, 'breakfast')}
                                />
                            )}
                            <Textarea
                                label="Message"
                                placeholder="Enter your message"
                                required
                                value={bookingDetailsForm.message}
                                onChange={(e) => handleInputChange(e, 'message')}
                            />
                        </SimpleGrid>

                        <Group position="center" mt="xl">
                            <Button variant="default" onClick={prevStep}>Back</Button>
                            <Button
                                onClick={nextStep}
                                disabled={
                                    !bookingDetailsForm.name.trim() ||
                                    !USER_REGEX.test(bookingDetailsForm.email) ||
                                    !bookingDetailsForm.numberOfGuests ||
                                    bookingDetailsForm.numberOfGuests > inputForm.numGuest ||
                                    !bookingDetailsForm.underCityTaxAge ||
                                    !bookingDetailsForm.street.trim() ||
                                    !bookingDetailsForm.number ||
                                    !bookingDetailsForm.postalCode.trim() ||
                                    !bookingDetailsForm.country.trim() ||
                                    (!inputForm.pricing.dogFee.included && bookingDetailsForm.numberOfDogs === undefined) ||
                                    !bookingDetailsForm.message.trim()
                                }
                                color="resirentPink" variant="white" radius="lg" className={classes.control}
                            >
                                Next step
                            </Button>
                        </Group>
                    </Container>
                </Stepper.Step>



                <Stepper.Step label="Review booking request" description="Request booking">
                    <Container size="xl" m="md" mt="xl">
                        {/* <Title order={2}>3. Third step</Title> */}
                        <BookingSummary
                            selectedRangeStart={selectedRange.start}
                            selectedRangeEnd={selectedRange.end}
                            inputForm={inputForm}
                            numPeople={bookingDetailsForm.numberOfGuests}
                            numDogs={bookingDetailsForm.numberOfDogs}
                            hasBreakfast={bookingDetailsForm.breakfast}
                            dateStates={dateStates}
                            numUnderage={bookingDetailsForm.underCityTaxAge}
                            message={bookingDetailsForm.message}
                            onSummaryCalculated={handleSummaryCalculated} // Pass the callback
                        />
                    </Container>
                    <Group justify="center" mt="xl">
                        <Button variant="default" onClick={prevStep}>Back</Button>
                        <Button onClick={handleFinalRequest}>Request booking</Button>
                    </Group>
                </Stepper.Step>
                <Stepper.Completed>
                    <Container size="xl" m="xl" mt="xl" style={{ textAlign: 'center' }}>
                        <Title order={2} style={{ marginBottom: '20px' }}>4. Booking Request Sent</Title>
                        <Text size="lg" style={{ marginBottom: '20px' }}>
                            Your booking request has been sent. You will receive an email confirmation shortly and the host will get back to you as soon as possible.
                        </Text>
                        <Button
                            component='a'
                            href={`https://${url_website_host}`}
                            color="resirentPink"
                            variant="white"
                            radius="lg"
                            size="md"
                            className={classes.control}
                            style={{ marginTop: '20px' }}
                        >
                            Go back to the website of the host
                        </Button>
                    </Container>
                </Stepper.Completed>

            </Stepper>
        </>
    );
};

export default CalendarTouristView;

import { createStyles, Accordion, Group, rem, Container, Title, Box } from '@mantine/core';

const useStyles = createStyles((theme) => ({
    wrapper: {
        paddingTop: `calc(${theme.spacing.xl} * 2)`,
        paddingBottom: `calc(${theme.spacing.xl} * 2)`,
    },

    title: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(24),
            maxWidth: '100%',
        },
        maxWidth: '66%',
    },

    item: {
        fontSize: theme.fontSizes.sm,
        backgroundColor: theme.colorScheme === 'dark' ? "#FFF5F9" : "#FFF5F9",
    },
}));

const placeholder =
    'It can’t help but hear a pin drop from over half a mile away, so it lives deep in the mountains where there aren’t many people or Pokémon.';

const mockdata = [
    {
        question: {
            '/': 'Why does ResiRent use a subscription model?',
            '/nl': 'Waarom hanteert ResiRent een abonnementen formule?',
            '/de': 'Warum verwendet ResiRent ein Abonnementmodell?',
        },
        answer: {
            '/': 'ResiRent provides service throughout the year to address issues, takes care of website hosting, and continuously works on improvements based on customer feedback.',
            '/nl': 'ResiRent biedt een service gedurende het jaar bij problemen, neemt de hosting van de website voor haar rekening en werkt continu aan verbeteringen op basis van feedback van de klanten.',
            '/de': 'ResiRent bietet das ganze Jahr über Service zur Lösung von Problemen, kümmert sich um das Hosting der Website und arbeitet kontinuierlich an Verbesserungen auf der Grundlage des Kundenfeedbacks.',
        },
    },
    {
        question: {
            '/': 'Why is there a cost when I choose the DIY package?',
            '/nl': 'Waarom wordt er een kost in rekening gebracht als ik de doe het zelf formule kies?',
            '/de': 'Warum entstehen Kosten, wenn ich das DIY-Paket wähle?',
        },
        answer: {
            '/': 'Our customers are property owners or operators with up to 2 accommodations in one building. We are here for small hosts.',
            '/nl': 'Dit dekt enerzijds de kost voor het maken van de on-line tutorials en de handleiding die jou het mogelijk maken om zonder problemen je website te creëren en is anderzijds een vergoeding voor de hulplijn.',
            '/de': 'Our customers are property owners or operators with up to 2 accommodations in one building. We are here for small hosts.',
        },
    },
    {
        question: {
            '/': 'Who owns the domain name?',
            '/nl': 'Wie is eigenaar van de domeinnaam?',
            '/de': 'Wer ist Eigentümer des Domainnamens?',
        },
        answer: {
            '/': 'You own the domain name, as the owner and/or operator of the accommodation.',
            '/nl': 'Dat ben jij, eigenaar en/of uitbater van de accommodatie.',
            '/de': 'Das sind Sie, als Eigentümer und/oder Betreiber der Unterkunft.',
        },
    },
    {
        question: {
            '/': 'Subscription Cancellation Notice?',
            '/nl': 'Opzegtermijn Abonnement?',
            '/de': 'Kündigungsfrist Abonnement?',
        },
        answer: {
            '/': 'The subscription is cancelable annually. We will send you a request to renew one month before the expiration date. If you cancel the subscription, you will lose access to the website builder. You will retain your own domain name and will be responsible for its management and payment.',
            '/nl': 'Het Abonnement is jaarlijks opzegbaar. Wij sturen je 1 maand voor de vervaldatum de vraag of u nog verder geabonneerd wenst te blijven. Als je het abonnement opzegt, heb je geen toegang meer tot de websitebouwer. Je behoudt je eigen domeinnaam en zal dan voor het beheer en de betaling zelf instaan.',
            '/de': 'Das Abonnement ist jährlich kündbar. Wir senden Ihnen einen Monat vor dem Ablaufdatum eine Anfrage zur Verlängerung. Wenn Sie das Abonnement kündigen, verlieren Sie den Zugriff auf den Website-Builder. Sie behalten Ihren eigenen Domainnamen und sind für dessen Verwaltung und Zahlung verantwortlich.',
        },
    },
];


export function FAQ() {
    const languagePrefix = window.location.pathname.startsWith('/nl') ? '/nl' : window.location.pathname.startsWith('/de') ? '/de' : '/';
    const { classes } = useStyles();
    return (
        <div className={classes.wrapper}>
            <Container size="lg">
                <Group position="center">
                    <Title mb="md" order={2} className={classes.title} ta="center">
                        Frequently Asked Questions
                    </Title>
                </Group>

                <Box m="md">
                    <Accordion defaultValue="" chevronPosition="right" variant="separated">
                        {mockdata.map((feature) => (
                            <Accordion.Item key={feature.question[languagePrefix]} value={feature.question[languagePrefix]} className={classes.item}>
                                <Accordion.Control> {feature.question[languagePrefix]}</Accordion.Control>
                                <Accordion.Panel>{feature.answer[languagePrefix]}</Accordion.Panel>
                            </Accordion.Item>
                        ))}
                    </Accordion>

                </Box>
            </Container>
        </div>
    );
}
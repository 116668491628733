import {
  createStyles,
  Text,
  Title,
  TextInput,
  Button,
  Select,
  Checkbox,
  rem,
  Group,
} from '@mantine/core';
import { useState } from 'react';
import image from '../../assets/homepage/contact-anja.jpeg';
import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `calc(${theme.spacing.xl} * 2)`,
    backgroundColor: 'white',
    fontFamily: 'Inter, sans-serif',
    color: 'black',
    textAlign: 'center',
    [theme.fn.smallerThan('sm')]: {
      padding: theme.spacing.xl,
    },
  },

  header: {
    maxWidth: '600px',
    textAlign: 'center',
    [theme.fn.smallerThan('sm')]: {
      fontSize: rem(14),
    },
  },

  formWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginTop: rem(20),
    width: '100%',
    maxWidth: rem(900),
    [theme.fn.smallerThan('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },

  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: rem(12),
    width: '48%',
    [theme.fn.smallerThan('sm')]: {
      width: '100%',
      alignItems: 'center',
    },
  },

  imageContainer: {
    width: '48%',
    [theme.fn.smallerThan('sm')]: {
      width: '100%',
      marginTop: rem(20),
      display: 'flex',
      justifyContent: 'center',
    },
  },

  image: {
    height: '350px',
    width: '60%',
    borderRadius: rem(8),
    [theme.fn.smallerThan('sm')]: {
      width: '80%',
      height: 'auto',
    },
  },

  inputGroup: {
    display: 'flex',
    gap: rem(10),
    width: '100%',
  },

  input: {
    width: '100%',
  },

  buttonGroup: {
    display: 'flex',
    gap: rem(10),
    marginTop: rem(10),
    [theme.fn.smallerThan('sm')]: {
      flexDirection: 'row',
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
  },

  buttonPrimary: {
    backgroundColor: '#004851',
    color: 'white',
    borderRadius: rem(4),
    fontWeight: 600,
    cursor: 'pointer',
    border: 'none',
    padding: `${rem(10)} ${rem(20)}`,
    '&:hover': {
      backgroundColor: '#00343a',
    },
    [theme.fn.smallerThan('sm')]: {
      padding: `${rem(8)} ${rem(14)}`,
      fontSize: rem(14),
    },
  },

  buttonSecondary: {
    backgroundColor: 'white',
    color: 'black',
    borderRadius: rem(4),
    fontWeight: 600,
    cursor: 'pointer',
    border: `1px solid #ccc`,
    padding: `${rem(10)} ${rem(20)}`,
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
    [theme.fn.smallerThan('sm')]: {
      padding: `${rem(8)} ${rem(14)}`,
      fontSize: rem(14),
    },
  },

  activeButton: {
    backgroundColor: '#004851 !important',
    color: 'white !important',
    border: 'none !important',
  },

  checkbox: {
    display: 'flex',
    alignItems: 'center',
  },

  privacyLink: {
    color: '#004851',
    textDecoration: 'none',

    '&:hover': {
      textDecoration: 'underline',
    },
  },

  iframeWrapper: {
    width: '100%',
    marginTop: rem(30),
    maxWidth: rem(900),
  },
}));

const translations = {
  '/': {
    "Contact Us": "Contact Us",
    "We’d love to hear from you": "We’d love to hear from you",
    "We’d love to hear from you! Whether you have a question, feedback, or a business inquiry, reach out to us, and we’ll get back to you as soon as possible. Let’s stay connected!": "We’d love to hear from you! Whether you have a question, feedback, or a business inquiry, reach out to us, and we’ll get back to you as soon as possible. Let’s stay connected!",
    "Send Us a Message": "Send Us a Message",
    "Book a meeting": "Book a meeting",
    "First name": "First name",
    "Last name": "Last name",
    "you@company.com": "you@company.com",
    "Country": "Country",
    "Phone number": "Phone number",
    "Message": "Message",
    "I agree to our friendly": "I agree to our friendly",
    "privacy policy": "privacy ploicy",
    "Send message": "Send message",
  },
  '/nl': {
    "Contact Us": "Neem contact met ons op",
    "We’d love to hear from you": "We horen graag van je",
    "We’d love to hear from you! Whether you have a question, feedback, or a business inquiry, reach out to us, and we’ll get back to you as soon as possible. Let’s stay connected!": "We horen graag van je! Of je nu een vraag, feedback of een zakelijke aanvraag hebt, neem contact met ons op en we zullen zo snel mogelijk reageren. Laten we verbonden blijven!",
    "Send Us a Message": "Stuur ons een bericht",
    "Book a meeting": "Boek een vergadering",
    "First name": "Voornaam",
    "Last name": "Achternaam",
    "you@company.com": "jij@bedrijf.com",
    "Country": "Land",
    "Phone number": "Telefoonnummer",
    "Message": "Bericht",
    "I agree to our friendly": "Ik ga akkoord met ons vriendelijke",
    "privacy policy": "privacybeleid",
    "Send message": "Bericht verzenden",
  },
  '/de': {
    "Contact Us": "Kontaktieren Sie uns",
    "We’d love to hear from you": "Wir würden gerne von Ihnen hören",
    "We’d love to hear from you! Whether you have a question, feedback, or a business inquiry, reach out to us, and we’ll get back to you as soon as possible. Let’s stay connected!": "Wir würden gerne von Ihnen hören! Ob Sie eine Frage, Feedback oder eine geschäftliche Anfrage haben, kontaktieren Sie uns, und wir werden so schnell wie möglich antworten. Lassen Sie uns in Verbindung bleiben!",
    "Send Us a Message": "Senden Sie uns eine Nachricht",
    "Book a meeting": "Buchen Sie ein Meeting",
    "First name": "Vorname",
    "Last name": "Nachname",
    "you@company.com": "Sie@firma.com",
    "Country": "Land",
    "Phone number": "Telefonnummer",
    "Message": "Nachricht",
    "I agree to our friendly": "Ich stimme unserem freundlichen",
    "privacy policy": "Datenschutzerklärung",
    "Send message": "Nachricht senden",
  }
};

export function ContactUs() {
  const { classes } = useStyles();
  const [checked, setChecked] = useState(false);
  const [showForm, setShowForm] = useState(true); // true = form, false = iframe
  const { t } = useTranslation();
  const languagePrefix = window.location.pathname.startsWith('/nl') ? '/nl' : window.location.pathname.startsWith('/de') ? '/de' : '/';

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <Text size="sm" color="#003D51">
          {translations[languagePrefix]["Contact Us"] || translations["/"]["Contact Us"]}
        </Text>
        <Title order={2}>
          {translations[languagePrefix]["We’d love to hear from you"] || translations["/"]["We’d love to hear from you"]}
        </Title>
        <Text>
          {translations[languagePrefix]["We’d love to hear from you! Whether you have a question, feedback, or a business inquiry, reach out to us, and we’ll get back to you as soon as possible. Let’s stay connected!"] || translations["/"]["We’d love to hear from you! Whether you have a question, feedback, or a business inquiry, reach out to us, and we’ll get back to you as soon as possible. Let’s stay connected!"]}
        </Text>
      </div>

      <Group className={classes.buttonGroup}>
        <Button
          className={`${classes.buttonSecondary} ${showForm ? classes.activeButton : ''
            }`}
          onClick={() => setShowForm(true)}
        >
          {translations[languagePrefix]["Send Us a Message"] || translations["/"]["Send Us a Message"]}
        </Button>

        <Button
          className={`${classes.buttonSecondary} ${!showForm ? classes.activeButton : ''
            }`}
          onClick={() => setShowForm(false)}
        >
          {translations[languagePrefix]["Book a meeting"] || translations["/"]["Book a meeting"]}
        </Button>
      </Group>

      {showForm ? (
        <div className={classes.formWrapper}>
          <div className={classes.formContainer}>
            <Group className={classes.inputGroup}>
              <TextInput className={classes.input} placeholder={translations[languagePrefix]["First name"] || translations["/"]["First name"]} />
              <TextInput className={classes.input} placeholder={translations[languagePrefix]["Last name"] || translations["/"]["Last name"]} />
            </Group>
            <TextInput className={classes.input} placeholder={translations[languagePrefix]["you@company.com"] || translations["/"]["you@company.com"]} />
            <Group className={classes.inputGroup}>
              <Select
                className={classes.input}
                data={[
                  { value: 'US', label: 'US +1' },
                  { value: 'UK', label: 'UK +44' },
                ]}
                placeholder={translations[languagePrefix]["Country"] || translations["/"]["Country"]} />
              <TextInput className={classes.input} placeholder={translations[languagePrefix]["Phone number"] || translations["/"]["Phone number"]} />
            </Group>
            <TextInput className={classes.input} placeholder={translations[languagePrefix]["Message"] || translations["/"]["Message"]} multiline />

            <Checkbox
              label={
                <Text>
                  {translations[languagePrefix]["I agree to our friendly"] || translations["/"]["I agree to our friendly"]}
                  {' '}
                  <a href="#" className={classes.privacyLink}>{translations[languagePrefix]["privacy policy"] || translations["/"]["privacy policy"]}</a>
                </Text>
              }
              checked={checked}
              onChange={(event) => setChecked(event.currentTarget.checked)}
            />

            <Button className={classes.buttonPrimary} fullWidth>
              {translations[languagePrefix]["Send message"] || translations["/"]["Send message"]}
            </Button>
          </div>

          <div className={classes.imageContainer}>
            <img src={image} alt="Support Team" className={classes.image} />
          </div>
        </div>
      ) : (
        <div className={classes.iframeWrapper}>
          <iframe
            src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ02L8LZkytqFtf7P6ewf38_e2P_WzKPvfFF4uOoXZXAaJoykYMJJghk2SYydiNOBcSA_iYWBhlE?gv=true://calendar.google.com/calendar/appointments/schedules/AcZssZ2r7IfyTxToIup5NcLdAzJZAVUrElKsCkGsd-R2INmcI-WpqtlGNXVLP67uNApAjYcXeHWPLedq?gv=true"
            width="100%"
            height="600"
            frameBorder="0"
            title="Book a Meeting"
          ></iframe>
        </div>
      )}
    </div>
  );
}

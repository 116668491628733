import {
  createStyles,
  Table,
  Group,
  Title,
  Text,
  Card,
  Divider,
  SimpleGrid,
  Container,
  rem,
  Image,
  Button,
  Box,
} from "@mantine/core";
import {
  IconCalendar,
  IconUser,
  IconCheck,
  IconSmartHome,
  IconShare,
  IconHomeHand,
  IconHomeStats,
} from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { Features } from "./Features";
import image from "../../assets/consulting/consulting.jpeg";

// Define translations for titles and descriptions
const titleTranslations = {
  "/": {
    ResiWeb: "ResiWeb",
    ResiCal: "ResiCal",
    "Web and Cal": "Web and Cal",
  },
  "/nl": {
    ResiWeb: "ResiWeb",
    ResiCal: "ResiCal",
    "Web and Cal": "Web en Cal",
  },
  "/de": {
    ResiWeb: "ResiWeb",
    ResiCal: "ResiCal",
    "Web and Cal": "Web und Cal",
  },
};

const mockdata = [
  {
    title: {
      "/": "Comprehensive Guide",
      "/nl": "Comprehensive Guide",
      "/de": "Comprehensive Guide",
    },
    description: {
      "/": "A comprehensive guide to the vacation rental industry, this package is ideal for those who are new to the business. We provide you with all the tools you need to get started, from a detailed step-by-step plan to legally sound terms and conditions. We also include mandatory materials and communication templates to ensure you are fully prepared to welcome your first guests. This package is priced at €1500 and includes:",
      "/nl":
        "Pakket waarin we samen met jullie de vakantieverhuur opstarten, van inrichting tot eerste klant. Dit pakket omvat: ",
      "/de":
        "Ein umfassender Leitfaden für die Ferienhausbranche, dieses Paket ist ideal für diejenigen, die neu im Geschäft sind. Wir stellen Ihnen alle Werkzeuge zur Verfügung, die Sie zum Einstieg benötigen, von einem detaillierten Schritt-für-Schritt-Plan bis hin zu rechtlich einwandfreien Allgemeinen Geschäftsbedingungen. Wir enthalten auch obligatorische Materialien und Kommunikationsvorlagen, um sicherzustellen, dass Sie vollständig auf den Empfang Ihrer ersten Gäste vorbereitet sind. Dieses Paket kostet 1500 € und beinhaltet:",
    },
    price: {
      "/": "€1500",
      "/nl": "€1500",
      "/de": "€1500",
    },
    monthly_price: {
      "/": "€ 19 per extra language/year",
      "/nl": "€ 19 per extra taal/jaar",
      "/de": "€ 19 für jede zusätzliche Sprache/Jahr",
    },
    btw: {
      "/": "excl. VAT",
      "/nl": "excl. BTW",
      "/de": "exkl. MwSt.",
    },
    featureList: [
      {
        "/": "1. Six hours of intensive guidance by us (to be planned together) in which we go through and adapt to your region and specific accommodation.",
        "/nl":
          "1.	Zes uur intensieve begeleiding waarin we samen de te nemen stappen aangepast aan jouw regio en specifieke accomodatie doornemen.",
        "/de":
          "1. Sechs Stunden intensive Anleitung von uns (gemeinsam zu planen), in denen wir durchgehen und an Ihre Region und spezifische Unterkunft anpassen.",
      },
      {
        "/": "2. A comprehensive guide that you will receive from us in which all steps are still worked out and which you can use before and after the guidance.",
        "/nl":
          "2. Een uitgewerkt witboek dat je van ons krijgt waarin alle stappen nog uitgewerkt staan en die je kan gebruiken voor en na de begeleiding.",
        "/de":
          "2. Ein detailliertes Handleitung, das Sie von uns erhalten, in dem alle Schritte noch ausgearbeitet sind und das Sie vor und nach der Anleitung verwenden können.",
      },
    ],
    icon: IconSmartHome,
  },
  {
    title: {
      "/": "Additional guidance",
      "/nl": "Additional guidance",
      "/de": "Additional guidance",
    },
    description: {
      "/": "Get personalized assistance tailored to your specific needs. Whether you require training for your cleaning staff, help with listing your property on multiple booking platforms, or streamlining guest communication, we’re here to support you. Our expert guidance ensures smooth operations and helps you optimize your short-term rental business.",
      "/nl":
        "Ontvang gepersonaliseerde ondersteuning die is afgestemd op uw specifieke behoeften. Of u nu training nodig heeft voor uw schoonmaakpersoneel, hulp bij het vermelden van uw eigendom op meerdere boekingsplatformen, of het stroomlijnen van de communicatie met gasten, wij zijn hier om u te ondersteunen. Onze deskundige begeleiding zorgt voor een soepele werking en helpt u uw vakantieverhuurbedrijf te optimaliseren.",
      "/de":
        "Erhalten Sie personalisierte Unterstützung, die auf Ihre spezifischen Bedürfnisse zugeschnitten ist. Egal, ob Sie Schulungen für Ihr Reinigungspersonal benötigen, Hilfe beim Einstellen Ihrer Immobilie auf mehreren Buchungsplattformen oder Unterstützung bei der Optimierung der Gästekommunikation – wir sind hier, um Ihnen zu helfen. Unsere fachkundige Beratung sorgt für reibungslose Abläufe und hilft Ihnen, Ihr Kurzzeitvermietungsgeschäft zu optimieren.",
    },
    price: {
      "/": "",
      "/nl": "",
      "/de": "",
    },
    monthly_price: {
      "/": "€ 19 per extra language/year",
      "/nl": "€ 19 per extra taal/jaar",
      "/de": "€ 19 für jede zusätzliche Sprache/Jahr",
    },
    btw: {
      "/": "",
      "/nl": "",
      "/de": "",
    },
    featureList: [
      {
        "/": "1. Customized Training & Support – Guidance for cleaning staff, customer communication, and daily operations.",
        "/nl":
          "1. Aangepaste Training & Ondersteuning – Begeleiding voor schoonmaakpersoneel, klantcommunicatie en dagelijkse werkzaamheden.",
        "/de":
          "1. Maßgeschneiderte Schulung & Unterstützung – Anleitung für Reinigungspersonal, Kundenkommunikation und tägliche Abläufe.",
      },
      {
        "/": "2. Platform Management Assistance – Help with setting up and optimizing listings across multiple booking sites.",
        "/nl":
          "2. Platformbeheer Ondersteuning – Hulp bij het opzetten en optimaliseren van vermeldingen op meerdere boekingssites.",
        "/de":
          "2. Unterstützung beim Plattformmanagement – Hilfe bei der Einrichtung und Optimierung von Einträgen auf mehreren Buchungsseiten.",
      },
      {
        "/": "3. Operational Optimization – Outsourcing solutions, automation strategies, and best practices for efficiency.",
        "/nl":
          "3. Operationele Optimalisatie – Outsourcing-oplossingen, automatiseringsstrategieën en best practices voor efficiëntie.",
        "/de":
          "3. Betriebsoptimierung – Outsourcing-Lösungen, Automatisierungsstrategien und Best Practices für mehr Effizienz.",
      },
    ],
    icon: IconHomeHand,
    icon2: IconCalendar,
  },
];

const useStyles = createStyles((theme) => ({
  description: {
    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(16),
      maxWidth: "100%",
      textAlign: "center",
      display: "block",
    },
    // maxwidth 2/3 of the screen
    maxWidth: "66%",
    margin: "auto",
    fontWeight: 400,
  },

  card: {
    border: `${rem(1)} solid ${
      theme.colorScheme === "dark"
        ? theme.colors.dark[5]
        : theme.colors.resirent[8]
    }`,
    "&:hover": {
      transform: "scale(1.01)",
      boxShadow: theme.shadows.md,
    },
    textAlign: "center", // Center-align the contents
  },

  cardSpecial: {
    border: `${rem(1)} solid ${
      theme.colorScheme === "dark"
        ? theme.colors.dark[5]
        : theme.colors.resirent[8]
    }`,
    "&:hover": {
      transform: "scale(1.01)",
      boxShadow: theme.shadows.md,
    },
    textAlign: "center", // Center-align the contents
  },

  title: {
    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(24),
      maxWidth: "100%",
    },
    maxWidth: "100%",
  },

  title1: {
    textAlign: "left", // Ensure left alignment
    width: "100%", // Take full width
    margin: "auto 0", // Prevent centering
  },

  // title1: {
  //     [theme.fn.smallerThan('sm')]: {
  //         fontSize: rem(24),
  //         maxWidth: '100%',
  //     },
  //     maxWidth: '66%',
  //     margin: 'auto',
  // },
}));

export function Consulting() {
  const { classes, theme } = useStyles();
  const languagePrefix = window.location.pathname.startsWith("/nl")
    ? "/nl"
    : window.location.pathname.startsWith("/de")
    ? "/de"
    : "/";
  const { t } = useTranslation();
  const isMobile = window.innerWidth <= 768;

  const features = mockdata.map((feature) => (
    <Card
      key={feature.title}
      shadow="md"
      radius="md"
      className={classes.card}
      padding="xl"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          gap: "1rem",
        }}
      >
        {/* Responsive Title and Price Layout */}
        {feature.title[languagePrefix] === "Comprehensive Guide" && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <div>
              <Text
                fw={500}
                className={classes.cardTitle}
                fz="xl"
                mb="xs"
                style={{
                  color: "#101828",
                  fontFamily: "Inter, sans-serif",
                  fontWeight: "600",
                  fontSize: "clamp(18px, 5vw, 24px)",
                }}
              >
                {feature.title[languagePrefix] || feature.description["/"]}
              </Text>
              <Text fz="sm" c="dimmed">
                {feature.subtitle &&
                  (feature.subtitle[languagePrefix] || feature.subtitle["/"])}
              </Text>
              <div style={{ marginTop: "8px" }}>
                <Text
                  fz="lg"
                  align="left"
                  style={{
                    color: "#667085",
                    fontFamily: "Inter, sans-serif",
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                >
                  Launch with Confidence
                </Text>
              </div>
            </div>
            <div style={{ textAlign: "right" }}>
              <Text
                fw={500}
                fz="xl"
                style={{ fontSize: "clamp(20px, 6vw, 30px)" }}
              >
                {feature.price &&
                  (feature.price[languagePrefix] || feature.price["/"])}
              </Text>
              <Text
                fz="xs"
                c="dimmed"
                align="right"
                style={{ fontSize: "clamp(12px, 3.5vw, 16px)" }}
              >
                {feature.btw &&
                  (feature.btw[languagePrefix] || feature.btw["/"])}
              </Text>
            </div>
          </div>
        )}

        {feature.title[languagePrefix] === "Additional guidance" && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <div>
              <Text
                fw={500}
                className={classes.cardTitle}
                fz="xl"
                mb="xs"
                style={{
                  color: "#101828",
                  fontFamily: "Inter, sans-serif",
                  fontWeight: "600",
                  fontSize: "clamp(18px, 5vw, 24px)",
                }}
              >
                {feature.title[languagePrefix] || feature.description["/"]}
              </Text>
              <Text fz="sm" c="dimmed">
                {feature.subtitle &&
                  (feature.subtitle[languagePrefix] || feature.subtitle["/"])}
              </Text>
              <div style={{ marginTop: "8px" }}>
                <Text
                  fz="lg"
                  align="left"
                  style={{
                    color: "#667085",
                    fontFamily: "Inter, sans-serif",
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                >
                  Tailored Expert Assistance
                </Text>
              </div>
            </div>
            <div>
              <Text
                fw={500}
                mt="md"
                mb="md"
                align="left"
                style={{
                  color: "#667085",
                  fontFamily: "Inter, sans-serif",
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                Hourly Rate
              </Text>
            </div>
          </div>
        )}

        <Divider my="md" />

        {/* Features Label */}
        <Text
          fw={500}
          mt="md"
          mb="md"
          align="left"
          style={{
            color: "#101828",
            fontFamily: "Inter, sans-serif",
            fontWeight: "600",
            fontSize: "16px",
          }}
        >
          Features
        </Text>

        <div style={{ flex: 1 }}>
          <Text
            mb="md"
            align="justify"
            style={{
              color: "#667085",
              fontFamily: "Inter, sans-serif",
              fontWeight: "400",
              fontSize: "16px",
            }}
          >
            {feature.description[languagePrefix] || feature.description["/"]}
          </Text>

          {/* Feature List Table */}
          <Table striped highlightOnHover color="#FFF5F9">
            <tbody>
              {feature.featureList &&
                feature.featureList.map((value, index) => (
                  <tr key={`${feature.title}-${index}`}>
                    <td style={{ textAlign: "left", width: "30px" }}>
                      <div
                        style={{
                          width: "50px", // Adjust size as needed
                          height: "50px", // Adjust size as needed
                          borderRadius: "50%", // Make it a circle
                          backgroundColor: "rgba(144, 238, 144, 0.5)", // Light green with transparency
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          filter: "blur(0px)", // Add blur effect (adjust value as needed)
                        }}
                      >
                        <IconCheck size={rem(30)} stroke={2} color="green" />{" "}
                        {/* Green checkmark */}
                      </div>
                    </td>
                    <td
                      style={{
                        textAlign: "left",
                        color: "#667085",
                        fontFamily: "Inter, sans-serif",
                        fontWeight: "400",
                        fontSize: "16px",
                      }}
                    >
                      {value && (value[languagePrefix] || value["/"])}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>

        {/* Buttons */}
        {feature.title[languagePrefix] === "Comprehensive Guide" && (
          <Button variant="filled" color="resirentPink" fullWidth mt="md">
            Get Started Today!
          </Button>
        )}

        {feature.title[languagePrefix] === "Additional guidance" && (
          <Button variant="filled" color="resirentPink" fullWidth mt="md">
          {isMobile ? "Contact Us" : "Contact Us to Discuss Your Needs!"}
        </Button>
        
        )}
      </div>
    </Card>
  ));

  return (
    <Box>
      {/* <Image
                src={image}
                alt="Consulting - hospitality in the small things"
                height={400}
            ></Image> */}

      <Container
        size="xl"
        py={100}
        style={{ paddingInline: "clamp(1rem, 4vw, 3rem)" }}
      >
        <Title
          order={2}
          className={classes.title1}
          mt="sm"
          style={{
            textAlign: "left",
            alignSelf: "flex-start",
            fontSize: "clamp(30px, 7vw, 60px)",
            fontFamily: "Inter, sans-serif",
            fontWeight: "600",
          }}
        >
          {t("consulting.titleLine1")}
          <br />
          {t("consulting.titleLine2")}
        </Title>

        <Group
          align="start"
          position="apart"
          noWrap
          spacing="lg"
          style={{ flexWrap: "wrap" }}
        >
          <Text
            className={classes.description}
            ta="left"
            my="lg"
            style={{
              flex: 1,
              fontFamily: "Inter, sans-serif",
              fontWeight: "400",
              fontSize: "clamp(16px, 4vw, 20px)",
              textAlign: "justify",
            }}
          >
            {t("consulting.text")}
          </Text>

          <Box
            style={{
              margin: "0 auto",
              padding: 0,
              width: "clamp(280px, 90vw, 400px)",
              height: "auto",
            }}
          >
            {["text2", "text3", "text7"].map((textKey) => (
              <Group
                key={textKey}
                align="center"
                noWrap
                style={{ marginBottom: "8px", flexWrap: "nowrap" }}
              >
                <div
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    backgroundColor: "rgba(144, 238, 144, 0.5)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <IconCheck size={rem(24)} stroke={2} color="#12B76A" />
                </div>
                <Text
                  className={classes.description}
                  ta="left"
                  my="lg"
                  style={{
                    marginLeft: rem(10),
                    fontFamily: "Inter, sans-serif",
                    fontWeight: "400",
                    fontSize: "clamp(14px, 4vw, 18px)",
                    whiteSpace: "normal",
                  }}
                >
                  {t(`consulting.${textKey}`)}
                </Text>
              </Group>
            ))}
          </Box>
        </Group>
      </Container>

      <Image
  src={image}
  alt="Consulting - hospitality in the small things"
  height={isMobile ? undefined : 492}
  width={isMobile ? undefined : 1216}
  style={{
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: "20px",
    overflow: "hidden",
    objectFit: "cover",
    maxWidth: "100%",
    ...(isMobile
      ? {
          width: "100%",
          height: "auto",
        }
      : {
          height: "492px",
          width: "1216px",
        }),
  }}
/>


      <Container size="xl" py={100}>
        {/* <Group position="center">
                    <Title order={4} weight={300} align="center" color="resirentPink">
                        {t("consulting.tag")}
                    </Title>
                </Group> */}

        {/* <Title order={2} className={classes.title1} ta="left" mt="sm" >
                    {t("consulting.title")}
                </Title> */}

        {/* <Text className={classes.description} ta="left" my="lg" style={{ marginTop: 0 }}>
                    {t("consulting.text")}
                </Text> */}

        {/* <Title order={2} className={classes.title1} ta="left" mt="sm" >
                    {t("consulting.title1")}
                </Title> */}

        {/* <Text className={classes.title1} ta="left">
                    <strong> {t("consulting.subtitle2")}</strong> {t("consulting.text5")}
                </Text> */}

        {/* <Text className={classes.title1} ta="left">
                    <strong> {t("consulting.subtitle6")}</strong> {t("consulting.text6")}
                </Text> */}

        {/* <Text className={classes.description} ta="left" my="lg">
                    {t("consulting.text2")}
                </Text> */}

        {/* <Text className={classes.description} ta="left" my="lg">
                    {t("consulting.text3")}
                </Text> */}

        {/* <Text className={classes.description} ta="left" my="lg">
                    {t("consulting.text7")}
                </Text> */}

        {/* <Features /> */}

        <Title
          order={2}
          className={classes.title}
          ta="center"
          mt="sm"
          style={{
            color: "#003D51",
            fontFamily: "Inter, sans-serif",
            fontWeight: "600",
            fontSize: "16px",
          }}
        >
          {t("consulting.subtitle2")}
        </Title>

        <Title
          order={2}
          className={classes.title}
          ta="center"
          mt="sm"
          style={{
            color: "#101828",
            fontFamily: "Inter, sans-serif",
            fontWeight: "600",
            fontSize: "36px",
          }}
        >
          {t("consulting.subtitle3")}
        </Title>

        <Text
          className={classes.description}
          ta="center"
          my="lg"
          style={{
            color: "#667085",
            fontFamily: "Inter, sans-serif",
            fontWeight: "400",
            fontSize: "20px",
          }}
        >
          {t("consulting.text5")}
        </Text>

        <Group position="center" pb="xl">
          <SimpleGrid
            cols={2}
            spacing="xl"
            mt={50}
            breakpoints={[{ maxWidth: "md", cols: 1 }]}
          >
            {features}
          </SimpleGrid>
        </Group>

        <Title
          order={2}
          className={classes.title}
          ta="left"
          mt="sm"
          style={{
            color: "#003D51",
            fontFamily: "Inter, sans-serif",
            fontWeight: "600",
            fontSize: "16px",
          }}
        >
          {t("consulting.subtitle5")}
        </Title>

        <Title
          order={2}
          className={classes.title}
          ta="left"
          mt="sm"
          style={{
            color: "#101828",
            fontFamily: "Inter, sans-serif",
            fontWeight: "600",
            fontSize: "36px",
          }}
        >
          {t("consulting.subtitle4")}
        </Title>

        <Text
          className={classes.description}
          ta="left"
          my="lg"
          style={{
            maxWidth: "100%",
            color: "#667085",
            fontFamily: "Inter, sans-serif",
            fontWeight: "400",
            fontSize: "20px",
          }}
        >
          {t("consulting.text4").split("!")[0]}
          <br />
          {t("consulting.text4").split("!")[1]}
        </Text>

        {/* <Text className={classes.description} ta="left" my="lg">
                    {t("consulting.text4")}
                </Text> */}

        <iframe
          src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ02L8LZkytqFtf7P6ewf38_e2P_WzKPvfFF4uOoXZXAaJoykYMJJghk2SYydiNOBcSA_iYWBhlE?gv=true://calendar.google.com/calendar/appointments/schedules/AcZssZ2r7IfyTxToIup5NcLdAzJZAVUrElKsCkGsd-R2INmcI-WpqtlGNXVLP67uNApAjYcXeHWPLedq?gv=true"
          width="100%"
          height="600"
          frameborder="0"
        ></iframe>
      </Container>
    </Box>
  );
}

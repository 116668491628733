import React, { useEffect, useState } from "react";
import {
  createStyles,
  Group,
  Title,
  TextInput,
  Button,
  Card,
  Container,
  Image,
  Box,
  Grid,
  Text,
  Select,
  Textarea,
  Progress
} from "@mantine/core";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

import QuillResizeImage from "quill-resize-image";
import { useTranslation } from "react-i18next";
import blogPost from "../../assets/blogPosts/blogPage/blog_post.jpg";
import useAuth from "../../hooks/useAuth";
import axios from "../../api/axios";
import cloudinaryConfig from "../../config/cloudinary.config";
import { Loader } from "@mantine/core";

Quill.register("modules/resize", QuillResizeImage);

const useStyles = createStyles((theme) => ({
  label: {
    fontWeight: 600,
    textAlign: "left",
    marginRight: theme.spacing.sm,
  },
  card: {
    padding: theme.spacing.xl,
    maxWidth: "900px",
    margin: "0 auto",
  },
  editor: {
    minHeight: "200px",
    height: "300px",
    marginBottom: "80px",
  },
  loadingOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    color: "#fff",
    zIndex: 9999,
  }
}));

export const CreateBlog = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { auth } = useAuth();

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [intervalId, setIntervalId] = useState(null);

// Function to simulate a smooth loading progress
const startLoading = () => {
  setLoading(true);
  setProgress(0);
  let currentProgress = 0;

  const interval = setInterval(() => {
    currentProgress += Math.floor(Math.random() * 10) + 5;
    if (currentProgress >= 95) { 
      clearInterval(interval); 
    }
    setProgress(currentProgress);
  }, 200);

  setIntervalId(interval); 
};

// Function to ensure progress reaches 100% before hiding
const stopLoading = () => {
  if (intervalId) clearInterval(intervalId); 
  setProgress(100);
  setTimeout(() => {
    setLoading(false);
  }, 500);
};

  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const [userImageFile, setUserImageFile] = useState(null);
  const [userImagePreview, setUserImagePreview] = useState(null);

  const [user, setUser] = useState(null); // Initialize user state

  const [form, setForm] = useState({
    title: "",
    author: auth.username,
    designation: "",
    profileUrl: "",
    userdescription: "",
    usertitle: "",
    userImage: "",
    content: "",
    description: "",
    author_id: 0,
    language: "EN", // Default language
  });

  useEffect(() => {
    const getUser = () => {
      axios
        .get(`/user/${auth.username}`)
        .then((res) => {
          setUser(res.data);

          // Update form with user data once fetched
          setForm((prevForm) => ({
            ...prevForm,
            author_id: res.data.id,
            designation: res.data?.designation || "",
            profileUrl: res.data?.profileUrl || "",
            usertitle: res.data?.title || "",
            userdescription: res.data?.description || "",
          }));

          if (res.data?.imageUrl) {
            setUserImagePreview(res.data.imageUrl);
          }
        })
        .catch((err) => {
          console.log("Error fetching data:", err);
        });
    };

    getUser();
  }, [auth]);

  const [errors, setErrors] = useState({});

  console.log(auth);

  const validate = () => {
    const newErrors = {};
    if (!form.title.trim()) {
      newErrors.title = "Title is required.";
    }
    if (!form.description.trim()) {
      newErrors.description = "Description is required.";
    }
    if (!form.usertitle.trim()) {
      newErrors.usertitle = "Autor Name is required.";
    }
    if (!form.designation.trim()) {
      newErrors.designation = "Designation is required.";
    }
    // if (!form.author.trim()) {
    //   newErrors.author = "Author is required.";
    // }
    if (!form.content.trim()) {
      newErrors.content = "Content is required.";
    }
    if (!form.language) {
      newErrors.language = "Language is required.";
    }
    return newErrors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));

    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
    }
  };

  const handleSelectChange = (value) => {
    setForm((prevForm) => ({ ...prevForm, language: value }));

    // Clear the language error dynamically
    if (errors.language) {
      setErrors((prevErrors) => ({ ...prevErrors, language: undefined }));
    }
  };

  const handleEditorChange = (content) => {
    setForm((prevForm) => ({ ...prevForm, content }));

    if (errors.content) {
      setErrors((prevErrors) => ({ ...prevErrors, content: undefined }));
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  // Remove the uploaded image
  const removeImage = () => {
    setImageFile(null);
    setImagePreview(null);
  };

  const handleUserImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setUserImageFile(file);
      setUserImagePreview(URL.createObjectURL(file));
    }
  };

  const removeUserImage = () => {
    setUserImageFile(null);
    setUserImagePreview(null);
  };

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
    resize: {
      locale: {},
    },
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "clean",
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    setErrors(validationErrors);
  
    if (Object.keys(validationErrors).length === 0 && auth) {
      try {
        startLoading();
  
        let blogImageUrl = "";
        let authorImageUrl = "";
  
        // Upload blog image to Cloudinary
        if (imageFile) {
          const randomId = Math.random().toString(36).substring(2, 15);
          const signResponse = await axios.get("/signuploadform", {
            params: {
              folder: auth.username,
              publicID: `blog-${randomId}`,
            },
          });
  
          const { apiKey, cloudName, signature, timestamp } = signResponse.data;
          const uploadUrl = `${cloudinaryConfig.BASE_URL}${cloudName}${cloudinaryConfig.UPLOAD_SUFFIX}`;
  
          const blogFormData = new FormData();
          blogFormData.append("file", imageFile);
          blogFormData.append("api_key", apiKey);
          blogFormData.append("timestamp", timestamp);
          blogFormData.append("signature", signature);
          blogFormData.append("folder", auth.username);
          blogFormData.append("public_id", `blog-${randomId}`);
  
          const cloudRes = await axios.post(uploadUrl, blogFormData);
          blogImageUrl = cloudRes.data.secure_url;
          console.log("Blog image URL:", blogImageUrl);
        }
  
        // Upload author image to Cloudinary
        if (userImageFile) {
          const randomId = Math.random().toString(36).substring(2, 15);
          const signResponse = await axios.get("/signuploadform", {
            params: {
              folder: auth.username,
              publicID: `author-${randomId}`,
            },
          });
  
          const { apiKey, cloudName, signature, timestamp } = signResponse.data;
          const uploadUrl = `${cloudinaryConfig.BASE_URL}${cloudName}${cloudinaryConfig.UPLOAD_SUFFIX}`;
  
          const authorFormData = new FormData();
          authorFormData.append("file", userImageFile);
          authorFormData.append("api_key", apiKey);
          authorFormData.append("timestamp", timestamp);
          authorFormData.append("signature", signature);
          authorFormData.append("folder", auth.username);
          authorFormData.append("public_id", `author-${randomId}`);
  
          const cloudRes = await axios.post(uploadUrl, authorFormData);
          authorImageUrl = cloudRes.data.secure_url;
        }
  
        // Final blog submission
        const formData = new FormData();
        formData.append("author_id", form.author_id);
        formData.append("title", form.title);
        formData.append("description", form.description);
        formData.append("content", form.content);
        formData.append("language", form.language);
        formData.append("readingTime", "");
        formData.append("category", "");
        formData.append("authorUsername", form.author);
        formData.append("designation", form.designation);
        formData.append("authorRole", form.usertitle);
        formData.append("authorProfileUrl", form.profileUrl);
        formData.append("userdescription", form.userdescription);
        formData.append("href", "");
  
        if (blogImageUrl) formData.append("blogImageUrl", blogImageUrl);
        if (authorImageUrl) formData.append("authorImageUrl", authorImageUrl);
  
        const response = await axios.post("/blog", formData, {
          headers: { "Content-Type": "multipart/form-data" },
          withCredentials: true,
        });
  
        console.log("Response:", response.data);
        stopLoading();
        alert("Blog saved successfully!");
  
        // Reset form state
        setForm({
          title: "",
          author: auth.username,
          designation: "",
          profileUrl: "",
          userdescription: "",
          usertitle: "",
          userImage: "",
          content: "",
          description: "",
          language: "EN",
        });
        setImageFile(null);
        setUserImageFile(null);
        setImagePreview(null);
        setUserImagePreview(null);
        setErrors({});
      } catch (error) {
        stopLoading();
        console.error(
          "Error saving blog:",
          error.response?.data || error.message
        );
        alert("Error saving blog. Please try again later.");
      }
    }
  };  

  return (
    <>
      <Container pt={50}>
        {/* <Box>
      <Image src={blogPost} alt="Landscape" height={400} /> */}
      {/* Loading Screen */}
        {loading && (
                <div className={classes.loadingOverlay}>
                  <Text size="xl" weight={700}>Saving...</Text>
                  <Progress value={progress} size="xl" color="blue" mt="md" w="300px" />
                  <Text size="lg" mt="sm">{progress}%</Text>
                </div>
              )}  
        <Container size="lg" py={100}>
          <Group position="center">
            <Title order={4} weight={100} align="center" color="resirentPink">
              <h1>{t("Add Blog")}</h1>
            </Title>
          </Group>

          <Container size="lg" mt="xl">
            <Card shadow="sm" className={classes.card} radius="md" withBorder>
              <form onSubmit={handleSubmit}>
                <Grid align="center" gutter="md">
                  {/* Title Input */}
                  <Grid.Col span={3}>
                    <Text className={classes.label}>{t("Title")}</Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                    <TextInput
                      placeholder={t("Enter blog title")}
                      value={form.title}
                      onChange={handleChange}
                      name="title"
                      error={errors.title}
                    />
                  </Grid.Col>

                  {/* Title Input */}
                  <Grid.Col span={3}>
                    <Text className={classes.label}>{t("Description")}</Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                    <TextInput
                      placeholder={t("Enter blog description")}
                      value={form.description}
                      onChange={handleChange}
                      name="description"
                      error={errors.description}
                    />
                  </Grid.Col>

                  <Grid.Col span={3}>
                    <Text className={classes.label}>
                      {t("Main Image Upload")}
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageUpload}
                    />
                    {imagePreview && (
                      <Box>
                        <Image src={imagePreview} alt="Preview" height={200} />
                        <Button color="red" mt="sm" onClick={removeImage}>
                          {t("Remove Image")}
                        </Button>
                      </Box>
                    )}
                  </Grid.Col>

                  {/* Author Input */}
                  {/* <Grid.Col span={3}>
                  <Text className={classes.label}>{t("Author")}</Text>
                </Grid.Col>
                <Grid.Col span={9}>
                  <TextInput
                    placeholder={t("Enter author's name")}
                    value={form.author}
                    onChange={handleChange}
                    name="author"
                    error={errors.author}
                  />
                </Grid.Col> */}

                  {/* Language Selection */}
                  <Grid.Col span={3}>
                    <Text className={classes.label}>{t("Language")}</Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                    <Select
                      data={[
                        { value: "EN", label: "English" },
                        { value: "NL", label: "Netherlands" },
                        { value: "DE", label: "Duits" },
                      ]}
                      placeholder={t("Select language")}
                      value={form.language}
                      onChange={handleSelectChange}
                      error={errors.language}
                    />
                  </Grid.Col>

                  {/* Content Rich Text Editor */}
                  <Grid.Col span={3}>
                    <Text className={classes.label}>{t("Content")}</Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                    <ReactQuill
                      value={form.content}
                      onChange={handleEditorChange}
                      modules={modules}
                      formats={formats}
                      className={classes.editor}
                    />
                    {errors.content && (
                      <Text color="red" size="sm" mt="sm">
                        {errors.content}
                      </Text>
                    )}
                  </Grid.Col>

                  <Grid.Col span={3}>
                    <Text className={classes.label}>{t("Author Name")}</Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                    <TextInput
                      placeholder={t("Enter Author Name")}
                      value={form.usertitle}
                      onChange={handleChange}
                      name="usertitle"
                      error={errors.usertitle}
                    />
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Text className={classes.label}>
                      {t("Author Designation")}
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                    <TextInput
                      placeholder={t("Enter Author Designation")}
                      value={form.designation}
                      onChange={handleChange}
                      name="designation"
                      error={errors.designation}
                    />
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Text className={classes.label}>
                      {t("Author Profile Url")}
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                    <TextInput
                      placeholder={t("Enter Author Profile Url")}
                      value={form.profileUrl}
                      onChange={handleChange}
                      name="profileUrl"
                      // error={errors.designation}
                    />
                  </Grid.Col>

                  <Grid.Col span={3}>
                    <Text className={classes.label}>{t("Author Image")}</Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleUserImageUpload}
                    />
                    {userImagePreview && (
                      <Box>
                        <Image
                          src={userImagePreview}
                          alt="Preview"
                          height={200}
                        />
                        <Button color="red" mt="sm" onClick={removeUserImage}>
                          {t("Remove Image")}
                        </Button>
                      </Box>
                    )}
                  </Grid.Col>

                  <Grid.Col span={3}>
                    <Text className={classes.label}>
                      {t("Author Description")}
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                    <Textarea
                      rows={6}
                      placeholder={t("Enter Author Description")}
                      value={form.userdescription}
                      onChange={handleChange}
                      name="userdescription"
                      // error={errors.designation}
                    />
                  </Grid.Col>
                </Grid>

                {/* Save Button */}
                <Group position="right" mt="lg">
                  <Button
                    type="submit"
                    size="lg"
                    px="3xl"
                    py="xs"
                    disabled={loading}
                  >
                     {t("Save")}
                  </Button>
                </Group>
              </form>
            </Card>
          </Container>
        </Container>
        {/* </Box> */}
      </Container>
    </>
  );
};

import React, { useEffect, useState } from 'react';
import axios from '../../api/axios';
import {
  Button,
  Group,
  TextInput,
  Box,
  Title,
  Checkbox,
  Container,
  Text,
  Paper,
  Anchor,
  HoverCard,
  Center,
  createStyles,
  rem,
  SimpleGrid,
  UnstyledButton,
} from '@mantine/core';
import useAuth from '../../hooks/useAuth';
import { IconLanguage } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { languagesList, enNavItems, nlNavItems, deNavItems } from '../../languages/translations';
import { useNavigate } from 'react-router-dom';

const WEBSITE_URL = '/websitejson';

const useStyles = createStyles((theme) => ({

  link: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    textDecoration: 'none',
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    fontWeight: 500,
    fontSize: theme.fontSizes.sm,

    [theme.fn.smallerThan('sm')]: {
      height: rem(42),
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },

    ...theme.fn.hover({
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.dark[2],
    }),
  },

  subLink: {
    width: '100%',
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    borderRadius: theme.radius.md,

    // ...theme.fn.hover({
    //     backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
    // }),

    '&:active': theme.activeStyles,
  },

  control: {
    '&:hover': {
      color: theme.white,
      backgroundColor: theme.colors.resirent[8],
    },

    border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.resirent[8] : theme.colors.resirent[8]}`,
    backgroundColor: theme.white,
    color: theme.colors.resirent[8],
    textAlign: 'center', // Center-align the contents
  },

  dropdownFooter: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
    margin: `calc(${theme.spacing.md} * -1)`,
    marginTop: theme.spacing.sm,
    padding: `${theme.spacing.md} calc(${theme.spacing.md} * 2)`,
    paddingBottom: theme.spacing.xl,
    borderTop: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
      }`,
  },

  hiddenMobile: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  hiddenDesktop: {
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },
  anchor: {
    color: theme.black,
  },
  button: {
    '&:hover': {
      backgroundColor: theme.white,
      color: theme.colors.resirent[8],
      border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.resirent[8] : theme.colors.resirent[8]}`,
    },
    // border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.resirent[8] : theme.colors.resirent[8]}`,
    backgroundColor: theme.white,
    color: theme.colors.resirent[8],
    textAlign: 'center', // Center-align the contents
  },
}));



export default function Dashboard() {
  const art = 'newsletter';
  const title = 'workshop';
  const { auth } = useAuth();
  const { classes, theme } = useStyles();
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [checkBox, setCheckBox] = useState(false);

  // JSON input
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [straat, setStraat] = useState('');
  const [stad, setStad] = useState('');
  const [postcode, setPostcode] = useState('');
  const [nummer, setNummer] = useState('');
  const [nameAccommodation, setNameAccommodation] = useState('');
  const [typeAccommodation, setTypeAccommodation] = useState('');
  const [email, setEmail] = useState('');
  const [domainNameAvailable, setDomainNameAvailable] = useState('');
  const [success, setSuccess] = useState(false);

  const [domainNameBox, setDomainNameBox] = useState(false);

  const handleAddClick = async (event) => {
    event.preventDefault();

    // Perform manual validation here
    if (!email || !/.+@.+\..+/.test(email)) {
      console.log('Invalid email address');
      return;
    }

    // Validation passed, proceed with form submission
    const newReview = {
      id: Date.now(),
      name: name,
      lastName: lastName,
      straat: straat,
      stad: stad,
      postcode: postcode,
      nummer: nummer,
      nameAccommodation: nameAccommodation,
      typeAccommodation: typeAccommodation,
      email: email,
      domainNameAvailable: domainNameAvailable,
    };

    setUsername(email);
    try {
      await axios.put(WEBSITE_URL, {
        username,
        art,
        title,
        content: [newReview],
      });
      setSuccess(true);
    } catch (err) {
      console.log(err);
    }
  };

  const handleChangeLanguage = (newLanguage) => {

    const currentPath = window.location.pathname;

    const language = currentPath.startsWith('/nl') ? 'nl' : currentPath.startsWith('/de') ? 'de' : '';
    const navItems = language === "nl" ? nlNavItems : language === "de" ? deNavItems : enNavItems;
    // Change the language using i18n or your preferred localization library
    i18n.changeLanguage(newLanguage);

    const currentKey = Object.keys(navItems).find(key => navItems[key].ref === currentPath);

    // If currentKey is undefined then continue without changing the URL
    if (currentKey === undefined) {
      return;
    }

    // Get the URL using getNavLinkHref() or any other logic
    const targetNavItems = newLanguage === "nl" ? nlNavItems : newLanguage === "de" ? deNavItems : enNavItems;
    const newPageUrl = targetNavItems[currentKey].ref;

    // Redirect to the new page
    navigate(newPageUrl);
  };

  const links = languagesList.map((item) => (
    <UnstyledButton className={classes.subLink} key={item.name}>
      <Group noWrap align="flex-start">
        <div>
          <Text size="sm" fw={500}>
            <Button
              key={item.short}
              component="a"
              data-value={item.short}
              onClick={(event) => {
                const newLanguage = event.currentTarget.getAttribute('data-value');
                handleChangeLanguage(newLanguage);
              }}
              className={classes.button}
            >
              {item.name}
            </Button>
          </Text>
        </div>
      </Group>
    </UnstyledButton >
  ));

  return (
    <>
      <Container>
        <Box mt="lg" mb="xl">
          <Title mt="lg" mb="lg">
            {t("dashboard_mainpage.title")} {auth.resiWeb}
          </Title>

          <Title mb="md" order={3} ta="left"> {t("dashboard_mainpage.profile")}</Title>
          <TextInput
            type="text"
            id="title"
            value={lastName}
            onChange={(event) => setLastName(event.target.value)}
            label="Naam"
            placeholder="Naam"
            required
          />
          <TextInput
            type="text"
            id="voornaam"
            onChange={(event) => setName(event.target.value)}
            value={name}
            label="Voornaam"
            placeholder="Voornaam"
            required
          />
          <TextInput
            type="text"
            id="email"
            value={email}
            onChange={(event) => {
              const newEmail = event.target.value;
              setEmail(newEmail);
              setUsername(newEmail);
            }}
            label="Email"
            placeholder="name@name.com"
            required
          />

          <Title mt="md" mb="md" order={3} ta="left"> {t("dashboard_mainpage.language")} </Title>

          <HoverCard position="bottom" radius="md" shadow="md" withinPortal>
            <HoverCard.Target>
              <Center inline>
                <Box component="span" mr={5}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconLanguage size={28} color={theme.fn.rgba(theme.colors.resirentPink[8], 1)} />
                    <Text size="sm" weight={500} ml={5}>
                      {i18n.language}
                    </Text>
                  </div>
                </Box>
              </Center>
            </HoverCard.Target>

            <HoverCard.Dropdown sx={{ overflow: 'hidden' }}>
              <SimpleGrid rows={2} spacing={0}>
                {links}
              </SimpleGrid>
            </HoverCard.Dropdown>
          </HoverCard>

          <Group justify="flex-end" mt="md">
            <Button
              type="submit"
              variant="outline"
              color="resirent"
              fullWidth
              mt="lg"
              disabled={!email || !/.+@.+\..+/.test(email)}
              onClick={handleAddClick}
            >
              Save changes
            </Button>
          </Group>
        </Box>
      </Container>
      {success ? (
        <Container size={420} my={40}>
          <Title ta="center">Saved changes!</Title>
        </Container>
      ) : (<> </>)}
    </>
  );
}

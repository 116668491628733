import blogPost from '../../assets/partners/vardan-papikyan-JzE1dHEaAew-unsplash.jpg'
import {
  createStyles,
  Group,
  Title,
  Text,
  Card,
  Container,
  rem,
  Image,
  Box,
  SimpleGrid,
} from '@mantine/core';
import { postsEn, postsNL } from '../../languages/blogPages';

const useStyles = createStyles((theme) => ({
  title: {
    [theme.fn.smallerThan('sm')]: {
      fontSize: rem(24),
    },
  },

  description: {
    maxWidth: 600,
    margin: 'auto',
    fontWeight: 200,
  },

  card: {
    transition: 'transform 150ms ease, box-shadow 150ms ease',

    '&:hover': {
      transform: 'scale(1.01)',
      boxShadow: theme.shadows.md,
    },
  },
}));

export default function Partners() {
  const { classes } = useStyles();
  const languagePrefix = window.location.pathname.startsWith('/nl') ? '/nl' : window.location.pathname.startsWith('/de') ? '/de' : '/';

  const content = {
    '/': {
      'groupTitle': 'OUR PARTNERS',
      'mainTitle': 'Collaborative Hospitality: Empowering Partnerships for Sustainable Tourism',
      'description': "We collaborate with a network of small businesses, local tourism offices, and hosts from diverse regions.",
      'description2': "Gathering feedback from local tourism offices is a priority for us as it helps us understand the specific needs of each region. For example, they have suggested language support since tourists come from all corners of the world. Our collaboration extends to working closely with local guides to comprehend the challenges faced by the tourism industry and explore ways to promote sustainable tourism in the region.",
      'description3': "Supporting local businesses is a fundamental aspect of our approach. We believe in endorsing businesses that operate sustainably within the region. Anja, an experienced member of our team, actively collaborates with several small businesses in Belgium and Austria, including Kewlox and More Circular. More Circular, which utilizes urban mining—a process that collects raw waste plaster from local construction sites, reducing material from ending up in a landfill.",
      'description4': "Our strong partnerships also extend to small hosts with diverse backgrounds and motivations. Theresia, one of our valued contacts, previously managed a small pension in the mountains near Schladming, Austria. Today, she operates a cozy apartment that frequently hosts her children and grandchildren, and between their visits, warmly welcomes other guests. Theresia's wealth of hospitality experience is invaluable, shaping our products and solutions to better meet the needs of other small hosts.",
    },
    '/nl': {
      'groupTitle': 'ONZE PARTNERS',
      'mainTitle': 'Samenwerkende Gastvrijheid: Partnerschappen voor Duurzaam Toerisme Versterken',
      'description': "Wij werken samen met een netwerk van kleine bedrijven, lokale toeristenkantoren en hosts uit diverse regio's.",
      'description2': "Het verzamelen van feedback van lokale toeristenkantoren heeft voor ons prioriteit, omdat het ons helpt de specifieke behoeften van elke regio te begrijpen. Zo hebben ze bijvoorbeeld taalondersteuning voorgesteld, aangezien toeristen van over de hele wereld komen. Onze samenwerking strekt zich uit tot nauwe samenwerking met lokale gidsen om de uitdagingen van de toeristenindustrie te begrijpen en manieren te verkennen om duurzaam toerisme in de regio te bevorderen.",
      'description3': "Het ondersteunen van lokale bedrijven is een fundamenteel aspect van onze aanpak. Wij geloven in het ondersteunen van bedrijven die duurzaam opereren binnen de regio. Anja, een ervaren lid van ons team, werkt actief samen met verschillende kleine bedrijven in België en Oostenrijk, waaronder Kewlox en More Circular. More Circular maakt gebruik van urban mining, een proces waarbij ruwe gipsafval wordt verzameld van lokale bouwplaatsen, waardoor materiaal niet op een stortplaats terechtkomt.",
      'description4': "Onze sterke partnerschappen strekken zich ook uit tot kleine hosts met diverse achtergronden en drijfveren. Theresia, een van onze gewaardeerde contacten, beheerde eerder bijvoorbeeld een klein pension in de bergen nabij Schladming, Oostenrijk. Vandaag exploiteert ze een gezellig appartement dat vaak wordt bezocht door haar kinderen en kleinkinderen, en tussen hun bezoeken door verwelkomt ze graag andere gasten. Theresia's rijke ervaring in gastvrijheid is van onschatbare waarde en vormt onze producten en oplossingen om beter aan de behoeften van andere kleine hosts te voldoen.",
    },
    '/de': {
      'groupTitle': 'UNSERE PARTNER',
      'mainTitle': 'Gemeinsame Gastfreundschaft: Partnerschaften für Nachhaltigen Tourismus Stärken',
      'description': "Wir arbeiten mit einem Netzwerk von kleinen Unternehmen, lokalen Tourismusbüros und Gastgebern aus verschiedenen Regionen zusammen.",
      'description2': "Das Sammeln von Feedback von lokalen Tourismusbüros hat für uns Priorität, da es uns hilft, die spezifischen Bedürfnisse jeder Region zu verstehen. Zum Beispiel haben sie Sprachunterstützung vorgeschlagen, da Touristen aus allen Teilen der Welt kommen. Unsere Zusammenarbeit erstreckt sich auch auf die enge Zusammenarbeit mit lokalen Guides, um die Herausforderungen der Tourismusbranche zu verstehen und Möglichkeiten zur Förderung nachhaltigen Tourismus in der Region zu erkunden.",
      'description3': "Die Unterstützung lokaler Unternehmen ist ein grundlegender Aspekt unserer Herangehensweise. Wir glauben daran, Unternehmen zu unterstützen, die nachhaltig in der Region tätig sind. Anja, ein erfahrenes Mitglied unseres Teams, arbeitet aktiv mit mehreren kleinen Unternehmen in Belgien und Österreich zusammen, darunter Kewlox und More Circular. More Circular nutzt Urban Mining, einen Prozess, bei dem Rohgipsabfälle von örtlichen Baustellen gesammelt werden, um zu verhindern, dass Material auf einer Mülldeponie landet.",
      'description4': "Unsere starken Partnerschaften erstrecken sich auch auf kleine Gastgeber mit unterschiedlichen Hintergründen und Antrieben. Theresia, eine unserer geschätzten Kontakte, leitete beispielsweise früher eine kleine Pension in den Bergen in der Nähe von Schladming, Österreich. Heute betreibt sie ein gemütliches Apartment, das häufig von ihren Kindern und Enkeln besucht wird, und zwischen ihren Besuchen begrüßt sie herzlich andere Gäste. Theresias reiche Erfahrung in der Gastfreundschaft ist von unschätzbarem Wert und formt unsere Produkte und Lösungen, um den Bedürfnissen anderer kleiner Gastgeber besser gerecht zu werden.",
    },
  };

  return (
    <Box>
      <Image
        src={blogPost}
        alt="Landscape"
        height={400}
      ></Image>
      <Container size="lg" py={100}>
        <Group position="center">
          <Title order={4} weight={100} align="center" color="resirentPink">
            OUR PARTNERS
          </Title>
        </Group>

        <Title order={2} className={classes.title} ta="center" mt="sm">
          {languagePrefix === '/nl' ? "Onze partners" : languagePrefix === '/de' ? "Unsere " : "Our partners and collaborators"}
        </Title>

        <Text className={classes.description} ta="center" my="lg">
          {/* {content[languagePrefix].description} */}
          {content[languagePrefix].description}
        </Text>

        <Text className={classes.description} ta="center" my="lg">
          {/* {content[languagePrefix].description} */}
          {content[languagePrefix].description2}
        </Text>

        <Text className={classes.description} ta="center" my="lg">
          {/* {content[languagePrefix].description} */}
          {content[languagePrefix].description3}
        </Text>

        <Text className={classes.description} ta="center" my="lg">
          {/* {content[languagePrefix].description} */}
          {content[languagePrefix].description4}
        </Text>

      </Container>
    </Box >
  );
}

import React, { useState, useEffect } from 'react';
import { NumberInput, createStyles, rem, Group, Title, Card, Button, MultiSelect } from '@mantine/core';
import axios from '../../api/axios';
import useAuth from '../../hooks/useAuth';
import CalendarBookedView from '../calendar/Calendar-booking/CalendarBookedView';

const WEBSITE_URL = '/websitejson';

// Style for the calendar view
const useStyles = createStyles((theme) => ({
    title: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(24),
            maxWidth: '100%',
            display: 'block',
        },
        // maxwidth 2/3 of the screen
        maxWidth: '66%',
        margin: 'auto',
    },

    titleCard: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(24),
            maxWidth: '100%',
            display: 'block',
        },
        // maxwidth 2/3 of the screen
        margin: 'auto',
    },

    description: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(16),
            maxWidth: '100%',
            textAlign: 'center',
            display: 'block',
        },
        // maxwidth 2/3 of the screen
        maxWidth: '66%',
        margin: 'auto',
        fontWeight: 400,
    },

    descriptionCard: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(16),
            maxWidth: '100%',
            textAlign: 'center',
            display: 'block',
        },
        // maxwidth 2/3 of the screen
        margin: 'auto',
        fontWeight: 400,
    },

    base: {
        transition: 'transform 150ms ease, box-shadow 150ms ease',

        '&:hover': {
            transform: 'scale(1.01)',
            boxShadow: theme.shadows.md,
        },

        // Blue neutral color
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.blue[2] : theme.colors.blue[2],
    },

    low: {
        transition: 'transform 150ms ease, box-shadow 150ms ease',

        '&:hover': {
            transform: 'scale(1.01)',
            boxShadow: theme.shadows.md,
        },

        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.green[2] : theme.colors.green[2],
    },

    high: {
        transition: 'transform 150ms ease, box-shadow 150ms ease',

        '&:hover': {
            transform: 'scale(1.01)',
            boxShadow: theme.shadows.md,
        },

        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.red[2] : theme.colors.red[2],
    },

    gray: {
        transition: 'transform 150ms ease, box-shadow 150ms ease',

        '&:hover': {
            transform: 'scale(1.01)',
            boxShadow: theme.shadows.md,
        },

        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.gray[2] : theme.colors.gray[2],
    },

    control: {
        '&:hover': {
            color: theme.white,
            backgroundColor: theme.colors.resirentPink[5],
        },

        border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.resirentPink[5] : theme.colors.resirentPink[8]}`,
        backgroundColor: theme.white,
        color: theme.colors.resirentPink[5],
        textAlign: 'center', // Center-align the contents
    },

    controlSecond: {
        '&:hover': {
            border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.resirentPink[5] : theme.colors.resirentPink[8]}`,
            backgroundColor: theme.white,
            color: theme.colors.resirentPink[5],
        },
        color: theme.white,
        backgroundColor: theme.colors.resirentPink[5],
        textAlign: 'center', // Center-align the contents
    },

}));

const CalendarViewHost = () => {

    const { classes } = useStyles();
    const { auth } = useAuth();
    // This should be the website of the user (fetch this too from the backend)
    const username = auth?.resiWeb;
    const title = "calendar-settings";
    const art = "en";

    const [formData, setFormData] = useState({
        pricing: {
            basePrice: {
                amount: 0,
                maxPeoplePrice: 0,
                extraCostPerPerson: 0,
                weekendExtraCost: 0,
            },
            highSeason: {
                amount: 0,
                maxPeoplePrice: 0,
                extraCostPerPerson: 0,
                weekendExtraCost: 0,
            },
            lowSeason: {
                amount: 0,
                maxPeoplePrice: 0,
                extraCostPerPerson: 0,
                weekendExtraCost: 0,
            },
            cleaningFee: {
                included: false,
                amount: 0,
            },
            cityTax: {
                included: false,
                amount: 0,
            },
            dogFee: {
                included: false,
                amount: 0,
            },
            breakfastFee: {
                included: false,
                amount: 0,
            },
            discountMonthly: {
                included: false,
                amount: 0,
            },
            discountWeek: {
                included: false,
                amount: 0,
            },
            parking: {
                included: false,
                amount: 0,
            }
        },
        minNights: 1,
        checkInDays: [{ value: 7, label: 'All' }],
        checkOutcheckInDaysMin: 0,
        cityTaxAge: 0,
        welcomeSentence: '',
        cancellationPolicy: '',
        houseRules: '',
    });

    // fetch the data from the server
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${WEBSITE_URL}`, {
                    params: { username, art, title },
                });
                setFormData(JSON.parse(response.data.entry.content));
            } catch (err) {
                console.log(err);
            }
        };
        fetchData();
    }, [username, art, title]);


    return (
        <div>
            <Group position="center">
                <Title order={2} className={classes.title} my="sm" display={'block'} ta="center">
                    Your Calendar Manager
                </Title>
            </Group>
            {CalendarBookedView(formData)}
        </div>
    );
};

export default CalendarViewHost;

import {
  createStyles,
  Text,
  Title,
  TextInput,
  Button,
  rem,
} from "@mantine/core";
import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: `calc(${theme.spacing.xl} * 3)`,
    backgroundColor: "#003D51",
    fontFamily: "Inter, sans-serif",
    color: "white",
    [theme.fn.smallerThan('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: theme.spacing.xl,
      gap: theme.spacing.md,
    },
  },

  textSection: {
    marginLeft: rem(110),
    maxWidth: "50%",
    textAlign: "left",
    [theme.fn.smallerThan('sm')]: {
      marginLeft: 0,
      maxWidth: '100%',
    },
  },

  title: {
    fontSize: rem(28),
    fontWeight: 700,
    marginBottom: theme.spacing.sm,
    [theme.fn.smallerThan('sm')]: {
      fontSize: rem(20),
    },
  },

  subtitle: {
    fontSize: rem(16),
    fontWeight: 400,
    maxWidth: rem(600),
    lineHeight: 1.5,
    [theme.fn.smallerThan('sm')]: {
      fontSize: rem(14),
    },
  },

  formContainer: {
    marginRight: rem(110),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: rem(8),
    [theme.fn.smallerThan('sm')]: {
      marginRight: 0,
      width: '100%',
    },
  },

  formWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flex: 1,
    [theme.fn.smallerThan('sm')]: {
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: rem(8),
    },
  },

  input: {
    flex: 1,
    borderRadius: rem(4),
    padding: rem(10),
    border: "none",
    fontSize: rem(14),
    color: "#333",
    width: rem(250),
    [theme.fn.smallerThan('sm')]: {
      width: '100%',
    },
  },

  button: {
    marginLeft: rem(10),
    backgroundColor: "black",
    color: "white",
    padding: `${rem(10)} ${rem(20)}`,
    borderRadius: rem(4),
    fontSize: rem(14),
    fontWeight: 600,
    cursor: "pointer",
    border: "none",
    "&:hover": {
      backgroundColor: "#222",
    },
    [theme.fn.smallerThan('sm')]: {
      marginLeft: 0,
      width: '100%',
      fontSize: rem(14),
    },
  },


  privacyText: {
    fontSize: rem(12),
    color: "white",
  },

  privacyLink: {
    color: "#8ac6d1",
    textDecoration: "none",

    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

const translations = {
  '/': {
    "Sign up for our newsletter": "Sign up for our newsletter",
    "Join the ResiRent community! The best way to receive interesting articles about responsible hosting and updates on new upcoming features! Our newsletters are only twice a month, but you can unsubscribe at any time. It is really easy!": "Join the ResiRent community! The best way to receive interesting articles about responsible hosting and updates on new upcoming features! Our newsletters are only twice a month, but you can unsubscribe at any time. It is really easy!",
    "Enter your email": "Enter your email",
    "Subscribe": "Subscribe",
    "We care about your data in our": "We care about your data in our",
    "privacy policy": "privacy policy",
  },
  '/nl': {
    "Sign up for our newsletter": "Meld je aan voor onze nieuwsbrief",
    "Join the ResiRent community! The best way to receive interesting articles about responsible hosting and updates on new upcoming features! Our newsletters are only twice a month, but you can unsubscribe at any time. It is really easy!": "Word lid van de ResiRent-gemeenschap! De beste manier om interessante artikelen over verantwoord hosten en updates over nieuwe functies te ontvangen! Onze nieuwsbrieven verschijnen maar twee keer per maand, maar je kunt je op elk moment afmelden. Het is echt makkelijk!",
    "Enter your email": "Voer je e-mail in",
    "Subscribe": "Abonneren",
    "We care about your data in our": "We geven om je gegevens in ons",
    "privacy policy": "privacybeleid"
  },
  '/de': {
    "Sign up for our newsletter": "Melde dich für unseren Newsletter an",
    "Join the ResiRent community! The best way to receive interesting articles about responsible hosting and updates on new upcoming features! Our newsletters are only twice a month, but you can unsubscribe at any time. It is really easy!": "Tritt der ResiRent-Community bei! Die beste Möglichkeit, interessante Artikel über verantwortungsbewusstes Hosting und Updates zu neuen Funktionen zu erhalten! Unser Newsletter erscheint nur zweimal im Monat, aber du kannst dich jederzeit abmelden. Es ist wirklich einfach!",
    "Enter your email": "Gib deine E-Mail-Adresse ein",
    "Subscribe": "Abonnieren",
    "We care about your data in our": "Wir kümmern uns um deine Daten in unserem",
    "privacy policy": "Datenschutzrichtlinie"
  },
};

export function Newsletter() {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const languagePrefix = window.location.pathname.startsWith('/nl') ? '/nl' : window.location.pathname.startsWith('/de') ? '/de' : '/';

  return (
    <div className={classes.wrapper}>
      <div className={classes.textSection}>
        <Title className={classes.title}>
          {translations[languagePrefix]["Sign up for our newsletter"] || translations["/"]["Sign up for our newsletter"]}
        </Title>
        <Text className={classes.subtitle}>
          {translations[languagePrefix]["Join the ResiRent community! The best way to receive interesting articles about responsible hosting and updates on new upcoming features! Our newsletters are only twice a month, but you can unsubscribe at any time. It is really easy!"] || translations["/"]["Join the ResiRent community! The best way to receive interesting articles about responsible hosting and updates on new upcoming features! Our newsletters are only twice a month, but you can unsubscribe at any time. It is really easy!"]}
        </Text>
      </div>

      <div className={classes.formContainer}>
        <div className={classes.formWrapper}>
          <TextInput className={classes.input} placeholder={translations[languagePrefix]["Enter your email"] || translations["/"]["Enter your email"]} />
          <Button
            className={classes.button}
            component="a"
            href="http://eepurl.com/isztuc"
          >
            {translations[languagePrefix]["Subscribe"] || translations["/"]["Subscribe"]}
          </Button>
        </div>
        <Text className={classes.privacyText}>
          {translations[languagePrefix]["We care about your data in our"] || translations["/"]["We care about your data in our"]}
          {' '}
          <a href="/policies/privacy" className={classes.privacyLink}>
            {translations[languagePrefix]["privacy policy"] || translations["/"]["privacy policy"]}
          </a>
        </Text>
      </div>
    </div>
  );
}

import { useEffect, useState, useRef } from 'react';
import axios from '../../api/axios';
import useAuth from '../../hooks/useAuth';
import { Link, useNavigate } from 'react-router-dom';
import useInput from '../../hooks/useInput';
import useToggle from '../../hooks/useToggle';
import { useTranslation } from "react-i18next";

const LOGIN_URL = '/login';

export default function Login() {

  const { setAuth } = useAuth();
  const { t } = useTranslation();

  const navigate = useNavigate();
  // const location = useLocation();
  // const from = location.state?.from?.pathname || "/";

  const userRef = useRef();
  const errRef = useRef();

  // const [user, setUser] = useState('');
  const [user, resetUser, userAttribs] = useInput('user', '');
  const [pwd, setPwd] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [check] = useToggle('persist', true);

  useEffect(() => {
    userRef.current.focus();
  }, [])

  useEffect(() => {
    setErrMsg('');
  }, [user, pwd])

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setTimeout(async () => {
      try {
        const response = await axios.post(
          LOGIN_URL,
          JSON.stringify({ user, pwd }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );
      const accessToken = response?.data?.accessToken;
      const role = response?.data?.role;
      const id = response?.data?.id;
      const resiWeb = response?.data?.resiWeb;
      const resiCal = response?.data?.resiCal;
      setAuth({ id, user, pwd, role, resiWeb, resiCal, accessToken });
      // setUser('');
      resetUser();
      setPwd('');
      setIsLoading(false);
      navigate('/dashboard/profile', { replace: true }); //TODO: navigate where??
      // navigate(from, {replace: true});

    } catch (err) {
      setIsLoading(false);
      if (!err?.response) {
          setErrMsg("No server response");
      } else if (err.response?.status === 400) {
          setErrMsg("No such E-Mail registered")
      } else if (err.response?.status === 401) {
          setErrMsg("Wrong Password.")
      } else if (err.response?.status === 402) {
          setErrMsg("You are not an admin of this website.")
      } else {
          setErrMsg("Login failed. Unknown error.")
      }
      if (errRef.current) {
        errRef.current.focus();
      }
      console.error(err);
    }
  }, 1000); // Simulate 2 seconds loading time
};

  
return (
  <div
    style={{
      display: "flex",
      minHeight: "100vh",
      alignItems: "center",
      justifyContent: "center",
      padding: "24px 16px",
      background: "white",
    }}
  >
    {isLoading ? (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "16px",
        }}
      >
        <div
          style={{
            width: "50px",
            height: "50px",
            border: "5px solid #f3f3f3",
            borderTop: "5px solid #E4C577",
            borderRadius: "50%",
            animation: "spin 1s linear infinite",
          }}
        />
        <p style={{ color: "#1F2937", fontSize: "16px" }}>Logging in...</p>
        <style>
          {`
            @keyframes spin {
              0% { transform: rotate(0deg); }
              100% { transform: rotate(360deg); }
            }
          `}
        </style>
      </div>
    ) : (
      <div
        style={{
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          borderRadius: "8px",
          width: "100%",
          maxWidth: "400px",
          padding: "24px",
        }}
      >
        <h2
          style={{
            textAlign: "center",
            marginBottom: "24px",
            fontSize: "24px",
            fontWeight: "bold",
            color: "#1F2937",
          }}
        >
          {t("login.title")}
        </h2>
        <form onSubmit={handleSubmit}>
          <div style={{ marginBottom: "16px" }}>
            <label htmlFor="email-address" style={{ display: "none" }}>
              Email address
            </label>
            <input
              id="email-address"
              name="email"
              type="email"
              ref={userRef}
              autoComplete="email"
              required
              placeholder="Email"
              style={{
                width: "100%",
                borderRadius: "4px",
                border: "1px solid #E5E7EB",
                padding: "12px",
                fontSize: "16px",
              }}
              {...userAttribs}
            />
          </div>
          <div style={{ marginBottom: "16px" }}>
            <label htmlFor="password" style={{ display: "none" }}>
              Password
            </label>
            <input
              id="password"
              name="password"
              type="password"
              required
              placeholder="Password"
              style={{
                width: "100%",
                borderRadius: "4px",
                border: "1px solid #E5E7EB",
                padding: "12px",
                fontSize: "16px",
              }}
              value={pwd}
              onChange={(e) => setPwd(e.target.value)}
            />
            {errMsg && (
              <p
                ref={errRef}
                aria-live="assertive"
                style={{
                  color: "#E53E3E",
                  fontSize: "14px",
                  marginTop: "8px",
                }}
              >
                {errMsg}
              </p>
            )}
          </div>
          <button
            type="submit"
            style={{
              width: "100%",
              padding: "12px",
              fontSize: "16px",
              fontWeight: "bold",
              borderRadius: "4px",
              border: "none",
              backgroundColor: "#E4C577",
              color: "#FFFFFF",
              cursor: "pointer",
            }}
          >
            {t("login.login")}
          </button>
        </form>
        <Link
          to="/forgot-password"
          style={{
            display: "block",
            textAlign: "center",
            marginTop: "16px",
            color: "#1F2937",
            fontSize: "14px",
          }}
        >
          {t("login.forgot")}
        </Link>
      </div>
    )}
  </div>
);
}
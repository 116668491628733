import {
    createStyles,
    Container,
    Title,
    Text,
    ThemeIcon,
    SimpleGrid,
    Box,
    Center,
    rem,
} from '@mantine/core';
import {
    IconMail,
    IconBolt,
    IconChartBar,
    IconMoodSmile,
    IconCommand,
    IconSearch,
} from '@tabler/icons-react';
import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme) => ({
    container: {
      backgroundColor: theme.colors.resirent[0],
      color: theme.white,
      paddingTop: rem(100),
      paddingBottom: rem(100),
      textAlign: 'center',
      fontFamily: 'Inter, sans-serif',
      [theme.fn.smallerThan('sm')]: {
        paddingTop: rem(60),
        paddingBottom: rem(60),
        paddingLeft: rem(12),
        paddingRight: rem(12),
      },
    },
    title: {
      fontSize: rem(36),
      fontWeight: 600,
      fontFamily: 'Inter, sans-serif',
      [theme.fn.smallerThan('sm')]: {
        fontSize: rem(22),
      },
    },
    description: {
      fontSize: rem(18),
      maxWidth: '800px',
      margin: '0 auto',
      color: theme.colors.gray[4],
      fontFamily: 'Inter, sans-serif',
      [theme.fn.smallerThan('sm')]: {
        fontSize: rem(14),
      },
    },
    grid: {
      marginTop: rem(40),
    },
    iconWrapper: {
      width: rem(60),
      height: rem(60),
      borderRadius: '50%',
      backgroundColor: '#F06595',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      opacity: 0.9,
      margin: '0 auto',
    },
    featureTitle: {
      fontSize: rem(20),
      fontWeight: 400,
      marginTop: rem(16),
      marginBottom: rem(8),
      fontFamily: 'Inter, sans-serif',
      letterSpacing: "0.8px",
      [theme.fn.smallerThan('sm')]: {
        fontSize: rem(16),
      },
    },
    featureDescription: {
      fontSize: rem(16),
      color: theme.colors.gray[4],
      fontFamily: 'Inter, sans-serif',
      [theme.fn.smallerThan('sm')]: {
        fontSize: rem(13),
      },
    },
  }));

const translations = {
    '/': {
        'Why Us': 'Why Us',
        'Maximize Your Short Term Rental’s Potential': 'Maximize Your Short Term Rental’s Potential',
        'ResiWeb helps you create a professional, cost-effective rental website with full control, direct guest communication, and built-in marketing tools.': 'ResiWeb helps you create a professional, cost-effective rental website with full control, direct guest communication, and built-in marketing tools.',
        'Professional Appearance': 'Professional Appearance',
        'Build trust and attract guests with a high-quality, well-designed website.': 'Build trust and attract guests with a high-quality, well-designed website.',
        'Direct Communication': 'Direct Communication',
        'Engage directly with guests for better service and faster responses.': 'Engage directly with guests for better service and faster responses.',
        'Marketing Tool': 'Marketing Tool',
        'Promote your property through social media and direct outreach.': 'Promote your property through social media and direct outreach.',
        'Full Control': 'Full Control',
        'Manage content, pricing, and rules without third-party restrictions.': 'Manage content, pricing, and rules without third-party restrictions.',
        'Lower Costs': 'Lower Costs',
        'Keep 100% of your earnings with no commission fees on bookings.': 'Keep 100% of your earnings with no commission fees on bookings.',
        'Source of Information': 'Source of Information',
        'Share maps, pricing, availability, and FAQs to help guests book easily.': 'Share maps, pricing, availability, and FAQs to help guests book easily.',
    },
    '/nl': {
        'Why Us': 'Waarom Wij',
        'Maximize Your Short Term Rental’s Potential': 'Maximaliseer Het Potentieel Van Uw Korte Termijn Verhuur',
        'ResiWeb helps you create a professional, cost-effective rental website with full control, direct guest communication, and built-in marketing tools.': 'ResiWeb helpt u bij het creëren van een professionele, kosteneffectieve verhuurwebsite met volledige controle, directe gastcommunicatie en ingebouwde marketingtools.',
        'Professional Appearance': 'Professionele Uitstraling',
        'Build trust and attract guests with a high-quality, well-designed website.': 'Bouw vertrouwen op en trek gasten aan met een hoogwaardige, goed ontworpen website.',
        'Direct Communication': 'Directe Communicatie',
        'Engage directly with guests for better service and faster responses.': 'Communiceer direct met gasten voor betere service en snellere reacties.',
        'Marketing Tool': 'Marketing Tool',
        'Promote your property through social media and direct outreach.': 'Promoot uw eigendom via sociale media en directe outreach.',
        'Full Control': 'Volledige Controle',
        'Manage content, pricing, and rules without third-party restrictions.': 'Beheer inhoud, prijzen en regels zonder beperkingen van derden.',
        'Lower Costs': 'Lagere Kosten',
        'Keep 100% of your earnings with no commission fees on bookings.': 'Behoud 100% van uw inkomsten zonder commissiekosten op boekingen.',
        'Source of Information': 'Informatiebron',
        'Share maps, pricing, availability, and FAQs to help guests book easily.': 'Deel kaarten, prijzen, beschikbaarheid en FAQ.',
    },
    '/de': {
        'Why Us': 'Warum Wir',
        'Maximize Your Short Term Rental’s Potential': 'Maximieren Sie Das Potenzial Ihrer Kurzzeitvermietung',
        'ResiWeb helps you create a professional, cost-effective rental website with full control, direct guest communication, and built-in marketing tools.': 'ResiWeb hilft Ihnen, eine professionelle, kostengünstige Vermietungswebsite mit vollständiger Kontrolle, direkter Gästekommunikation und integrierten Marketing-Tools zu erstellen.',
        'Professional Appearance': 'Professionelles Erscheinungsbild',
        'Build trust and attract guests with a high-quality, well-designed website.': 'Bauen Sie Vertrauen auf und ziehen Sie Gäste mit einer hochwertigen, gut gestalteten Website an.',
        'Direct Communication': 'Direkte Kommunikation',
        'Engage directly with guests for better service and faster responses.': 'Kommunizieren Sie direkt mit Gästen für besseren Service und schnellere Antworten.',
        'Marketing Tool': 'Marketing-Tool',
        'Promote your property through social media and direct outreach.': 'Bewerben Sie Ihre Immobilie über soziale Medien und direkte Ansprache.',
        'Full Control': 'Volle Kontrolle',
        'Manage content, pricing, and rules without third-party restrictions.': 'Verwalten Sie Inhalte, Preise und Regeln ohne Einschränkungen durch Dritte.',
        'Lower Costs': 'Niedrigere Kosten',
        'Keep 100% of your earnings with no commission fees on bookings.': 'Behalten Sie 100% Ihrer Einnahmen ohne Provisionsgebühren auf Buchungen.',
        'Source of Information': 'Informationsquelle',
        'Share maps, pricing, availability, and FAQs to help guests book easily.': 'Teilen Sie Karten, Preise, Verfügbarkeit und FAQs, um Gästen die Buchung zu erleichtern.',
    },
};

const features = [
    { icon: IconMail, title: 'Professional Appearance', description: 'Build trust and attract guests with a high-quality, well-designed website.' },
    { icon: IconBolt, title: 'Direct Communication', description: 'Engage directly with guests for better service and faster responses.' },
    { icon: IconChartBar, title: 'Marketing Tool', description: 'Promote your property through social media and direct outreach.' },
    { icon: IconMoodSmile, title: 'Full Control', description: 'Manage content, pricing, and rules without third-party restrictions.' },
    { icon: IconCommand, title: 'Lower Costs', description: 'Keep 100% of your earnings with no commission fees on bookings.' },
    { icon: IconSearch, title: 'Source of Information', description: 'Share maps, pricing, availability, and FAQs to help guests book easily.' },
];

export default function MotivationSection() {
    const { classes } = useStyles();
    const { t } = useTranslation();
    const languagePrefix = window.location.pathname.startsWith('/nl') ? '/nl' : window.location.pathname.startsWith('/de') ? '/de' : '/';

    return (
        <Container size="xl" className={classes.container}>
            <Text size="sm" weight={500}>
                {translations[languagePrefix]['Why Us'] || translations['/']['Why Us']}
            </Text>
            <Title className={classes.title}>
                {translations[languagePrefix]['Maximize Your Short Term Rental’s Potential'] || translations['/']['Maximize Your Short Term Rental’s Potential']}
            </Title>
            <Text className={classes.description}>
                {translations[languagePrefix]['ResiWeb helps you create a professional, cost-effective rental website with full control, direct guest communication, and built-in marketing tools.'] ||
                    translations['/']['ResiWeb helps you create a professional, cost-effective rental website with full control, direct guest communication, and built-in marketing tools.']}
            </Text>
            <SimpleGrid cols={3} spacing={40} breakpoints={[{ maxWidth: 'md', cols: 2 }, { maxWidth: 'sm', cols: 1 }]} className={classes.grid}>
                {features.map((feature, index) => (
                    <Box key={index}>
                        <Center>
                            <ThemeIcon className={classes.iconWrapper} size="xl" radius="xl">
                                <feature.icon size={32} color="white" stroke={1.5} />
                            </ThemeIcon>
                        </Center>
                        <Text className={classes.featureTitle}>
                            {translations[languagePrefix][feature.title] || translations['/'][feature.title]}
                        </Text>
                        <Text className={classes.featureDescription}>
                            {translations[languagePrefix][feature.description] || translations['/'][feature.description]}
                        </Text>
                    </Box>
                ))}
            </SimpleGrid>
        </Container>
    );
}

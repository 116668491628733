import { createStyles, Accordion, Group, rem, Container, Title, Box } from '@mantine/core';

const useStyles = createStyles((theme) => ({
    wrapper: {
        paddingTop: `calc(${theme.spacing.xl} * 2)`,
        paddingBottom: `calc(${theme.spacing.xl} * 2)`,
    },

    title: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(24),
            maxWidth: '100%',
        },
        maxWidth: '66%',
    },

    item: {
        fontSize: theme.fontSizes.sm,
        backgroundColor: theme.colorScheme === 'dark' ? "#FFF5F9" : "#FFF5F9",
    },
}));

const placeholder =
    'It can’t help but hear a pin drop from over half a mile away, so it lives deep in the mountains where there aren’t many people or Pokémon.';

const mockdata = [
    {
        question: {
            '/': '1. Legal Requirements',
            '/nl': 'Waarom hanteert ResiRent een abonnementen formule?',
            '/de': 'Warum verwendet ResiRent ein Abonnementmodell?',
        },
        answer: {
            '/': 'Navigating the legal landscape is crucial when establishing and managing a holiday rental. Our expertise ensures you are well-informed about local regulations, permits, and compliance standards. We guide you through the process of obtaining necessary licenses, meeting safety requirements, and understanding tax implications. By addressing legal aspects comprehensively, we help you build a solid foundation for your holiday rental business, fostering trust with guests and local authorities alike.',
            '/nl': 'ResiRent biedt een service gedurende het jaar bij problemen, neemt de hosting van de website voor haar rekening en werkt continu aan verbeteringen op basis van feedback van de klanten.',
            '/de': 'ResiRent bietet das ganze Jahr über Service zur Lösung von Problemen, kümmert sich um das Hosting der Website und arbeitet kontinuierlich an Verbesserungen auf der Grundlage des Kundenfeedbacks.',
        },
    },
    {
        question: {
            '/': '2. Strategic decisions',
            '/nl': 'Waarom wordt er een kost in rekening gebracht als ik de doe het zelf formule kies?',
            '/de': 'Warum entstehen Kosten, wenn ich das DIY-Paket wähle?',
        },
        answer: {
            '/': "Crafting a successful go-to-market strategy is pivotal for the prosperity of your holiday rental. Our consulting services delve into strategic decision-making, offering insights into market analysis and return on investment assessments. Whether you're a first-time host or looking to enhance your existing property's performance, we provide strategic guidance to optimize your rental's visibility, pricing, and overall market positioning.",
            '/nl': 'Dit dekt enerzijds de kost voor het maken van de on-line tutorials en de handleiding die jou het mogelijk maken om zonder problemen je website te creëren en is anderzijds een vergoeding voor de hulplijn.',
            '/de': 'Our customers are property owners or operators with up to 2 accommodations in one building. We are here for small hosts.',
        },
    },
    {
        question: {
            '/': '3. The start-up phase',
            '/nl': 'Wie is eigenaar van de domeinnaam?',
            '/de': 'Wer ist Eigentümer des Domainnamens?',
        },
        answer: {
            '/': "Embarking on your journey in holiday rental management requires careful planning and execution. Our consulting services guide you through the initial phases, covering essential aspects like investments in movable goods, assigning responsibilities, and establishing effective communication channels. From operational planning to ensuring legal compliance, we provide a comprehensive roadmap to kickstart your holiday rental venture with confidence.",
            '/nl': 'Dat ben jij, eigenaar en/of uitbater van de accommodatie.',
            '/de': 'Das sind Sie, als Eigentümer und/oder Betreiber der Unterkunft.',
        },
    },
    {
        question: {
            '/': '4. Online Presence',
            '/nl': 'Opzegtermijn Abonnement?',
            '/de': 'Kündigungsfrist Abonnement?',
        },
        answer: {
            '/': "In the digital age, a robust online presence is crucial for the success of your holiday rental. We guide you through establishing a compelling online identity, choosing the right platforms, and optimizing your property's visibility. From crafting engaging listings to leveraging social media, our expertise ensures that your holiday home stands out in the competitive online marketplace, attracting the right guests and maximizing your property's potential.",
            '/nl': 'Het Abonnement is jaarlijks opzegbaar. Wij sturen je 1 maand voor de vervaldatum de vraag of u nog verder geabonneerd wenst te blijven. Als je het abonnement opzegt, heb je geen toegang meer tot de websitebouwer. Je behoudt je eigen domeinnaam en zal dan voor het beheer en de betaling zelf instaan.',
            '/de': 'Das Abonnement ist jährlich kündbar. Wir senden Ihnen einen Monat vor dem Ablaufdatum eine Anfrage zur Verlängerung. Wenn Sie das Abonnement kündigen, verlieren Sie den Zugriff auf den Website-Builder. Sie behalten Ihren eigenen Domainnamen und sind für dessen Verwaltung und Zahlung verantwortlich.',
        },
    },
    {
        question: {
            '/': '5. Operational Management',
            '/nl': 'Opzegtermijn Abonnement?',
            '/de': 'Kündigungsfrist Abonnement?',
        },
        answer: {
            '/': "Efficient operational management is the backbone of a successful holiday rental. We delve into the nitty-gritty of day-to-day tasks, offering insights into housekeeping protocols, effective communication strategies, and streamlining your property's operations. Our hands-on approach ensures that you're well-equipped to handle the practical aspects of hosting, creating a seamless experience for both you and your guests.",
            '/nl': 'Het Abonnement is jaarlijks opzegbaar. Wij sturen je 1 maand voor de vervaldatum de vraag of u nog verder geabonneerd wenst te blijven. Als je het abonnement opzegt, heb je geen toegang meer tot de websitebouwer. Je behoudt je eigen domeinnaam en zal dan voor het beheer en de betaling zelf instaan.',
            '/de': 'Das Abonnement ist jährlich kündbar. Wir senden Ihnen einen Monat vor dem Ablaufdatum eine Anfrage zur Verlängerung. Wenn Sie das Abonnement kündigen, verlieren Sie den Zugriff auf den Website-Builder. Sie behalten Ihren eigenen Domainnamen und sind für dessen Verwaltung und Zahlung verantwortlich.',
        },
    },
];


export function Features() {
    const languagePrefix = window.location.pathname.startsWith('/nl') ? '/nl' : window.location.pathname.startsWith('/de') ? '/de' : '/';
    const { classes } = useStyles();
    return (
        <div className={classes.wrapper}>
            <Container size="lg">
                <Group position="center">
                    <Title mb="md" order={2} className={classes.title} ta="center">
                        Key Considerations
                    </Title>
                </Group>

                <Box m="md">
                    <Accordion defaultValue="" chevronPosition="right" variant="separated">
                        {mockdata.map((feature) => (
                            <Accordion.Item key={feature.question[languagePrefix]} value={feature.question[languagePrefix]} className={classes.item}>
                                <Accordion.Control> {feature.question[languagePrefix]}</Accordion.Control>
                                <Accordion.Panel>{feature.answer[languagePrefix]}</Accordion.Panel>
                            </Accordion.Item>
                        ))}
                    </Accordion>

                </Box>
            </Container>
        </div>
    );
}
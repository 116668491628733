import {
  createStyles,
  Accordion,
  Container,
  Title,
  Text,
  Box,
  rem,
} from "@mantine/core";
import { IconPlus, IconMinus } from "@tabler/icons-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: `calc(${theme.spacing.xl} * 2)`,
    paddingBottom: `calc(${theme.spacing.xl} * 2)`,
  },

  title: {
    textAlign: "center",
    fontSize: rem(28),
    fontWeight: 700,
    fontFamily: "Inter, sans-serif",
    color: "#111827",
    [theme.fn.smallerThan('sm')]: {
      fontSize: rem(22),
    },
  },

  subtitle: {
    textAlign: "center",
    color: "#6B7280",
    marginTop: theme.spacing.xs,
    marginBottom: theme.spacing.xl,
    fontFamily: "Inter, sans-serif",
    fontSize: rem(16),
    [theme.fn.smallerThan('sm')]: {
      fontSize: rem(14),
    },
  },

  answer: {
    color: "#4B5563",
    fontSize: rem(14),
    lineHeight: 1.6,
    marginTop: rem(8),
    fontFamily: "Inter, sans-serif",
    paddingLeft: rem(36),
    [theme.fn.smallerThan('sm')]: {
      fontSize: rem(13),
    },
  },

  control: {
    fontWeight: 600,
    fontSize: rem(16),
    fontFamily: "Inter, sans-serif",
    color: "#111827",
    paddingRight: rem(4),
    [theme.fn.smallerThan('sm')]: {
      fontSize: rem(14),
    },
  },

  item: {
    borderBottom: `1px solid ${theme.colors.gray[3]}`,
    padding: `${theme.spacing.md} 0`,
  },

  iconCircle: {
    width: rem(28),
    height: rem(28),
    minWidth: rem(28),
    borderRadius: rem(50),
    backgroundColor: "#ffffff",
    border: `1.5px solid #003D51`,
    color: "#003D51",
    fontSize: rem(16),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 700,
  },
}));

const mockdata = [
  {
    question: {
      "/": "Is the ResiRent content only meant for Small hosts?",
      "/nl": "Is de ResiRent inhoud alleen bedoeld voor kleine hosts?",
      "/de": "Ist der ResiRent-Inhalt nur für kleine Gastgeber gedacht?",
    },
    answer: {
      "/": "Certainly not! The ResiRent tips and blog posts can be enjoyed by everyone, including hosts and tourists. In fact, we encourage collaboration between tourists and hosts.",
      "/nl": "Zeker niet! De ResiRent-tips en blogposts kunnen door iedereen worden genoten, inclusief hosts en toeristen. In feite moedigen we samenwerking tussen toeristen en hosts aan.",
      "/de": "Ganz bestimmt nicht! Die ResiRent-Tipps und Blog-Beiträge können von jedem genossen werden, einschließlich Gastgebern und Touristen. Tatsächlich fördern wir die Zusammenarbeit zwischen Touristen und Gastgebern.",
    },
  },
  {
    question: {
      "/": "What are tips for anyone wanting to start their own holiday rental?",
      "/nl": "Wat zijn tips voor iedereen die een eigen vakantieverhuur wil beginnen?",
      "/de": "Was sind Tipps für alle, die ihre eigene Ferienunterkunft starten möchten?",
    },
    answer: {
      "/": "Start by checking with your local government and building rules. Then focus on creating a welcoming space, promoting sustainability, and using platforms like ResiRent to gain visibility.",
      "/nl": "Begin met het controleren van de lokale overheid en bouwregels. Richt je daarna op het creëren van een gastvrije ruimte, het bevorderen van duurzaamheid en het gebruik van platforms zoals ResiRent om zichtbaarheid te krijgen.",
      "/de": "Begin damit, die örtlichen Vorschriften und Bauvorschriften zu überprüfen. Konzentrieren Sie sich dann darauf, einen einladenden Raum zu schaffen, Nachhaltigkeit zu fördern und Plattformen wie ResiRent zu nutzen, um Sichtbarkeit zu erlangen.",
    },
  },
  {
    question: {
      "/": "Does Anja have any certificates in sustainable tourism?",
      "/nl": "Heeft Anja certificaten in duurzaam toerisme?",
      "/de": "Hat Anja Zertifikate im Bereich nachhaltiger Tourismus?",
    },
    answer: {
      "/": "Yes, she does! Anja has obtained multiple certificates in sustainable tourism and is dedicated to eco-friendly practices.",
      "/nl": "Ja, dat heeft ze! Anja heeft meerdere certificaten behaald in duurzaam toerisme en zet zich in voor milieuvriendelijke praktijken.",
      "/de": "Ja, das hat sie! Anja hat mehrere Zertifikate im Bereich nachhaltiger Tourismus erworben und setzt sich für umweltfreundliche Praktiken ein.",
    },
  },
  {
    question: {
      "/": "Can I join the ResiRent team?",
      "/nl": "Kan ik me bij het ResiRent-team voegen?",
      "/de": "Kann ich dem ResiRent-Team beitreten?",
    },
    answer: {
      "/": "Absolutely! We are always looking for enthusiastic people to join us.",
      "/nl": "Zeker! We zijn altijd op zoek naar enthousiaste mensen om ons te versterken.",
      "/de": "Absolut! Wir sind immer auf der Suche nach begeisterten Menschen, die uns verstärken.",
    },
  },
  {
    question: {
      "/": "How Can I Contact Support?",
      "/nl": "Hoe kan ik contact opnemen met de klantenservice?",
      "/de": "Wie kann ich den Support kontaktieren?",
    },
    answer: {
      "/": "Just head to resirent.org/contact.",
      "/nl": "Ga gewoon naar resirent.org/contact.",
      "/de": "Gehen Sie einfach zu resirent.org/contact.",
    },
  },
  {
    question: {
      "/": "Do I Need to Create an Account?",
      "/nl": "Moet ik een account aanmaken?",
      "/de": "Muss ich ein Konto erstellen?",
    },
    answer: {
      "/": "Nope! Blog content is open to all. But for using tools like your calendar and dashboard, you’ll need to sign up.",
      "/nl": "Nee! Bloginhoud is voor iedereen toegankelijk. Maar voor het gebruik van tools zoals je agenda en dashboard, moet je je wel aanmelden.",
      "/de": "Nein! Blog-Inhalte sind für alle zugänglich. Um jedoch Tools wie deinen Kalender und dein Dashboard zu nutzen, musst du dich anmelden.",
    },
  },
];

const translations = {
  '/': {
    'Frequently asked questions': 'Frequently asked questions',
    'Everything you need to know about the product and billing.': 'Everything you need to know about the product and billing.',
  },
  '/nl': {
    'Frequently asked questions': 'Veelgestelde vragen',
    'Everything you need to know about the product and billing.': 'Alles wat u moet weten over het product en facturering.',
  },
  '/de': {
    'Frequently asked questions': 'Häufig gestellte Fragen',
    'Everything you need to know about the product and billing.': 'Alles, was Sie über das Produkt und die Abrechnung wissen müssen.',
  }
};

export function FAQ() {
  const [openedItem, setOpenedItem] = useState(null);
  const { t } = useTranslation();
  const languagePrefix = window.location.pathname.startsWith('/nl') ? '/nl' : window.location.pathname.startsWith('/de') ? '/de' : '/';

  // const languagePrefix = window.location.pathname.startsWith("/nl")
  //   ? "/"
  //   : window.location.pathname.startsWith("/de")
  //   ? "/"
  //   : "/";
  const { classes } = useStyles();

  return (
    <div className={classes.wrapper}>
      <Container size="md">
        <Title order={2} className={classes.title}>
          {translations[languagePrefix]?.['Frequently asked questions'] || translations['/']['Frequently asked questions']}
        </Title>
        <Text className={classes.subtitle}>
          {translations[languagePrefix]?.['Everything you need to know about the product and billing.'] || translations['/']['Everything you need to know about the product and billing.']}
        </Text>

        <Accordion
          value={openedItem}
          onChange={setOpenedItem}
          classNames={{
            item: classes.item,
            content: classes.answer,
          }}
          chevronPosition="left"
          disableChevronRotation
          styles={{ chevron: { display: "none" } }} // hide default
        >
          {mockdata.map((faq) => {
            const isOpen = openedItem === faq.question[languagePrefix];

            return (
              <Accordion.Item
                key={faq.question[languagePrefix]}
                value={faq.question[languagePrefix]}
              >
                <Accordion.Control className={classes.control}>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <span>{faq.question[languagePrefix]}</span>
                    <span className={classes.iconCircle}>
                      {isOpen ? "–" : "+"}
                    </span>
                  </Box>
                </Accordion.Control>

                <Accordion.Panel>{faq.answer[languagePrefix]}</Accordion.Panel>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </Container>
    </div>
  );
}
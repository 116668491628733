import React from 'react';
import { useEffect } from 'react';
import { Container, Title, Text, List, Anchor, Group } from '@mantine/core';

const SubHeading = ({ children, order }) => (
    <Title order={order} mt="xl" mb="sm" style={{ color: '#f783ac' }}>{children}</Title>
);

const TermsEN = () => (
    <>
        <Container size="md" py="xl">
            <Title align="center" mb="md" style={{ color: '#003d51' }}>
                Terms and conditions
            </Title>
            <Text align="center" color="dimmed" mb="lg">
                Last updated on November 21, 2024
            </Text>
            <SubHeading order={2}>
            1. Definitions
            </SubHeading>
            <List size="md" spacing="md" mb="md">
                <List.Item>
                    <strong>Software Provider:</strong> ResiRent, the provider of the software and consulting services.
                </List.Item>
                <List.Item>
                    <strong>User:</strong> The individual or entity using the software.
                </List.Item>
                <List.Item>
                    <strong>Service:</strong> The services provided by the software, such as website development, calendar
                    management, and consulting.
                </List.Item>
            </List>

            <SubHeading order={2}>
               2. Acceptance of Terms
            </SubHeading>
            <Text size="md" mb="md">
                By using the software, you acknowledge that you have read, understood, and agree to these
                terms and conditions. If you do not agree to these terms and conditions, you must refrain
                from using the software provided by ResiRent.
            </Text>

            <SubHeading order={2}>
                3. License and Usage Rights
            </SubHeading>
            <Text size="md" mb="md">
                ResiRent grants you a non-exclusive, non-transferable license to use the software in
                accordance with these terms. You may not redistribute, decompile, or modify the software.
            </Text>
            <SubHeading order={2}>
                4. Service and Product Description
            </SubHeading>
            <Text mt="lg" mb="sm">
                4.1 Website Template for Vacation Rentals
            </Text>
            <Text size="md" mb="md">
                ResiRent offers a pre-designed, pre-programmed website template specifically for vacation
                rentals. This template includes basic functionalities and layouts, allowing clients to easily
                create a personalized website by adding their own content, images, descriptions, and other
                property-related information. The template is fully customizable, so texts, images, and other
                non-structural elements can be modified.
            </Text>

            <Text mt="lg" mb="sm">
                4.2 Responsibility for Content Management
            </Text>
            <Text size="md" mb="md">
                Although ResiRent provides a flexible and customizable platform, the client is fully
                responsible for uploading, updating, and maintaining all content on their website. This
                includes, but is not limited to, property descriptions, photos, prices, booking information, and
                any legal or policy-related content. ResiRent is not responsible for the accuracy or legal
                compliance of the content uploaded by the client.
            </Text>

            <Text mt="lg" mb="sm">
                4.3 Consultancy and Advisory Services
            </Text>
            <Text mt="sm" mb="sm">
                4.3.1 General Advice for Vacation Rentals
            </Text>
            <Text size="md" mb="md">
                ResiRent offers general advisory services to help clients set up and manage a successful
                vacation rental. These advisory services are provided as general guidelines and are not
                tailored to the legal, financial, or operational needs of the client’s specific situation. All
                recommendations should be considered as suggestions and do not constitute guarantees or
                binding instructions.
            </Text>
            <Text mt="sm" mb="sm">
                4.3.2 Limitations of Liability for Advice
            </Text>
            <Text size="md" mb="md">
                The client acknowledges that ResiRent’s advice, while based on industry best practices, is not
                legally binding advice and does not replace professional consultation in areas such as
                taxation, legislation, or property management. ResiRent is not liable for any consequences
                arising from the client’s application of our advice.
                This text outlines the responsibilities of the client and ResiRent, emphasizing that the
                platform provides a customizable website template but leaves the content management and
                legal responsibility to the client. It also clarifies that the advisory services are general and not
                legally binding.
            </Text>

            <SubHeading order={2}>
                5. Maintenance and Update of the Website
            </SubHeading>
            <Text mt="lg" mb="sm">
                5.1 Responsibility for Updates and Changes
            </Text>
            <Text size="md" mb="md">
                The client is responsible for all updates, changes, and maintenance of the website after its
                launch. While the ResiRent platform allows for website customizations, ResiRent does not
                guarantee compatibility with third-party software or plugins added by the client and is not
                liable for any issues arising from such additions.
            </Text>

            <Text mt="lg" mb="sm">
                5.2 Periodic System Updates
            </Text>
            <Text size="md" mb="md">
                ResiRent reserves the right to perform periodic updates to the underlying code and structure
                of the website template to improve performance, security, or usability. While we strive to
                make these updates as seamless as possible, certain customizations or third-party
                integrations made by the client may need to be reconfigured or adjusted.
                This section highlights the client’s responsibility for maintaining their website post-launch
                and the potential need for adjustments if third-party software or plugins are used. It also
                outlines ResiRent’s right to perform system updates that may require the client to make
                changes to customizations.
            </Text>
            <SubHeading order={2}>
                6. Limitations of Liability
            </SubHeading>
            <Text mt="lg" mb="sm">
                6.1 Availability of the Service and Technical Issues
            </Text>
            <Text size="md" mb="md">
                Although ResiRent strives for high availability and functionality of our platform, we cannot guarantee uninterrupted access or completely error-free operation of the website template or advisory services. ResiRent is not liable for any loss, damage, or costs arising from technical problems, service interruptions, or data loss.
            </Text>

            <Text mt="lg" mb="sm">
                6.2 Disclaimer of Warranties
            </Text>
            <Text size="md" mb="md">
                ResiRent provides all services "as is" without any form of warranty, express or implied. The client assumes all risks associated with the use of the website template and advisory services. ResiRent disclaims all warranties, including but not limited to, implied warranties of fitness for a particular purpose, merchantability, and non-infringement.
            </Text>

            <SubHeading order={2}>
                7. Changes to the Terms and Conditions
            </SubHeading>
            <Text size="md" mb="md">
                ResiRent reserves the right to modify these Terms and Conditions at any time. Changes will be communicated to the client and will take effect immediately upon notification. By continuing to use ResiRent's services after being notified of changes, the client agrees to the updated Terms and Conditions.
            </Text>
            <Text size="md" mb="md">
                These Terms and Conditions constitute the entire agreement between ResiRent and the client. For any questions or clarifications, please contact our customer service.
            </Text>

            <SubHeading order={2}>
                8. User Responsibilities
            </SubHeading>
            <Text size="md" mb="md">
                As a user of the software, you are solely responsible for the way in which you use it. The Software Provider is not responsible for any damage or loss arising from your misuse or incorrect use of the software.
            </Text>
            <Text mt="lg" mb="sm">
                Compliance with Laws:
            </Text>
            <Text size="md" mb="md">
                ResiRent is designed to simplify and improve the management of vacation accommodations for owners and landlords. It is important to note that the use of our software must always comply with local regulations (e.g., having a tourist license or VAT number), rules of the Homeowners' Association, and the regulations of the Owners' Association (VvE). We do not support or endorse activities that violate these laws or agreements. Users are responsible for ensuring that their use of our software is fully compliant with all relevant legal and contractual obligations.
            </Text>
            <Text mt="lg" mb="sm">
                Accuracy of Information:
            </Text>
            <Text size="md" mb="md">
            Users must ensure that all entered data is accurate and up-to-date.
            </Text>

            <SubHeading order={2}>
                8. Fees and payments
            </SubHeading>
            <Text size="md" mb="md">
                Payment Terms: Payment Methods: via bank transfer or online payment through the website.
            </Text>
            <Text size="md" mb="md">
                One-time Fee: setup of the website and/or calendar plus onboarding.
            </Text>
            <Text size="md" mb="md">
                Annual Subscription: begins 2 months after setup and after quality check by ResiRent and the client.
            </Text>


            <SubHeading order={2}>
                9. Privacy and data protection
            </SubHeading>
            <Text size="md" mb="md">
                Data Collection: Types of data collected and how they are used: see the document regarding ResiRent's privacy policy.
            </Text>
            <Text size="md" mb="md">
                Privacy Policy: We refer to the document regarding ResiRent's privacy policy.
            </Text>

            <SubHeading order={2}>
                10. Liability protection
            </SubHeading>
            <Text size="md" mb="md">
                The Software Provider, ResiRent, is not liable for any direct, indirect, incidental,consequential, or exemplary damages, including but not limited to damages for loss of profits, data, or other intangible losses, arising from or in connection with the use or inabili to use the software.
            </Text>

            <SubHeading order={2}>
                11. Indemnity
            </SubHeading>
            <Text size="md" mb="md">
                Users indemnify ResiRent, the software provider, against any claims, damages, liabilities, or costs arising from the use of the software or the violation of the Terms and Conditions.
            </Text>

            <SubHeading order={2}>
                12. Changes to the service
            </SubHeading>
            <Text size="md" mb="md">
                The Software Provider, ResiRent, reserves the right to modify, suspend, or discontinue any aspects of the software at any time without prior notice. This includes, but is not limited to,features, functionalities, and access to the software.
            </Text>

            <SubHeading order={2}>
                13. Termination
            </SubHeading>
            <Text size="md" mb="md">
                Conditions under which the agreement can be terminated by both parties.
            </Text>
            <Text size="md" mb="md">
                The client may terminate the agreement at any time. The actual termination will begin at theend of the month in which the notice is given. This notice must be provided via email or letter.
            </Text>

            <SubHeading order={2}>
                14. Intellectual property
            </SubHeading>
            <Text size="md" mb="md">
                All intellectual property rights related to the software, including but not limited to copyrights,trademarks, patents, and trade secrets, are the exclusive property of the Software Provider.You agree not to reproduce, modify, distribute, or create derivative works based on the software without the prior written consent of the Software Provider.
            </Text>

            <SubHeading order={2}>
                15. Warranty
            </SubHeading>
            <Text size="md" mb="md">
                The software is provided on an "as is" basis without any warranties or representations,express or implied, including but not limited to the implied warranties of merchantability,fitness for a particular purpose, or non-infringement. The Software Provider does not guarantee the accuracy, reliability, or completeness of the software's functionality.
            </Text>

            <SubHeading order={2}>
                16. Applicable law and dispute
            </SubHeading>
            <Text size="md" mb="md">
                Applicable Law: These terms and conditions, as well as all agreements concluded between ResiRent and its clients, are governed exclusively by Swiss law. The United Nations Convention on Contracts for the International Sale of Goods (CISG) is expressly excluded.
            </Text>
            <Text size="md" mb="md">
                Dispute Resolution: In the event of disputes arising from or related to these terms and conditions, the parties will first attempt to resolve the dispute amicably. If an amicable settlement is not possible, the dispute will be submitted to the competent court in Zürich.
            </Text>
            <Text size="md" mb="md">
                Competent Court: Exclusive jurisdiction for disputes lies with the competent courts of Zurich,Switzerland.
            </Text>
            <Text size="md" mb="md">
                Other Provisions: If any provision of these terms and conditions is deemed invalid or unenforceable, the remaining provisions will remain fully effective. The invalid or unenforceable provision will be replaced by a valid provision that most closely reflects the intention of the original provision.
            </Text>

            <SubHeading order={2}>
                Miscellaneous
            </SubHeading>
            <Text size="md" mb="md">
                Entire Agreement: Statement that the T&C constitute the entire agreement between the parties.
            </Text>
            <Text size="md" mb="md">
                Sever ability: If any provision is deemed invalid, the remaining provisions will remain in effect
            </Text>
            <Text size="md" mb="md">
                Changes to the Terms and Conditions:** Procedure for making changes to the T&C.
            </Text>
            
            <div style={{ padding: '20px', backgroundColor: '#f9f9f9', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <Text size="md" weight={700} style={{ color: '#1a3d4c' }}>Brand Name: ResiRent</Text>
                
                <Text size="md" weight={700} style={{ color: '#1a3d4c' }}>Sustainable Small Accommodations Bulteel, CHE291 088 716</Text>
                
                <Text size="md" weight={700} style={{ color: '#1a3d4c' }}>Address: Schützenrütistrasse 10, 8044 Gockhausen, Switzerland</Text>
                
                <Text size="md" weight={700} style={{ color: '#1a3d4c' }}>Email:
                    <Anchor href="mailto:anjabulteel@me.com" style={{ textDecoration: 'none', color: '#1a73e8', fontWeight: 500 }}> anjabulteel@me.com</Anchor>
                </Text>
                
                <Text size="md" weight={700} style={{ color: '#1a3d4c' }}>Phone: +41 764 240 582</Text>
                
                <Text size="md" weight={700} style={{ color: '#1a3d4c' }}>Website: 
                    <Anchor href="https://resirent.org" target="_blank" style={{ textDecoration: 'none', color: '#1a73e8', fontWeight: 500 }}> ResiRent.org</Anchor>
                </Text>
            </div>


        </Container>
</>
);

const TermsNL = () => (
    <>
      <Container size="md" py="xl">
            <Title align="center" mb="md" style={{ color: '#003d51' }}>
                Algemene Voorwaarden
            </Title>
            <Text align="center" color="dimmed" mb="lg">
                Last updated on November 21, 2024
            </Text>
            <SubHeading order={2}>
                1. Definities
            </SubHeading>
            <List size="md" spacing="md" mb="md">
                <List.Item>
                    <strong>Software Provider:</strong> ResiRent, aanbieder van de software en consulting.
                </List.Item>
                <List.Item>
                    <strong>User:</strong> De persoon of entiteit die de software gebruikt.
                </List.Item>
                <List.Item>
                    <strong>Service:</strong> De diensten die door de software worden geleverd, zoals website ontwikkeling,
                    kalenderbeheer en consulting.
                </List.Item>
            </List>

            <SubHeading order={2}>
               2. Aanvaarding van de voorwaarden
            </SubHeading>
            <Text size="md" mb="md">
                Door de software te gebruiken, erkent u dat u deze algemene voorwaarden hebt gelezen,
                begrepen hebt en ermee akkoord gaat. Als u niet akkoord gaat met deze algemene
                voorwaarden, dient u af te zien van het gebruik van de software die door ResiRent wordt
                geleverd.
            </Text>

            <SubHeading order={2}>
                3. Licentie en gebruiksrechten
            </SubHeading>
            <Text size="md" mb="md">
                ResiRent verleent u een niet-exclusieve, niet-overdraagbare licentie om de software te
                gebruiken in overeenstemming met deze voorwaarden. U mag de software niet
                herdistribueren, decompileren, of wijzigingen aanbrengen.
            </Text>
            <SubHeading order={2}>
                4. Dienst -en productbeschrijving
            </SubHeading>
            <Text mt="lg" mb="sm">
                4.1 Website-template voor Vakantieverhuur
            </Text>
            <Text size="md" mb="md">
                ResiRent biedt een vooraf ontworpen, voorgeprogrammeerde website-template specifiek
                voor vakantieverhuur. Deze template bevat basisfunctionaliteiten en lay-outs, waarmee
                Klanten op een eenvoudige manier een gepersonaliseerde website kunnen creëren door eigen
                content, afbeeldingen, beschrijvingen en andere woninggerelateerde informatie toe tevoegen. De template is volledig aanpasbaar, zodat teksten, afbeeldingen en andere niet-structurele elementen gewijzigd kunnen worden.
            </Text>

            <Text mt="lg" mb="sm">
                4.2 Verantwoordelijkheid voor Contentbeheer
            </Text>
            <Text size="md" mb="md">
                Hoewel ResiRent een flexibel en aanpasbaar platform biedt, is de Klant zelf volledig
                verantwoordelijk voor het uploaden, bijwerken en onderhouden van alle content op hun
                website. Dit omvat, maar is niet beperkt tot, woningbeschrijvingen, foto’s, prijzen,
                boekingsinformatie en eventuele juridische of beleidsmatige inhoud. ResiRent is niet
                verantwoordelijk voor de nauwkeurigheid of juridische conformiteit van content die door de
                Klant wordt geüpload.
            </Text>

            <Text mt="lg" mb="sm">
                4.3 Consultatie- en Adviesdiensten
            </Text>
            <Text mt="sm" mb="sm">
                4.3.1 Algemene Adviezen voor Vakantieverhuur
            </Text>
            <Text size="md" mb="md">
                ResiRent biedt algemene adviesdiensten aan om Klanten te helpen bij het opzetten en
                beheren van een succesvolle vakantieverhuur. Deze adviezen worden verstrekt als algemene
                richtlijnen en zijn niet specifiek afgestemd op de juridische, financiële of operationele
                behoeften van de individuele situatie van de Klant. Alle aanbevelingen dienen beschouwd te
                worden als suggesties en vormen geen garantie of bindende instructies.
            </Text>
            <Text mt="sm" mb="sm">
                4.3.2 Beperkingen van Aansprakelijkheid bij Advies
            </Text>
            <Text size="md" mb="md">
                De Klant erkent dat het advies van ResiRent, hoewel gebaseerd op de beste praktijken binnen
                de branche, geen juridisch bindend advies is en geen vervanging vormt voor professionele
                raadpleging op gebieden zoals belasting, wetgeving of vastgoedbeheer. ResiRent is niet
                aansprakelijk voor gevolgen die voortvloeien uit de toepassing van ons advies door de Klant.
            </Text>

            <SubHeading order={2}>
                5. Onderhoud en update van de website
            </SubHeading>
            <Text mt="lg" mb="sm">
                5.1 Verantwoordelijkheid voor Updates en Wijzigingen
            </Text>
            <Text size="md" mb="md">
                De Klant is verantwoordelijk voor alle updates, wijzigingen en onderhoud van de website na
                de lancering. Hoewel het ResiRent-platform aanpassingen aan de website mogelijk maakt,
                garandeert ResiRent geen compatibiliteit met door de Klant toegevoegde software of plug-ins
                van derden en is niet aansprakelijk voor problemen die voortvloeien uit dergelijke
                toevoegingen.
            </Text>

            <Text mt="lg" mb="sm">
                5.2 Periodieke Systeemupdates
            </Text>
            <Text size="md" mb="md">
                ResiRent behoudt zich het recht voor om periodieke updates door te voeren aan de
                onderliggende code en structuur van de website-template ter verbetering van prestaties,
                veiligheid of bruikbaarheid. Hoewel wij proberen om deze updates zo soepel mogelijk te laten
                verlopen, kunnen bepaalde aanpassingen of integraties van derden door de Klant opnieuw
                geconfigureerd of aangepast moeten worden.
            </Text>
            <SubHeading order={2}>
                6. Aansprakelijkheidsbeperking
            </SubHeading>
            <Text mt="lg" mb="sm">
                6.1 Beschikbaarheid van de Service en Technische Problemen
            </Text>
            <Text size="md" mb="md">
                Hoewel ResiRent streeft naar een hoge beschikbaarheid en functionaliteit van ons platform,
                kunnen wij geen ononderbroken toegang of volledig foutloze werking van de website-
                template of adviesdiensten garanderen. ResiRent is niet aansprakelijk voor verlies, schade of
                kosten door technische problemen, onderbrekingen in de service of gegevensverlies.
            </Text>

            <Text mt="lg" mb="sm">
                6.2 Afwijzing van Garanties
            </Text>
            <Text size="md" mb="md">
                ResiRent levert alle diensten “zoals ze zijn” zonder enige vorm van garantie, expliciet of
                impliciet. De Klant draagt alle risico’s verbonden aan het gebruik van de website-template en
                adviesdiensten. ResiRent wijst alle garanties af, inclusief, maar niet beperkt tot, impliciete
                garanties voor geschiktheid voor een bepaald doel, verkoopbaarheid en niet-inbreuk.
            </Text>

            <SubHeading order={2}>
                7. Wijzigingen in de algemene voorwaarden
            </SubHeading>
            <Text size="md" mb="md">
                ResiRent behoudt zich het recht voor om deze Algemene Voorwaarden op elk moment te wijzigen.
                Wijzigingen worden aan de Klant meegedeeld en gaan direct na deze kennisgeving in. Door
                gebruik te blijven maken van de diensten van ResiRent na kennisgeving van wijzigingen, stemt de
                Klant in met de bijgewerkte Algemene Voorwaarden.
            </Text>
            <Text size="md" mb="md">
                Deze Algemene Voorwaarden vormen de volledige overeenkomst tussen ResiRent en de Klant. Voor
                vragen of verduidelijkingen kunt u contact opnemen met onze klantenservice.
            </Text>

            <SubHeading order={2}>
                8. UGebruikersverantwoordelijkheden
            </SubHeading>
            <Text size="md" mb="md">
                Als gebruiker van de software bent u als enige verantwoordelijk voor de manier waarop u deze
                gebruikt. De Software Provider is niet verantwoordelijk voor enige schade of verliezen die
                voortvloeien uit uw misbruik of onjuist gebruik van de software.
            </Text>
            <Text mt="lg" mb="sm">
                Naleven van wetten:
            </Text>
            <Text size="md" mb="md">
                ResiRent is ontworpen om het beheer van vakantie-accommodaties voor
                eigenaren en verhuurders te vereenvoudigen en te verbeteren. Het is belangrijk op te merken
                dat het gebruik van onze software altijd moet voldoen aan de lokale verordeningen (bv. Het
                hebben van een toeristische licentie of btw-nummer), regels van de Vereniging van Eigenaren
                en de reglementen van de VvE. Wij steunen of ondersteunen geen activiteiten die in strijd zijn
                met deze wetten of overeenkomsten. Gebruikers zijn verantwoordelijk voor ervoor te zorgen
                dat hun gebruik van onze software volledig in overeenstemming is met alle relevante
                wettelijke en contractuele verplichtingen
            </Text>
            <Text mt="lg" mb="sm">
                Nauwkeurigheid van informatie:
            </Text>
            <Text size="md" mb="md">
                Gebruikers moeten ervoor zorgen dat alle ingevoerde
                gegevens juist en up-to-date zijn.
            </Text>

            <SubHeading order={2}>
                8. Vergoedingen en betalingen
            </SubHeading>
            <Text size="md" mb="md">
                Betalingsvoorwaarden: Betalingsmethoden: per overschrijving of online betaling via website.
            </Text>
            <Text size="md" mb="md">
                Eenmalige kost: opstart van de website en/of kalender plus onboarding
            </Text>
            <Text size="md" mb="md">
                Jaarlijks Abonnement: gaat in 2 maanden na de opstart en na kwaliteit check van ResiRent en
                de klant.
            </Text>


            <SubHeading order={2}>
                9. Privacy en gegevensbescherming
            </SubHeading>
            <Text size="md" mb="md">
                Gegevensverzameling: Soorten verzamelde gegevens en hoe deze worden gebruikt: zie
                document rond privacy beleid van ResiRent.
            </Text>
            <Text size="md" mb="md">
                Privacy beleid: We verwijzen naar het document rond het privacy beleid van ResiRent.
            </Text>

            <SubHeading order={2}>
                10. Bescherming van aansprakelijkheid
            </SubHeading>
            <Text size="md" mb="md">
                De Software Provider, ResiRent is niet aansprakelijk voor enige directe, indirecte, incidentele,
                gevolg- of voorbeeldschade, inclusief maar niet beperkt tot schade voor verlies van winst,
                gegevens of andere immateriële verliezen, voortvloeiend uit of in verband met het gebruik of
                het onvermogen om de software te gebruiken.
            </Text>

            <SubHeading order={2}>
                11. Vrijwaring
            </SubHeading>
            <Text size="md" mb="md">
                Gebruikers vrijwaren ResiRent, de softwareprovider van enige claims, schade,
                aansprakelijkheden of kosten voortvloeiend uit het gebruik van de software of schending van
                de T&C.
            </Text>

            <SubHeading order={2}>
                12. Wijzigingen in de dienst
            </SubHeading>
            <Text size="md" mb="md">
                De Software Provider, ResiRent behoudt zich het recht voor om op elk moment en zonder
                voorafgaande kennisgeving aspecten van de software te wijzigen, op te schorten of stop te
                zetten. Dit omvat, maar is niet beperkt tot, functies, functionaliteiten en toegang tot de
                software.
            </Text>

            <SubHeading order={2}>
                13. Beëindiging
            </SubHeading>
            <Text size="md" mb="md">
                Voorwaarden waaronder de overeenkomst kan worden beëindigd door beide partijen.
            </Text>
            <Text size="md" mb="md">
                De klant kan op elk ogenblik de samenwerking stopzetten. De feitelijke stopzetting start op
                het einde van de maand van de melding. Deze melding dient per mail of brief te gebeuren.
            </Text>

            <SubHeading order={2}>
                14. Intellectuele eigendom
            </SubHeading>
            <Text size="md" mb="md">
                Alle intellectuele eigendomsrechten met betrekking tot de software, inclusief maar niet
                beperkt tot auteursrechten, handelsmerken, patenten en handelsgeheimen, zijn het
                exclusieve eigendom van de Software Provider. U stemt ermee in de software niet te
                Gegevensverzameling: Soorten verzamelde gegevens en hoe deze worden gebruikt: zie
                document rond privacy beleid van ResiRent.Privacy beleid: We verwijzen naar het document rond het privacy beleid van ResiRent.
                reproduceren, te wijzigen, te distribueren of afgeleide werken op basis van de software te
                creëren zonder voorafgaande schriftelijke toestemming van de Software Provider.
            </Text>

            <SubHeading order={2}>
                15. Garantie
            </SubHeading>
            <Text size="md" mb="md">
                De software wordt geleverd op een "as is" basis zonder enige garanties of verklaringen,
                expliciet of impliciet, inclusief maar niet beperkt tot de impliciete garanties van
                verkoopbaarheid, geschiktheid voor een bepaald doel of niet-inbreuk. De Software Provider
                garandeert niet de nauwkeurigheid, betrouwbaarheid of volledigheid van de functionaliteit
                van de software.
            </Text>

            <SubHeading order={2}>
                16. Toepasselijk recht en geschillenbeslechting
            </SubHeading>
            <Text size="md" mb="md">
                Toepasselijk Recht: Op deze algemene voorwaarden en op alle overeenkomsten die tussen
                [naam onderneming] en haar klanten worden gesloten, is uitsluitend het Zwitserse recht van
                toepassing. Het Weens Koopverdrag (CISG) wordt uitdrukkelijk uitgesloten.
            </Text>
            <Text size="md" mb="md">
                Geschillenbeslechting: In geval van geschillen die voortvloeien uit of verband houden met
                deze algemene voorwaarden, zullen partijen eerst proberen het geschil in der minne te
                schikken. Indien een minnelijke schikking niet mogelijk is, zal het geschil worden voorgelegd
                aan de bevoegde rechtbank in [plaats, bijv. Zürich of de vestigingsplaats van uw bedrijf].
            </Text>
            <Text size="md" mb="md">
                Bevoegde Rechtbank: De exclusieve jurisdictie voor geschillen ligt bij de bevoegde
                rechtbanken van Zürich, Zwitserland.
            </Text>
            <Text size="md" mb="md">
                Overige bepalingen: Indien een bepaling van deze algemene voorwaarden ongeldig of niet-
                afdwingbaar wordt verklaard, blijven de overige bepalingen volledig van kracht. De ongeldige
                of niet-afdwingbare bepaling zal worden vervangen door een geldige bepaling die het dichtst
                aansluit bij de bedoeling van de oorspronkelijke bepaling.
            </Text>

            <SubHeading order={2}>
                Diversen
            </SubHeading>
            <Text size="md" mb="md">
                Volledige overeenkomst: Verklaring dat de T&C de volledige overeenkomst tussen de
                partijen vormen.
            </Text>
            <Text size="md" mb="md">
                Scheidbaarheid: Indien een bepaling ongeldig wordt verklaard, blijven de overige
                bepalingen van kracht.
            </Text>
            <Text size="md" mb="md">
                Wijzigingen in de Voorwaarden: Procedure voor het aanbrengen van wijzigingen in de T&C.
            </Text>
            
            <div style={{ padding: '20px', backgroundColor: '#f9f9f9', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <Text size="md" weight={700} style={{ color: '#1a3d4c' }}>Brand Name: ResiRent</Text>
                
                <Text size="md" weight={700} style={{ color: '#1a3d4c' }}>Sustainable Small Accommodations Bulteel, CHE291 088 716</Text>
                
                <Text size="md" weight={700} style={{ color: '#1a3d4c' }}>Address: Schützenrütistrasse 10, 8044 Gockhausen, Switzerland</Text>
                
                <Text size="md" weight={700} style={{ color: '#1a3d4c' }}>Email:
                    <Anchor href="mailto:anjabulteel@me.com" style={{ textDecoration: 'none', color: '#1a73e8', fontWeight: 500 }}> anjabulteel@me.com</Anchor>
                </Text>
                
                <Text size="md" weight={700} style={{ color: '#1a3d4c' }}>Phone: +41 764 240 582</Text>
                
                <Text size="md" weight={700} style={{ color: '#1a3d4c' }}>Website: 
                    <Anchor href="https://resirent.org" target="_blank" style={{ textDecoration: 'none', color: '#1a73e8', fontWeight: 500 }}> ResiRent.org</Anchor>
                </Text>
            </div>


        </Container>
</>
);

const TermsDE = () => (
    <>
              <Container size="md" py="xl">
            <Title align="center" mb="md" style={{ color: '#003d51' }}>
                Allgemeine Geschäftsbedingungen
            </Title>
            <Text align="center" color="dimmed" mb="lg">
                Last updated on November 21, 2024
            </Text>
            <SubHeading order={2}>
                1. Definitionen
            </SubHeading>
            <List size="md" spacing="md" mb="md">
                <List.Item>
                    <strong>Softwareanbieter:</strong> ResiRent, der Anbieter der Software und Beratungsdienste.
                </List.Item>
                <List.Item>
                    <strong>Nutzer:</strong> Die Einzelperson oder Organisation, die die Software verwendet.
                </List.Item>
                <List.Item>
                    <strong>Dienstleistung:</strong> Die durch die Software bereitgestellten Dienstleistungen, wie z.B. Website-
                    Entwicklung, Kalenderverwaltung und Beratung.
                </List.Item>
            </List>

            <SubHeading order={2}>
               2. Akzeptanz der Bedingungen
            </SubHeading>
            <Text size="md" mb="md">
                Durch die Nutzung der Software bestätigen Sie, dass Sie diese Geschäftsbedingungen
                gelesen, verstanden und akzeptiert haben. Wenn Sie mit diesen Geschäftsbedingungen nicht
                einverstanden sind, dürfen Sie die von ResiRent bereitgestellte Software nicht verwenden.
            </Text>

            <SubHeading order={2}>
                3. Lizenz-und Nutzungsrechte
            </SubHeading>
            <Text size="md" mb="md">
                ResiRent gewährt Ihnen eine nicht-exklusive, nicht übertragbare Lizenz zur Nutzung der
                Software gemäß diesen Bedingungen. Sie dürfen die Software nicht weiterverbreiten,
                dekompilieren oder modifiziere
            </Text>
            <SubHeading order={2}>
                4. Service und Produktbeschreibung
            </SubHeading>
            <Text mt="lg" mb="sm">
                4.1 Website-Vorlage für Ferienwohnungen
            </Text>
            <Text size="md" mb="md">
                ResiRent bietet eine vorgefertigte, vorprogrammierte Website-Vorlage speziell für
                Ferienwohnungen an. Diese Vorlage umfasst grundlegende Funktionen und Layouts, sodass
                Kunden einfach eine personalisierte Website erstellen können, indem sie eigene Inhalte,
                Bilder, Beschreibungen und andere objektrelevante Informationen hinzufügen. Die Vorlage ist
                vollständig anpassbar, sodass Texte, Bilder und andere nicht-strukturelle Elemente geändert
                werden können.
            </Text>

            <Text mt="lg" mb="sm">
                4.2 Verantwortung für das Content-Management
            </Text>
            <Text size="md" mb="md">
                Obwohl ResiRent eine flexible und anpassbare Plattform bietet, ist der Kunde vollständig
                verantwortlich für das Hochladen, Aktualisieren und Pflegen aller Inhalte auf seiner Website.
                Dies umfasst, ist jedoch nicht beschränkt auf, Objektbeschreibungen, Fotos, Preise,
                Buchungsinformationen und alle rechtlichen oder policy-relevanten Inhalte. ResiRent ist
                nicht für die Genauigkeit oder rechtliche Übereinstimmung der vom Kunden hochgeladenen
                Inhalte verantwortlich.
            </Text>

            <Text mt="lg" mb="sm">
                4.3 Beratungs- und Beratungsdienste
            </Text>
            <Text mt="sm" mb="sm">
                4.3.1 Allgemeine Beratung für Ferienwohnungen
            </Text>
            <Text size="md" mb="md">
                ResiRent bietet allgemeine Beratungsdienste an, um Kunden bei der Einrichtung und
                Verwaltung einer erfolgreichen Ferienwohnung zu unterstützen. Diese Beratungsdienste
                werden als allgemeine Richtlinien angeboten und sind nicht auf die rechtlichen, finanziellen
                oder betrieblichen Bedürfnisse der spezifischen Situation des Kunden zugeschnitten. Alle
                Empfehlungen sollten als Vorschläge betrachtet werden und stellen keine Garantien oder
                verbindlichen Anweisungen dar.
            </Text>
            <Text mt="sm" mb="sm">
                4.3.2 Haftungsbeschränkungen für Beratung
            </Text>
            <Text size="md" mb="md">
                Der Kunde erkennt an, dass die Beratung von ResiRent, obwohl sie auf bewährten Praktiken
                der Branche basiert, keine rechtlich verbindliche Beratung ist und keine professionelle
                Beratung in Bereichen wie Besteuerung, Gesetzgebung oder Immobilienverwaltung ersetzt.
                ResiRent haftet nicht für Konsequenzen, die sich aus der Anwendung unserer Beratung durch
                den Kunden ergeben.
            </Text>

            <SubHeading order={2}>
                5. Wartung und Aktualisierung der Website
            </SubHeading>
            <Text mt="lg" mb="sm">
                5.1 Verantwortung für Updates und Änderungen
            </Text>
            <Text size="md" mb="md">
                Der Kunde ist für alle Updates, Änderungen und Wartungsmaßnahmen der Website nach
                deren Veröffentlichung verantwortlich. Obwohl die ResiRent-Plattform Anpassungen an der
                Website ermöglicht, garantiert ResiRent nicht die Kompatibilität mit von Dritten
                hinzugefügter Software oder Plugins und haftet nicht für Probleme, die durch solche
                Ergänzungen entstehen.
            </Text>

            <Text mt="lg" mb="sm">
                5.2 Periodische Systemupdates
            </Text>
            <Text size="md" mb="md">
                ResiRent behält sich das Recht vor, regelmäßig Updates am zugrunde liegenden Code und an
                der Struktur der Website-Vorlage durchzuführen, um die Leistung, Sicherheit oder
                Benutzerfreundlichkeit zu verbessern. Obwohl wir uns bemühen, diese Updates so nahtlos
                wie möglich zu gestalten, müssen bestimmte Anpassungen oder Integrationen von Dritten,
                die vom Kunden vorgenommen wurden, möglicherweise neu konfiguriert oder angepasst
                werden.
            </Text>
            <SubHeading order={2}>
                6. Haftungsbeschränkungen
            </SubHeading>
            <Text mt="lg" mb="sm">
                6.1 Verfügbarkeit des Dienstes und technische Probleme
            </Text>
            <Text size="md" mb="md">
                Obwohl ResiRent eine hohe Verfügbarkeit und Funktionalität unserer Plattform anstrebt,
                können wir keinen unterbrechungsfreien Zugriff oder eine vollständig fehlerfreie Nutzung der
                Website-Vorlage oder der Beratungsdienste garantieren. ResiRent haftet nicht für Verluste,
                Schäden oder Kosten, die aus technischen Problemen, Serviceunterbrechungen oder
                Datenverlusten entstehen.
            </Text>

            <Text mt="lg" mb="sm">
                6.2 Haftungsausschluss für Garantien
            </Text>
            <Text size="md" mb="md">
                ResiRent stellt alle Dienstleistungen „wie sie sind“ zur Verfügung, ohne jegliche Form von
                Garantien, weder ausdrücklich noch stillschweigend. Der Kunde übernimmt alle Risiken, die
                mit der Nutzung der Website-Vorlage und der Beratungsdienste verbunden sind. ResiRent
                schließt alle Garantien aus, einschließlich, aber nicht beschränkt auf, stillschweigende
                Garantien hinsichtlich der Eignung für einen bestimmten Zweck, der Marktfähigkeit und der
                Nichtverletzung von Rechten Dritter.
            </Text>

            <SubHeading order={2}>
                7. Änderungen der Geschäftbedingungen
            </SubHeading>
            <Text size="md" mb="md">
                ResiRent behält sich das Recht vor, diese Geschäftsbedingungen jederzeit zu ändern. Änderungen
                werden dem Kunden mitgeteilt und treten unmittelbar nach der Benachrichtigung in Kraft. Durch
                die fortgesetzte Nutzung der Dienste von ResiRent nach der Mitteilung über Änderungen erklärt
                sich der Kunde mit den aktualisierten Geschäftsbedingungen einverstanden.
            </Text>
            <Text size="md" mb="md">
                Diese Geschäftsbedingungen stellen die gesamte Vereinbarung zwischen ResiRent und dem
                Kunden dar. Bei Fragen oder Klarstellungen wenden Sie sich bitte an unseren Kundenservice.
            </Text>

            <SubHeading order={2}>
                8. Verantwortlichkeiten des Nutzers
            </SubHeading>
            <Text size="md" mb="md">
                Verantwortlichkeiten des Nutzers
            </Text>
            <Text size="md" mb="md">
                Als Nutzer der Software sind Sie allein verantwortlich für die Art und Weise, wie Sie diese
                verwenden. Der Softwareanbieter ist nicht verantwortlich für Schäden oder Verluste, die
                durch Ihre unsachgemäße oder fehlerhafte Nutzung der Software entstehen.
            </Text>
            <Text mt="lg" mb="sm">
            Einhaltung von Gesetzen:
            </Text>
            <Text size="md" mb="md">
                ResiRent wurde entwickelt, um das Management von Ferienunterkünften für Eigentümer und
                Vermieter zu vereinfachen und zu verbessern. Es ist wichtig zu beachten, dass die Nutzung
                unserer Software stets den lokalen Vorschriften (z. B. dem Vorhandensein einer
                Touristenlizenz oder einer Umsatzsteuer-Identifikationsnummer), den Regeln der
                Eigentümergemeinschaft und den Vorschriften der Wohnungseigentümergemeinschaft (VvE)
                entsprechen muss. Wir unterstützen oder billigen keine Aktivitäten, die gegen diese Gesetze
                oder Vereinbarungen verstoßen. Nutzer sind dafür verantwortlich, sicherzustellen, dass ihre
                Nutzung unserer Software vollständig mit allen relevanten rechtlichen und vertraglichen
                Verpflichtungen übereinstimmt.
            </Text>
            <Text mt="lg" mb="sm">
            Richtigkeit der Informationen:
            </Text>
            <Text size="md" mb="md">
                Nutzer müssen sicherstellen, dass alle eingegebenen Daten korrekt und aktuell sind.
            </Text>

            <SubHeading order={2}>
                8. Gebühren und Zahlungen
            </SubHeading>
            <Text size="md" mb="md">
                Zahlungsmethoden: Per Banküberweisung oder Online-Zahlung über die Website.
            </Text>
            <Text size="md" mb="md">
                Einmalige Gebühr: Einrichtung der Website und/oder des Kalenders sowie Onboarding.
            </Text>
            <Text size="md" mb="md">
                Jährliches Abonnement: Beginnt 2 Monate nach der Einrichtung und nach der
                Qualitätsprüfung durch ResiRent und den Kunden.
            </Text>


            <SubHeading order={2}>
                9. Datenschutz und Daten verarbeitung
            </SubHeading>
            <Text size="md" mb="md">
                Arten der erfassten Daten und deren Verwendung: Siehe das Dokument zur
                Datenschutzrichtlinie von ResiRent.
            </Text>
            <Text size="md" mb="md">
                Datenschutzrichtlinie: Wir verweisen auf das Dokument zur Datenschutzrichtlinie von
                ResiRent.
            </Text>

            <SubHeading order={2}>
                10. Haftungsschutz
            </SubHeading>
            <Text size="md" mb="md">
                Der Softwareanbieter ResiRent haftet nicht für direkte, indirekte, zufällige, Folge- oder
                exemplarische Schäden, einschließlich, aber nicht beschränkt auf Schäden durch
                Gewinnverluste, Datenverluste oder andere immaterielle Verluste, die aus der Nutzung oder
                der Unfähigkeit zur Nutzung der Software entstehen oder damit in Zusammenhang stehen.
                            </Text>

            <SubHeading order={2}>
                11. Schadloshaltung
            </SubHeading>
            <Text size="md" mb="md">
                Nutzer stellen ResiRent, den Softwareanbieter, von allen Ansprüchen, Schäden, Haftungen
                oder Kosten frei, die aus der Nutzung der Software oder der Verletzung der
                Geschäftsbedngungen entstehen.
            </Text>

            <SubHeading order={2}>
                12. Änderungen am Dienst
            </SubHeading>
            <Text size="md" mb="md">
                Der Softwareanbieter ResiRent behält sich das Recht vor, jederzeit und ohne vorherige
                Ankündigung Änderungen an der Software vorzunehmen, sie auszusetzen oder bestimmte
                Aspekte einzustellen. Dies umfasst unter anderem Funktionen, Features und den Zugriff auf
                die Software.
            </Text>

            <SubHeading order={2}>
                13. Kündigung
            </SubHeading>
            <Text size="md" mb="md">
                Bedingungen für die Kündigung der Vereinbarung durch beide Parteien
            </Text>
            <Text size="md" mb="md">
                Der Kunde kann die Vereinbarung jederzeit kündigen. Die Kündigung wird zum Ende des
                Monats wirksam, in dem die Mitteilung erfolgt. Diese Mitteilung muss per E-Mail oder Brief
                erfolgen.
            </Text>

            <SubHeading order={2}>
                14. Geistiges Eigentum
            </SubHeading>
            <Text size="md" mb="md">
                Alle geistigen Eigentumsrechte an der Software, einschließlich, aber nicht beschränkt auf
                Urheberrechte, Marken, Patente und Geschäftsgeheimnisse, sind das ausschließliche
                Eigentum des Softwareanbieters. Sie erklären sich damit einverstanden, die Software ohne
                vorherige schriftliche Zustimmung des Softwareanbieters weder zu reproduzieren, zu
                modifizieren, zu verteilen noch abgeleitete Werke darauf basierend zu erstelle
            </Text>

            <SubHeading order={2}>
                15. Gewährleistung
            </SubHeading>
            <Text size="md" mb="md">
                Die Software wird auf einer „wie besehen“-Basis bereitgestellt, ohne jegliche Garantien oder
                Zusicherungen, weder ausdrücklich noch stillschweigend. Dies umfasst, aber ist nicht
                beschränkt auf, stillschweigende Garantien der Marktgängigkeit, Eignung für einen
                bestimmten Zweck oder Nichtverletzung von Rechten Dritter. Der Softwareanbieter
                übernimmt keine Gewähr für die Genauigkeit, Zuverlässigkeit oder Vollständigkeit der
                Funktionalität der Software.
            </Text>

            <SubHeading order={2}>
                16. Anwendbares Recht und Streitbeilegung
            </SubHeading>
            <Text size="md" mb="md">
                Diese Geschäftsbedingungen sowie alle zwischen ResiRent und seinen Kunden geschlossenen
                Vereinbarungen unterliegen ausschließlich dem Schweizer Recht. Das Übereinkommen der
                Vereinten Nationen über Verträge des internationalen Warenkaufs (CISG) ist ausdrücklich
                ausgeschlossen.
            </Text>
            <Text size="md" mb="md">
                Streitbeilegung: Im Falle von Streitigkeiten, die sich aus oder im Zusammenhang mit diesen
                Geschäftsbedingungen ergeben, werden die Parteien zunächst versuchen, die Streitigkeit
                einvernehmlich zu lösen. Sollte eine einvernehmliche Lösung nicht möglich sein, wird der
                Streitfall vor das zuständige Gericht in Zürich gebracht.
            </Text>
            <Text size="md" mb="md">
                Zuständiges Gericht: Für Streitigkeiten ist ausschließlich das zuständige Gericht in Zürich,
                Schweiz, zuständig.
            </Text>
            <Text size="md" mb="md">
                Sonstige Bestimmungen: Sollte eine Bestimmung dieser Geschäftsbedingungen für ungültig
                oder nicht durchsetzbar erklärt werden, bleiben die übrigen Bestimmungen vollständig
                wirksam. Die ungültige oder nicht durchsetzbare Bestimmung wird durch eine gültige
                Bestimmung ersetzt, die der Absicht der ursprünglichen Bestimmung am nächsten kommt.
            </Text>

            <SubHeading order={2}>
                Sonstiges
            </SubHeading>
            <Text size="md" mb="md">
                Gesamtvereinbarung: Diese Geschäftsbedingungen stellen die vollständige Vereinbarung
                zwischen den Parteien dar.
            </Text>
            <Text size="md" mb="md">
                Salvatorische Klausel: Sollte eine Bestimmung dieser Geschäftsbedingungen für ungültig
                befunden werden, bleiben die übrigen Bestimmungen weiterhin in Kraft.
            </Text>
            <Text size="md" mb="md">
                Änderungen der Geschäftsbedingungen: Verfahren zur Änderung der Geschäftsbedingungen.
            </Text>
            
            <div style={{ padding: '20px', backgroundColor: '#f9f9f9', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <Text size="md" weight={700} style={{ color: '#1a3d4c' }}>Brand Name: ResiRent</Text>
                
                <Text size="md" weight={700} style={{ color: '#1a3d4c' }}>Sustainable Small Accommodations Bulteel, CHE291 088 716</Text>
                
                <Text size="md" weight={700} style={{ color: '#1a3d4c' }}>Address: Schützenrütistrasse 10, 8044 Gockhausen, Switzerland</Text>
                
                <Text size="md" weight={700} style={{ color: '#1a3d4c' }}>Email:
                    <Anchor href="mailto:anjabulteel@me.com" style={{ textDecoration: 'none', color: '#1a73e8', fontWeight: 500 }}> anjabulteel@me.com</Anchor>
                </Text>
                
                <Text size="md" weight={700} style={{ color: '#1a3d4c' }}>Phone: +41 764 240 582</Text>
                
                <Text size="md" weight={700} style={{ color: '#1a3d4c' }}>Website: 
                    <Anchor href="https://resirent.org" target="_blank" style={{ textDecoration: 'none', color: '#1a73e8', fontWeight: 500 }}> ResiRent.org</Anchor>
                </Text>
            </div>


        </Container>
</>
);


const TermsPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const languagePrefix = window.location.pathname.startsWith('/nl')
        ? '/nl'
        : window.location.pathname.startsWith('/de')
        ? '/de'
        : '';

    const TermsComponent =
        languagePrefix === '/nl' ? TermsNL : languagePrefix === '/de' ? TermsDE : TermsEN;

    return (
        <Container size="md" py="xl">
            <TermsComponent />
        </Container>
    );
};

export default TermsPage;
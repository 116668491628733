import React from "react";
import image from "../../../assets/homepage/Calendar.png";
import {
  createStyles,
  Group,
  rem,
  Button,
  Title,
  Box,
  Container,
  Text,
  Image,
  Paper,
  List,
} from "@mantine/core";
import { IconHome } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import {
  IconClock,
  IconCheck,
  IconCalendar,
  IconBolt,
  IconReceipt,
  IconUsers,
} from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
  title: {
    maxWidth: "66%",
    fontWeight: 600,
    fontSize: rem(40),
    textAlign: "center",

    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(24), // Reduce for mobile
      lineHeight: 1.3, // Improve vertical spacing
      maxWidth: "100%",
      paddingLeft: rem(16),
      paddingRight: rem(16),
    },
  },

  paperProduct: {
    [theme.fn.smallerThan("sm")]: {
      width: "100%",
      marginTop: rem(30),
    },
    width: "50%",
    marginTop: rem(60),
    marginBottom: rem(60),
  },

  benefitsTitle: {
    fontSize: theme.fontSizes.xl,
    fontWeight: 700,
    maxWidth: "66%",
    marginTop: rem(16),
    marginBottom: rem(8),
    textAlign: "left",

    [theme.fn.smallerThan("sm")]: {
      maxWidth: "100%",
      textAlign: "center",
      fontSize: rem(20),
      paddingLeft: rem(16),
      paddingRight: rem(16),
    },
  },

  benefitsSubTitle: {
    fontSize: theme.fontSizes.md,
    fontWeight: 700,
    maxWidth: "66%",
    marginTop: rem(16),
    marginBottom: rem(16),
    textAlign: "left",

    [theme.fn.smallerThan("sm")]: {
      maxWidth: "100%",
      textAlign: "center",
      fontSize: rem(16),
      paddingLeft: rem(16),
      paddingRight: rem(16),
    },
  },

  description: {
    maxWidth: "66%",
    margin: "auto",
    fontWeight: 400,
    textAlign: "left",

    [theme.fn.smallerThan("sm")]: {
      maxWidth: "100%",
      textAlign: "center",
      fontSize: rem(16),
      paddingLeft: rem(16),
      paddingRight: rem(16),
    },
  },

  title: {
    maxWidth: "66%",
    fontWeight: 600,

    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(24),
      textAlign: "center",
      maxWidth: "100%",
      paddingLeft: rem(16),
      paddingRight: rem(16),
    },
  },

  listItem: {
    display: "flex",
    alignItems: "center",
    textAlign: "left",
    maxWidth: "66%",
    fontSize: theme.fontSizes.sm,
    lineHeight: 1.6,
    marginBottom: rem(8),
    listStyleType: "none",
  },

  control1: {
    backgroundColor: theme.white,
    color: "#344054",
    border: `${rem(1)} solid ${theme.colors.resirentPink[8]}`,

    "&:hover": {
      backgroundColor: theme.colors.resirentPink[8],
      color: theme.white,
    },
  },

  control: {
    "&:hover": {
      border: `${rem(1)} solid ${
        theme.colorScheme === "dark"
          ? theme.colors.resirentPink[5]
          : theme.colors.resirentPink[8]
      }`,
      backgroundColor: theme.white,
      color: theme.colors.resirentPink[5],
    },

    "&:active": {
      variant: "filled",
      color: "resirentPink",
    },

    textAlign: "center",
  },

  responsiveColumn: {
    display: "flex",
    justifyContent: "center",
    gap: rem(32),

    [theme.fn.smallerThan("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },

  responsiveBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: rem(200),

    [theme.fn.smallerThan("sm")]: {
      width: "100%",
    },
  },
  buttonsWrapper: {
    display: "flex",
    justifyContent: "center",
    gap: rem(16),
    flexWrap: "wrap",

    [theme.fn.smallerThan("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      gap: rem(12),
    },
  },

  responsiveButton: {
    minWidth: rem(150),

    [theme.fn.smallerThan("sm")]: {
      width: "100%",
      textAlign: "center",
    },
  },

  benefitButton: {
    textAlign: "center",
    padding: "6px 12px",
    fontFamily: "Inter, sans-serif",
    fontSize: "20px",
    fontWeight: 500,
    width: rem(150),

    [theme.fn.smallerThan("sm")]: {
      width: "100%",
    },
  },

  benefitDescription: {
    color: "#667085",
    fontSize: "16px",
    width: rem(512),
    height: "auto",
    marginLeft: "30px",

    [theme.fn.smallerThan("sm")]: {
      width: "100%",
      marginLeft: "0px",
      paddingLeft: "40px",
      paddingRight: "16px",
    },
  },
  mobileColumn: {
    [theme.fn.smallerThan("sm")]: {
      flexDirection: "column !important",
      alignItems: "center !important",
    },
  },
  mobileBox: {
    [theme.fn.smallerThan("sm")]: {
      width: "100% !important",
    },
  },
  mobileList: {
    [theme.fn.smallerThan("sm")]: {
      paddingLeft: "16px !important",
      paddingRight: "16px !important",
    },
  },
  mobileDescription: {
    [theme.fn.smallerThan("sm")]: {
      width: "100% !important",
      marginLeft: "0 !important",
      paddingLeft: "30px",
      paddingRight: "16px",
    },
  },
}));

export default function ResiCalendarHeader({ language }) {
  const { classes, theme } = useStyles();
  const { t } = useTranslation();

  const content = {
    "/": {
      groupTitle: "CALENDAR",
      mainTitle:
        "Effortless management for you, a smooth experience for your guests!",
      // 'descriptionTitle': 'Direct Booking Requests for Mutual Trust',
      firstDescription:
        "Guests can easily select their desired dates, check availability, and submit a request with just a few clicks. You'll receive details from at least one person to review before confirming—building mutual trust from the start.",
      secondDescription:
        "Effortlessly set prices for low, mid, or high season, and add custom criteria like cleaning fees. Stay informed with automatic email notifications for every request.",
    },
    "/nl": {
      groupTitle: "CALENDAR",
      mainTitle:
        "Moeiteloos beheer voor u, een vlotte ervaring voor uw gasten!",
      // 'descriptionTitle': 'Directe Boekingsaanvragen voor Wederzijds Vertrouwen ',
      firstDescription:
        "Gasten kunnen eenvoudig hun gewenste data selecteren, de beschikbaarheid controleren en met slechts een paar klikken een aanvraag indienen. U ontvangt de gegevens van ten minste één persoon om te beoordelen voordat u bevestigt—zo wordt wederzijds vertrouwen vanaf het begin opgebouwd.",
      secondDescription:
        "Stel moeiteloos prijzen in voor het laagseizoen, het midden van het seizoen of het hoogseizoen, en voeg aangepaste criteria toe, zoals schoonmaakkosten. Blijf op de hoogte met automatische e-mailmeldingen voor elke aanvraag.",
    },
    "/de": {
      groupTitle: "CALENDAR",
      mainTitle:
        "Mühelose Verwaltung für Sie, eine reibungslose Erfahrung für Ihre Gäste!",
      // 'descriptionTitle': 'Direct Booking Requests for Mutual Trust',
      firstDescription:
        "Gäste können problemlos ihre gewünschten Daten auswählen, die Verfügbarkeit prüfen und mit nur wenigen Klicks eine Anfrage senden. Sie erhalten die Details von mindestens einer Person zur Überprüfung, bevor Sie bestätigen – und bauen so von Anfang an gegenseitiges Vertrauen auf.",
      secondDescription:
        "Stellen Sie mühelos Preise für das Niedrig-, Mittel- oder Hochsaison ein und fügen Sie benutzerdefinierte Kriterien wie Reinigungsgebühren hinzu. Bleiben Sie mit automatischen E-Mail-Benachrichtigungen über jede Anfrage informiert.",
    },
  };

  const benefitsContent = {
    "/": {
      benefitsHeading: "Seamless Scheduling for Hosts & Guests",
      benefitsHeading2: "The Benefits of a Smart Calendar & Booking System",
      benefitsHeading3:
        "Seamlessly manage your reservations while providing a smooth booking experience for your guests.",
      forYou: [
        {
          title: "Save Time",
          description:
            "Automatic updates and instant notifications keep you organized.",
          icon: <IconClock size={20} />,
        },
        {
          title: "Stay in Control",
          description:
            "Set custom prices, review guest details, and approve requests.",
          icon: <IconCheck size={20} />,
        },
        {
          title: "Look Professional",
          description:
            "A seamless calendar boosts trust and reduces questions.",
          icon: <IconCalendar size={20} />,
        },
      ],
      forGuests: [
        {
          title: "Easy Booking",
          description: "Clear availability and quick request submission.",
          icon: <IconBolt size={20} />,
        },
        {
          title: "Transparent Pricing",
          description:
            "View costs upfront, including extras like cleaning fees.",
          icon: <IconReceipt size={20} />,
        },
        {
          title: "Build Trust",
          description: "Personal connection through shared details.",
          icon: <IconUsers size={20} />,
        },
      ],
    },
    "/nl": {
      benefitsHeading: "Naadloze planning voor gastheren en gasten",
      benefitsHeading2:
        "De voordelen van een slim kalender- en boekingssysteem",
      benefitsHeading3:
        "Beheer moeiteloos uw reserveringen en bied uw gasten een soepele boekervaring.",
      forYou: [
        {
          title: "Bespaar tijd",
          description:
            "Automatische updates en directe meldingen houden u georganiseerd.",
          icon: <IconClock size={20} />,
        },
        {
          title: "Blijf de controle behouden",
          description:
            "Stel aangepaste prijzen in, bekijk gastgegevens en keur aanvragen goed.",
          icon: <IconCheck size={20} />,
        },
        {
          title: "Lijkt professioneel",
          description:
            "Een naadloze kalender vergroot het vertrouwen en vermindert vragen.",
          icon: <IconCalendar size={20} />,
        },
      ],
      forGuests: [
        {
          title: "Eenvoudige boeking",
          description:
            "Duidelijke beschikbaarheid en snelle aanvraagindiening.",
          icon: <IconBolt size={20} />,
        },
        {
          title: "Transparante prijsstelling",
          description:
            "Bekijk de kosten vooraf, inclusief extra's zoals schoonmaakkosten.",
          icon: <IconReceipt size={20} />,
        },
        {
          title: "Bouw vertrouwen op",
          description: "Persoonlijke verbinding door gedeelde gegevens.",
          icon: <IconUsers size={20} />,
        },
      ],
    },
    "/de": {
      benefitsHeading: "Nahtlose Planung für Gastgeber & Gäste",
      benefitsHeading2:
        "Die Vorteile eines intelligenten Kalender- und Buchungssystems",
      benefitsHeading3:
        "Verwalten Sie Ihre Reservierungen nahtlos und bieten Sie Ihren Gästen eine reibungslose Buchungserfahrung.",
      forYou: [
        {
          title: "Zeit Sparen",
          description:
            "Automatische Updates und sofortige Benachrichtigungen halten Sie organisiert.",
          icon: <IconClock size={20} />,
        },
        {
          title: "Behalten Sie die Kontrolle",
          description:
            "Stellen Sie benutzerdefinierte Preise ein, überprüfen Sie die Gästedaten und genehmigen Sie Anfragen.",
          icon: <IconCheck size={20} />,
        },
        {
          title: "Wirken Sie professionell",
          description:
            "Ein nahtloser Kalender stärkt das Vertrauen und reduziert Fragen.",
          icon: <IconCalendar size={20} />,
        },
      ],
      forGuests: [
        {
          title: "Einfache Buchung",
          description: "Klare Verfügbarkeit und schnelle Anfrageeinreichung.",
          icon: <IconBolt size={20} />,
        },
        {
          title: "Transparente Preisgestaltung",
          description:
            "Sehen Sie die Kosten im Voraus, einschließlich zusätzlicher Gebühren wie Reinigungsgebühren.",
          icon: <IconReceipt size={20} />,
        },
        {
          title: "Vertrauen aufbauen",
          description: "Persönliche Verbindung durch geteilte Details.",
          icon: <IconUsers size={20} />,
        },
      ],
    },
  };

  const languagePrefix = window.location.pathname.startsWith("/nl")
    ? "/nl"
    : window.location.pathname.startsWith("/de")
    ? "/de"
    : "/";

  const currentBenefits = benefitsContent[languagePrefix];

  return (
    <Box>
      <Container size="xl" py={100} align="center">
        <Group position="center">
          <Text
            component="div" // Use div to allow block-level styling
            style={{
              padding: "10px 20px", // Adjust padding as needed
              border: "2px solid #e0e0eb", // Light purple border
              borderRadius: "50px", // Large border radius for rounded corners
              backgroundColor: "white", // White background
              color: "#003D51", // Dark teal color
              fontWeight: 500, // Medium font weight
              fontSize: "14px", // Adjust font size
              fontFamily: "Inter, sans-serif",
              textAlign: "center",
              display: "inline-block", // Make it inline-block
              //   cursor: 'pointer', // Optional: Show pointer cursor on hover
            }}
          >
            ResiCal
          </Text>
        </Group>

        <Title
          order={2}
          className={classes.title}
          mt="sm"
          style={{ color: "#101828", fontFamily: "Inter, sans-serif" }}
        >
          {content[languagePrefix].mainTitle}
        </Title>

        <Text className={classes.description} mt="sm">
          {content[languagePrefix].firstDescription}
        </Text>

        <Text className={classes.description} mt="xl">
          {content[languagePrefix].secondDescription}
        </Text>

        <Text
          className={classes.description}
          mt="xl"
          style={{
            color: "#667085",
            fontFamily: "Inter, sans-serif",
            fontWeight: "400",
            fontSize: "20px",
            textAlign: "center",
          }}
        >
          {content[languagePrefix].secondDescription}
        </Text>

        <br></br>

        <Group className={classes.buttonsWrapper} mt="md">
          <Button
            color="resirentPink"
            radius="sm"
            component="a"
            href={t("resiweb.calendar")}
            size="md"
            className={`${classes.control1} ${classes.responsiveButton}`}
          >
            Contact us
          </Button>

          <Button
            color="resirentPink"
            radius="sm"
            component="a"
            href={t("contact1.href")}
            size="md"
            className={`${classes.control} ${classes.responsiveButton}`}
          >
            Try ResiWeb
          </Button>
        </Group>

        <br></br>
        <br></br>

        <Paper
          shadow="xl"
          withBorder
          className={classes.paperProduct}
          style={{
            overflow: "hidden",
            borderRadius: "10px 10px 0 0",
            padding: 0, // Remove padding
            margin: 0, // Remove margin
            width: "auto", // Adjust width to image
          }}
        >
          <Image
            src={image}
            alt="ResiRent"
            className={classes.imageProduct}
            style={{
              width: "100%",
              height: "auto",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              borderBottomLeftRadius: "0px",
              borderBottomRightRadius: "0px",
              margin: 0, // Remove margin
              padding: 0, // Remove padding
            }}
          />
        </Paper>

        <div style={{ textAlign: "left", width: "100%" }}>
          <Text
            className={classes.benefitsTitle}
            mt="xl"
            style={{
              fontFamily: "Inter, sans-serif",
              color: "#003D51",
              fontSize: "16px",
              fontWeight: "600",
            }}
          >
            {currentBenefits.benefitsHeading}
          </Text>
        </div>

        {/* <Text className={classes.benefitsTitle} mt="xl">
                    {currentBenefits.benefitsHeading}
                </Text> */}

        {currentBenefits.benefitsHeading2 && (
          <div style={{ textAlign: "left", width: "100%" }}>
            <Text
              className={classes.benefitsTitle}
              mt="xl"
              style={{
                fontFamily: "Inter, sans-serif",
                color: "#101828",
                fontWeight: 600,
              }}
            >
              {currentBenefits.benefitsHeading2}
            </Text>
          </div>
        )}

        {currentBenefits.benefitsHeading3 && (
          <div style={{ textAlign: "left", width: "100%" }}>
            <Text
              className={classes.benefitsTitle}
              mt="xl"
              style={{
                fontFamily: "Inter, sans-serif",
                color: "#667085",
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "20px",
              }}
            >
              {currentBenefits.benefitsHeading3}
            </Text>
          </div>
        )}

        <br></br>

        <Group
          position="center"
          spacing="xl"
          grow
          mt="md"
          align="center"
          className={classes.mobileColumn} // 🟢 mobile flex stack
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "200px",
            }}
            className={classes.mobileBox} // 🟢 mobile full width
          >
            <Button
              fullWidth
              className={classes.button}
              style={{
                textAlign: "center",
                backgroundColor: "#003D51",
                color: "white",
                padding: "6px 12px",
                fontFamily: "Inter, sans-serif",
                fontSize: "20px",
                fontWeight: "500",
                width: "150px",
              }}
            >
              <Text className={classes.benefitsSubTitle}>For You</Text>
            </Button>
            <List
              spacing="sm"
              size="sm"
              className={classes.mobileList} // 🟢 mobile padding
              styles={{ listStyleType: "none", paddingLeft: 0 }}
            >
              <br />
              {currentBenefits.forYou.map((item, index) => (
                <List.Item
                  key={index}
                  className={classes.listItem}
                  style={{ marginBottom: "10px" }}
                >
                  <br />
                  <span
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "24px",
                      height: "24px",
                      borderRadius: "50%",
                      backgroundColor: "#E8F5E9",
                      marginRight: "8px",
                    }}
                  >
                    {React.cloneElement(item.icon, {
                      style: { color: "#4CAF50" },
                    })}
                  </span>
                  <b>{item.title}</b>
                  <br />
                  <div
                    style={{
                      color: "#667085",
                      fontSize: "16px",
                      width: "512px",
                      height: "auto",
                      marginLeft: "30px",
                    }}
                    className={classes.mobileDescription} // 🟢 mobile adjustment
                  >
                    {item.description}
                  </div>
                </List.Item>
              ))}
            </List>
          </Box>

          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "200px",
            }}
            className={classes.mobileBox}
          >
            <Button
              fullWidth
              className={classes.button}
              style={{
                textAlign: "center",
                backgroundColor: "#E4E7ECBD",
                color: "#003D51",
                padding: "6px 12px",
                fontFamily: "Inter, sans-serif",
                fontSize: "20px",
                fontWeight: "500",
                width: "150px",
              }}
            >
              <Text className={classes.benefitsSubTitle}>For Your Guests</Text>
            </Button>
            <List
              spacing="sm"
              size="sm"
              className={classes.mobileList}
              styles={{ listStyleType: "none", paddingLeft: 0 }}
            >
              <br />
              {currentBenefits.forGuests.map((item, index) => (
                <List.Item
                  key={index}
                  className={classes.listItem}
                  style={{ marginBottom: "10px" }}
                >
                  <br />
                  <span
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "24px",
                      height: "24px",
                      borderRadius: "50%",
                      backgroundColor: "#E8F5E9",
                      marginRight: "8px",
                    }}
                  >
                    {React.cloneElement(item.icon, {
                      style: { color: "#4CAF50" },
                    })}
                  </span>
                  <b>{item.title}</b>
                  <br />
                  <div
                    style={{
                      color: "#667085",
                      fontSize: "16px",
                      width: "512px",
                      height: "auto",
                      marginLeft: "30px",
                    }}
                    className={classes.mobileDescription}
                  >
                    {item.description}
                  </div>
                </List.Item>
              ))}
            </List>
          </Box>
        </Group>
      </Container>
      {/* <Box align="right">
                        <Text component='a' href={imageButton[currentImage].link} order={1} weight={400} color="dimmed" mt="md">
                            {imageButton[currentImage].name}
                        </Text>
                    </Box> */}
    </Box>
  );
}

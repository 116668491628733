import React from "react";
import image from "../../../assets/products/main.png";
import {
  createStyles,
  Grid,
  Title,
  Text,
  Box,
  Container,
  Image,
  Paper,
} from "@mantine/core";

const useStyles = createStyles((theme) => ({
  section: {
    backgroundColor: "#ffffff",
    fontFamily: "Inter, sans-serif",
    paddingTop: 80,
    paddingBottom: 80,
  },

  textCol: {
    paddingRight: 80,
    [theme.fn.smallerThan("md")]: {
      paddingRight: theme.spacing.md,
      textAlign: "center",
    },
  },

  badge: {
    fontSize: 12,
    fontWeight: 600,
    textTransform: "uppercase",
    letterSpacing: "0.05em",
    color: "#003D51",
    marginBottom: 12,
  },

  title: {
    fontSize: 30,
    fontWeight: 600,
    marginBottom: theme.spacing.md,
    [theme.fn.smallerThan("sm")]: {
      fontSize: 24,
    },
  },

  paragraph: {
    fontSize: 16,
    color: "#4A4A4A",
    marginBottom: theme.spacing.sm,
    maxWidth: 500,
    [theme.fn.smallerThan("md")]: {
      margin: "0 auto",
    },
  },

  imageWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.fn.smallerThan("md")]: {
      marginBottom: theme.spacing.xl,
    },
  },

  imagePaper: {
    borderRadius: "24px",
    overflow: "hidden",
    boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.1)",
  },

  image: {
    display: "block",
    width: "100%",
    height: "auto",
  },
}));

export default function MainProduct({ language }) {
  const { classes } = useStyles();

  const content = {
    "/": {
      groupTitle: "EASY-TO-USE WEBSITE BUILDER",
      mainTitle: "Develop your own identity in the marketplace",
      description:
        "As convenient as standard booking platforms that do not require more skills than you need to be listed on a standard booking platform...",
      description2:
        "..., but this is your own website without any transactional commission fees to be paid.",
      description3:
        "Edit paragraphs, insert images, add reviews, and links to make your content shine.",
    },
    "/nl": {
      groupTitle: "EENVOUDIGE WEBSITE-BOUWER",
      mainTitle: "Ontwikkel je eigen identiteit in de markt",
      description: "Net zo eenvoudig als standaard boekingsplatforms en vereist geen extra vaardigheden dan nodig is om op een gewoon boekingsplatform vermeld te worden...",
      description2: "..., maar dit is jouw eigen website zonder transactiekosten of commissies.",
      description3: "Bewerk paragrafen, voeg afbeeldingen toe, plaats reviews en links om je content te laten stralen.",
    },
    "/de": {
      groupTitle: "BENUTZERFREUNDLICHER WEBSITE-BAUKASTEN",
      mainTitle: "Entwickeln Sie Ihre eigene Identität auf dem Markt",
      description:
        "So bequem wie gängige Buchungsplattformen und erfordert keine weiteren Kenntnisse als die, die Sie für die Listung auf einer Standard-Buchungsplattform benötigen...",
      description2:
        "... aber dies ist Ihre eigene Website, ohne dass Transaktionsprovisionen anfallen.",
      description3:
        "Bearbeiten Sie Absätze, fügen Sie Bilder ein, ergänzen Sie Bewertungen und setzen Sie Links, um Ihre Inhalte zum Strahlen zu bringen.",
    },
  };

  const languagePrefix = window.location.pathname.startsWith("/nl")
    ? "/nl"
    : window.location.pathname.startsWith("/de")
      ? "/de"
      : "/";


  return (
    <Box className={classes.section}>
      <Container size="lg">
        <Grid gutter={40} align="center">
          {/* Left Side Image */}
          <Grid.Col span={12} md={6} className={classes.imageWrapper}>
            <Paper p={0} className={classes.imagePaper}>
              <Image
                src={image}
                alt="Website editor mockup"
                className={classes.image}
              />
            </Paper>
          </Grid.Col>

          {/* Right Side Text */}
          <Grid.Col span={12} md={6} className={classes.textCol}>
            <Text className={classes.badge}>
              {content[languagePrefix].groupTitle}
            </Text>
            <Title className={classes.title}>
              {content[languagePrefix].mainTitle}
            </Title>
            <Text className={classes.paragraph}>
              {content[languagePrefix].description}
            </Text>
            {content[languagePrefix].description2 && (
              <Text className={classes.paragraph}>
                {content[languagePrefix].description2}
              </Text>
            )}
            {content[languagePrefix].description3 && (
              <Text className={classes.paragraph}>
                {content[languagePrefix].description3}
              </Text>
            )}
          </Grid.Col>
        </Grid>
      </Container>
    </Box>
  );
}

import React from 'react'
import stickers_batteries from '../../assets/stickers/ResiStickerBatteries.jpg'
import stickers_bottles from '../../assets/stickers/ResiStickerBottles.jpg'
import stickers_bottlesPlastic from '../../assets/stickers/ResiStickerBottlesPlastic.jpg'
import stickers_capsules from '../../assets/stickers/ResiStickerCapsules.jpg'
import stickers_cardbord from '../../assets/stickers/ResiStickerCardbord.jpg'
import stickers_curtains from '../../assets/stickers/ResiStickerCurtains.jpg'
import stickers_stickerfoil from '../../assets/stickers/ResiStickerFoil.jpg'
import stickers_light from '../../assets/stickers/ResiStickerLight.jpg'
import stickers_nondrinkable from '../../assets/stickers/ResiStickerNonDrinkable.jpg'
import stickers_paper from '../../assets/stickers/ResiStickerPaper.jpg'
import stickers_PE from '../../assets/stickers/ResiStickerPE.jpg'
import stickers_plastic from '../../assets/stickers/ResiStickerPlastic.jpg'
import stickers_potable from '../../assets/stickers/ResiStickerPotable.jpg'
import stickers_residual from '../../assets/stickers/ResiStickerResidual.jpg'
import stickers_compost from '../../assets/stickers/ResiStickerCompostable.jpg'
import { createStyles, Title, Image, Container, SimpleGrid, Button, rem, Group, Text } from '@mantine/core';

const useStyles = createStyles((theme) => ({
    title: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(24),
            maxWidth: '100%',
        },
        maxWidth: '66%',
    },

    paperProduct: {
        [theme.fn.smallerThan('sm')]: {
            width: '100%',
            marginTop: rem(30),
        },
        width: '50%',
        marginTop: rem(60),
    },

    description: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(14),
            maxWidth: '100%',
            textAlign: 'center',
            display: 'block',
        },
        maxWidth: '66%',
        margin: 'auto',
        fontWeight: 400,
    },

    control: {
        backgroundColor: theme.white,
        color: "#344054",
        border: `${rem(1)} solid ${theme.colors.resirentPink[8]}`,

        "&:hover": {
            backgroundColor: theme.colors.resirentPink[8],
            color: theme.white,
        },
    },

    controlNew: {
        backgroundColor: "#003D51",
        color: theme.white,
        border: `${rem(3)} solid #003D51`,

        "&:hover": {
            backgroundColor: theme.white,
            color: "#003D51",
        },
    },

    card: {
        border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.resirent[8]}`,
        '&:hover': {
            transform: 'scale(1.01)',
            boxShadow: theme.shadows.md,
        },
        textAlign: 'center',
    },
}));

export default function ResiAccessoires({ language }) {

    const { classes } = useStyles();
    const languagePrefix = window.location.pathname.startsWith('/nl') ? '/nl' : window.location.pathname.startsWith('/de') ? '/de' : '/';

    const images = [
        { src: stickers_compost, alt: 'Placeholder image', title: 'Compostable', description: 'Anything digestible fits this label. In most countries it comes down to fruit, vegetables and garden. Sometimes these products can be thrown in the compostable bin with a bio-degradable sack, but in some countries the sack is not allowed.' },
        { src: stickers_plastic, alt: 'Placeholder image', title: 'Plastic all', description: 'Includes the following product categories, which are typically disposed of in different ways and require different recycling procedures.' },
        { src: stickers_batteries, alt: 'Placeholder image', title: 'Batteries', description: 'A sticker to be used on a small paper basket for empty batteries to be recycled.' },
        { src: stickers_bottles, alt: 'Placeholder image', title: 'Glass', description: 'Glass is the product we are most familiar with to dispose of. We only made one sticker for glass, to avoid too much detail. Often, glass must be disposed by color in a collection point.' },
        { src: stickers_PE, alt: 'Placeholder image', title: 'PE&HD', description: 'bottles of shampoo, detergent, ...' },
        { src: stickers_bottlesPlastic, alt: 'Placeholder image', title: 'PET', description: 'only beverage bottles, because we bring them back to the store' },
        { src: stickers_capsules, alt: 'Placeholder image', title: 'Capsules', description: 'if you have a coffee machine with capsules such as a Nespresso machine, you can use this sticker. Be aware that in many cases, the companies that produce this coffee take back the capsules and even have sustainability programs (e.g., Nespresso).' },
        { src: stickers_curtains, alt: 'Placeholder image', title: 'Be careful with the cutains!', description: 'on several occasions we experienced guests opening the window without moving the curtains beforehand. Unfortunately, this led to damage and we had to either have the curtain repaired or even buy a new one.' },
        { src: stickers_light, alt: 'Placeholder image', title: 'Light out', description: 'We find it important to make a small sticker that kindly asks guests to turn off the lights.' },
        { src: stickers_nondrinkable, alt: 'Placeholder image', title: 'Non-potable water', description: 'Not suitable for drinking but may still be used for other purposes' },
        { src: stickers_paper, alt: 'Placeholder image', title: 'Paper', description: 'Be sure to dispose of any plastic wrap first.' },
        { src: stickers_stickerfoil, alt: 'Placeholder image', title: 'Foil', description: 'No PET and NO-HD, yoghurt cups, pre-prepared food packages, etc.' },
        { src: stickers_cardbord, alt: 'Placeholder image', title: 'Cardboard', description: 'Cardboard is a growing and huge problem. One often has not enough with one bin for cardboard. Separate the cardboard from the paper though. Both products are recycled differently.' },
        { src: stickers_potable, alt: 'Placeholder image', title: 'Potable water', description: 'Often water is drinkable, so you do not need to buy bottles!' },
        { src: stickers_residual, alt: 'Placeholder image', title: 'Residual', description: 'for residual waste, we took a humorous icon that looks a bit hopeless because there are no options left.' },
    ];

    const content = {
        '/': {
            mainTitle: 'Eco-Friendly Stickers: Effortless Recycling with Positive Impact',
            description: 'Many guests may overlook recycling on vacation, but simple stickers and clearly labeled bins make it easy. Friendly reminders promote eco-friendly habits for a sustainable stay.',
            description2: 'We carefully designed stickers based on global waste management policies, combining guest experiences for sustainability. The illustrations provide a friendly, positive hint, encouraging recycling. Guests were enthusiastic about the easy-to-understand design, reinforcing our accommodation’s eco-friendly values. The stickers helped create a lasting, positive impression.',
            button1: 'Get the stickers',
            button2: 'Read the blogs',
            href: '/blog/2'
        },
        '/nl': {
            mainTitle: 'Eco-vriendelijke stickers: moeiteloos recyclen met een positieve impact',
            description: 'Veel gasten vergeten misschien te recyclen tijdens hun vakantie, maar eenvoudige stickers en duidelijk gemarkeerde afvalbakken maken het gemakkelijk. Vriendelijke herinneringen stimuleren milieuvriendelijke gewoonten voor een duurzaam verblijf.',
            description2: 'We hebben de stickers zorgvuldig ontworpen op basis van wereldwijde afvalbeheerbeleid, waarbij we gastervaringen hebben gecombineerd voor duurzaamheid. De illustraties geven een vriendelijke, positieve hint en moedigen recycling aan. Gasten waren enthousiast over het eenvoudig te begrijpen ontwerp, wat de milieuvriendelijke waarden van onze accommodatie versterkte. De stickers hebben bijgedragen aan een blijvende, positieve indruk.',
            button1: 'Koop de stickers',
            button2: 'Lees de blogs',
            href: '/blog/2'
        },
        '/de': {
            mainTitle: 'Umweltfreundliche Aufkleber: Mühelose Mülltrennung mit positiver Wirkung',
            description: 'Viele Gäste übersehen das Recycling im Urlaub, aber einfache Aufkleber und klar gekennzeichnete Mülltonnen machen es leicht. Freundliche Erinnerungen fördern umweltfreundliche Gewohnheiten für einen nachhaltigen Aufenthalt.',
            description2: 'Wir haben die Aufkleber sorgfältig basierend auf globalen Abfallmanagementrichtlinien entworfen und dabei Gästeerfahrungen für mehr Nachhaltigkeit integriert. Die Illustrationen geben einen freundlichen, positiven Hinweis und ermutigen zum Recycling. Die Gäste waren begeistert von dem leicht verständlichen Design, das die umweltfreundlichen Werte unserer Unterkunft unterstreicht. Die Aufkleber haben dazu beigetragen, einen nachhaltigen, positiven Eindruck zu hinterlassen.',
            button1: 'Hol dir die Aufkleber',
            button2: 'Lese die Blogs',
            href: '/blog/2'
        }
    };

    return (
        <Container size="xl" py={100} align="center">
            <Group position="center">
                <Text component="div" style={{ padding: '10px 20px', border: '2px solid #e0e0eb', borderRadius: '50px', backgroundColor: 'white', color: '#003D51', fontWeight: 500, fontSize: '14px', fontFamily: 'Inter, sans-serif', textAlign: 'center', display: 'inline-block' }}>
                    ResiRent Stickers
                </Text>
            </Group>

            <Title order={2} className={classes.title} ta="center" mt="sm" style={{ fontFamily: "Inter, sans-serif", fontSize: "40px", fontWeight: "500", color: "#101828" }}>
                {content[languagePrefix].mainTitle.split(" ").slice(0, 3).join(" ")} <br />
                {content[languagePrefix].mainTitle.split(" ").slice(3).join(" ")}
            </Title>

            <Text className={classes.description} ta="center" my="lg" style={{ fontFamily: "Inter, sans-serif" }}>
                {content[languagePrefix].description}
            </Text>

            <Text className={classes.description} ta="center" my="lg" style={{ fontFamily: "Inter, sans-serif" }}>
                {content[languagePrefix].description2}
            </Text>

            <Button align="center" mb="md" radius="sm" component="a" href="https://resirent.myshopify.com/products/resistickers" mt="md" size="md" className={classes.control}>
                {content[languagePrefix].button1}
            </Button>

            <Button align="center" m="md" radius="sm" component="a" href={content[languagePrefix].href} mt="md" size="md" className={classes.controlNew}>
                {content[languagePrefix].button2}
            </Button>

            <SimpleGrid cols={4} spacing={20} mt="md" breakpoints={[{ maxWidth: 'md', cols: 3 }, { maxWidth: 'sm', cols: 2 }]}> 
                {images.map((img, idx) => (
                    <Image key={idx} src={img.src} alt={img.alt} title={img.title} description={img.description} />
                ))}
            </SimpleGrid>
        </Container>
    );
}

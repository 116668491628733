import { createStyles, Text, Container, ActionIcon, rem, Image } from '@mantine/core';
import React from 'react';
import logo from '../../assets/ResiRent_Dark_Logo.jpg';
import spartaLogo from '../../assets/sparta_logo.png';
import facebook from '../../assets/facebook_logo.png';
import linkdin from '../../assets/LinkedIn_logo.png';
import instagram from '../../assets/instagram_logo.png';
import bluefly from '../../assets/Bluesky_Logo.png';
import { enFooter, nlFooter, deFooter } from '../../languages/translations';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';

const useStyles = createStyles((theme) => ({
    footer: {
        backgroundColor: theme.white,
        padding: `${rem(30)} ${rem(10)}`,
        color: '#667085',

        [theme.fn.smallerThan('sm')]: {
            padding: `${rem(20)} ${rem(10)}`,
        },
    },
    container: {
        maxWidth: '1200px',
        margin: '0 auto',
        padding: `0 ${rem(20)}`,
    },
    inner: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: rem(20),
        alignItems: 'flex-start',
        flexWrap: 'wrap',

        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column',
            alignItems: 'center',
            gap: rem(10),
        },
    },
    group: {
        display: 'flex',
        flexDirection: 'column',
        gap: rem(6),
      
        [theme.fn.smallerThan('sm')]: {
          alignItems: 'center',
        },
      },
      
    link: {
        fontSize: theme.fontSizes.sm,
        lineHeight: 1.3,
        '&:hover': {
            textDecoration: 'underline',
        },
        cursor: 'pointer',
    },
    bottom: {
        marginTop: rem(10),
        paddingTop: rem(10),
        borderTop: `${rem(1)} solid #667085`,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',

        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column',
            gap: rem(4),
            textAlign: 'center',
        },
    },
    social: {
        display: 'flex',
        gap: rem(10),
        justifyContent: 'center',
        flexWrap: 'wrap',

        [theme.fn.smallerThan('sm')]: {
            gap: rem(6),
        },
    },
    footerLinks: {
        display: 'flex',
        gap: rem(12),
        alignItems: 'center',
        flexWrap: 'wrap',

        [theme.fn.smallerThan('sm')]: {
            justifyContent: 'center',
        },
    },
    logoContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: rem(6),

        [theme.fn.smallerThan('sm')]: {
            alignItems: 'center',
            textAlign: 'center',
        },
    },
    spartaLogo: {
        cursor: 'pointer',
        transition: 'transform 0.3s ease',
        width: rem(90),

        [theme.fn.smallerThan('sm')]: {
            width: rem(70),
        },

        '&:hover': {
            transform: 'scale(1.05)',
        },
    },
    boldText: {
        fontWeight: 700,
        fontSize: theme.fontSizes.sm,
    },
    linkGroupsWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: rem(20),
      
        [theme.fn.smallerThan('sm')]: {
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)', // ✅ 3 columns
          gap: rem(16),
          justifyItems: 'center',
        },
      },
      
}));


const Footer = () => {
    const { classes } = useStyles();
    const { i18n } = useTranslation();
    const language = i18n.language;
    const navigate = useNavigate();

    const data = language === "en" ? enFooter : language === "nl" ? nlFooter : deFooter;

    return (
        <footer className={classes.footer}>
            <Container className={classes.container}>
                {/* Top Section: Logo and Navigation Links */}
                <div className={classes.inner}>
                    {/* ResiRent Logo Section */}
                    <div className={classes.logoContainer}>
                        <Image src={logo} alt="ResiRent logo" width={140} height={40} />
                        <Text size="xs">
                            Conscious hosting for thriving<br />
                            tourism
                        </Text>
                        <div className={classes.social} style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                            {/* Facebook */}
                            <a href="https://www.facebook.com/share/1828guC8oJ/" target="_blank" rel="noopener noreferrer">
                                <img src={facebook} alt="Facebook" width="32" height="32" />
                            </a>

                            {/* Instagram */}
                            <a href="https://www.instagram.com/resirent_responsible_rentals/" target="_blank" rel="noopener noreferrer">
                                <img src={instagram} alt="Instagram" width="32" height="32" />
                            </a>

                            {/* Bluefly */}
                            <a href="" target="_blank" rel="noopener noreferrer">
                                <img src={bluefly} alt="Bluefly" width="32" height="32" />
                            </a>

                            {/* LinkedIn */}
                            <a href="https://www.linkedin.com/company/resirent.org/" target="_blank" rel="noopener noreferrer">
                                <img src={linkdin} alt="LinkedIn" width="50" height="50" style={{ alignSelf: 'center', marginLeft: '-10px' }} />
                            </a>
                        </div>
                    </div>

                    {/* Navigation Links */}
                                        {/* Navigation Links */}
                    <div className={classes.linkGroupsWrapper}>
                    {data.map((group) => (
                        <div key={group.title} className={classes.group}>
                        <Text weight={700} size="sm">{group.title}</Text>
                        {group.links.map((link, index) => (
                            <Text key={index} className={classes.link} component="a" href={link.link}>
                            {link.label}
                            </Text>
                        ))}
                        </div>
                    ))}
                    </div>


                    {/* Sparta Capital Logo Section */}
                    <div className={classes.logoContainer}>
                        <Text size="sm" className={classes.boldText}>
                            Technology Partner
                        </Text>
                        <Image
                            src={spartaLogo}
                            alt="Sparta Capital Logo"
                            width={100}
                            height="auto"
                            className={classes.spartaLogo}
                            onClick={() => window.open("https://spartacapitalgroup.com/", "_blank")}
                        />
                    </div>
                </div>

                {/* Bottom Section: Copyright, Privacy, Terms, and Social Media */}
                <div className={classes.bottom}>
                    <div className={classes.footerLinks}>
                        <Text size="sm" className={classes.link}>
                            © 2025 ResiRent. All rights reserved.
                        </Text>
                        {/* Privacy Link */}
                        <Text onClick={() => navigate('/policies/privacy')} className={classes.link}>
                            Privacy
                        </Text>
                        {/* Terms Link */}
                        <Text onClick={() => navigate('/policies/terms')} className={classes.link}>
                            Terms
                        </Text>
                    </div>
                </div>
            </Container>
        </footer>
    );
};

export default Footer;

import {
    TextInput,
    PasswordInput,
    Checkbox,
    Anchor,
    Paper,
    Title,
    Text,
    Container,
    Group,
    Button,
} from '@mantine/core';
import logo from '../../assets/Long_logo_dark.svg';
import { useEffect, useState, useRef } from 'react';
import axios from '../../api/axios.js'
import useAuth from '../../hooks/useAuth';
import { useParams } from 'react-router-dom';

const USER_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const RESET_URL = '/password';

export default function PasswordReset() {

    const { persist, setPersist } = useAuth();
    const { user, token } = useParams();

    const errRef = useRef();

    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);

    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [validMatchPwd, setValidMatchPwd] = useState(false);

    useEffect(() => {
        // Check if password meets complexity criteria
        const hasValidLength = matchPwd.length >= 8;
        const hasUppercase = /[A-Z]/.test(matchPwd);
        const hasSpecialChar = /[\W_]/.test(matchPwd);
        const hasNumber = /\d/.test(matchPwd);

        // Set validMatchPwd based on complexity criteria
        setValidMatchPwd(hasValidLength && hasUppercase && hasSpecialChar && hasNumber);
    }, [matchPwd]);

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    // useEffect(() => {
    //   userRef.current.focus();
    // }, [])

    useEffect(() => {
        setValidPwd(PWD_REGEX.test(pwd));
        setValidMatch(pwd === matchPwd);
    }, [pwd, matchPwd])

    useEffect(() => {
        setErrMsg('');
    }, [pwd, matchPwd])

    useEffect(() => {
        localStorage.setItem("persist", persist);
    }, [persist])

    const handleSubmit = async (e) => {
        e.preventDefault();

        const v2 = PWD_REGEX.test(pwd);
        if (!v2) {
            setErrMsg("Invalid Entry");
            return;
        }
        try {
            const response = await axios.put(RESET_URL,
                JSON.stringify({ user, token, password: pwd }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                });
            console.log(response.data)
            console.log(JSON.stringify(response));
            setSuccess(true);
        } catch (err) {
            console.error(err)
            if (!err?.response) {
                setErrMsg("No server response")
            } else if (err.response?.status === 409) {
                setErrMsg("Username already taken")
            } else {
                setErrMsg("Registration failed. Unknown error.")
            }
            setErrMsg(JSON.stringify(err))
            // errRef.current.focus();
        }
    }

    return (
        <>
            {success ? (
                <Container size={420} my={40}>
                    <Title ta="center">
                        Password reset successfully!
                    </Title>
                    <Text c="dimmed" size="sm" ta="center" mt={5}>
                        Go to the login page{' '}
                        <Anchor size="sm" component="a" href="/login">
                            Login
                        </Anchor>
                    </Text>
                </Container>
            ) : (
                <Container size={420} my={40}>
                    {/* <Image width={100} height={100} src={logo} align="center" alt="ResiRent logo" /> */}
                    <Title ta="center">
                        Reset password
                    </Title>

                    <form onSubmit={handleSubmit}>
                        <Paper withBorder shadow="md" p={30} mt={30} radius="md">

                            <PasswordInput
                                label="New password"
                                placeholder="Your password"
                                required mt="md"
                                value={pwd}
                                onChange={(e) => setPwd(e.target.value)}
                                aria-describedby="pwdnote" />
                            <PasswordInput
                                label="Password"
                                placeholder="Confirm password"
                                required mt="md"
                                value={matchPwd}
                                onChange={(e) => setMatchPwd(e.target.value)}
                                aria-describedby="pwdnote" />

                            <span className={!validMatchPwd ? "invalid" : "hide"}>
                                {matchPwd.length < 8 && "Password must be at least 8 characters long. "}
                                {matchPwd.length >= 8 && !/[A-Z]/.test(matchPwd) && "Password must contain at least one uppercase letter. "}
                                {matchPwd.length >= 8 && !/[\W_]/.test(matchPwd) && "Password must contain at least one special character. "}
                                {matchPwd.length >= 8 && !/\d/.test(matchPwd) && "Password must contain at least one number. "}
                            </span>

                            <Button type="submit" variant="outline" color="resirent" fullWidth mt="lg" disabled={!validPwd || !validMatch ? true : false}>
                                Reset Password
                            </Button>

                        </Paper>

                    </form>
                </Container >
            )}
        </>
    );
}
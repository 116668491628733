import the_start from '../../../../assets/blogPosts/OurStory/TheVillage.jpg'
import pdf1 from '../../../../assets/blogPosts/Recycling/stickers_pdf_pages/ResiStickers_en_it_de_fr.jpg'
import pdf2 from '../../../../assets/blogPosts/Recycling/stickers_pdf_pages/ResiStickers_en_nl_de_fr.png'
import pdf3 from '../../../../assets/blogPosts/Recycling/stickers_pdf_pages/ResiStickers_es_pt_el_en.png'
import waste_bad from '../../../../assets/blogPosts/Recycling/waste_bad.jpg'
import waste_good from '../../../../assets/blogPosts/Recycling/waste_good.jpg'
import mona_lisa_picture from '../../../../assets/blogPosts/Recycling/mona_lisa.jpg'
import mona_lisa_text from '../../../../assets/blogPosts/Recycling/mona_lisa_text.jpg'
import { Image } from '@mantine/core';

export const recyclingEN = [
    {
        id: 'section-1',
        title: '1. Introduction',
        content: "Your guests occasionally leave your accommodation in a complete mess upon departure? You or your cleaner must then spend a ton of time to collect PET bottles, glass bottles, magazines and food residues? As a host, this can be very frustrating, because despite our efforts to inform them upon arrival, they often don’t remember it or don’t follow our guidelines! But don’t panic, there are solutions to these issues that can make a big difference. Keep reading to learn more.",
    },
    {
        id: 'section-2',
        title: '2. What waste do guest leave in our accommodations?',
        content: (<>
            <p className="mt-6 leading-8 resirent-p text-justify">
                Encouraging responsible waste management amongst our guests was a real challenge. Despite our best efforts to provide clear instructions and guidelines, some guests came from regions where recycling practices were different or were unfamiliar with proper waste disposal, leading to unintentional waste accumulation. As a result, waste disposal became a significant problem for us. A perfect example is the pile of bottles in the picture on the left. This is the waste from a family of four who only stayed for 2 nights! However, it's not always like this. The family of three in the picture on the right stayed for six nights and hardly produced any waste. While this was a dream scenario, it has sadly remained the exception rather than the rule.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2">
                <div className="p-5">
                    <Image maw={350} mx="auto" radius="md" src={waste_bad} alt="description" />
                </div>
                <div className="p-5">
                    <Image maw={350} mx="auto" radius="md" src={waste_good} alt="description" />
                </div>

            </div>
            <p className="mt-6 leading-8 resirent-p text-justify">
                For a long time, I wondered how I could make sure that my guests  always dispose their waste, regardless of whether they produce a mountain or a little bit. To tackle this problem, I started talking to my guests about this and soon realized that one problem is that waste management isn’t universal.  Living in Switzerland, one of the cleanest countries in the world with a meticulous culture around waste management, I realized I am used to such waste management. People from other regions are often less educated on the topic, as they don’t take care of waste the same way as people do in Switzerland. Thus, I realized that I had to be clearer about what waste management is and how it is done properly.
            </p>
            <p className="mt-6 leading-8 resirent-p text-justify">
                In consequence, I wrote a complete and elaborate guideline on an information sheet which i put visibly on the table next to the keys in the accommodation. I thought that by giving them the opportunity to read everything on their own in a step-by-step way, guests would find their way to the right bin.
            </p>
            <p className="mt-6 leading-8 resirent-p text-justify">
                However, I was quickly very disappointed and could only conclude that people do not read such sheets, no matter how easy they were to read. I got completely convinced about them not reading it after getting panic Whatsapp’s from guests asking for the WIFI code, which is written in bold on the first line of the same information sheet. If they do not even find the WIFI code, there is no chance they have read the beginning of our instructions.
            </p>
        </>),
    },
    {
        id: 'section-3',
        title: '1. How can we solve the problem of unsorted waste?',
        content: (<>
            <p className="mt-6 leading-8 resirent-p text-justify">
                Luckily, I am also a mum of four creative children. So, I involved them in this and asked them for help. Since they know the problem but are not part of it, they could help me to think out of the box for a solution. Although I had been refining and rewriting my information sheet, there was still no result. Consequently, I was getting desperate. I didn’t know why it didn’t work. All I knew was that people weren’t reading the information sheet, so I had to make it easier. After brainstorming and a lot of tryouts, we got an idea. A way to share information easily in a matter of second, a way so easy that you don’t have to think about it.
                Stickers! Stickers are an easy way to communicate waste disposal, thus we started designing our own Resirent stickers. We tried to make the stickers clear, yet still elegant and fun and on top of that we made sure they are from recycled materials and got tailored to our guest’s diverse cultural and linguistic backgrounds. After testing them out in our accommodations we soon realized it worked like magic!
            </p>
            <p className="mt-6 leading-8 resirent-p text-justify">
                Our solution worked like magic!  The Resistickers are effective because they use visuals to communicate waste disposal instructions, are made from recycled materials, and are tailored to guests' diverse cultural and linguistic backgrounds. They provide a positive and friendly way of giving feedback to guests, reinforcing our commitment to sustainability and enhancing their overall experience. In the following paragraphs, we explain the effectiveness of the stickers in detail. It's an enjoyable and fun read, but if you're short on time, you can skip ahead by using the arrow.
            </p>
        </>),
    },
    {
        id: 'section-4',
        title: '4. Our brain loves pictures',
        content: (
            <>
                <p className="mt-6 leading-8 resirent-p text-justify">
                    As humans, we tend to process images more easily than text. To test this out you can try the following experiment. Look at the pictures below and see how long it takes you to understand it. Now try it again,but now hover over the picture and see how long it takes you now. It took me at least five minutes to figure out that the first one represents the Mona Lisa and her beautiful smile (maybe I am a bit slow though;-)). thus it is perfectly normal that it takes us less energy to interpret a picture than to read the text and use our imagination to visualize what the text says.
                </p>
                <Image maw={350} mx="auto" radius="md" src={mona_lisa_text} alt="description" />
                <Image maw={350} mx="auto" radius="md" src={mona_lisa_picture} alt="description" />
            </>
        ),
    },
    {
        id: 'section-5',
        title: '5. Clear is kind when it comes to communication ',
        content: (
            <>
                <p className="mt-6 leading-8 resirent-p text-justify">
                    Stickers provide a positive and friendly way to communicate with tourists, which tends to be the most effective approach. Tourists are looking for a relaxing vacation and a time to wind down, so it is important to make sure that you don’t make their time frustrating and stressful rather than enjoyable. Reading an information sheet may seem like a chore for a lot of tourists, so it was important to me to find an easier and more positive way of communication. Stickers are a great and fun way of communicating information easily and positively. Not only are they easy to understand, but they're also aesthetically pleasing and fun. Through these stickers tourists associate the bin with something positive and thus makes them more likely to recycle. On top of that tourists who may not have thought about waste management, may now see these colorful stickers from the corner of their eyes, and through that realize that they can put for example their bottles in a specific bin.
                </p>
                <p className="mt-6 leading-8 resirent-p text-justify">
                    Using a directive or accusatory tone can often backfire and lead to negative reactions.  Again, it reminded me  of one of the leadership lectures I attended a few years ago. The professor, a good-looking Canadian, who engaged very much did an exercise with us. One of us had to leave the classroom and another person had to hide the first person’s laptop. When the person came back, none of us were supposed to say anything and the person had to look for her computer. She did not find it. In the second experiment, the person had to go out again and when coming back we had to clap our hands if she was looking in the wrong direction. Eventually, she found the laptop. In the third experiment, she had to leave again, and we helped her when she came back by clapping our hands when she was looking in the ‘right’ direction. In the last experiment, she found the laptop in the fastest way. The entire experiment was about the role of feedback.
                </p>
                <p className="mt-6 leading-8 resirent-p text-justify">
                    Of course, no feedback is the worst as shown in experiment number one. However, when you receive only negative feedback or very directive guidance, you might do what you are supposed to do, but and feel a negative association towards that activity. Compare that with a situation at work where your line manager either gives no feedback, only negative feedback, or positive feedback. In the first situation, you will not know what to do. In the second, you know what to do but it is at the expense of the joy of work, in the third one, you know what to do and you do it with a smile.
                </p>
                <p className="mt-6 leading-8 resirent-p text-justify">
                    When we brainstormed about our stickers and why they could work, it reminded me of this funny experiment and the Canadian professor. It opened my eyes at that time, but unfortunately, I had not applied the learning in my own day-to-day operations.
                </p>
            </>
        ),
    },
    {
        id: 'section-6',
        title: '6. More than just stickers',
        content: " My daughter and I take sustainability to heart. We are strong believers that if everyone makes small efforts, the world will be better off. The ResiStickers serve a dual purpose: they are not only practical for waste management, but also help us communicate our values and identity as responsible hosts. By using recycled materials and adhering to sustainable principles, we demonstrate our commitment to the environment. Our guests who prioritize sustainability appreciate these details, which enriches their experience and aligns with their values. Ultimately, these small touches contribute to our guests' positive impressions of our accommodation as a place that shares their values and seeks to make a positive impact."
    },
    {
        id: 'section-7',
        title: '7. For everyone in their own language',
        content: (<>
            <p className="mt-6 leading-8 resirent-p text-justify">
                Finally, I am always surprised and intrigued by where my guests come from and which language they speak. I like to learn some words in foreign languages, and it is always a pleasure to see the guests smile if I, with the emphasis on try, to speak some of their local language. Although most of them understand the basic English and would understand simple directions in English, I thought it is positive and charming to also provide these guidelines in their local language. After all, I already kind of ask them to do something in a paternal way and I want them to feel good, not to feel as if I want to educate them. A little, even symbolic, item like a local language seemed to me already a good step to accomplish that feel-good and positive attitude.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-3">
                <div className="p-5">
                    <Image maw={350} mx="auto" radius="md" src={pdf1} alt="description" />
                </div>
                <div className="p-5">
                    <Image maw={350} mx="auto" radius="md" src={pdf2} alt="description" />
                </div>
                <div className="p-5">
                    <Image maw={350} mx="auto" radius="md" src={pdf3} alt="description" />
                </div>
            </div>
        </>)
    },
    {
        id: 'section-8',
        title: '8. Conclusion',
        content: (<>
            <p className="mt-6 leading-8 resirent-p text-justify">
                We spent a lot of time deciding how to wire up the actual content of the stickers. We looked at different countries and their waste management policies and tried to combine our experiences with guests and as guests, and crafted the stickers to be sustainable in every way. It was a pleasure to see that our hard work paid off and that the guests were very enthusiastic about it! The magic of the stickers comes from the illustrations that give a positive and friendly hint, and from the small touch that the stickers give that contributes to our guests' positive impressions of our accommodation as a place that shares their values and seeks to make a positive impact.
            </p>
            <p className="mt-6 leading-8 resirent-p text-justify">
                We had a lot of fun getting carried away with the design and putting extra layers of polish to make it beautiful to put on your bins. Learn more about the content of the stickers by pressing the button bellow.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
                <a
                    href="https://resirent.myshopify.com/products/resistickers"
                    className="rounded-md resirent-btn-color px-3.5 py-2.5 resirent-btn-text shadow-sm hover:resirent-btn-hover-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >Get the stickers</a>
                <a href="/products/stickers" className="leading-6 resirent-btn-text">
                    Learn more <span aria-hidden="true">→</span>
                </a>
            </div>
        </>),
    },
    // Add more sections as needed
];

export const recyclingNL = [
    {
        id: 'section-1',
        title: '1. Introductie',
        content: "Uw gasten laten bij vertrek uw accommodatie af en toe in een complete puinhoop achter? U of uw schoonmaker moet dan veel tijd besteden aan het inzamelen van PET-flessen, glazen flessen, tijdschriften en etensresten? Als verhuurder kan dit erg frustrerend zijn, want ondanks onze inspanningen om ze bij aankomst te informeren, herinneren ze het zich vaak niet meer of volgen ze onze richtlijnen niet op! Maar geen paniek, er zijn oplossingen voor deze problemen die een groot verschil kunnen maken. Blijf lezen voor meer informatie.",
    },
    {
        id: 'section-2',
        title: '2. Welk afval laten gasten achter in onze accommodaties?',
        content: (<>
            <p className="mt-6 leading-8 resirent-p text-justify">
                Het stimuleren van verantwoord afvalbeheer bij onze gasten was een echte uitdaging. Ondanks onze inspanningen om duidelijke instructies en richtlijnen te geven, kwamen sommige gasten uit regio's waar recyclingpraktijken anders waren of niet vertrouwd waren met de juiste afvalverwerking, wat leidde tot onbedoelde afvalophoping. Als gevolg hiervan werd afvalverwerking een groot probleem voor ons. Een perfect voorbeeld is de stapel flessen op de foto links. Dit is het afval van een gezin van vier personen dat slechts 2 nachten verbleef! Het is echter niet altijd zo. Het gezin van drie personen op de foto rechts bleef zes nachten en produceerde nauwelijks afval. Hoewel dit een droomscenario was, is het helaas eerder uitzondering dan regel gebleven.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2">
                <div className="p-5">
                    <Image maw={350} mx="auto" radius="md" src={waste_bad} alt="description" />
                </div>
                <div className="p-5">
                    <Image maw={350} mx="auto" radius="md" src={waste_good} alt="description" />
                </div>

            </div>
            <p className="mt-6 leading-8 resirent-p text-justify">
                Lange tijd heb ik me afgevraagd hoe ik ervoor kon zorgen dat mijn gasten altijd hun afval weggooien, of ze nu een berg produceren of een klein beetje. Om dit probleem aan te pakken, begon ik hierover met mijn gasten te praten en realiseerde ik me al snel dat een probleem is dat afvalbeheer niet universeel is. Wonende in Zwitserland, een van de schoonste landen ter wereld met een nauwgezette cultuur rond afvalbeheer, besefte ik dat ik gewend ben aan dergelijk afvalbeheer. Mensen uit andere regio's zijn vaak minder goed op de hoogte van het onderwerp, omdat ze niet op dezelfde manier met afval omgaan als mensen in Zwitserland. Zo realiseerde ik me dat ik duidelijker moest zijn over wat afvalbeheer is en hoe het op de juiste manier wordt gedaan.
            </p>
            <p className="mt-6 leading-8 resirent-p text-justify">
                Daarom schreef ik een volledige en uitgebreide richtlijn op een informatieblad dat ik zichtbaar op de tafel legde naast de sleutels in de accommodatie. Ik dacht dat door ze de mogelijkheid te geven alles zelf stap voor stap te lezen, gasten de weg naar de juiste bak zouden vinden.
            </p>
            <p className="mt-6 leading-8 resirent-p text-justify">
                Ik was echter al snel erg teleurgesteld en kon alleen maar concluderen dat mensen zulke blaadjes niet lezen, hoe makkelijk ze ook waren om te lezen. Ik raakte er volledig van overtuigd dat ze het niet hadden gelezen nadat ik in paniek Whatsapp's kreeg van gasten die de wificode vroegen, die vetgedrukt staat op de eerste regel van hetzelfde informatieblad. Als ze de wificode niet eens vinden, is er geen kans dat ze het begin van onze instructies hebben gelezen.
            </p>
        </>),
    },
    {
        id: 'section-3',
        title: '1. Hoe lossen we het probleem van ongesorteerd afval op?',
        content: (<>
            <p className="mt-6 leading-8 resirent-p text-justify">
                Gelukkig ben ik ook mama van vier creatieve kinderen. Dus heb ik ze hierbij betrokken en om hulp gevraagd. Omdat ze het probleem kennen maar er geen deel van uitmaken, konden ze me helpen om out of the box te denken voor een oplossing. Hoewel ik mijn informatieblad had verfijnd en herschreven, was er nog steeds geen resultaat. Bijgevolg werd ik wanhopig. Ik wist niet waarom het niet werkte. Ik wist alleen dat mensen het informatieblad niet lazen, dus ik moest het makkelijker maken. Na brainstormen en veel uitproberen kregen we een idee. Een manier om gemakkelijk informatie te delen in een kwestie van seconden, een manier die zo gemakkelijk is dat u er niet over na hoeft te denken: Stickers! Stickers zijn een makkelijke manier om afvalverwerking te communiceren, daarom zijn we begonnen met het ontwerpen van onze eigen Resirent-stickers. We hebben geprobeerd de stickers duidelijk, maar toch elegant en leuk te maken en bovendien hebben we ervoor gezorgd dat ze zijn afgestemd op de diverse culturele en taalkundige achtergronden van onze gasten. Nadat we ze hadden uitgeprobeerd in onze accommodaties, beseften we al snel dat het werkte als magie!
            </p>
            <p className="mt-6 leading-8 resirent-p text-justify">

                Onze oplossing werkte als magie! De Resistickers zijn effectief omdat ze afbeeldingen gebruiken om instructies voor afvalverwerking te communiceren, die zijn afgestemd op de diverse culturele en taalkundige achtergronden van de gasten. Ze bieden een positieve en vriendelijke manier om feedback te geven aan gasten, waardoor we ons streven naar duurzaamheid versterken en hun algehele ervaring verbeteren. In de volgende paragrafen leggen we de effectiviteit van de stickers in detail uit. Het is leuk en leuk om te lezen, maar als je weinig tijd hebt, kun je verder springen door de pijl te gebruiken.
            </p>
        </>),
    },
    {
        id: 'section-4',
        title: '4. Ons brein houdt van plaatjes',
        content: (
            <>
                <p className="mt-6 leading-8 resirent-p text-justify">
                    Als mensen hebben we de neiging om afbeeldingen gemakkelijker te verwerken dan tekst. Om dit uit te testen kun je het volgende experiment proberen. Kijk naar de afbeeldingen hieronder en zie hoe lang het duurt voordat je het begrijpt. Probeer het nu nog eens, maar ga nu met de muis over de afbeelding en kijk hoe lang het nu duurt. Het kostte me minstens vijf minuten om erachter te komen dat de eerste de Mona Lisa en haar mooie glimlach vertegenwoordigt (misschien ben ik een beetje traag;-)). Het is dus volkomen normaal dat het ons minder energie kost om een ​​afbeelding te interpreteren dan om de tekst te lezen en onze verbeeldingskracht te gebruiken om te visualiseren wat de tekst zegt.
                </p>
                <Image maw={350} mx="auto" radius="md" src={mona_lisa_text} alt="description" />
                <Image maw={350} mx="auto" radius="md" src={mona_lisa_picture} alt="description" />
            </>
        ),
    },
    {
        id: 'section-5',
        title: '5. Duidelijkheid is het best als het gaat om communicatie',
        content: (
            <>
                <p className="mt-6 leading-8 resirent-p text-justify">
                    Stickers bieden een positieve en vriendelijke manier om met toeristen te communiceren, wat meestal de meest effectieve aanpak is. Toeristen zijn op zoek naar een ontspannende vakantie en een tijd om tot rust te komen, dus het is belangrijk ervoor te zorgen dat u hun tijd niet frustrerend en stressvol maakt in plaats van plezierig. Het lezen van een informatieblad lijkt misschien een hele klus voor veel toeristen, dus het was belangrijk voor mij om een ​​gemakkelijkere en positievere manier van communiceren te vinden. Stickers zijn een geweldige en leuke manier om informatie gemakkelijk en positief over te brengen. Ze zijn niet alleen gemakkelijk te begrijpen, maar ze zijn ook esthetisch en leuk. Door middel van deze stickers associëren toeristen de prullenbak met iets positiefs waardoor ze eerder geneigd zijn om te recyclen. Bovendien kunnen toeristen die misschien niet aan afvalbeheer hebben gedacht, deze kleurrijke stickers nu vanuit hun ooghoeken zien en daardoor beseffen dat ze bijvoorbeeld hun flessen in een specifieke bak kunnen deponeren.
                </p>
                <p className="mt-6 leading-8 resirent-p text-justify">
                    Het gebruik van een directieve of beschuldigende toon werkt vaak averechts en leidt tot negatieve reacties. Nogmaals, het deed me denken aan een van de lezingen over leiderschap die ik een paar jaar geleden bijwoonde. Een Canadese professor, die erg betrokken was, deed een oefening met ons. Een van ons moest het klaslokaal verlaten en een ander moest de laptop van de eerste persoon verstoppen. Toen de persoon terugkwam, mocht niemand van ons iets zeggen en moest de persoon op zoek naar haar computer. Ze vond het niet. Bij het tweede experiment moest de persoon weer naar buiten en bij terugkomst moesten we in de handen klappen als ze de verkeerde kant op keek. Uiteindelijk vond ze de laptop. Bij het derde experiment moest ze weer weg, en hielpen we haar toen ze terugkwam door in onze handen te klappen als ze in de ‘goede’ richting keek. Bij het laatste experiment vond ze de laptop op de snelste manier. Het hele experiment ging over de rol van feedback.
                </p>
                <p className="mt-6 leading-8 resirent-p text-justify">

                    Natuurlijk is geen feedback het ergst, zoals blijkt uit experiment nummer één. Wanneer u echter alleen negatieve feedback of zeer directieve begeleiding ontvangt, doet u misschien wel wat u moet doen, maar voelt u een negatieve associatie met die activiteit. Vergelijk dat eens met een situatie op het werk waarin uw lijnmanager ofwel geen feedback geeft, alleen negatieve feedback, ofwel positieve feedback. In de eerste situatie weet u niet wat u moet doen. Bij de tweede weet je wat je moet doen maar gaat het ten koste van het werkplezier, bij de derde weet je wat je moet doen en doe je het met een glimlach.
                </p>
                <p className="mt-6 leading-8 resirent-p text-justify">

                    Toen we brainstormden over onze stickers en waarom ze zouden kunnen werken, deed het me denken aan dit grappige experiment en de Canadese professor. Het opende toen mijn ogen, maar helaas had ik het geleerde niet toegepast in mijn eigen dagelijkse werkzaamheden.
                </p>
            </>
        ),
    },
    {
        id: 'section-6',
        title: '6. Meer dan alleen stickers',
        content: " Mijn dochter en ik nemen duurzaamheid ter harte. We zijn er sterk van overtuigd dat als iedereen kleine inspanningen levert, de wereld beter af zal zijn. De ResiStickers dienen een tweeledig doel: ze zijn niet alleen praktisch voor afvalbeheer, maar helpen ons ook onze waarden en identiteit als verantwoordelijke gastheren te communiceren. Door duurzame principes na te leven, tonen we onze toewijding aan het milieu. Onze gasten die duurzaamheid vooropstellen, waarderen deze details, die hun ervaring verrijken en aansluiten bij hun waarden. Uiteindelijk dragen deze kleine details bij aan de positieve indruk van onze gasten van onze accommodatie als een plek die hun waarden deelt en een positieve impact probeert te maken."
    },
    {
        id: 'section-7',
        title: '7. Voor iedereen in zijn eigen taal',
        content: (<>
            <p className="mt-6 leading-8 resirent-p text-justify">
                Tot slot ben ik altijd verrast en geïntrigeerd door waar mijn gasten vandaan komen en welke taal ze spreken. Ik leer graag wat woorden in vreemde talen, en het is altijd een plezier om de gasten te zien glimlachen als ik, met de nadruk op proberen, iets van hun lokale taal spreek. Hoewel de meesten van hen het basis Engels begrijpen en eenvoudige aanwijzingen in het Engels zouden begrijpen, vond ik het positief en charmant om deze richtlijnen ook in hun lokale taal te geven. Ik vraag ze tenslotte al een beetje om iets op een moederlijke manier te doen en ik wil dat ze zich goed voelen, niet het gevoel hebben dat ik ze wil opvoeden. Een klein, zelfs symbolisch item als een lokale taal leek me al een goede stap om die feelgood en positieve instelling te bewerkstelligen.

            </p>
            <div className="grid grid-cols-1 md:grid-cols-3">
                <div className="p-5">
                    <Image maw={350} mx="auto" radius="md" src={pdf1} alt="description" />
                </div>
                <div className="p-5">
                    <Image maw={350} mx="auto" radius="md" src={pdf2} alt="description" />
                </div>
                <div className="p-5">
                    <Image maw={350} mx="auto" radius="md" src={pdf3} alt="description" />
                </div>
            </div>
        </>)
    },
    {
        id: 'section-8',
        title: '8. Conclusie',
        content: (<>
            <p className="mt-6 leading-8 resirent-p text-justify">
                We hebben veel tijd besteed aan het beslissen hoe we de daadwerkelijke inhoud van de stickers moesten aansluiten. We keken naar verschillende landen en hun afvalbeheerbeleid en probeerden onze ervaringen met gasten en als gasten te combineren. Het was een genoegen om te zien dat ons harde werk zijn vruchten afwierp en dat de gasten er erg enthousiast over waren! De magie van de stickers komt van de illustraties die een positieve en vriendelijke hint geven, en van de kleine aanraking die de stickers geven die bijdraagt ​​aan de positieve indruk van onze gasten van onze accommodatie als een plek die hun waarden deelt en een positieve indruk probeert te maken invloed.
            </p>
            <p className="mt-6 leading-8 resirent-p text-justify">
                We hadden veel plezier om ons te laten meeslepen door het ontwerp en door extra lagen lak aan te brengen om het mooi te maken om op je bakken te zetten. Lees meer over de inhoud van de stickers door op de onderstaande knop te drukken.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
                <a
                    href="https://resirent.myshopify.com/products/resistickers"
                    className="rounded-md resirent-btn-color px-3.5 py-2.5 resirent-btn-text shadow-sm hover:resirent-btn-hover-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >Koop de stickers</a>
                <a href="/be/producten/stickers" className="leading-6 resirent-btn-text">
                    Leer meer <span aria-hidden="true">→</span>
                </a>
            </div>
        </>),
    },
    // Add more sections as needed
];

export const recyclingDE =
    [
        {
            "id": "section-1",
            "title": "1. Einleitung",
            "content": "Ihre Gäste hinterlassen Ihre Unterkunft gelegentlich in einem völligen Chaos bei der Abreise? Sie oder Ihr Reinigungsteam müssen dann eine Menge Zeit aufwenden, um PET-Flaschen, Glasflaschen, Zeitschriften und Essensreste zu sammeln? Als Gastgeber kann das sehr frustrierend sein, denn trotz unserer Bemühungen, sie bei der Ankunft zu informieren, erinnern sie sich oft nicht daran oder folgen unseren Anweisungen nicht! Aber keine Panik, es gibt Lösungen für diese Probleme, die einen großen Unterschied machen können. Lesen Sie weiter, um mehr zu erfahren."
        },
        {
            "id": "section-2",
            "title": "2. Welchen Müll hinterlassen Gäste in unseren Unterkünften?",
            "content": (
                <div>
                    <p>
                        Verantwortungsvolles Abfallmanagement bei unseren Gästen zu fördern, war eine echte Herausforderung. Trotz unserer besten Bemühungen, klare Anweisungen und Richtlinien zu geben, kamen einige Gäste aus Regionen, in denen Recyclingpraktiken anders waren oder sie nicht mit der richtigen Abfallentsorgung vertraut waren, was zu unbeabsichtigter Müllansammlung führte. Infolgedessen wurde die Abfallentsorgung zu einem erheblichen Problem für uns. Ein perfektes Beispiel ist der Haufen Flaschen auf dem Bild links. Das ist der Abfall einer vierköpfigen Familie, die nur für 2 Nächte hier war! Es ist jedoch nicht immer so. Die dreiköpfige Familie auf dem Bild rechts blieb sechs Nächte und produzierte kaum Abfall. Obwohl dies ein Traumszenario war, blieb es leider die Ausnahme und nicht die Regel.
                    </p>
                    <div>
                        <div className="p-5">
                            <Image maw={350} mx="auto" radius="md" src={waste_bad} alt="description" />
                        </div>
                        <div className="p-5">
                            <Image maw={350} mx="auto" radius="md" src={waste_good} alt="description" />
                        </div>
                    </div>
                    <p>
                        Lange Zeit fragte ich mich, wie ich sicherstellen könnte, dass meine Gäste ihren Müll immer entsorgen, egal ob sie einen Berg oder ein bisschen produzieren. Um dieses Problem anzugehen, begann ich, mit meinen Gästen darüber zu sprechen und merkte bald, dass das Problem darin besteht, dass Abfallmanagement nicht universell ist. Da ich in der Schweiz lebe, einem der saubersten Länder der Welt mit einer akribischen Kultur im Umgang mit Abfall, wurde mir klar, dass ich an solche Abfallwirtschaft gewöhnt bin. Menschen aus anderen Regionen sind oft weniger gebildet in diesem Thema, da sie nicht auf die gleiche Weise mit Abfall umgehen wie die Menschen in der Schweiz. Daher wurde mir klar, dass ich deutlicher sein musste, was Abfallmanagement ist und wie es richtig gemacht wird.
                    </p>
                    <p>
                        Infolgedessen schrieb ich eine vollständige und ausführliche Richtlinie auf einem Informationsblatt, das ich sichtbar auf den Tisch neben den Schlüsseln in der Unterkunft legte. Ich dachte, indem ich ihnen die Möglichkeit gebe, alles selbst Schritt für Schritt zu lesen, würden die Gäste ihren Weg zum richtigen Mülleimer finden.
                    </p>
                    <p>
                        Allerdings war ich schnell sehr enttäuscht und konnte nur feststellen, dass die Leute solche Blätter nicht lesen, egal wie leicht sie zu lesen waren. Ich war vollkommen davon überzeugt, dass sie es nicht lesen, nachdem ich Panik-Whatsapps von Gästen bekam, die nach dem WLAN-Code fragten, der in fetter Schrift in der ersten Zeile desselben Informationsblatts steht. Wenn sie nicht einmal den WLAN-Code finden, gibt es keine Chance, dass sie den Anfang unserer Anweisungen gelesen haben.
                    </p>
                </div>
            ),
        },
        {
            "id": "section-3",
            "title": "3. Wie können wir das Problem des unsortierten Abfalls lösen?",
            "content": (
                <div>
                    <p>
                        Glücklicherweise bin ich auch Mutter von vier kreativen Kindern. Also bezog ich sie mit ein und bat sie um Hilfe. Da sie das Problem kennen, aber nicht Teil davon sind, konnten sie mir helfen, über den Tellerrand hinaus nach einer Lösung zu suchen. Obwohl ich mein Informationsblatt verfeinert und neu geschrieben hatte, gab es immer noch kein Ergebnis. Folglich wurde ich verzweifelt. Ich wusste nicht, warum es nicht funktionierte. Alles, was ich wusste, war, dass die Leute das Informationsblatt nicht lasen, also musste ich es einfacher machen. Nach Brainstorming und vielen Versuchen hatten wir eine Idee. Eine Möglichkeit, Informationen in Sekundenschnelle leicht zu teilen, so einfach, dass man nicht darüber nachdenken muss.
                        Aufkleber! Aufkleber sind eine einfache Möglichkeit, die Abfallentsorgung zu kommunizieren, also begannen wir, unsere eigenen Resirent-Aufkleber zu entwerfen. Wir versuchten, die Aufkleber klar, aber dennoch elegant und lustig zu gestalten und stellten darüber hinaus sicher, dass sie aus recycelten Materialien hergestellt wurden und auf die vielfältigen kulturellen und sprachlichen Hintergründe unserer Gäste zugeschnitten waren. Nachdem wir sie in unseren Unterkünften getestet hatten, stellten wir bald fest, dass sie wie ein Zauber wirkten!
                    </p>
                    <p>
                        Unsere Lösung funktionierte wie ein Zauber! Die Resistickers sind effektiv, weil sie visuelle Mittel nutzen, um Anweisungen zur Abfallentsorgung zu kommunizieren, aus recycelten Materialien hergestellt werden und auf die vielfältigen kulturellen und sprachlichen Hintergründe der Gäste zugeschnitten sind. Sie bieten eine positive und freundliche Möglichkeit, den Gästen Rückmeldung zu geben, verstärken unser Engagement für Nachhaltigkeit und verbessern deren Gesamterlebnis. In den folgenden Absätzen erklären wir die Wirksamkeit der Aufkleber im Detail. Es ist eine unterhaltsame und lustige Lektüre, aber wenn Sie wenig Zeit haben, können Sie mit dem Pfeil nach vorne springen.
                    </p>
                </div>
            ),
        },
        {
            "id": "section-4",
            "title": "4. Unser Gehirn liebt Bilder",
            "content": (
                <div>
                    <p>
                        Als Menschen verarbeiten wir Bilder leichter als Text. Um dies zu testen, können Sie das folgende Experiment ausprobieren. Schauen Sie sich die Bilder unten an und sehen Sie, wie lange es dauert, bis Sie sie verstehen. Versuchen Sie es noch einmal, aber jetzt fahren Sie mit der Maus über das Bild und sehen Sie, wie lange es jetzt dauert. Ich habe mindestens fünf Minuten gebraucht, um herauszufinden, dass das erste Bild die Mona Lisa und ihr schönes Lächeln darstellt (vielleicht bin ich ein bisschen langsam;-)). Es ist also völlig normal, dass es uns weniger Energie kostet, ein Bild zu interpretieren, als einen Text zu lesen und unsere Vorstellungskraft zu nutzen, um zu visualisieren, was der Text sagt.
                    </p>
                    <img src={mona_lisa_text} alt="Beschreibung" />
                    <img src={mona_lisa_picture} alt="Beschreibung" />
                </div>
            ),
        },
        {
            id: 'section-5',
            title: '5. Klarheit ist freundlich in der Kommunikation',
            content: (
                <>
                    <p className="mt-6 leading-8 resirent-p text-justify">
                        Aufkleber bieten eine positive und freundliche Art, mit Touristen zu kommunizieren, was sich als die effektivste Methode erweist. Touristen suchen nach einem entspannenden Urlaub und einer Zeit zum Abschalten, daher ist es wichtig, sicherzustellen, dass ihre Zeit nicht frustrierend und stressig, sondern angenehm wird. Das Lesen eines Informationsblattes kann für viele Touristen wie eine Last erscheinen, deshalb war es mir wichtig, eine einfachere und positivere Kommunikationsform zu finden. Aufkleber sind eine großartige und spaßige Art, Informationen leicht und positiv zu vermitteln. Sie sind nicht nur leicht zu verstehen, sondern auch ästhetisch ansprechend und unterhaltsam. Durch diese Aufkleber verbinden die Touristen die Mülleimer mit etwas Positivem und sind somit eher geneigt zu recyceln. Außerdem könnten Touristen, die vielleicht nicht über Abfallmanagement nachgedacht haben, diese bunten Aufkleber aus dem Augenwinkel sehen und dadurch realisieren, dass sie zum Beispiel ihre Flaschen in einen bestimmten Behälter werfen können.
                    </p>
                    <p className="mt-6 leading-8 resirent-p text-justify">
                        Die Verwendung eines direktiven oder anklagenden Tons kann oft nach hinten losgehen und zu negativen Reaktionen führen. Es erinnerte mich wieder an einen der Führungsvorträge, die ich vor einigen Jahren besucht habe. Der Professor, ein gut aussehender Kanadier, der sehr engagiert war, führte eine Übung mit uns durch. Einer von uns musste den Klassenraum verlassen und eine andere Person musste den Laptop der ersten Person verstecken. Als die Person zurückkam, sollten wir alle nichts sagen und die Person musste nach ihrem Computer suchen. Sie fand ihn nicht. Im zweiten Experiment musste die Person wieder rausgehen und beim Zurückkommen mussten wir in die Hände klatschen, wenn sie in die falsche Richtung schaute. Schließlich fand sie den Laptop. Im dritten Experiment musste sie wieder rausgehen, und wir halfen ihr bei der Rückkehr, indem wir klatschten, wenn sie in die „richtige“ Richtung schaute. Im letzten Experiment fand sie den Laptop am schnellsten. Das gesamte Experiment drehte sich um die Rolle von Feedback.
                    </p>
                    <p className="mt-6 leading-8 resirent-p text-justify">
                        Natürlich ist kein Feedback das Schlimmste, wie im ersten Experiment gezeigt. Erhält man jedoch nur negatives Feedback oder sehr direktive Anweisungen, kann man zwar tun, was man soll, aber fühlt eine negative Assoziation zu dieser Aktivität. Vergleichen Sie das mit einer Situation bei der Arbeit, in der Ihr Vorgesetzter entweder kein Feedback gibt, nur negatives Feedback oder positives Feedback. In der ersten Situation wissen Sie nicht, was zu tun ist. In der zweiten wissen Sie, was zu tun ist, aber es geht auf Kosten der Freude an der Arbeit; im dritten Fall wissen Sie, was zu tun ist, und Sie tun es mit einem Lächeln.
                    </p>
                    <p className="mt-6 leading-8 resirent-p text-justify">
                        Als wir über unsere Aufkleber und warum sie funktionieren könnten nachdachten, erinnerte es mich an dieses lustige Experiment und den kanadischen Professor. Es öffnete mir damals die Augen, aber leider hatte ich das Gelernte nicht in meinen eigenen täglichen Abläufen angewendet.
                    </p>
                </>
            ),
        },
        {
            id: 'section-6',
            title: '6. Mehr als nur Aufkleber',
            content: "Meine Tochter und ich nehmen Nachhaltigkeit sehr ernst. Wir glauben fest daran, dass die Welt besser dran wäre, wenn jeder kleine Anstrengungen unternimmt. Die ResiStickers erfüllen einen doppelten Zweck: Sie sind nicht nur praktisch für das Abfallmanagement, sondern helfen uns auch, unsere Werte und Identität als verantwortungsbewusste Gastgeber zu kommunizieren. Indem wir recycelte Materialien verwenden und uns an nachhaltige Prinzipien halten, demonstrieren wir unser Engagement für die Umwelt. Unsere Gäste, die Nachhaltigkeit schätzen, wissen diese Details zu schätzen, was ihr Erlebnis bereichert und mit ihren Werten übereinstimmt. Letztendlich tragen diese kleinen Details dazu bei, dass unsere Gäste einen positiven Eindruck von unserer Unterkunft als einem Ort bekommen, der ihre Werte teilt und einen positiven Einfluss sucht."
        },
        {
            id: 'section-7',
            title: '7. Für jeden in seiner eigenen Sprache',
            content: (<>
                <p className="mt-6 leading-8 resirent-p text-justify">
                    Schließlich bin ich immer wieder überrascht und fasziniert davon, woher meine Gäste kommen und welche Sprache sie sprechen. Ich lerne gerne ein paar Worte in fremden Sprachen, und es ist immer ein Vergnügen zu sehen, wie die Gäste lächeln, wenn ich, mit Betonung auf versuchen, ein paar Worte ihrer Landessprache spreche. Obwohl die meisten von ihnen Grundkenntnisse in Englisch haben und einfache Anweisungen auf Englisch verstehen würden, dachte ich, es ist positiv und charmant, diese Richtlinien auch in ihrer Landessprache anzubieten. Schließlich bitte ich sie ja schon auf eine väterliche Art und Weise, etwas zu tun, und ich möchte, dass sie sich gut fühlen, nicht so, als wollte ich sie belehren. Ein kleines, sogar symbolisches, Element wie eine lokale Sprache schien mir bereits ein guter Schritt, um dieses Wohlfühlgefühl und eine positive Einstellung zu erreichen.
                </p>
                <div className="grid grid-cols-1 md:grid-cols-3">
                    <div className="p-5">
                        <Image maw={350} mx="auto" radius="md" src={pdf1} alt="Beschreibung" />
                    </div>
                    <div className="p-5">
                        <Image maw={350} mx="auto" radius="md" src={pdf2} alt="Beschreibung" />
                    </div>
                    <div className="p-5">
                        <Image maw={350} mx="auto" radius="md" src={pdf3} alt="Beschreibung" />
                    </div>
                </div>
            </>)
        },
        {
            id: 'section-8',
            title: '8. Schlussfolgerung',
            content: (<>
                <p className="mt-6 leading-8 resirent-p text-justify">
                    Wir haben viel Zeit darauf verwendet, zu entscheiden, wie wir den tatsächlichen Inhalt der Aufkleber gestalten sollen. Wir schauten uns verschiedene Länder und ihre Abfallmanagementpolitik an und versuchten, unsere Erfahrungen mit Gästen und als Gäste zu kombinieren. Es war erfreulich zu sehen, dass unsere harte Arbeit Früchte trug und die Gäste sehr begeistert davon waren! Die Magie der Aufkleber kommt von den Illustrationen, die einen positiven und freundlichen Hinweis geben, und von der kleinen Berührung, die die Aufkleber geben, die zur positiven Eindruck unserer Gäste von unserer Unterkunft als einem Ort beiträgt, der ihre Werte teilt und einen positiven Einfluss zu machen versucht.
                </p>
                <p className="mt-6 leading-8 resirent-p text-justify">
                    Wir hatten viel Spaß daran, uns in das Design hineinzusteigern und zusätzliche Lackschichten aufzutragen, um sie schön genug zu machen, um sie auf Ihre Behälter zu kleben. Erfahren Sie mehr über den Inhalt der Aufkleber, indem Sie auf den untenstehenden Knopf klicken.
                </p>
                <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
                    <a
                        href="https://resirent.myshopify.com/products/resistickers"
                        className="rounded-md resirent-btn-color px-3.5 py-2.5 resirent-btn-text shadow-sm hover:resirent-btn-hover-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >Kaufen Sie die Aufkleber</a>
                    <a href="/de/produkte/aufkleber" className="leading-6 resirent-btn-text">
                        Erfahren Sie mehr <span aria-hidden="true">→</span>
                    </a>
                </div>
            </>),
        },
    ]      
import {
  createStyles,
  Group,
  Title,
  Text,
  Card,
  SimpleGrid,
  Container,
  rem,
  Image,
  AspectRatio,
  Button,
  List,
} from "@mantine/core";
import { CheckCircle } from "lucide-react";
import { IconMail, IconBolt } from "@tabler/icons-react";
import resiweb from "../../assets/homepage/resiweb.jpg";
import resiCal from "../../assets/homepage/Calendar.png";
import image from "../../assets/consulting/consulting-1.jpeg";
import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme) => ({
  section: {
    padding: rem(60),
    [theme.fn.smallerThan("sm")]: {
      padding: rem(30),
    },
  },
  subtitle: {
    fontSize: rem(14),
    fontWeight: 600,
    textAlign: "center",
    color: theme.colors.resirent[0],
    fontFamily: "Inter, sans-serif",
    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(12),
    },
  },
  title: {
    fontSize: rem(36),
    fontWeight: 550,
    textAlign: "center",
    fontFamily: "Inter, sans-serif",
    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(22),
    },
  },
  description: {
    fontSize: rem(18),
    textAlign: "center",
    maxWidth: rem(800),
    margin: "0 auto",
    color: theme.colors.gray[6],
    fontFamily: "Inter, sans-serif",
    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(14),
    },
  },
  card: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: rem(32),
    gap: rem(32),
    backgroundColor: theme.white,
    boxShadow: theme.shadows.md,
    borderRadius: rem(12),
    [theme.fn.smallerThan("sm")]: {
      flexDirection: "column",
      padding: rem(20),
      textAlign: "center",
      width: "100%",
    },
  },
  iconWrapper: {
    width: rem(50),
    height: rem(50),
    borderRadius: "50%",
    backgroundColor: theme.colors.pink[1],
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.fn.smallerThan("sm")]: {
      margin: "0 auto",
    },
  },
  buttonGroup: {
    marginTop: rem(20),
    display: "flex",
    gap: rem(10),
    justifyContent: "flex-start",
    [theme.fn.smallerThan("sm")]: {
      justifyContent: "center",
      flexWrap: "wrap",
    },
  },
  cardContent: {
    flex: 1,
    maxWidth: "50%",
    [theme.fn.smallerThan("sm")]: {
      maxWidth: "100%",
      order: 2,
    },
  },
  imageContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "50%",
    [theme.fn.smallerThan("sm")]: {
      width: "100%",
      order: 1,
    },
  },

  image: {
    maxWidth: "100%",
    height: "auto",
    borderRadius: rem(12),
  },
  button: {
    fontSize: rem(14),
    padding: `${rem(8)} ${rem(12)}`,
    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(12),
      padding: `${rem(6)} ${rem(10)}`,
    },
  },
}));

const features = [
  {
    title: "Website",
    description:
      "Develop your own identity and express it on your own website.",
    icon: IconMail,
    image: resiweb,
    checklist: [
      "Create a custom website to showcase your property",
      "Manage listings and availability easily",
      "Engage directly with potential guests",
    ],
  },
  {
    title: "Calendar",
    description:
      "Add a calendar plug-in to your website to enable direct bookings.",
    icon: IconBolt,
    image: resiCal,
    checklist: [
      "Sync with external booking platforms",
      "Automate availability and reservations",
      "Reduce booking conflicts and streamline scheduling",
    ],
  },
  {
    title: "Get Advice",
    description: "Get in touch with us for consultation on your hosting needs.",
    icon: IconMail,
    image: image,
    checklist: [
      "Personalized guidance for your rental business",
      "Increase revenue through optimization",
      "Make your Rental business more sustainable",
    ],
  },
];

const translations = {
  "/": {
    "Creating your own website: overwhelming?":
      "Creating your own website: overwhelming?",
    "You’re not alone! You might feel stuck, not sure where to start or how to handle the technical site":
      "You’re not alone! You might feel stuck, not sure where to start or how to handle the technical site",
    "That’s where we come in. We’ve created a simple process to help you build and maintain your own professional website: stress free and effective.":
      "That’s where we come in. We’ve created a simple process to help you build and maintain your own professional website: stress free and effective.",
    Website: "Website",
    "Develop your own identity and express it on your own website.":
      "Develop your own identity and express it on your own website.",
    "Create a custom website to showcase your property":
      "Create a custom website to showcase your property",
    "Manage listings and availability easily":
      "Manage listings and availability easily",
    "Engage directly with potential guests":
      "Engage directly with potential guests",
    Calendar: "Calendar",
    "Add a calendar plug-in to your website to enable direct bookings.":
      "Add a calendar plug-in to your website to enable direct bookings.",
    "Sync with external booking platforms":
      "Sync with external booking platforms",
    "Automate availability and reservations":
      "Automate availability and reservations",
    "Reduce booking conflicts and streamline scheduling":
      "Reduce booking conflicts and streamline scheduling",
    "Get Advice": "Get Advice",
    "Get in touch with us for consultation on your hosting needs.":
      "Get in touch with us for consultation on your hosting needs.",
    "Personalized guidance for your rental business":
      "Personalized guidance for your rental business",
    "Increase revenue through optimization":
      "Increase revenue through optimization",
    "Make your Rental business more sustainable":
      "Make your Rental business more sustainable",
    "Learn more": "Learn more",
    "Contact us": "Contact us",
  },
  "/nl": {
    "Creating your own website: overwhelming?":
      "Een eigen website maken: overweldigend?",
    "You’re not alone! You might feel stuck, not sure where to start or how to handle the technical site":
      "Je bent niet alleen! Je weet niet waar te beginnen, hoe de technische kant werkt, geen zorgen",
    "That’s where we come in. We’ve created a simple process to help you build and maintain your own professional website: stress free and effective.":
      "Dit probleem hebben wij opgelost. Met onze oplossing maak je en onderhoud je eenvoudig je eigen, professional website: zonder stress en met resultaat.",
    Website: "Website",
    "Develop your own identity and express it on your own website.":
      "Ontwikkel uw eigen identiteit en druk deze uit op uw eigen website.",
    "Create a custom website to showcase your property":
      "Creëer een aangepaste website om uw eigendom te presenteren",
    "Manage listings and availability easily":
      "Beheer eenvoudig aanbiedingen en beschikbaarheid",
    "Engage directly with potential guests":
      "Communiceer direct met potentiële gasten",
    Calendar: "Kalender",
    "Add a calendar plug-in to your website to enable direct bookings.":
      "Voeg een kalenderplug-in toe aan uw website om directe boekingen mogelijk te maken.",
    "Sync with external booking platforms":
      "Synchroniseer met externe boekingsplatforms",
    "Automate availability and reservations":
      "Automatiseer beschikbaarheid en reserveringen",
    "Reduce booking conflicts and streamline scheduling":
      "Verminder boekingsconflicten en stroomlijn de planning",
    "Get Advice": "Krijg Advies",
    "Get in touch with us for consultation on your hosting needs.":
      "Neem contact met ons op voor advies over uw hostingbehoeften.",
    "Increase revenue through optimization":
      "Verhoog de omzet door optimalisatie",
    "Make your Rental business more sustainable":
      "Maak uw verhuurbedrijf duurzamer",
    "Personalized guidance for your rental business":
      "Maak jouw vakantie verhuur duurzaam",
    "Learn more": "Leer meer",
    "Contact us": "Neem contact op",
  },
  "/de": {
    "Creating your own website: overwhelming?":
      "Eine eigene Website erstellen: überwältigend?",
    "You’re not alone! You might feel stuck, not sure where to start or how to handle the technical site":
      "Du bist nicht allein! Vielleicht fühlst du dich festgefahren, weißt nicht, wo du anfangen sollst oder wie du die technischen Aspekte der Website bewältigen sollst",
    "That’s where we come in. We’ve created a simple process to help you build and maintain your own professional website: stress free and effective.":
      "Da kommen wir ins Spiel. Wir haben einen einfachen Prozess entwickelt, der dir hilft, deine eigene professionelle Website zu erstellen und zu verwalten – stressfrei und effektiv.",
    Website: "Webseite",
    "Develop your own identity and express it on your own website.":
      "Entwickeln Sie Ihre eigene Identität und drücken Sie sie auf Ihrer eigenen Webseite aus.",
    "Create a custom website to showcase your property":
      "Erstellen Sie eine benutzerdefinierte Webseite, um Ihre Immobilie zu präsentieren",
    "Manage listings and availability easily":
      "Verwalten Sie Angebote und Verfügbarkeit einfach",
    "Engage directly with potential guests":
      "Kommunizieren Sie direkt mit potenziellen Gästen",
    Calendar: "Kalender",
    "Add a calendar plug-in to your website to enable direct bookings.":
      "Fügen Sie ein Kalender-Plugin zu Ihrer Webseite hinzu, um direkte Buchungen zu ermöglichen.",
    "Sync with external booking platforms":
      "Synchronisieren Sie mit externen Buchungsplattformen",
    "Automate availability and reservations":
      "Automatisieren Sie Verfügbarkeit und Reservierungen",
    "Reduce booking conflicts and streamline scheduling":
      "Reduzieren Sie Buchungskonflikte und optimieren Sie die Planung",
    "Get Advice": "Beratung Erhalten",
    "Get in touch with us for consultation on your hosting needs.":
      "Kontaktieren Sie uns für eine Beratung zu Ihren Hosting-Bedürfnissen.",
    "Personalized guidance for your rental business":
      "Personalisierte Beratung für Ihr Vermietungsgeschäft",
    "Increase revenue through optimization":
      "Umsatz durch Optimierung steigern",
    "Make your Rental business more sustainable":
      "Machen Sie Ihr Vermietungsgeschäft nachhaltiger",
    "Learn more": "Mehr erfahren",
    "Contact us": "Kontaktiere uns",
  },
};

export default function FeaturingSection() {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const languagePrefix = window.location.pathname.startsWith("/nl")
    ? "/nl"
    : window.location.pathname.startsWith("/de")
    ? "/de"
    : "/";

  return (
    <Container size="xl" className={classes.section}>
      <Text className={classes.subtitle}>
        {translations[languagePrefix][
          "Creating your own website: overwhelming?"
        ] || translations["/"]["Creating your own website: overwhelming?"]}
      </Text>
      <Title className={classes.title}>
        {translations[languagePrefix][
          "You’re not alone! You might feel stuck, not sure where to start or how to handle the technical site"
        ] ||
          translations["/"][
            "You’re not alone! You might feel stuck, not sure where to start or how to handle the technical site"
          ]}
        {/* <span style={{ display: "block" }}> Hosting Solutions</span> */}
      </Title>
      <Text className={classes.description}>
        {translations[languagePrefix][
          "That’s where we come in. We’ve created a simple process to help you build and maintain your own professional website: stress free and effective."
        ] ||
          translations["/"][
            "That’s where we come in. We’ve created a simple process to help you build and maintain your own professional website: stress free and effective."
          ]}
      </Text>
      <SimpleGrid cols={1} spacing={50} mt={60}>
        {features.map((feature, index) => (
          <Card key={index} className={classes.card} radius="md">
            {index % 2 === 0 ? (
              <>
                <div className={classes.cardContent}>
                  <div className={classes.iconWrapper}>
                    <feature.icon size={24} color="#ff5a5f" />
                  </div>
                  <Title order={3} mt="md">
                    {translations[languagePrefix][feature.title] ||
                      translations["/"][feature.title]}
                  </Title>
                  <Text mt="sm" color="gray.6">
                    {translations[languagePrefix][feature.description] ||
                      translations["/"][feature.description]}
                  </Text>
                  <List
                    spacing="xs"
                    size="sm"
                    mt="md"
                    icon={<CheckCircle size={20} color="green" />}
                  >
                    {feature.checklist.map((item, idx) => (
                      <List.Item key={idx}>
                        {translations[languagePrefix][item] ||
                          translations["/"][item]}
                      </List.Item>
                    ))}
                  </List>
                  <div className={classes.buttonGroup}>
                    <Button
                      variant="outline"
                      sx={{
                        color: "black",
                        backgroundColor: "white",
                        border: "1px solid gray",
                        "&:hover": { backgroundColor: "#f0f0f0" }, // Light hover effect
                      }}
                    >
                      {translations[languagePrefix]["Learn more"] ||
                        translations["/"]["Learn more"]}
                    </Button>

                    <Button
                      sx={{
                        backgroundColor: "#003D51",
                        color: "white",
                        "&:hover": { backgroundColor: "#002B3A" }, // Slightly darker on hover
                      }}
                    >
                      {translations[languagePrefix]["Contact us"] ||
                        translations["/"]["Contact us"]}
                    </Button>
                  </div>
                </div>
                <div className={classes.imageContainer}>
                  <>
                    <Image
                      src={feature.image}
                      alt={feature.title}
                      className={classes.image}
                    />
                    {feature.title === "Get Advice" && (
                      <Text
                        size="xs"
                        color="dimmed"
                        mt={4}
                        align="center"
                        weight="bold"
                        sx={{ fontStyle: "italic" }}
                      >
                        Copyright by @Bert Stephani
                      </Text>
                    )}
                  </>
                </div>
              </>
            ) : (
              <>
                <div className={classes.imageContainer}>
                  <Image
                    src={feature.image}
                    alt={feature.title}
                    className={classes.image}
                  />
                </div>
                <div className={classes.cardContent}>
                  <div className={classes.iconWrapper}>
                    <feature.icon size={24} color="#ff5a5f" />
                  </div>
                  <Title order={3} mt="md">
                    {translations[languagePrefix][feature.title] ||
                      translations["/"][feature.title]}
                  </Title>
                  <Text mt="sm" color="gray.6">
                    {translations[languagePrefix][feature.description] ||
                      translations["/"][feature.description]}
                  </Text>
                  <List
                    spacing="xs"
                    size="sm"
                    mt="md"
                    icon={<CheckCircle size={20} color="green" />}
                  >
                    {feature.checklist.map((item, idx) => (
                      <List.Item key={idx}>
                        {translations[languagePrefix][item] ||
                          translations["/"][item]}
                      </List.Item>
                    ))}
                  </List>
                  <div className={classes.buttonGroup}>
                    <Button
                      variant="outline"
                      sx={{
                        color: "black",
                        backgroundColor: "white",
                        border: "1px solid gray",
                        "&:hover": { backgroundColor: "#f0f0f0" }, // Light hover effect
                      }}
                    >
                      {translations[languagePrefix]["Learn more"] ||
                        translations["/"]["Learn more"]}
                    </Button>

                    <Button
                      sx={{
                        backgroundColor: "#003D51",
                        color: "white",
                        "&:hover": { backgroundColor: "#002B3A" }, // Slightly darker on hover
                      }}
                    >
                      {translations[languagePrefix]["Contact us"] ||
                        translations["/"]["Contact us"]}
                    </Button>
                  </div>
                </div>
              </>
            )}
          </Card>
        ))}
      </SimpleGrid>
    </Container>
  );
}

import axios from "../api/axios";
import useAuth from "./useAuth";

const useLogout = () => {
    const { setAuth } = useAuth();

    const logout = async () => {

        await axios('/logout', {
            withCredentials: true
        }).then((res) => {
            console.log("hello")
            console.log(res.data);

        }).catch((err) => {
            console.log(err);
        });
        setAuth({});
    }
    return logout;
}

export default useLogout;
import React from 'react'
import image from '../../assets/products/resiWebsite.png';
import { createStyles, Group, rem, Button, Title, Box, Container, Text, Image, Paper } from '@mantine/core';
import ResiWebsiteHeader from './ResiWebsite/Header';
import MainProduct from './ResiWebsite/MainProduct';
import MainProductSecond from './ResiWebsite/MainPage2';
import MainProductThird from './ResiWebsite/MainPage3';
import ProductPage from './ResiWebsite/Page';
import { FAQ } from '../prices/FAQ';
import Benefits from './ResiWebsite/Benefits';

const useStyles = createStyles((theme) => ({
    title: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(24),
            maxWidth: '100%',
        },
        maxWidth: '66%',
    },

    paperProduct: {
        [theme.fn.smallerThan('sm')]: {
            width: '100%',
            marginTop: rem(30),
        },
        width: '80%',
        marginTop: rem(60),
    },

    description: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(16),
            maxWidth: '100%',
            textAlign: 'center',
            display: 'block',
        },
        // maxwidth 2/3 of the screen
        maxWidth: '66%',
        margin: 'auto',
        fontWeight: 400,
    },

    control: {
        '&:hover': {
            border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.resirentPink[5] : theme.colors.resirentPink[8]}`,
            backgroundColor: theme.white,
            color: theme.colors.resirentPink[5],
        },

        '&:active': {
            variant: 'filled',
            color: 'resirentPink',
        },

        textAlign: 'center', // Center-align the contents
    },

    card: {
        border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.resirent[8]}`,
        '&:hover': {
            transform: 'scale(1.01)',
            boxShadow: theme.shadows.md,
        },
        textAlign: 'center', // Center-align the contents
    },
}));

export default function ResiWebsite({ language }) {

    const { classes } = useStyles();

    const content = {
        '/': {
            'groupTitle': 'Why ResiWebsite?',
            'mainTitle': 'Created for small hosts by small hosts to help you grow your business.',
            'description': 'At ResiRent, we help you to short let your accommodation sustainable and optimize your revenues. Our approach is rooted in the idea of being professional, independent and sustainable. Hop on board and get your short-term rental ready for the 21st century.',
        },
        '/nl': {
            'groupTitle': 'WEBSITE',
            'mainTitle': 'Vereenvoudig uw accommodatie-ervaring: Ontdek alles-in-één oplossingen voor uw hostingbehoeften!',
            'description': 'Bij ResiRent helpen we u om uw accommodatie duurzaam te verhuren en uw inkomsten te optimaliseren. Onze aanpak is gebaseerd op het idee van professionaliteit, onafhankelijkheid en duurzaamheid. Stap aan boord en maak uw kortetermijnverhuur klaar voor de 21e eeuw.',
        },
        '/de': {
            'groupTitle': 'Kleine Gastgeber stärken',
            'mainTitle': 'Optimieren Sie Ihr Unterkunftserlebnis: Entdecken Sie All-in-One-Lösungen für Ihre Hosting-Bedürfnisse!',
            'description': 'Bei ResiRent helfen wir Ihnen, Ihre Unterkunft nachhaltig zu vermieten und Ihre Einnahmen zu maximieren. Unser Ansatz basiert auf den Prinzipien von Professionalität, Unabhängigkeit und Nachhaltigkeit. Steigen Sie ein und machen Sie Ihre Kurzzeitvermietung fit für das 21. Jahrhundert.',
        },
    };
    const languagePrefix = window.location.pathname.startsWith('/nl') ? '/nl' : window.location.pathname.startsWith('/de') ? '/de' : '/';

    return (
        <>
            <Box bg="#FFFFFF">
                <ResiWebsiteHeader language={language} />
            </Box >
            <Box bg="#FFF5F9">
                <MainProduct language={language} />
            </Box >
            <Box bg="#FFFFFF">
                <Benefits language={language} />
            </Box >
            <Box bg="#FFFFFF">
                <MainProductSecond language={language} />
                <MainProductThird language={language} />
            </Box >
            <Box bg="#FFFFFF">
                <ProductPage language={language} />
            </Box >
        </>
    )
}

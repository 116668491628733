import resiweb from '../../assets/homepage/resiweb.jpg'
import resiAccessoires from '../../assets/homepage/resiaccessoires.png'
import resiCal from '../../assets/homepage/Calendar.png'
import blog from '../../assets/homepage/Blog.png'
import {
    createStyles,
    Group,
    Title,
    Text,
    Card,
    SimpleGrid,
    Container,
    rem,
    Image,
    AspectRatio,
    Button,
} from '@mantine/core';

// Assuming you have a variable called 'languagePrefix' that determines the selected language
// You can use it to access the appropriate translations like this:


const useStyles = createStyles((theme) => ({
    title: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(24),
            maxWidth: '100%',
            display: 'block',
        },
        // maxwidth 2/3 of the screen
        maxWidth: '66%',
        margin: 'auto',
    },

    titleCard: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(24),
            maxWidth: '100%',
            display: 'block',
        },
        // maxwidth 2/3 of the screen
        margin: 'auto',
    },

    description: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(16),
            maxWidth: '100%',
            textAlign: 'center',
            display: 'block',
        },
        // maxwidth 2/3 of the screen
        maxWidth: '66%',
        margin: 'auto',
        fontWeight: 400,
    },

    descriptionCard: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(16),
            maxWidth: '100%',
            textAlign: 'center',
            display: 'block',
        },
        // maxwidth 2/3 of the screen
        margin: 'auto',
        fontWeight: 400,
    },

    card: {
        transition: 'transform 150ms ease, box-shadow 150ms ease',

        '&:hover': {
            transform: 'scale(1.01)',
            boxShadow: theme.shadows.md,
        },

        backgroundColor: "#FFF5F9",
    },

    control: {
        '&:hover': {
            color: theme.white,
            backgroundColor: theme.colors.resirentPink[5],
        },

        border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.resirentPink[5] : theme.colors.resirentPink[8]}`,
        backgroundColor: theme.white,
        color: theme.colors.resirentPink[5],
        textAlign: 'center', // Center-align the contents
    },

}));

const content = {
    '/': {
        'groupTitle': 'OUR PORTFOLIO',
        'mainTitle': 'Explore Our Featured Holiday Homes',
        'description': "Discover a curated selection of stunning holiday homes created with ResiRent's website builder. Explore unique and beautiful accommodations handpicked from our hosts who have crafted their websites with love and care. Whether you're planning a relaxing getaway or an adventure-packed vacation, our featured holiday homes offer the perfect setting for your next trip."
    },
    '/nl': {
        'groupTitle': 'ONS PORTFOLIO',
        'mainTitle': 'Ontdek Onze Geselecteerde Vakantiehuizen',
        'description': "Ontdek een zorgvuldig samengestelde selectie prachtige vakantiehuizen die zijn gemaakt met de website-builder van ResiRent. Verken unieke en mooie accommodaties, met zorg geselecteerd door onze gastheren die hun websites met liefde en zorg hebben gemaakt. Of u nu een ontspannende vakantie of een avontuurlijke vakantie plant, onze geselecteerde vakantiehuizen bieden de perfecte setting voor uw volgende reis."
    },
    '/de': {
        'groupTitle': 'UNSER PORTFOLIO',
        'mainTitle': 'Entdecken Sie Unsere Ausgewählten Ferienhäuser',
        'description': "Entdecken Sie eine sorgfältig ausgewählte Auswahl an atemberaubenden Ferienhäusern, die mit dem Website-Baukasten von ResiRent erstellt wurden. Erkunden Sie einzigartige und schöne Unterkünfte, die von unseren Gastgebern handverlesen wurden, die ihre Websites mit Liebe und Sorgfalt gestaltet haben. Ob Sie einen entspannenden Urlaub oder einen abenteuerreichen Urlaub planen, unsere vorgestellten Ferienhäuser bieten den perfekten Rahmen für Ihre nächste Reise."
    },
}


export function HostHeader() {
    const { classes } = useStyles();
    const languagePrefix = window.location.pathname.startsWith('/nl') ? '/nl' : window.location.pathname.startsWith('/de') ? '/de' : '/';

    return (
        <Container size="lg" pt={100} pb={50} >
            <Group position="center">
                <Title order={4} weight={300} align="center" color="resirentPink">
                    {content[languagePrefix].groupTitle}
                </Title>
            </Group>
            <Group position="center">
                <Title order={2} className={classes.title} my="sm" display={'block'} ta="center">
                    {content[languagePrefix].mainTitle}
                </Title>
            </Group>
            <Text className={classes.description} ta="center" my="lg">
                {content[languagePrefix].description}
            </Text>
        </Container>
    );
}
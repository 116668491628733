import React, { useState } from "react";
import {
  createStyles,
  Header,
  HoverCard,
  Group,
  Button,
  UnstyledButton,
  Text,
  SimpleGrid,
  ThemeIcon,
  Divider,
  Center,
  Box,
  Burger,
  Drawer,
  Collapse,
  ScrollArea,
  rem,
  Image,
  Container,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconChevronDown, IconLanguage } from "@tabler/icons-react";
import logo from "../../assets/Long_logo_light@4x.png";
import {
  enProducts,
  nlProducts,
  enNavItems,
  nlNavItems,
  deNavItems,
  deProducts,
} from "../../languages/translations";
import { useTranslation } from "react-i18next";
import useAuth from "../../hooks/useAuth";
import useLogout from "../../hooks/useLogout";
import { useNavigate } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  header: {
    backgroundColor: theme.colors.resirent[0],
    color: "white",
    position: "relative",
  },
  logo: {
    marginLeft: "90px",
    [theme.fn.smallerThan("sm")]: {
      marginLeft: 0,
    },
  },
  link: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    textDecoration: "none",
    color: theme.white,
    fontWeight: 500,
    fontSize: theme.fontSizes.md,
    letterSpacing: "0.8px",
    [theme.fn.smallerThan("sm")]: {
      height: rem(42),
      display: "flex",
      alignItems: "center",
      width: "100%",
    },
    ...theme.fn.hover({
      backgroundColor: theme.colors.resirent,
    }),
  },
  linkDrawer: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    textDecoration: "none",
    color: theme.colorScheme.resirent,
    fontWeight: 500,
    fontSize: theme.fontSizes.md,
    [theme.fn.smallerThan("sm")]: {
      height: rem(42),
      display: "flex",
      alignItems: "center",
      width: "100%",
    },
    ...theme.fn.hover({
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.resirent
          : theme.colors.gray[1],
    }),
  },
  subLink: {
    width: "100%",
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    borderRadius: theme.radius.md,
    ...theme.fn.hover({
      backgroundColor: theme.colorScheme === "dark"
        ? theme.colors.dark[7]
        : theme.colors.gray[0],
    }),
    "&:active": theme.activeStyles,
  },
  dropdownFooter: {
    backgroundColor: theme.colorScheme === "dark"
      ? theme.colors.dark[7]
      : theme.colors.gray[0],
    margin: `calc(${theme.spacing.md} * -1)`,
    marginTop: theme.spacing.sm,
    padding: `${theme.spacing.md} calc(${theme.spacing.md} * 2)`,
    paddingBottom: theme.spacing.xl,
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[1]
    }`,
  },
  hiddenMobile: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },
  hiddenDesktop: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },
  leftCorner: {
    display: "flex",
    gap: 10,
    alignItems: "center",
    position: "absolute",
    right: 15,
    top: 22,
    zIndex: 10,
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },
  dashboardButton: {
    backgroundColor: "black",
    color: "white",
    padding: "5px 15px",
    borderRadius: "5px",
  },
  logoutButton: {
    backgroundColor: "white",
    color: "black",
    padding: "5px 15px",
    borderRadius: "5px",
    border: "1px solid black",
  },
  signinButton: {
    backgroundColor: "white",
    color: "black",
    padding: "10px 20px",
    fontSize: "16px",
    height: "50px",
    minWidth: "110px",
    borderRadius: "8px",
    border: "1px solid black",
  },
}));

export function NavBar() {
  const { auth } = useAuth();
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);
  const [linksOpened, { toggle: toggleLinks }] = useDisclosure(false);
  const { classes, theme } = useStyles();
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const Logout = useLogout();

  const signOut = async () => {
    await Logout();
    navigate(t("links.home"));
  };

  const handleChangeLanguage = (newLanguage) => {
    const currentPath = window.location.pathname;
    const currentLang = currentPath.startsWith("/nl") ? "nl" :
                        currentPath.startsWith("/de") ? "de" : "";
    const navItems =
      currentLang === "nl" ? nlNavItems :
      currentLang === "de" ? deNavItems : enNavItems;

    i18n.changeLanguage(newLanguage);

    const currentKey = Object.keys(navItems).find(
      (key) => navItems[key].ref === currentPath
    );
    if (!currentKey) return;

    const targetNavItems =
      newLanguage === "nl" ? nlNavItems :
      newLanguage === "de" ? deNavItems : enNavItems;

    const newPageUrl = targetNavItems[currentKey].ref;
    navigate(newPageUrl);
  };

  const languagePrefix = i18n.language;
  const navItems =
    languagePrefix === "nl" ? nlNavItems :
    languagePrefix === "de" ? deNavItems : enNavItems;

  const products =
    languagePrefix === "nl" ? nlProducts :
    languagePrefix === "de" ? deProducts : enProducts;

    const productLinks = products.map((item) => (
      <UnstyledButton
        component="a"
        href={item.ref}
        className={classes.subLink}
        key={item.title}
        style={{ textAlign: "left" }}
      >
        <Group noWrap align="flex-start" spacing="md">
          <ThemeIcon size={34} variant="default" radius="md">
            <item.icon
              size={rem(22)}
              color={theme.fn.rgba(theme.colors.resirentPink[8], 1)}
            />
          </ThemeIcon>
          <Box>
            <Text size="sm" fw={500}>{item.title}</Text>
            <Box className={classes.hiddenMobile}>
              <Text size="xs" color="dimmed" style={{ whiteSpace: "normal" }}>{item.description}</Text>
            </Box>
          </Box>
        </Group>
      </UnstyledButton>
    ));
  

  return (
    <Box>
      <Header height={80} className={classes.header} px="md">
        <Group align="center" spacing={40} sx={{ height: "100%", flexGrow: 1 }}>
          <a href={navItems.home.ref} className={classes.logo}>
            <Image width={123} height={39.2} src={logo} alt="ResiRent logo" />
          </a>

          <Group className={classes.hiddenMobile} spacing={0} sx={{ height: "100%" }}>
            <a href={navItems.home.ref} className={classes.link}>{navItems.home.title}</a>
            <HoverCard width={600} position="bottom" radius="md" shadow="md" withinPortal>
              <HoverCard.Target>
                <a href="/" className={classes.link}>
                  <Center inline>
                    <Box component="span" mr={5}>{navItems.products.title}</Box>
                    <IconChevronDown size={16} color={theme.white} />
                  </Center>
                </a>
              </HoverCard.Target>
              <HoverCard.Dropdown sx={{ overflow: "hidden" }}>
                <Group position="apart" px="md">
                  <Text fw={500}>{navItems.products.title}</Text>
                </Group>
                <Divider my="sm" mx="-md" color={theme.colorScheme === "dark" ? "dark.5" : "gray.1"} />
                <SimpleGrid cols={2} spacing={0}>{productLinks}</SimpleGrid>
                <div>
                  <Group position="apart" px="md">
                    <Text size="sm" color="#003D51">{navItems.products.subsubtitle}</Text>
                    <Button color="#003D51" component="a" href={navItems.products.ref}>{navItems.products.subtitle}</Button>
                  </Group>
                </div>
              </HoverCard.Dropdown>
            </HoverCard>
            <a href={navItems.blog.ref} className={classes.link}>{navItems.blog.title}</a>
            <a href={navItems.pricing.ref} className={classes.link}>{navItems.pricing.title}</a>
            <a href={navItems.hosts.ref} className={classes.link}>{navItems.hosts.title}</a>
          </Group>

          <Group
            className={classes.hiddenMobile}
            sx={{ marginLeft: "auto", marginRight: "90px", gap: "20px" }}
          >
            <HoverCard width={200} position="bottom" shadow="md">
              <HoverCard.Target>
                <UnstyledButton style={{ color: "white", display: "flex", alignItems: "center" }}>
                  <IconLanguage size={22} color={theme.fn.rgba(theme.colors.resirentPink[8], 1)} style={{ marginRight: 5 }} />
                  <Text size="sm" fw={500}>{t("language")}</Text>
                </UnstyledButton>
              </HoverCard.Target>
              <HoverCard.Dropdown>
                <SimpleGrid cols={1} spacing="xs">
                  <Button variant="subtle" fullWidth onClick={() => handleChangeLanguage("en")}>English</Button>
                  <Button variant="subtle" fullWidth onClick={() => handleChangeLanguage("nl")}>Nederlands</Button>
                  <Button variant="subtle" fullWidth onClick={() => handleChangeLanguage("de")}>Deutsch</Button>
                </SimpleGrid>
              </HoverCard.Dropdown>
            </HoverCard>

            {auth?.role ? (
              <>
                <Button className={classes.dashboardButton} component="a" href="/dashboard/profile">{t("dashboard")}</Button>
                <Button className={classes.logoutButton} onClick={signOut} component="a" href={navItems.login.ref}>{t("logout.title")}</Button>
              </>
            ) : (
              <Button className={classes.signinButton} component="a" href={navItems.login.ref}>{t("login.title")}</Button>
            )}
          </Group>

          {/* Mobile Actions */}
          <Box className={classes.leftCorner}>
            <HoverCard width={120} position="bottom-end" shadow="md">
              <HoverCard.Target>
                <UnstyledButton>
                  <IconLanguage size={22} color={theme.white} />
                </UnstyledButton>
              </HoverCard.Target>
              <HoverCard.Dropdown>
                <SimpleGrid cols={1} spacing="xs">
                  <Button variant="subtle" fullWidth onClick={() => handleChangeLanguage("en")}>EN</Button>
                  <Button variant="subtle" fullWidth onClick={() => handleChangeLanguage("nl")}>NL</Button>
                  <Button variant="subtle" fullWidth onClick={() => handleChangeLanguage("de")}>DE</Button>
                </SimpleGrid>
              </HoverCard.Dropdown>
            </HoverCard>
            <Burger color={theme.white} opened={drawerOpened} onClick={toggleDrawer} />
          </Box>
        </Group>
      </Header>

      <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        size="100%"
        padding="md"
        title="Navigation"
        withCloseButton={true}
        className={classes.hiddenDesktop}
        zIndex={1000000}
      >
        <ScrollArea h={`calc(100vh - ${rem(60)})`} mx="-md">
          <Divider my="sm" color={theme.colorScheme.resirent} />

          <a href={navItems.home.ref} className={classes.linkDrawer}>{navItems.home.title}</a>
          <UnstyledButton className={classes.linkDrawer} onClick={toggleLinks}>
            <Center inline>
              <Box component="span" mr={5}>{navItems.products.title}</Box>
              <IconChevronDown size={16} color={theme.black} />
            </Center>
          </UnstyledButton>
          <Collapse in={linksOpened}><Container>{productLinks}</Container></Collapse>
          <a href={navItems.blog.ref} className={classes.linkDrawer}>{navItems.blog.title}</a>
          <a href={navItems.pricing.ref} className={classes.linkDrawer}>{navItems.pricing.title}</a>
          <a href={navItems.hosts.ref} className={classes.linkDrawer}>{navItems.hosts.title}</a>

          <Divider my="sm" color={theme.colorScheme.resirent} />

          <Group direction="column" spacing="md" pb="xl" px="md">
            {auth?.role ? (
              <>
                <Button className={classes.dashboardButton} component="a" href="/dashboard/profile">{t("dashboard")}</Button>
                <Button className={classes.logoutButton} onClick={signOut} component="a" href={navItems.login.ref}>{t("logout.title")}</Button>
              </>
            ) : (
              <Button className={classes.signinButton} component="a" href={navItems.login.ref}>{t("login.title")}</Button>
            )}
          </Group>
        </ScrollArea>
      </Drawer>
    </Box>
  );
}

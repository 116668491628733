import {
    createStyles,
    Table,
    Group,
    Title,
    Text,
    Card,
    SimpleGrid,
    Container,
    rem,
    Button,
    Box,
} from '@mantine/core';
import { IconCalendar, IconUser, IconCheck } from '@tabler/icons-react';
import { FAQ } from './FAQ';
import { useTranslation } from "react-i18next";

// Define translations based on language prefixes
const translations = {
    '/nl': {
        title: "Kies bet Plan dat bij jou Past voor een accommodatie ",
        subscription: "ABONNEMENT",
        description: "Kies de optie die het beste bij je past-of het nu alleen een kalender is om in je bestaande website in te voegen, een volledige website, of zowel een website als kalender. Jij hebt de keuze! ",
        buttonText: "Probeer het uit",
        title2: "Prijzen: Eénmalige opstartkost",
        title3: "We passen de prijzen aan voor eigenaren met meerdere accommodaties. ",
        description3: "De prijzen gelden voor zowel de website als de kalender. Voor 2 accommodaties is de prijs €399 per jaar, voor 3 accommodaties €499 per jaar. Heb je meer accommodaties? Neem contact met ons op, dan bespreken we jouw behoeften en sturen we een op maat gemaakte offerte. "
    },
    '/de': {
        title: "Entdecken Sie das Perfekte Paket für Sie",
        subscription: "ABONNEMENT",
        description: "Erleben Sie die Leistungsfähigkeit unserer Webhosting-Dienste risikofrei für zwei Wochen mit unserer unverbindlichen Testphase. Unsere Hosting-Lösungen sind nicht nur funktionsreich, sondern auch eine kostengünstige Wahl für kleine Hosts. Egal, ob Sie gerade erst anfangen oder Ihr aktuelles Hosting aufrüsten möchten, unsere Pläne sind darauf ausgerichtet, Ihren Anforderungen und Ihrem Budget gerecht zu werden. Machen Sie heute den ersten Schritt zuverlässiges und kostengünstiges Hosting.",
        buttonText: "Jetzt ausprobieren",
        title2: "Preise: Einmalige Einrichtungskosten",
        title3: "We passen de prijzen aan voor eigenaren met meerdere accommodaties. ",
        description3: "De prijzen gelden voor zowel de website als de kalender. Voor 2 accommodaties is de prijs €399 per jaar, voor 3 accommodaties €499 per jaar. Heb je meer accommodaties? Neem contact met ons op, dan bespreken we jouw behoeften en sturen we een op maat gemaakte offerte. "
    },
    '/': {
        title: "Choose the Plan That Fits You for one accommodation ",
        subscription: "SUBSCRIPTION",
        description: "Pick the option that suits your needs-whether it's just a calendar to plug into your existing website, a full website, or both a website and calendar. It's all up to you! ",
        buttonText: "Try it out",
        title2: "Prices: One-time Setup Cost",
        title3: "Of course, we adjust prices for owners with multiple properties.",
        description3: "The prices cover both the website and the calendar. For 2 properties, the price is €399 per year; for 3 properties, it's €499 per year. Have more properties? Contact us to discuss your needs, and we'll provide a customized quote just for you! "
    },
    // Add more translations as needed
};

// Define translations for titles and descriptions
const titleTranslations = {
    '/': {
        ResiWeb: "ResiWeb",
        ResiCal: "ResiCal",
        "Web and Cal": "ResiWeb and ResiCal",
    },
    '/nl': {
        ResiWeb: "ResiWeb",
        ResiCal: "ResiCal",
        "Web and Cal": "ResiWeb en ResiCal",
    },
    '/de': {
        ResiWeb: "ResiWeb",
        ResiCal: "ResiCal",
        "Web and Cal": "ResiWeb und ResiCal",
    },
};

const mockdata = [
    {
        title: 'ResiCal',
        description: {
            '/': 'People say it can run at the same speed as lightning striking. Its icy body is so cold; it will not melt even if it is immersed in magma.',
            '/nl': 'Mensen zeggen dat het net zo snel kan rennen als de bliksem inslaat. Zijn ijzige lichaam is zo koud; het zal niet smelten, zelfs niet als het in magma wordt ondergedompeld.',
            '/de': 'Es wird gesagt, es könne mit der gleichen Geschwindigkeit wie ein Blitz laufen. Sein eisiger Körper ist so kalt, dass er selbst bei Kontakt mit Magma nicht schmilzt.',
        },
        price: {
            '/': '€ 159/year',
            '/nl': '€ 159/jaar',
            '/de': '€ 159/Jahr',
        },
        monthly_price: {
            '/': '€ 19 per extra language/year',
            '/nl': '€ 19 per extra taal/jaar',
            '/de': '€ 19 für jede zusätzliche Sprache/Jahr',
        },
        btw: {
            '/': 'excl. VAT',
            '/nl': 'excl. BTW',
            '/de': 'exkl. MwSt.',
        },
        featureList: [
            {
                '/': 'Plug-in Website',
                '/nl': 'Plug-in website',
                '/de': 'Plug-in-Website',
            },
            {
                '/': 'Login Details',
                '/nl': 'Inloggegevens',
                '/de': 'Anmeldeinformationen',
            },
            {
                '/': 'Manual PDF and Digital Support',
                '/nl': 'Handleiding pdf en digitaal',
                '/de': 'Handbuch PDF und digital',
            },
            {
                '/': 'Helpline',
                '/nl': 'Hulplijn',
                '/de': 'Support',
            },
            {
                '/': 'Updates',
                '/nl': 'Updates',
                '/de': 'Updates',
            },
            {
                '/': 'Template for three languages',
                '/nl': 'Template voor drie talen',
                '/de': 'Vorlage für drei Sprachen',
            },
        ],
        icon: IconCalendar,
    },
    {
        title: 'ResiWeb',
        description: {
            '/': 'Already have a website? Then, you can try the calendar!',
            '/nl': 'Heb je al een website? Dan kun je de kalender proberen!',
            '/de': 'Haben Sie bereits eine Website? Dann können Sie den Kalender ausprobieren!',
        },
        price: {
            '/': '€ 249/year',
            '/nl': '€ 249/jaar',
            '/de': '€ 249/Jahr',
        },
        monthly_price: {
            '/': '€ 19 per extra language/year',
            '/nl': '€ 19 per extra taal/jaar',
            '/de': '€ 19 für jede zusätzliche Sprache/Jahr',
        },
        btw: {
            '/': 'excl. VAT',
            '/nl': 'excl. BTW',
            '/de': 'exkl. MwSt.',
        },
        featureList: [
            {
                '/': 'Domain Name',
                '/nl': 'Domeinnaam',
                '/de': 'Domainname',
            },
            {
                '/': 'Green Hosting',
                '/nl': 'Groene hosting',
                '/de': 'Grünes Hosting',
            },
            {
                '/': 'Login Details Manual PDF and Digital Support',
                '/nl': 'Inloggegevens Handleiding pdf en digitaal Hulplijn',
                '/de': 'Anmeldeinformationen Handbuch PDF und digitaler Support',
            },
            {
                '/': 'Updates',
                '/nl': 'Updates',
                '/de': 'Updates',
            },
            {
                '/': 'Template for three languages',
                '/nl': 'Template voor drie talen',
                '/de': 'Vorlage für drei Sprachen',
            },
        ],
        icon: IconUser,
        icon2: IconCalendar,
    },
    {
        title: 'Web and Cal',
        description: {
            '/': "They're popular, but they're rare. Trainers who show them off recklessly may be targeted by thieves.",
            '/nl': 'Ze zijn populair, maar ze zijn zeldzaam. Trainers die ze roekeloos laten zien, kunnen het doelwit worden van dieven.',
            '/de': 'Sie sind beliebt, aber sie sind selten. Trainer, die sie leichtsinnig zeigen, können von Dieben ins Visier genommen werden.',
        },
        price: {
            '/': '€ 369/year',
            '/nl': '€ 369/jaar',
            '/de': '€ 369/Jahr',
        },
        monthly_price: {
            '/': '€ 19 per extra language/year',
            '/nl': '€ 19 per extra taal/jaar',
            '/de': '€ 19 für jede zusätzliche Sprache/Jahr',
        },
        btw: {
            '/': 'excl. VAT',
            '/nl': 'excl. BTW',
            '/de': 'exkl. MwSt.',
        },
        featureList: [
            {
                '/': 'Domain Name',
                '/nl': 'Domeinnaam',
                '/de': 'Domainnamen',
            },
            {
                '/': 'Green Hosting',
                '/nl': 'Groene hosting',
                '/de': 'Grünes Hosting',
            },
            {
                '/': 'Login details',
                '/nl': 'Inloggegevens',
                '/de': 'Anmeldeinformationen',
            },
            {
                '/': 'Manual PDF and Digital Support',
                '/nl': 'Handleiding pdf en digitaal Hulplijn',
                '/de': 'Handbuch PDF und digitaler Support',
            },
            {
                '/': 'Updates',
                '/nl': 'Updates',
                '/de': 'Updates',
            },
            {
                '/': 'Template for three languages',
                '/nl': 'Template voor drie talen',
                '/de': 'Vorlage für drei Sprachen',
            },
        ],
        icon: IconUser,
        icon2: IconCalendar,
    },
];

const mockdataPrices = [

    {
        title: {
            '/': 'Free package',
            '/nl': 'Gratis pakket',
            '/de': 'Kostenloses Paket',
        },
        price: 'Free',
        description1: {
            '/': '1 hour of support for your website ',
            '/nl': '1 uur begeleiding voor de website',
            '/de': '1 uur begeleiding voor de website',
        },
        description2: {
            '/': '1 hour for your calendar. ',
            '/nl': '1 uur voor de kalender. ',
            '/de': '1 uur voor de kalender. ',
        },

        btw: {
            '/': '',
            '/nl': '',
            '/de': '',
        },
        extra: {
            '/': 'Max. 2 people',
            '/nl': 'Max. 2 personen',
            '/de': 'Max. 2 Personen',
        },
    },
    {
        title: {
            '/': 'Guidance and training ',
            '/nl': 'Begeleiding en opleiding ',
            '/de': 'Begeleiding en opleiding ',
        },
        price: '€299',
        description1: {
            '/': 'Need more help?',
            '/nl': 'Need more help?',
            '/de': 'Need more help?',
        },
        description2: {
            '/': 'packages of three 1h 15min sessions for both your website and calendar.',
            '/nl': "Pakketten van drie sessies van lu15', zowel voor de website als voor de kalender.",
            '/de': "Pakketten van drie sessies van lu15', zowel voor de website als voor de kalender.",
        },

        btw: {
            '/': 'excl. VAT',
            '/nl': 'excl. BTW',
            '/de': 'exkl. MwSt.',
        },
        extra: {
            '/': 'Max. 2 people',
            '/nl': 'Max. 2 personen',
            '/de': 'Max. 2 Personen',
        },
    },
];

const useStyles = createStyles((theme) => ({
    description: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(16),
            maxWidth: '100%',
            textAlign: 'center',
            display: 'block',
        },
        // maxwidth 2/3 of the screen
        maxWidth: '66%',
        margin: 'auto',
        fontWeight: 400,
    },

    card: {
        border: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.resirent[8]}`,
        '&:hover': {
            transform: 'scale(1.01)',
            boxShadow: theme.shadows.md,
        },
        textAlign: 'center', // Center-align the contents
    },

    cardSpecial: {
        border: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.resirent[8]}`,
        '&:hover': {
            transform: 'scale(1.01)',
            boxShadow: theme.shadows.md,
        },
        textAlign: 'center', // Center-align the contents
    },

    title: {
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(24),
            maxWidth: '100%',
        },
        maxWidth: '100%',
    },

}));


export function Prices() {
    const { classes, theme } = useStyles()
    const { t } = useTranslation();
    const languagePrefix = window.location.pathname.startsWith('/nl') ? '/nl' : window.location.pathname.startsWith('/de') ? '/de' : '/';

    const features = mockdata.map((feature) => (
        <Card key={feature.title} shadow="md" radius="md" className={classes.card} padding="xl">
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <div>
                    <feature.icon size={rem(50)} stroke={2} color={theme.fn.rgba(theme.colors.resirentPink[8], 1)} />
                    {feature.title === 'Web and Cal' ? <feature.icon2 size={rem(50)} stroke={2} color={theme.fn.rgba(theme.colors.resirentPink[8], 1)} /> : null}
                </div>
                <div>
                    <Text fw={500} className={classes.cardTitle} fz="xl" mt="md" mb="md">
                        {titleTranslations[languagePrefix]?.[feature.title] || feature.title}
                    </Text>
                </div>
                <div style={{ flex: 1 }} mb="md">
                    <Table striped highlightOnHover color="#FFF5F9">
                        <tbody>
                            {feature.featureList.map((value, index) => (
                                <tr key={`${feature.title}-${index}`}>
                                    <td style={{ textAlign: 'left' }}>
                                        <IconCheck size={rem(30)} stroke={2} color={theme.fn.rgba(theme.colors.resirentPink[8], 1)} />
                                    </td>
                                    <td style={{ textAlign: 'left' }}>
                                        {value[languagePrefix] || value['/']} {/* Use the translation based on the selected language or fallback to English */}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
                <div style={{ marginTop: 'auto' }} mt="md">
                    <Box pt="md" align="left">
                        <Button color="resirentPink" component="a" href={t("resiweb.href")} variant="outline">
                            {t("resiweb.title")}
                        </Button>
                    </Box>
                    <Text mb="md" mt="md" fz="xl" c="dimmed" align="left">
                        {feature.price[languagePrefix] || feature.price['/']} {/* Use the translation based on the selected language or fallback to English */}
                    </Text>
                    <Text mb="md" c="dimmed" align="left">
                        {feature.monthly_price[languagePrefix] || feature.monthly_price['/']} {/* Use the translation based on the selected language or fallback to English */}
                    </Text>
                    {/* Smaller text than above */}
                    <Text mb="md" fz="xs" c="dimmed" align="left">
                        {feature.btw[languagePrefix] || feature.btw['/']}
                    </Text>
                </div>
            </div>
        </Card>
    ));

    const featuresPrices = mockdataPrices.map((feature) => (
        <Card key={feature.title} shadow="md" radius="md" className={classes.card} padding="xl">
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <div>
                    <Text fw={500} className={classes.cardTitle} fz="xl" mt="md" mb="md">
                        {feature.title[languagePrefix]}
                    </Text>
                </div>
                <Box ta="center" mt="md">
                    {feature.title['/'] === 'Guidance and training ' ? (
                        <Text
                            c="dimmed"
                            fw={500}
                            fz="md"
                            style={{ cursor: "pointer", textDecoration: "underline", color: "blue" }}
                            onClick={() => window.location.href = 'mailto:resirent@resirent.org'}
                        >
                            {feature.description1[languagePrefix]}
                        </Text>
                    ) : (
                        <Text c="dimmed" fw={500} fz="md">
                            {feature.description1[languagePrefix]}
                        </Text>
                    )}

                    <Text c="dimmed" fw={500} fz="md" mt="xs">
                        {feature.description2[languagePrefix]}
                    </Text>
                </Box>
                <div style={{ marginTop: 'auto' }} mt="md">
                    <Text mb="md" mt="md" fz="xl" weight={400} align="center" color="resirentPink">
                        {feature.price} {/* Use the translation based on the selected language or fallback to English */}
                    </Text>
                    <Text mb="md" fz="xs" c="dimmed" align="center">
                        {feature.btw[languagePrefix] || feature.btw['/']}
                    </Text>
                </div>
                {feature.title === 'Workshop' ? <Text mb="md" c="dimmed" align="center"> {feature.extra[languagePrefix] || feature.extra['/']}</Text> : null}
            </div>
        </Card>
    ));

    return (
        <Container size="lg" py={100}>

            <Group position="center">
                <Title order={4} weight={300} align="center" color="resirentPink">
                    {translations[languagePrefix]?.subscription || "Subscriptions"}
                </Title>
            </Group>

            <Title order={2} className={classes.title} ta="center" mt="sm">
                {translations[languagePrefix]?.title || "Discover Affordable Website Hosting with a Risk-Free Two-Week Trial"}
            </Title>

            <Text className={classes.description} ta="center" my="lg">
                {translations[languagePrefix]?.description || "Default description text"}
            </Text>

            <Title order={2} className={classes.title} ta="center" mt="sm">
                {translations[languagePrefix]?.title3 || "Discover Affordable Website Hosting with a Risk-Free Two-Week Trial"}
            </Title>

            <Text className={classes.description} ta="center" my="lg">
                {translations[languagePrefix]?.description3 || "Default description text"}
            </Text>

            <Group position="center" pb="xl">
                <SimpleGrid cols={3} spacing="xl" mt={50} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
                    {features}
                </SimpleGrid>
            </Group>

            <Title order={2} className={classes.title} ta="center" mt="xl">
                {translations[languagePrefix]?.title2 || "Prices"}
            </Title>

            {/* <Text className={classes.description} ta="center" my="lg">
                {translations[languagePrefix]?.description2 || "Default description text"}
            </Text> */}

            <Group position="center">
                <SimpleGrid cols={2} spacing="xl" mt={50} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
                    {featuresPrices}
                </SimpleGrid>
            </Group>

            <Box bg="#FFFFFF">
                <FAQ />
            </Box >
        </Container>
    );
}
import React from "react";
import image from "../../../assets/products/Colors.png";
import image2 from "../../../assets/products/talen.png";
import image3 from "../../../assets/products/resirent.org.png";
import {
  createStyles,
  rem,
  Title,
  Box,
  Container,
  Text,
  Image,
  Paper,
  SimpleGrid,
} from "@mantine/core";

const useStyles = createStyles((theme) => ({
  title: {
    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(24),
      maxWidth: "100%",
      display: "block",
    },
    // maxwidth 2/3 of the screen
    maxWidth: "66%",
    margin: "auto",
  },

  paperProduct: {
    [theme.fn.smallerThan("sm")]: {
      width: "100%",
      marginTop: rem(30),
    },
    alignItems: "center",
    width: "80%",
  },

  description: {
    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(16),
      maxWidth: "100%",
      display: "block",
    },
    // maxwidth 2/3 of the screen
    maxWidth: "66%",
    margin: "auto",
    fontWeight: 400,
  },

  control: {
    "&:hover": {
      border: `${rem(3)} solid ${
        theme.colorScheme === "dark"
          ? theme.colors.resirentPink[5]
          : theme.colors.resirentPink[8]
      }`,
      backgroundColor: theme.white,
      color: theme.colors.resirentPink[5],
    },

    "&:active": {
      variant: "filled",
      color: "resirentPink",
    },

    textAlign: "center", // Center-align the contents
  },

  card: {
    border: `${rem(3)} solid ${
      theme.colorScheme === "dark"
        ? theme.colors.dark[5]
        : theme.colors.resirent[8]
    }`,
    "&:hover": {
      transform: "scale(1.01)",
      boxShadow: theme.shadows.md,
    },
    textAlign: "center", // Center-align the contents
  },
}));

export default function MainProductThird({ language }) {
  const { classes } = useStyles();

  const content = {
    "/": {
      groupTitle: "GREEN HOSTING",
      mainTitle: "Responsible hosting starts with a green hosting provider",
      description:
        "We are using green hosting services, minimizing our environmental footprint.",
    },
    "/nl": {
      groupTitle: "GROENE HOSTING",
      mainTitle: "Verantwoorde hosting begint bijeen groene hostingprovider",
      description:
        "Wij maken gebruik van groene hostingdiensten, waarmee wij onze ecologische voetafdruk minimaliseren.",
    },
    "/de": {
      groupTitle: "GRÜNES HOSTING",
      mainTitle:
        "Verantwortungsbewusstes Hosting beginnt bei einem grünen Hosting-Anbieter",
      description:
        "Wir nutzen grüne Hosting-Dienste und minimieren so unseren ökologischen Fußabdruck.",
    },
  };

  const content2 = {
    "/": {
      groupTitle: "MULTILINGUAL SUPPORT",
      mainTitle: "Reach a wider audience with less effort",
      description:
        "Easily add multiple languages to cater to a diverse audience.",
    },
    "/nl": {
      groupTitle: "MEERTALIGE ONDERSTEUNING",
      mainTitle: "Bereik een groter publiek met minder moeite",
      description:
        "Voeg eenvoudig meerdere talen toe om een ​​divers publiek te bedienen.",
    },
    "/de": {
      groupTitle: "MEHRSPRACHIGER SUPPORT",
      mainTitle: "Erreichen Sie ein größeres Publikum mit weniger Aufwand",
      description:
        "Fügen Sie ganz einfach mehrere Sprachen hinzu, um ein vielfältiges Publikum anzusprechen.",
    },
  };

  const content3 = {
    "/": {
      groupTitle: "PRIVATE POLICY",
      mainTitle: "Protect Yourself Legally with a Privacy Policy",
      description:
        "Benefit from built-in Terms and Conditions to ensure your legal protection.",
    },
    "/nl": {
      groupTitle: "PRIVÉBELEID",
      mainTitle: "Bescherm uzelf juridisch met een privacybeleid",
      description:
        "Profiteer van de ingebouwde Algemene Voorwaarden om uw juridische bescherming te garanderen.",
    },
    "/de": {
      groupTitle: "DATENSCHUTZRICHTLINIE",
      mainTitle: "Schützen Sie sich rechtlich mit einer Datenschutzerklärung",
      description:
        "Profitieren Sie von integrierten Geschäftsbedingungen, um Ihren rechtlichen Schutz zu gewährleisten.",
    },
  };

  const languagePrefix = window.location.pathname.startsWith("/nl")
    ? "/nl"
    : window.location.pathname.startsWith("/de")
    ? "/de"
    : "/";

  return (
    <Box>
      {/* Section 1: Green Hosting */}
      <Container size="lg" pt={100}>
        <Paper
          radius="xl"
          shadow="xl"
          p="xl"
          style={{
            backgroundColor: "#003D51",
            minHeight: "420px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <SimpleGrid
            cols={2}
            spacing="xl"
            breakpoints={[{ maxWidth: 768, cols: 1 }]}
            style={{ width: "100%" }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                paddingRight: "1rem",
              }}
            >
              <Text
                fw={600}
                size="sm"
                mb={6}
                style={{ color: "#D7FEDB" }}
                tt="uppercase"
              >
                {content[languagePrefix].groupTitle}
              </Text>

              <Title
                order={2}
                my="sm"
                c="white"
                style={{ whiteSpace: "pre-line" }}
              >
                {content[languagePrefix].mainTitle
                  .split(" ")
                  .slice(0, 4)
                  .join(" ") +
                  "\n" +
                  content[languagePrefix].mainTitle
                    .split(" ")
                    .slice(4)
                    .join(" ")}
              </Title>

              <Text size="md" mt="md" style={{ color: "#E9D7FE" }} maw={400}>
                {content[languagePrefix].description}
              </Text>
            </Box>

            <Box display="flex" justify="center" align="center">
              <Paper
                shadow="md"
                radius="md"
                p="xs"
                withBorder
                style={{
                  backgroundColor: "white",
                  padding: "1rem",
                  borderRadius: "16px",
                }}
              >
                <Image src={image3} alt="Green Hosting" radius="md" />
              </Paper>
            </Box>
          </SimpleGrid>
        </Paper>
      </Container>

      {/* Section 2: Multilingual Support */}
      <Container size="lg" pt={80}>
        <Paper
          radius="xl"
          shadow="xl"
          p="xl"
          style={{
            backgroundColor: "#003D51",
            minHeight: "420px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <SimpleGrid
            cols={2}
            spacing="xl"
            breakpoints={[{ maxWidth: 768, cols: 1 }]}
            style={{ width: "100%" }}
          >
            <Box display="flex" justify="center" align="center">
              <Paper
                shadow="md"
                radius="md"
                p="xs"
                withBorder
                style={{
                  backgroundColor: "white",
                  padding: "1rem",
                  borderRadius: "16px",
                }}
              >
                <Image src={image2} alt="Multilingual Support" radius="md" />
              </Paper>
            </Box>

            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                paddingLeft: "1rem",
              }}
            >
              <Text
                fw={600}
                size="sm"
                mb={6}
                style={{ color: "#D7FEDB" }}
                tt="uppercase"
              >
                {content2[languagePrefix].groupTitle}
              </Text>

              <Title
                order={2}
                my="sm"
                c="white"
                style={{ whiteSpace: "pre-line" }}
              >
                {content2[languagePrefix].mainTitle
                  .split(" ")
                  .slice(0, 5)
                  .join(" ") +
                  "\n" +
                  content2[languagePrefix].mainTitle
                    .split(" ")
                    .slice(5)
                    .join(" ")}
              </Title>

              <Text size="md" mt="md" style={{ color: "#E9D7FE" }} maw={400}>
                {content2[languagePrefix].description}
              </Text>
            </Box>
          </SimpleGrid>
        </Paper>
      </Container>

      {/* Section 3: Privacy Policy (unchanged layout, just centered) */}
      <Container size="lg" pt={150} pb={30}>
        <Paper
          radius="lg"
          p="xl"
          style={{
            backgroundColor: "#F9FAFB",
            textAlign: "center",
          }}
        >
          <Text
            fw={600}
            size="sm"
            mb={6}
            tt="uppercase"
            style={{ color: "#003D51" }}
          >
            {content3[languagePrefix].groupTitle}
          </Text>

          <Title
            order={2}
            my="sm"
            style={{
              whiteSpace: "normal",
            }}
          >
            {content3[languagePrefix].mainTitle}
          </Title>

          <Text
            size="md"
            mt="md"
            maw={520}
            mx="auto"
            style={{ color: "#6B7280" }}
          >
            {content3[languagePrefix].description}
          </Text>
        </Paper>
      </Container>
    </Box>
  );
}

import React from 'react';
import { createStyles, Group, Container, Title, Box, Text, Paper, SimpleGrid, rem } from '@mantine/core';
import { IconMail, IconBolt, IconChartBar } from '@tabler/icons-react';
import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme) => ({
    title: {
        textAlign: 'left',
        fontSize: '2rem',
        fontWeight: 700,
        marginBottom: '1rem',
        fontFamily: 'Inter, sans-serif',
        [theme.fn.smallerThan('sm')]: {
          fontSize: rem(22),
        },
      },
      subtitle: {
        textAlign: 'left',
        fontSize: '1rem',
        fontWeight: 300,
        color: theme.colors.gray[6],
        maxWidth: '60%',
        margin: '0',
        fontFamily: 'Inter, sans-serif',
        [theme.fn.smallerThan('sm')]: {
          fontSize: rem(14),
          maxWidth: '100%',
        },
      },
      cardTitle: {
        fontSize: '1.1rem',
        fontWeight: 600,
        fontFamily: 'Inter, sans-serif',
        marginBottom: '0.5rem',
        marginTop: '5rem',
        [theme.fn.smallerThan('sm')]: {
          fontSize: rem(16),
          marginTop: rem(20),
        },
      },
      cardText: {
        fontSize: '1rem',
        color: theme.colors.gray[7],
        fontFamily: 'Inter, sans-serif',
        [theme.fn.smallerThan('sm')]: {
          fontSize: rem(14),
        },
      },
      paper: {
        padding: '1.5rem',
        borderRadius: '8px',
        backgroundColor: '#EDFBF4',
        textAlign: 'left',
      },
    iconWrapper: {
        width: '40px',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.colors.resirent[0],
        borderRadius: '6px',
        marginBottom: '1rem',
    },
    icon: {
        color: theme.white,
    }
}));

const translations = {
    '/': {
        "Who are we for?": "Who are we for?",
        "Responsible Short Term Rental for Sustainable Hosting": "Responsible Short Term Rental for Sustainable Hosting",
        "Our platform is designed to support hosts who prioritize sustainability in tourism, offering diverse hosting options and guidance to help reduce overcrowding while promoting local gems.": "Our platform is designed to support hosts who prioritize sustainability in tourism, offering diverse hosting options and guidance to help reduce overcrowding while promoting local gems.",
        "The passionate host": "The passionate host",
        "Hosting is your passion, and we’re here to help you do it even better.": "Hosting is your passion, and we’re here to help you do it even better.",
        "Anywhere you host": "Anywhere you host",
        "One single property, or a few even in different countries, we’ve got you covered.": "One single property, or a few even in different countries, we’ve got you covered.",
        "No matter IT knowledge": "No matter IT knowledge",
        "Easy to build and maintain your website.": "Easy to build and maintain your website.",
    },
    '/nl': {
        "Who are we for?": "Voor wie zijn we?",
        "Responsible Short Term Rental for Sustainable Hosting": "Verantwoordelijke Kortetermijnverhuur voor Duurzaam Gastheerschap",
        "Our platform is designed to support hosts who prioritize sustainability in tourism, offering diverse hosting options and guidance to help reduce overcrowding while promoting local gems.": "Ons platform is ontworpen om hosts te ondersteunen die duurzaamheid in het toerisme prioriteren, door diverse hostingopties en begeleiding te bieden om overbevolking te verminderen en lokale pareltjes te promoten.",
        "The passionate host": "De gepassioneerde gastgever",
        "Hosting is your passion, and we’re here to help you do it even better.": "Gastvrijheid is jouw passie, en wij zijn er om je te helpen dat nóg beter te doen.",
        "Anywhere you host": "Waar je ook gastgever bent",
        "One single property, or a few even in different countries, we’ve got you covered.": "Een vakantiewoning, of zelfs verspreid in verschillende landen, we helpen je verder.",
        "No matter IT knowledge": "Geen technische kennis nodig",
        "Easy to build and maintain your website.": "Eenvoudig om je eigen website te bouwen en te onderhouden.",
    },
    '/de': {
        "Who are we for?": "Für wen sind wir da?",
        "Responsible Short Term Rental for Sustainable Hosting": "Verantwortungsbewusste Kurzzeitvermietung für Nachhaltiges Gastgeben",
        "Our platform is designed to support hosts who prioritize sustainability in tourism, offering diverse hosting options and guidance to help reduce overcrowding while promoting local gems.": "Unsere Plattform wurde entwickelt, um Gastgeber zu unterstützen, die Nachhaltigkeit im Tourismus priorisieren, indem sie vielfältige Hosting-Optionen und Anleitungen bieten, um Überfüllung zu reduzieren und lokale Schätze zu fördern.",
        "The passionate host": "Der leidenschaftliche Gastgeber",
        "Hosting is your passion, and we’re here to help you do it even better.": "Gastgeben ist Ihre Leidenschaft, und wir sind hier, um Ihnen zu helfen, es noch besser zu machen.",
        "Anywhere you host": "Wo immer Sie hosten",
        "One single property, or a few even in different countries, we’ve got you covered.": "Eine einzelne Immobilie oder mehrere auch in verschiedenen Ländern, wir haben Sie abgedeckt.",
        "No matter IT knowledge": "Unabhängig von IT-Kenntnissen",
        "Easy to build and maintain your website.": "Einfach, Ihre Website zu erstellen und zu pflegen.",
    },
};

export default function HomePage() {
    const { classes } = useStyles();
    const { t } = useTranslation(); // Get the translation function
    const languagePrefix = window.location.pathname.startsWith('/nl') ? '/nl' : window.location.pathname.startsWith('/de') ? '/de' : '/';

    return (
        <Box py={50}>
            <Container size="lg">
                <Text weight={600} color="#003D51" align="left">
                    {translations[languagePrefix]["Who are we for?"] || translations["/"]["Who are we for?"]}
                </Text>
                <Title className={classes.title}>
                    {translations[languagePrefix]["Responsible Short Term Rental for Sustainable Hosting"] || translations["/"]["Responsible Short Term Rental for Sustainable Hosting"]}
                </Title>
                <Text className={classes.subtitle}>
                    {translations[languagePrefix]["Our platform is designed to support hosts who prioritize sustainability in tourism, offering diverse hosting options and guidance to help reduce overcrowding while promoting local gems."] || translations["/"]["Our platform is designed to support hosts who prioritize sustainability in tourism, offering diverse hosting options and guidance to help reduce overcrowding while promoting local gems."]}
                </Text>
                <SimpleGrid cols={3} breakpoints={[{ maxWidth: 'md', cols: 1 }]} spacing="md" mt={30}>
                    <Paper className={classes.paper} shadow="sm">
                        <div className={classes.iconWrapper}>
                            <IconMail size={24} className={classes.icon} />
                        </div>
                        <Text className={classes.cardTitle}>
                            {translations[languagePrefix]["The passionate host"] || translations["/"]["The passionate host"]}
                        </Text>
                        <Text className={classes.cardText}>
                            {translations[languagePrefix]["Hosting is your passion, and we’re here to help you do it even better."] || translations["/"]["Hosting is your passion, and we’re here to help you do it even better."]}
                        </Text>
                    </Paper>
                    <Paper className={classes.paper} shadow="sm">
                        <div className={classes.iconWrapper}>
                            <IconBolt size={24} className={classes.icon} />
                        </div>
                        <Text className={classes.cardTitle}>
                            {translations[languagePrefix]["Anywhere you host"] || translations["/"]["Anywhere you host"]}
                        </Text>
                        <Text className={classes.cardText}>
                            {translations[languagePrefix]["One single property, or a few even in different countries, we’ve got you covered."] || translations["/"]["One single property, or a few even in different countries, we’ve got you covered."]}
                        </Text>
                    </Paper>
                    <Paper className={classes.paper} shadow="sm">
                        <div className={classes.iconWrapper}>
                            <IconChartBar size={24} className={classes.icon} />
                        </div>
                        <Text className={classes.cardTitle}>
                            {translations[languagePrefix]["No matter IT knowledge"] || translations["/"]["No matter IT knowledge"]}
                        </Text>
                        <Text className={classes.cardText}>
                        {translations[languagePrefix]["Easy to build and maintain your website."] || translations["/"]["Easy to build and maintain your website."]}
                        </Text>
                    </Paper>
                </SimpleGrid>
            </Container>
        </Box>
    );
}



import React from "react";
import image from "../../../assets/products/resiweb.png";
import {
  createStyles,
  Container,
  Title,
  Text,
  Button,
  Group,
  Box,
  Grid,
  Badge,
  Image,
  rem,
} from "@mantine/core";
import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme) => ({
  wrapper: {
    backgroundColor: "#F9FAFB",
    fontFamily: "Inter, sans-serif",
    paddingTop: 80,
    paddingBottom: 80,
  },

  innerContainer: {
    paddingLeft: 80,
    paddingRight: 0,
    [theme.fn.smallerThan("sm")]: {
      paddingLeft: theme.spacing.md,
      paddingRight: theme.spacing.md,
    },
  },
  title: {
    fontSize: 42,
    fontWeight: 600,
    lineHeight: 1.2,
    [theme.fn.smallerThan("sm")]: {
      fontSize: 32,
      textAlign: "center",
    },
  },

  description: {
    fontSize: 16,
    color: "#4A4A4A",
    marginTop: theme.spacing.md,
    maxWidth: 500,
    [theme.fn.smallerThan("sm")]: {
      textAlign: "center",
      margin: "0 auto",
    },
  },

  buttonGroup: {
    marginTop: theme.spacing.xl,
    [theme.fn.smallerThan("sm")]: {
      justifyContent: "center",
    },
  },

  primaryButton: {
    backgroundColor: "#003D51",
    color: "#fff",
    height: "50px",
    padding: "12px 24px",
    fontSize: "16px",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "#002A3B",
    },
  },

  secondaryButton: {
    backgroundColor: "#fff",
    border: `1px solid #003D51`,
    color: "#003D51",
    height: "50px",
    padding: "12px 24px",
    fontSize: "16px",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "#f1f5f9",
    },
  },

  imageCol: {
    paddingRight: 0,
    display: "flex",
    justifyContent: "flex-end",

    [theme.fn.smallerThan("sm")]: {
      justifyContent: "center",
      paddingRight: theme.spacing.md,
    },
  },

  image: {
    width: "100%",
    maxWidth: 500,
  },
  resiWebBadge: {
    width: rem(120),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 20px",
    border: "2px solid #e0e0eb",
    borderRadius: "50px",
    backgroundColor: "white",
    color: "#003D51",
    fontWeight: 500,
    fontSize: rem(14),
    fontFamily: "Inter, sans-serif",
    textAlign: "center",

    [theme.fn.smallerThan("sm")]: {
      marginLeft: "auto",
      marginRight: "auto", // ✅ Center on mobile
    },
  },
}));

export default function ResiWebsiteHeader() {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const content = {
    "/": {
      groupTitle: "ResiWeb",
      mainTitle: "Effortlessly create a stunning, professional website",
      description:
        "With ResiWebsite, small hosts don't have to compromise on quality. Our intuitive template, designed by hosts for hosts, gives your site a polished, professional look from the start : easy to use, authentic, and built with your needs in mind.",
    },
    "/nl": {
      groupTitle: "ResiWeb",
      mainTitle: "Creëer moeiteloos een verbluffende, professionele website",
      description:
        "Met ResiWebsite hoeven kleine verhuurders geen concessies te doen aan kwaliteit. Onze intuïtieve template, ontworpen door hosts voor hosts, geeft je site vanaf het begin een professionele en verzorgde uitstraling: eenvoudig in gebruik, authentiek en volledig afgestemd op jouw behoeften.",
    },
    "/de": {
      groupTitle: "ResiWeb",
      mainTitle:
        "Erstellen Sie mühelos eine atemberaubende, professionelle Website",
      description:
        "Mit ResiWebsite müssen kleine Gastgeber keine Kompromisse bei der Qualität eingehen. Unsere intuitive Vorlage, von Gastgebern für Gastgeber entworfen, verleiht Ihrer Website von Anfang an ein elegantes, professionelles Erscheinungsbild – benutzerfreundlich, authentisch und perfekt auf Ihre Bedürfnisse abgestimmt.",
    },
  };

  const languagePrefix = window.location.pathname.startsWith("/nl")
    ? "/nl"
    : window.location.pathname.startsWith("/de")
    ? "/de"
    : "/";

  return (
    <Box className={classes.wrapper}>
      <Container size="xl" className={classes.innerContainer}>
        <Grid align="center">
          <Grid.Col span={12} md={6}>
            <Text component="div" className={classes.resiWebBadge}>
              ResiWeb
            </Text>
            <Title className={classes.title}>
              {content[languagePrefix].mainTitle}
            </Title>
            <Text className={classes.description}>
              {content[languagePrefix].description}
            </Text>
            <Group spacing="md" className={classes.buttonGroup}>
              <Button
                variant="outline"
                className={classes.secondaryButton}
                component="a"
                href="/contact"
              >
                {t("resiweb.contact")}
              </Button>
              <Button
                className={classes.primaryButton}
                component="a"
                href={t("resiweb.href")}
              >
                {t("resiweb.try")}
              </Button>
            </Group>
          </Grid.Col>

          {/* RIGHT SIDE IMAGE */}
          <Grid.Col span={12} md={6} className={classes.imageCol}>
            <Image
              src={image}
              alt="ResiWebsite mockup"
              className={classes.image}
            />
          </Grid.Col>
        </Grid>
      </Container>
    </Box>
  );
}

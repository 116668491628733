// Skeleton of the ResiRent website
import { Outlet } from 'react-router-dom';
import React from 'react';
import Footer from '../footer/Footer';
import Navbar from './Navbar';
import { Grid } from '@mantine/core';

const LayoutDashboard = () => {

    return (
        <main>
            <Grid>
                <Grid.Col span={3}>  <Navbar /></Grid.Col>
                <Grid.Col span="auto">  <Outlet /></Grid.Col>
            </Grid>
            <Footer />
        </main>
    );
}

export default LayoutDashboard;
import React from 'react';
import ImageSlider from './ImageSlider';
import  Featuring  from './Featuring';
import { BlogHomePage } from './BlogHomePage';
import {
    createStyles,
    rem,
    Box,
} from '@mantine/core';
import HomePage from './CustomersPage';
import { FAQ } from './FAQ';
import { Newsletter } from '../informative/Newsletter';
import Banner from './Banner';
import Hero from './Hero';
import MotivationBanner from './MotivationBanner';
import { ContactUs} from './ContactUs';

const useStyles = createStyles((theme) => ({
    wrapper: {
        paddingTop: `calc(${theme.spacing.xl} * 2)`,
        minHeight: rem(420),
        backgroundColor: theme.colors.gray[0],
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top left',
        position: 'relative',
        color: theme.black,
    },
}));

export default function Home() {

    return (
        <>
            {/* <ImageSlider /> */}
            {/* <Box bg="#F783AC">
                <Banner />
            </Box> */}
            <Box bg="#F9FAFB">
                {/* <Box> */}
                <Hero />
            </Box>
            <Box bg="#003D51">
                <MotivationBanner />
            </Box>
            <Featuring />
            {/* <Hero /> */}
            <HomePage />
            
            <FAQ />
            <Box bg="#F9FAFB">
                <BlogHomePage />
            </Box>
            <div>
            <ContactUs />
            </div>
            <Newsletter />
        </>
    )
}
import blogPost from "../../assets/blogPosts/blogPage/blog_post.jpg";
import {
  createStyles,
  Group,
  Grid,
  Title,
  Text,
  TextInput,
  Card,
  Container,
  rem,
  Image,
  Button,
  Box,
} from "@mantine/core";
import { IconEye, IconEyeOff } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import axios from "../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const useStyles = createStyles((theme) => ({
  title: {
    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(24),
    },
  },
  description: {
    maxWidth: 600,
    margin: "auto",
    fontWeight: 200,
    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(14),
    },
  },
  card: {
    transition: "transform 150ms ease, box-shadow 150ms ease",
    "&:hover": {
      transform: "scale(1.01)",
      boxShadow: theme.shadows.md,
    },
  },
  blogHeader: {
    color: "#101828",
    fontFamily: "Inter, sans-serif",
    fontWeight: 600,
    fontSize: "48px",
    textAlign: "center",
    marginTop: "-10px",
    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(26),
    },
  },
  blogSubHeader: {
    color: "#667085",
    fontFamily: "Inter, sans-serif",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "30px",
    textAlign: "center",
    marginTop: rem(16),
    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(14),
    },
  },
  actionButtons: {
    position: "absolute",
    top: 10,
    right: 10,
    display: "flex",
    gap: "5px",
  },
  addBlogButton: {
    border: '1px solid #ccc',
    borderRadius: '4px',
    padding: '8px 16px',
    backgroundColor: "#003D51",
    color: "#fff",
    fontFamily: "Inter, sans-serif",
    fontWeight: 500,
    fontSize: '16px',
    marginTop: '20px',
    [theme.fn.smallerThan("sm")]: {
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
}));

export default function Blog() {
  const { auth } = useAuth();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const languagePrefix = window.location.pathname.startsWith("/nl")
    ? "/nl"
    : window.location.pathname.startsWith("/de")
    ? "/de"
    : "";

  const [blogs, setBlogs] = useState([]);
  const [email, setEmail] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 6;

  useEffect(() => {
    getBlogs();
  }, []);

  const getBlogs = () => {
    axios
      .get("/blog")
      .then((res) => setBlogs(res.data))
      .catch((error) => console.log("Error fetching data:", error));
  };

  const handleVisibilityToggle = async (id, newVisibility) => {
    const confirmMessage = newVisibility
      ? "Do you want to make this blog visible to users?"
      : "Do you want to make this blog invisible to users?";

    if (window.confirm(confirmMessage)) {
      try {
        await axios.put(`/blog/${id}/visibility`, {
          is_visible: newVisibility,
        });
        getBlogs();
      } catch (error) {
        console.error("Error updating blog visibility:", error);
      }
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Do you want to delete this blog?")) {
      try {
        await axios.delete(`/blog/${id}`);
        getBlogs();
      } catch (error) {
        console.error("Error deleting blog:", error);
      }
    }
  };

  const handleSubscribe = () => {
    console.log("Subscribing with email:", email);
  };

  const handleAddBlogClick = () => {
    navigate("/create-blog");
  };

  const filtered_blogs = blogs
    .filter((blog) =>
      languagePrefix === "/nl"
        ? blog.language === "NL"
        : languagePrefix === "/de"
        ? blog.language === "DE"
        : blog.language === "EN"
    )
    .filter((blog) => auth?.role === "Admin" || blog.is_visible);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    }).format(date);
  };

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = filtered_blogs.slice(indexOfFirstPost, indexOfLastPost);
  const totalPages = Math.ceil(filtered_blogs.length / postsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <Box>
      <Container size="lg" py={50}>
        <Group position="center">
          <Title order={4} align="center" color="#003D51">
            <h1>{t("Blog")}</h1>
          </Title>
        </Group>

        <Title className={classes.blogHeader}>
          {languagePrefix === "/nl"
            ? "Inzichten en Tips voor Slimme Gastheerschap"
            : languagePrefix === "/de"
            ? "Unsere neuesten Blogbeiträge"
            : "Insights & Tips for Smart Hosting"}
        </Title>

        {languagePrefix === "" && (
          <Text className={classes.blogSubHeader}>
            Explore expert advice, industry trends, and sustainability tips to optimize your <br /> short-term rental business.
          </Text>
        )}

        {/* Subscription */}
        <Container size="sm" mt="xl">
          <Group position="center">
            <TextInput
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.currentTarget.value)}
              style={{ width: '300px' }}
            />
            <Button onClick={handleSubscribe} style={{ backgroundColor: "#003D51", color: "#fff" }}>
              Subscribe
            </Button>
          </Group>
        </Container>

        {/* Add Blog */}
        {auth?.role === "Admin" && (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button className={classes.addBlogButton} onClick={handleAddBlogClick}>
              + Add Blog
            </Button>
          </div>
        )}

        {/* Blog Cards */}
        <Container size="lg" py={50}>
          <Grid gutter="xl">
            {currentPosts.map((post) => (
              <Grid.Col span={12} sm={6} md={4} key={post.id}>
                <Card
                  className={classes.card}
                  shadow="xl"
                  withBorder
                  radius="md"
                  p="md"
                  onClick={() => navigate(`${languagePrefix}/blog/${post.id}`)}
                  style={{ position: "relative" }}
                >
                  <Image src={post.imageUrl || blogPost} alt={post.title} height={200} fit="cover" mb="md" />
                  {/* Admin Controls */}
                  {auth?.role === "Admin" && (
                    <div className={classes.actionButtons}>
                      <button onClick={(e) => { e.stopPropagation(); handleDelete(post.id); }} style={{ background: "red", color: "#fff", borderRadius: "50%", border: "none", width: "30px", height: "30px" }}>🗑</button>
                      <Link to={`/edit-blog/${post.id}`} onClick={(e) => e.stopPropagation()} style={{ background: "#ef7e2e", color: "#fff", borderRadius: "50%", width: "30px", height: "30px", display: "flex", justifyContent: "center", alignItems: "center" }}>✎</Link>
                    </div>
                  )}

                  {auth?.role === "Admin" && (
                    <div style={{ position: "absolute", top: "10px", left: "10px" }}>
                      {post.is_visible ? (
                        <IconEye color="green" onClick={(e) => { e.stopPropagation(); handleVisibilityToggle(post.id, false); }} style={{ cursor: "pointer" }} />
                      ) : (
                        <IconEyeOff color="red" onClick={(e) => { e.stopPropagation(); handleVisibilityToggle(post.id, true); }} style={{ cursor: "pointer" }} />
                      )}
                    </div>
                  )}
                  <Text color="dimmed" size="sm" mb="xs">{post.date ? formatDate(post.date) : "No Date"}</Text>
                  <Title order={4} mb="xs">{post.title}</Title>
                  <Text size="sm" color="dimmed">{post.description}</Text>
                </Card>
              </Grid.Col>
            ))}
          </Grid>

          {/* Pagination */}
          <Group position="apart" mt="xl">
            <Button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>Previous</Button>
            <Text>{currentPage} / {totalPages}</Text>
            <Button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>Next</Button>
          </Group>
        </Container>
      </Container>
    </Box>
  );
}

import {
  TextInput,
  PasswordInput,
  Checkbox,
  Anchor,
  Paper,
  Title,
  Text,
  Container,
  Group,
  Button,
  Select,
} from '@mantine/core';
import { useEffect, useState, useRef } from 'react';
import axios from '../../api/axios.js';
import useAuth from '../../hooks/useAuth';
import { useTranslation } from "react-i18next";

const USER_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const REGISTER_URL = '/register';

export default function Register() {
  const { persist, setPersist } = useAuth();

  const userRef = useRef();
  const errRef = useRef();

  const [user, setUser] = useState('');
  const [validName, setValidName] = useState(false);

  const [pwd, setPwd] = useState('');
  const [validPwd, setValidPwd] = useState(false);

  const [matchPwd, setMatchPwd] = useState('');
  const [validMatch, setValidMatch] = useState(false);

  const [accessKeyChecked, setAccessKeyChecked] = useState(false);
  const [userLevel, setUserLevel] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showLoadingLayout, setShowLoadingLayout] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setValidName(USER_REGEX.test(user));
  }, [user]);

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(pwd));
    setValidMatch(pwd === matchPwd);
  }, [pwd, matchPwd]);

  useEffect(() => {
    setErrMsg('');
  }, [user, pwd, matchPwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    

    const v1 = USER_REGEX.test(user);
    const v2 = PWD_REGEX.test(pwd);
    if (accessKeyChecked && !userLevel) {
      setErrMsg("Please select a user level.");
      setIsLoading(false);
      return;
    }

    if (accessKeyChecked && !accessToken) {
      setErrMsg("Access token required for admin/staff registration");
      setIsLoading(false);
      return;
    }

    if (!v1 || !v2) {
      setErrMsg("Invalid Entry");
      setIsLoading(false);
      return;
    }
    setTimeout(async () => {
    try {
      const response = await axios.post(REGISTER_URL,
        JSON.stringify({ user, pwd, userLevel, accessToken }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        });
      setSuccess(true);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      if (!err?.response) {
        setErrMsg(t("Network error. Please try again."));
      } else if (err.response?.status === 409) {
        setErrMsg(t("errors.userTaken"));
      } else {
        setErrMsg(err.response.data?.message || "Registration failed. Try again.");
      }
      if (errRef.current) {
        errRef.current.focus();
      }
    }
  }, 2000);
  };

  return (
    <>
      {success ? (
        <Container size={420} my={40}>
          <Title ta="center">Registration successful!</Title>
          <Text c="dimmed" size="sm" ta="center" mt={5}>
            Go to the login page{' '}
            <Anchor size="sm" component="a" href="/login">
              Login
            </Anchor>
          </Text>
        </Container>
      ) : (
        <Container size={420} my={40}>
          <Title ta="center">Register</Title>
          <Text c="dimmed" size="sm" ta="center" mt={5}>
            Already have an account?{' '}
            <Anchor size="sm" component="a" href="/login">
              Login
            </Anchor>
          </Text>

          <form onSubmit={handleSubmit}>
          {isLoading ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "16px",
                }}
              >
                <div
                  style={{
                    border: "8px solid #f3f3f3",
                    borderTop: "8px solid #E4C577",
                    borderRadius: "50%",
                    width: "50px",
                    height: "50px",
                    animation: "spin 1s linear infinite",
                  }}
                />
                <p
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "#E4C577",
                    marginTop: "10px",
                  }}
                >
                  Registering...
                </p>
                <style>
                  {`
                    @keyframes spin {
                      0% { transform: rotate(0deg); }
                      100% { transform: rotate(360deg); }
                    }
                  `}
                </style>
              </div>
            ) : (
            <Paper withBorder shadow="md" p={30} mt={30} radius="md">
              <TextInput
                label="Email"
                placeholder="name@name.com"
                required
                value={user}
                onChange={(e) => setUser(e.target.value)}
              />
              <PasswordInput
                label="Password"
                placeholder="Your password"
                required
                mt="md"
                value={pwd}
                onChange={(e) => setPwd(e.target.value)}
                aria-describedby="pwdnote"
              />
              <PasswordInput
                label="Confirm Password"
                placeholder="Confirm password"
                required
                mt="md"
                value={matchPwd}
                onChange={(e) => setMatchPwd(e.target.value)}
                aria-describedby="pwdnote"
              />

              <Group direction="column" spacing="xs" mt="md">
                <Checkbox
                  label={
                    <Text color={pwd.length >= 8 ? "green" : "red"}>
                      Password must be at least 8 characters long
                    </Text>
                  }
                  checked={pwd.length >= 8}
                  disabled
                />
                <Checkbox
                  label={
                    <Text color={/[A-Z]/.test(pwd) && /[a-z]/.test(pwd) ? "green" : "red"}>
                      Password must contain at least one uppercase letter and one lowercase letter
                    </Text>
                  }
                  checked={/[A-Z]/.test(pwd) && /[a-z]/.test(pwd)}
                  disabled
                />
                <Checkbox
                  label={
                    <Text color={/[\W_]/.test(pwd) ? "green" : "red"}>
                      Password must contain at least one special character
                    </Text>
                  }
                  checked={/[\W_]/.test(pwd)}
                  disabled
                />
                <Checkbox
                  label={
                    <Text color={/\d/.test(pwd) ? "green" : "red"}>
                      Password must contain at least one number
                    </Text>
                  }
                  checked={/\d/.test(pwd)}
                  disabled
                />
                <Checkbox
                  label={
                    <Text color={pwd === matchPwd && pwd ? "green" : "red"}>
                      Password and Confirm Password must match
                    </Text>
                  }
                  checked={pwd === matchPwd && pwd !== ""}
                  disabled
                />
              </Group>

              <Checkbox label="Have an access key?" mt="md" checked={accessKeyChecked} onChange={(e) => setAccessKeyChecked(e.target.checked)} />

              {accessKeyChecked && (
                <>
                  <Select
                    label="User Level"
                    placeholder="Select user level"
                    required
                    data={[
                      { value: 'admin', label: 'Admin' },
                      { value: 'staff', label: 'Staff' }
                    ]}
                    value={userLevel}
                    onChange={(level) => setUserLevel(level)}
                  />
                  <TextInput
                    label="Access Token"
                    placeholder="Enter access token"
                    required
                    value={accessToken}
                    onChange={(e) => setAccessToken(e.target.value)}
                  />
                </>
              )}

              <Button
                type="submit"
                variant="outline"
                color="resirent"
                fullWidth
                mt="lg"
                disabled={!validName || !validPwd || !validMatch}
              >
                Sign up
              </Button>
              <Text ref={errRef} color="red" size="sm">
                {errMsg}
              </Text>
            </Paper>
            )}
          </form>
        </Container>
      )}
    </>
  );
}
import {
    createStyles, SimpleGrid, Card, Image, Text, Container, AspectRatio, Title, Box,
    Group, Avatar, Button, rem
} from '@mantine/core';
import { hostsDE, hostsEn, hostsNL } from '../../languages/hostPages';
import { HostHeader } from './HostsHeader';

const useStyles = createStyles((theme) => ({
    card: {
        transition: 'transform 150ms ease, box-shadow 150ms ease',

        '&:hover': {
            transform: 'scale(1.01)',
            boxShadow: theme.shadows.md,
        },

        backgroundColor: "#FFF5F9",
    },

    title: {
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontWeight: 600,
    },

    control: {
        '&:hover': {
            color: theme.white,
            backgroundColor: theme.colors.resirentPink[5],
        },

        border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.resirentPink[5] : theme.colors.resirentPink[8]}`,
        backgroundColor: theme.white,
        color: theme.colors.resirentPink[5],
        textAlign: 'center', // Center-align the contents
    },
}));

export default function Hosts() {
    const { classes } = useStyles();
    const languagePrefix = window.location.pathname.startsWith('/nl') ? '/nl' : window.location.pathname.startsWith('/de') ? '/de' : '';
    const posts = languagePrefix === '/nl' ? hostsNL : languagePrefix === '/de' ? hostsDE : hostsEn;

    const content = {
        '/': {
            'groupTitle': 'EMPOWERING SMALL HOSTS',
            'mainTitle': 'Streamline Your Accommodation Experience: Discover All-in-One Solutions for Your Hosting Needs!',
            'description': 'At ResiRent, we help you to short let your accommodation sustainable and optimize your revenues. Our approach is rooted in the idea of being professional, independent and sustainable. Hop on board and get your short-term rental ready for the 21st century.',
        },
        '/nl': {
            'groupTitle': 'KLEINE GASTGEVERS VERSTERKEN',
            'mainTitle': 'Vereenvoudig uw accommodatie-ervaring: Ontdek alles-in-één oplossingen voor uw hostingbehoeften!',
            'description': 'Bij ResiRent helpen we u om uw accommodatie duurzaam te verhuren en uw inkomsten te optimaliseren. Onze aanpak is gebaseerd op het idee van professionaliteit, onafhankelijkheid en duurzaamheid. Stap aan boord en maak uw kortetermijnverhuur klaar voor de 21e eeuw.',
        },
        '/de': {
            'groupTitle': 'KLEINE GASTGEBER STÄRKEN',
            'mainTitle': 'Optimieren Sie Ihr Unterkunftserlebnis: Entdecken Sie All-in-One-Lösungen für Ihre Hosting-Bedürfnisse!',
            'description': 'Bei ResiRent helfen wir Ihnen, Ihre Unterkunft nachhaltig zu vermieten und Ihre Einnahmen zu maximieren. Unser Ansatz basiert auf den Prinzipien von Professionalität, Unabhängigkeit und Nachhaltigkeit. Steigen Sie ein und machen Sie Ihre Kurzzeitvermietung fit für das 21. Jahrhundert.',
        },
    };

    const cards = posts.map((article) => (
        <Card key={article.title} p="md" radius="md" component="a" href={`${languagePrefix}/blog/${article.id}`} className={classes.card}>
            <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'xs', cols: 1 }, { maxWidth: 'md', cols: 2 }]}>
                <AspectRatio ratio={1920 / 1080}>
                    <Image src={article.image} radius="md" />
                </AspectRatio>
                <Box>
                    <Title order={4} weight={300} transform="uppercase" color="resirentPink">
                        {article.place}
                    </Title>
                    <Title order={2} className={classes.title} my="sm" display={'block'}>
                        {article.accommodationName}
                    </Title>
                    <Text mt="md">{article.description}</Text>
                    <Button align="center" mb="md" radius="xl" component="a" href={article.websiteUrl} mt="md" size="md" className={classes.control}>
                        {languagePrefix === '/nl' ? "Bekijk hun website " : languagePrefix === '/de' ? "Visit the website" : "Visit the website"}
                    </Button>
                </Box>
            </SimpleGrid>
            <Box mb="md" mt="md">
                <Group noWrap>
                    <Avatar src={article.host.imageUrl} size={128} />
                    <div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Text pr="md" fz="xs" tt="uppercase" fw={700} c="dimmed">
                                {article.host.role}
                            </Text>
                        </div>

                        <Text fz="lg" fw={500} className={classes.name}>
                            {languagePrefix === '/nl' ? "Gastgever: " : languagePrefix === '/de' ? "Gastgeber: " : "Host: "}   {article.host.name}
                        </Text>

                        <Group noWrap spacing={10} mt={5}>
                            <Text fz="xs">
                                {article.host.description}
                            </Text>
                        </Group>
                    </div>
                </Group>
            </Box>
        </Card>
    ));

    return (
        <>
            <HostHeader />
            <Container pb={100}>
                <SimpleGrid cols={1} breakpoints={[{ maxWidth: 'xs', cols: 1 }, { maxWidth: 'md', cols: 1 }]}>
                    {cards}
                </SimpleGrid>
            </Container>
        </>
    );
}
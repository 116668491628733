import React, { useEffect, useState } from 'react';
import { Container, Paper, Text, Button, Box, SimpleGrid, Select, rem, Textarea } from '@mantine/core';
import { createStyles } from '@mantine/core';
import useAuth from '../../../hooks/useAuth';
import axios from '../../../api/axios';
import MonthViewHost from './CalendarPriceViewHost';
import { useTranslation } from "react-i18next";
import { t } from 'i18next';


// Calendar generator for HostView
// Takes username and gets calendar data from the database
// Displays the Host view on a monthly basis
const CalendarPriceView = (inputForm) => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const { classes } = useStyles();
    const [selectedRange, setSelectedRange] = useState({ start: null, end: null });
    const [dateStates, setDateStates] = useState('');
    const { auth } = useAuth(); // get username. Right now hard-coded
    const username = auth?.resiWeb;
    const WEBSITE_URL = '/websitejson';
    const [saved, setSaved] = useState(false);
    const [isSaveDisabled, setIsSaveDisabled] = useState(false);

    const art = "en";
    const title = "calendar-pricing";

    const [price, setPrice] = useState('');
    const [label, setLabel] = useState('');

    const priceOptions = [
        { value: 'high', label: 'High Price' },
        { value: 'low', label: 'Low Price' },
        { value: 'base', label: 'Base Price' },
      ];

    // Fetch the DateStates from the database
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${WEBSITE_URL}`, {
                    params: { username, art, title },
                });
                setDateStates(JSON.parse(response.data.entry.content));
                console.log(response)
            } catch (err) {
                console.log(err);
            }
        };
        fetchData();
    }, [username, art, title]);

    const handleCategorySaveClick = (selectedRange, price, label) => {
        const newDateStates = { ...dateStates };

        const startDate = new Date(selectedRange.start);
        const endDate = new Date(selectedRange.end);

        const startKey = startDate.toISOString().slice(0, 10);
        const endKey = endDate.toISOString().slice(0, 10);

        // Function to check if two ranges overlap
        const rangesOverlap = (range1, range2) => {
            return (
                new Date(range1.start) < new Date(range2.end) &&
                new Date(range2.start) < new Date(range1.end)
            );
        };

        // Find existing ranges that overlap with the selected range
        const overlappingRanges = Object.entries(newDateStates).filter(([key, range]) => {
            return rangesOverlap(range, { start: startKey, end: endKey });
        });

        // Add new range
        const newRangeKey = `${startKey}-${endKey}`;
        newDateStates[newRangeKey] = {
            start: startKey,
            end: endKey,
            price,
            label
        };

        // Split existing ranges if necessary
        overlappingRanges.forEach(([key, range]) => {
            if (range.price !== 'price') { // Only modify non-price ranges
                if (new Date(range.start) < new Date(startKey)) {
                    const beforeRangeKey = `${range.start}-${new Date(new Date(startKey).setDate(startDate.getDate() - 1)).toISOString().slice(0, 10)}`;
                    newDateStates[beforeRangeKey] = {
                        start: range.start,
                        end: new Date(new Date(startKey).setDate(startDate.getDate() - 1)).toISOString().slice(0, 10),
                        price: range.price,
                        label: range.label
                    };
                }
                if (new Date(range.end) > new Date(endKey)) {
                    const afterRangeKey = `${new Date(new Date(endKey).setDate(endDate.getDate() + 1)).toISOString().slice(0, 10)}-${range.end}`;
                    newDateStates[afterRangeKey] = {
                        start: new Date(new Date(endKey).setDate(endDate.getDate() + 1)).toISOString().slice(0, 10),
                        end: range.end,
                        price: range.price,
                        label: range.label
                    };
                }
            }
        });

        // Remove overlapping ranges from newDateStates
        overlappingRanges.forEach(([key]) => {
            delete newDateStates[key];
        });

        // Update the dateStates state with the new values
        setDateStates(newDateStates);
        saveFormData(newDateStates);
        setPrice('');
        setLabel('');
        setSelectedRange({ start: null, end: null });
    };

    const handleStop = (rangeKey) => {
        const newDateStates = { ...dateStates };
        if (newDateStates[rangeKey]) {
            delete newDateStates[rangeKey];
            setDateStates(newDateStates);
            saveFormData(newDateStates);
        }
    };

    // push dateStates to database based on username
    // Can only be called inside a hook!
    // Username entry must exist
    const saveFormData = (dateStates) => {
        const update = async () => {
            // You can now send this formData to your server or perform other actions.
            try {
                await axios.put(WEBSITE_URL, {
                    username,
                    art,
                    title,
                    content: dateStates,
                });
                setSaved(true);
                // reset save after 5 sec.
                setTimeout(() => {
                    setSaved(false);
                }, 5000);
            } catch (err) {
                console.log(err);
            }
            // Confirmation text
        };
        update();
    };

    const handlePrevMonth = () => {
        const newDate = new Date(currentDate);
        newDate.setMonth(newDate.getMonth() - 1);
        setCurrentDate(newDate);
    };

    const handleNextMonth = () => {
        const newDate = new Date(currentDate);
        newDate.setMonth(newDate.getMonth() + 1);
        setCurrentDate(newDate);
    };

    const handleAccept = (rangeKey) => {
        const newDateStates = { ...dateStates };
        if (newDateStates[rangeKey]) {
            newDateStates[rangeKey].price = 'low';
            setDateStates(newDateStates);
            saveFormData(newDateStates);
        }
    };

    const handleDecline = (rangeKey) => {
        const newDateStates = { ...dateStates };
        if (newDateStates[rangeKey]) {
            delete newDateStates[rangeKey];
            setDateStates(newDateStates);
            saveFormData(newDateStates);
        }
    };

    return (
        <>
            <Paper className={classes.paper}
                m="md"
                sx={(theme) => ({
                    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[2],
                })}>
                <SimpleGrid cols={1} spacing="md" verticalSpacing="md" breakpoints={[{ maxWidth: 'xs', cols: 1 }, { maxWidth: 'md', cols: 1 }]}>
                    <Container size="xl" m="md" mt="xl">
                        <SimpleGrid cols={3} spacing="md" verticalSpacing="md">
                            <Button variant="outline" color="resirentPink" onClick={handlePrevMonth}>
                                {t('calendar-view.previous')}
                            </Button>
                            <Text size="md" weight={500} align="center">
                                {currentDate.toLocaleString('default', { month: 'long' })} {currentDate.getFullYear()}
                            </Text>
                            <Button variant="outline" color="resirentPink" onClick={handleNextMonth}>
                                {t('calendar-view.next')}
                            </Button>
                        </SimpleGrid>

                        <SimpleGrid cols={7} spacing="xss" verticalSpacing="xs" mt="md">
                            {MonthViewHost(inputForm, currentDate, dateStates, selectedRange, setSelectedRange, 'Booked', handleAccept, handleDecline, handleStop)}
                        </SimpleGrid>
                    </Container>
                </SimpleGrid>
                <Container size="xl" m="md">
                    <Text size="xl" weight={500}>
                        Set the price range
                    </Text>
                    <Select
                        data={priceOptions}
                        placeholder="Select price range"
                        value={price}
                        onChange={(value) => setPrice(value)}
                        disabled={!selectedRange.start || !selectedRange.end}
                    />

                    {!selectedRange.start || !selectedRange.end ?
                        <Text size="sm" weight={500} color="red">
                            Select a date range first
                        </Text> : null}

                    <Box style={{ marginTop: '20px' }}>
                        <Button
                            align="center"
                            mb="md"
                            radius="xl"
                            onClick={() => handleCategorySaveClick(selectedRange, price, label)}
                            size="md"
                            className={classes.controlSecond}
                            disabled={isSaveDisabled}
                        >
                                {t('calendar-view.save')}
                        </Button>
                    </Box>
                </Container>
            </Paper>
           
        </>
    );
};

export default CalendarPriceView;

const useStyles = createStyles((theme) => ({
    datepicker: {
        mt: "md"
    },

    paper: {
        m: "md",
        radius: "md",
        align: "center"
    },

    box: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        padding: theme.spacing.xs,
        borderRadius: theme.radius.md,
        cursor: 'pointer',
        align: "center",

        '&:hover': {
            backgroundColor:
                theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1],
        },
    },

    input: {
        backgroundColor: theme.white,
        borderColor: theme.colors.gray[4],
        color: theme.black,

        '&::placeholder': {
            color: theme.colors.gray[5],
        },
    },

    inputLabel: {
        color: theme.black,
    },

    control: {
        backgroundColor: theme.colorScheme.resirentPink,
    },

    controlSecond: {
        '&:hover': {
            border: `${rem(3)} solid ${theme.colorScheme === 'dark' ? theme.colors.resirentPink[5] : theme.colors.resirentPink[8]}`,
            backgroundColor: theme.white,
            color: theme.colors.resirentPink[5],
        },
        color: theme.white,
        backgroundColor: theme.colors.resirentPink[5],
        textAlign: 'center', // Center-align the contents
    },
}));

import React, { useState } from 'react';
import { Modal, Button, Text, Group } from '@mantine/core';

const BookingInfoModal = ({ isOpen, onClose, onStop, onCancel, bookingInfo }) => {
    const [showConfirmation, setShowConfirmation] = useState(false);

    if (!bookingInfo) return null;

    const handleCancelClick = () => {
        setShowConfirmation(true);
    };

    const handleConfirmCancel = () => {
        setShowConfirmation(false);
        onCancel();
    };

    const handleCancelConfirmation = () => {
        setShowConfirmation(false);
    };

    return (
        <Modal opened={isOpen} onClose={onClose} title="Booking Information">
            <Text size="sm">Booking Details:</Text>
            <Text size="sm">Check-in date: {bookingInfo.start}</Text>
            <Text size="sm">Check-out date: {bookingInfo.end}</Text>
            <Text size="sm">Label: {bookingInfo.label}</Text>
            <Text size="sm">Status: {bookingInfo.booked}</Text>

            <Button fullWidth mt="md" color="red" onClick={handleCancelClick}>
                Cancel Booking
            </Button>

            <Modal
                opened={showConfirmation}
                onClose={handleCancelConfirmation}
                title="Confirm Cancellation"
            >
                <Text size="sm">Do you really want to cancel this booking?</Text>
                <Group position="center" mt="md">
                    <Button color="red" onClick={handleConfirmCancel}>
                        Yes, Cancel
                    </Button>
                    <Button variant="default" onClick={handleCancelConfirmation}>
                        No, Keep
                    </Button>
                </Group>
            </Modal>
        </Modal>
    );
};

export default BookingInfoModal;


import React from 'react';
import {
  createStyles,
  Text,
  Box,
  Container,
  Title,
  Grid,
  Group,
  ThemeIcon,
} from '@mantine/core';
import { IconBolt } from '@tabler/icons-react';

const useStyles = createStyles((theme) => ({
  wrapper: {
    backgroundColor: '#ffffff',
    fontFamily: 'Inter, sans-serif',
    paddingTop: 80,
    paddingBottom: 80,
  },

  iconCircle: {
    backgroundColor: '#E7FAEF',
    borderRadius: '50%',
    width: 48,
    height: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing.md,
  },

  heading: {
    fontSize: 24,
    fontWeight: 700,
    marginBottom: theme.spacing.sm,
  },

  subheading: {
    fontSize: 16,
    color: '#4A4A4A',
    maxWidth: 320,
  },

  benefitTitle: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 4,
  },

  benefitDesc: {
    fontSize: 14,
    color: '#4A4A4A',
    lineHeight: 1.5,
  },
}));

export default function Benefits({ language }) {
  const { classes } = useStyles();

  const content = {
    '/': {
      mainTitle: 'Why Have Your Own Website?',
      intro:
        'A dedicated website for your short-term rental gives you independence, flexibility, and direct access to your ideal guests.',
      benefits: [
        {
          title: 'Attract the Right Guests',
          description:
            "Don't just compete on price and location—target the customers who truly appreciate your property and what it offers.",
        },
        {
          title: 'Direct Communication',
          description:
            'Engage with potential guests before they book and make it easy for returning guests to book directly with you.',
        },
        {
          title: 'Showcase Your Personality',
          description:
            'Highlight what makes your rental unique by adding personal touches and local recommendations that matter to you.',
        },
        {
          title: 'Full Control Over Policies',
          description:
            'Set your own prices, cancellation rules, and booking conditions without limitations from third-party platforms.',
        },
        {
          title: 'Better Visibility & Branding',
          description:
            'Make it easier for guests to find, share, and refer your property through search engines and social media.',
        },
        {
          title: 'Save on Fees',
          description:
            'Avoid costly commissions and transaction fees when guests book directly through your website’s calendar.',
        },
      ],
    },
    '/nl': {
      mainTitle: 'Waarom zou u een eigen website hebben?',
      intro:
        'Een toegewijde website voor je kortetermijnverhuur geeft je onafhankelijkheid, flexibiliteit en direct toegang tot je ideale gasten.',
      benefits: [
        {
          title: 'Trek de juiste gasten aan',
          description:
            "Competeer niet alleen op prijs en locatie — richt je op de klanten die je eigendom en wat het biedt echt waarderen.",
        },
        {
          title: 'Directe communicatie',
          description:
            'Communiceer met potentiële gasten voordat ze boeken en maak het voor terugkerende gasten gemakkelijk om direct bij jou te boeken.',
        },
        {
          title: 'Toon je persoonlijkheid',
          description:
            'Benadruk wat je verhuur uniek maakt door persoonlijke accenten en lokale aanbevelingen toe te voegen die voor jou belangrijk zijn.',
        },
        {
          title: 'Volledige controle over beleidsregels',
          description:
            'Stel je eigen prijzen, annuleringsregels en boekingsvoorwaarden in zonder beperkingen van externe platforms.',
        },
        {
          title: 'Betere zichtbaarheid en branding',
          description:
            'Maak het gemakkelijker voor gasten om je eigendom te vinden, te delen en door te verwijzen via zoekmachines en sociale media.',
        },
        {
          title: 'Bespaar op kosten',
          description:
            'Vermijd kostbare commissies en transactie kosten wanneer gasten direct boeken via de kalender op je website.',
        },
      ],
    },
    '/de': {
      mainTitle: 'Warum Eine Eigene Website?',
      intro:
        'Eine eigene Website für Ihre Kurzzeitvermietung gibt Ihnen Unabhängigkeit, Flexibilität und direkten Zugang zu Ihren idealen Gästen.',
      benefits: [
        {
          title: 'Ziehen Sie die richtigen Gäste an',
          description:
            "Konkurrenzen Sie nicht nur mit Preis und Lage – sprechen Sie gezielt die Kunden an, die Ihre Unterkunft und ihr Angebot wirklich schätzen.",
        },
        {
          title: 'Direkte Kommunikation',
          description:
            'Interagieren Sie mit potenziellen Gästen, bevor sie buchen, und machen Sie es wiederkehrenden Gästen leicht, direkt bei Ihnen zu buchen.',
        },
        {
          title: 'Präsentieren Sie Ihre Persönlichkeit',
          description:
            'Heben Sie hervor, was Ihre Unterkunft einzigartig macht, indem Sie persönliche Akzente und lokale Empfehlungen hinzufügen, die Ihnen wichtig sind.',
        },
        {
          title: 'Vollständige Kontrolle über Richtlinien',
          description:
            'Setzen Sie Ihre eigenen Preise, Stornobedingungen und Buchungsrichtlinien fest, ohne Einschränkungen durch Drittanbieter-Plattformen.',
        },
        {
          title: 'Bessere Sichtbarkeit & Markenbildung',
          description:
            'Erleichtern Sie es Gästen, Ihre Unterkunft über Suchmaschinen und soziale Medien zu finden, zu teilen und weiterzuempfehlen.',
        },
        {
          title: 'Sparen Sie an Gebühren',
          description:
            'Vermeiden Sie teure Provisionen und Transaktionsgebühren, wenn Gäste direkt über den Kalender Ihrer Website buchen.',
        },
      ],
    },
  };

  const languagePrefix = window.location.pathname.startsWith('/nl')
    ? '/nl'
    : window.location.pathname.startsWith('/de')
      ? '/de'
      : '/';


  const data = content[languagePrefix];

  return (
    <Box className={classes.wrapper}>
      <Container size="lg">
        <Grid gutter={40} align="flex-start">
          {/* LEFT SIDE */}
          <Grid.Col span={12} md={4}>
            <div className={classes.iconCircle}>
              <ThemeIcon color="blue" variant="light" radius="xl" size={40}>
                <IconBolt size={30} stroke={1.5} color='#003D51' />
              </ThemeIcon>
            </div>
            <Title order={2} className={classes.heading}>
              {data.mainTitle}
            </Title>
            <Text className={classes.subheading}>{data.intro}</Text>
          </Grid.Col>

          {/* RIGHT SIDE - BENEFITS LIST */}
          <Grid.Col span={12} md={8}>
            <Grid>
              {data.benefits.map((benefit, index) => (
                <Grid.Col span={12} sm={6} key={index}>
                  <Text className={classes.benefitTitle}>{benefit.title}</Text>
                  <Text className={classes.benefitDesc}>{benefit.description}</Text>
                </Grid.Col>
              ))}
            </Grid>
          </Grid.Col>
        </Grid>
      </Container>
    </Box>
  );
}

import {
    createStyles,
    Image,
    Center,
    Container,
    Title,
    Button,
    Group,
    Text,
    Box,
    SimpleGrid,
    rem,
} from '@mantine/core';
import { IconPlayerPlayFilled } from '@tabler/icons-react';
import image from '../../assets/homepage/thecityghent.jpg';
import { PlayCircle } from "lucide-react";

const translations = {
    '/': {
        title: "A simple tool to build a professional website\nfor your",
        highlight: "Short Term Rental",
        description: "Use ResiWeb to easily create a website with a custom domain, featuring Google Maps, activity suggestions, FAQs, and more.",
        demoButton: "Demo",
        contactButton: "Contact Us",
    },
    '/de': {
        title: "Ein einfaches Tool zur Erstellung einer professionellen Website\nfür Ihre",
        highlight: "Kurzzeitvermietung",
        description: "Nutzen Sie ResiWeb, um ganz einfach eine Website mit eigener Domain zu erstellen, mit Google Maps, Aktivitätsvorschlägen, FAQs und mehr.",
        demoButton: "Demo",
        contactButton: "Kontaktieren Sie uns",
    },
    '/nl': {
        title: "Een eenvoudige tool om een professionele website te bouwen\nvoor uw",
        highlight: "Korte termijn verhuur",
        description: "Gebruik ResiWeb om eenvoudig een website te maken met een aangepaste domeinnaam, inclusief Google Maps, activiteitenvoorstellen, veelgestelde vragen en meer.",
        demoButton: "Demo",
        contactButton: "Neem contact op",
    }
};


const useStyles = createStyles((theme) => ({
    title: {
      textAlign: 'center',
      fontSize: rem(48),
      fontWeight: 700,
      maxWidth: '1140px',
      margin: '0 auto 8px auto',
      lineHeight: '1.2',
      fontFamily: 'Inter, sans-serif',
      whiteSpace: 'pre-line',
      [theme.fn.smallerThan('sm')]: {
        fontSize: rem(26),
        padding: `0 ${rem(12)}`,
      },
    },
    highlight: {
      color: '#F06595',
      fontWeight: 700,
    },
    description: {
      textAlign: 'center',
      fontSize: rem(20),
      maxWidth: '900px',
      margin: '8px auto',
      color: '#667085',
      fontFamily: 'Inter, sans-serif',
      [theme.fn.smallerThan('sm')]: {
        fontSize: rem(14),
        padding: `0 ${rem(12)}`,
      },
    },
    buttonGroup: {
      display: 'flex',
      justifyContent: 'center',
      gap: rem(12),
      marginTop: rem(16),
      flexWrap: 'wrap',
      [theme.fn.smallerThan('sm')]: {
        flexDirection: 'column',
        alignItems: 'stretch',
        gap: rem(8),
        padding: `0 ${rem(12)}`,
      },
    },
    buttonPrimary: {
      backgroundColor: theme.white,
      color: theme.black,
      fontSize: rem(18),
      padding: `${rem(10)} ${rem(20)}`,
      fontFamily: 'Inter, sans-serif',
      border: `1px solid ${theme.colors.gray[5]}`,
      '&:hover': {
        backgroundColor: theme.colors.gray[1],
      },
      [theme.fn.smallerThan('sm')]: {
        fontSize: rem(14),
        padding: `${rem(8)} ${rem(14)}`,
      },
    }, 
    buttonSecondary: {
      fontSize: rem(18),
      padding: `${rem(10)} ${rem(20)}`,
      fontFamily: 'Inter, sans-serif',
      backgroundColor: theme.colors.resirent[0],
      color: theme.white,
      '&:hover': {
        backgroundColor: theme.colors.dark[8],
      },
      [theme.fn.smallerThan('sm')]: {
        fontSize: rem(14),
        padding: `${rem(8)} ${rem(14)}`,
      },
    },
    imageContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: rem(30),
      [theme.fn.smallerThan('sm')]: {
        marginTop: rem(20),
        padding: `0 ${rem(12)}`,
      },
    },
    image: {
      maxWidth: '60%',
      height: 'auto',
      borderRadius: theme.radius.md,
      [theme.fn.smallerThan('sm')]: {
        maxWidth: '100%',
      },
    },
  }));
  
  export default function Hero() {
    const languagePrefix = window.location.pathname.startsWith('/nl') ? '/nl' : window.location.pathname.startsWith('/de') ? '/de' : '/';
    const { classes } = useStyles();
    const content = translations[languagePrefix];
  
    return (
      <Container size="xl" py={50}>
        <Title className={classes.title}>
          {content.title} <span className={classes.highlight}>{content.highlight}</span>
        </Title>
        <Text className={classes.description}>{content.description}</Text>
        
        <Group className={classes.buttonGroup}>
          <Button
            component="a"
            href="https://www.youtube.com/embed/cWx7FPUVlOw?&autoplay=1"
            size="md"
            className={classes.buttonPrimary}
            leftIcon={
              <div>
                <PlayCircle size={25} strokeWidth={1.5} />
              </div>
            }
          >
            {content.demoButton}
          </Button>
          <Button component="a" href="/contact" size="md" className={classes.buttonSecondary}>
            {content.contactButton}
          </Button>
        </Group>
  
        <Box className={classes.imageContainer}>
          <Image src={image} alt="ResiWeb" className={classes.image} />
        </Box>
      </Container>
    );
  }
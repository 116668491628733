import React from 'react';
import { useEffect } from 'react';
import { Container, Title, Text, List, Anchor, Group } from '@mantine/core';

const SubHeading = ({ children, order }) => (
    <Title order={order} mt="xl" mb="sm" style={{ color: '#f783ac' }}>{children}</Title>
);

const PrivacyPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Container size="md" py="xl">
            <Title align="center" mb="md" style={{ color: '#003d51' }}>
                Privacy Statement
            </Title>
            <Text align="center" color="dimmed" mb="lg">
                Last updated on November 21, 2024
            </Text>

            <Text size="md" mb="lg" style={{ color: '#003d51' }}>
                This page was last updated on November 21, 2024. We are aware that you place your trust in
                us, and we see it as our responsibility to protect your privacy. On this page, we explain which
                data we collect when you use our website, why we collect this data, and how we use it to
                improve your user experience. This way, you can fully understand how we operate.
            </Text>
            <Text size="md" mb="lg" style={{ color: '#003d51' }}>
                This privacy policy applies to the services of ResiRent. Please be aware that ResiRent is not
                responsible for the privacy policies of other sites and resources. By using this website, you
                indicate your acceptance of this privacy policy.
            </Text>
            <Text size="md" mb="lg" style={{ color: '#003d51' }}>
                ResiRent respects the privacy of all users of its site and ensures that the personal information
                you provide to us is treated confidentially.
            </Text>
            <SubHeading order={2}>
                Use of Our Services
            </SubHeading>

            <Text size="md" mb="md">
                When you sign up for one of our services, we ask you to provide personal data. This data is
                used to be able to perform the IT service. The data is stored on our own secure servers at
                Hostpoint AG.
                We will not combine this data with other personal data we hold.
                You give consent to display certain data relevant for your visitors on your website: your
                name, the name of the accommodation, the address of the accommodation, email address,
                VAT number. You can choose whether or not to display your phone number on your website.
            </Text>

            <SubHeading order={2}>
                Communications
            </SubHeading>
            <Text size="md" mb="md">
                When you send an email or other messages to us, it is possible that we will store these
                messages. Sometimes we may ask you for your personal information that is relevant to the
                specific situation. This allows us to process your questions and respond to your requests. The
                data is stored on the secure servers of Hostpoint AG. We will not combine this data with other
                personal data we hold.
                We collect data for research purposes to gain a better understanding of our customers, so we
                can tailor our services accordingly.
            </Text>
            <Text size="md" mb="md">
                We collect data for research purposes to gain a better understanding of our customers, so we
                can tailor our services accordingly.
            </Text>

            <SubHeading order={2}>
                Cookies
            </SubHeading>
            <Text size="md" mb="md">
                This website uses "cookies" (text files that are placed on your computer) to help analyze how
                users use the site. The information generated by the cookie about your use of the website
                may be transferred to the secure servers of Hostpoint AG.

                We use this information to track how you use the website, generate reports on website
                activity, and provide other services related to website activity and internet usage.
            </Text>
            <SubHeading order={2}>
                Purposes
            </SubHeading>
            <Text size="md" mb="md">
                We do not collect or use information for any purposes other than those described in this
                privacy policy, unless we have obtained your consent in advance.
            </Text>
            <SubHeading order={2}>
                Photo and Video Material
            </SubHeading>
            <Text size="md" mb="md">
                Our staff, (professional) photographers, and videographers sometimes create visual content.
                This content may be used for print and digital communication from ResiRent. If your
                accommodation is included in the visual content, we will first ask for your permission to use it
                for marketing purposes.
            </Text>
            <SubHeading order={2}>
                Third parties
            </SubHeading>
            <Text size="md" mb="md">
                The information is not shared with third parties. In some cases, the information may be
                shared internally. Our employees are obligated to respect the confidentiality of your data.
            </Text>
            <SubHeading order={2}>
                Changes
            </SubHeading>
            <Text size="md" mb="md">
                This privacy statement is aligned with the use of and the features available on this site. Any
                adjustments and/or changes to this site may lead to modifications in this privacy statement.
                Therefore, it is advisable to regularly review this privacy statement.
            </Text>
            <SubHeading order={2}>
                Choice regarding personal data
            </SubHeading>
            <Text size="md" mb="md">
                We offer all visitors the opportunity to view, modify, or delete any personal information that
                has been provided.
            </Text>
            <SubHeading order={2}>
                Edit and unsubscribe from the newsletter
            </SubHeading>
            <Text size="md" mb="md">
                At the bottom of each email, you will find the option to update your information or
                unsubscribe.
            </Text>
            <SubHeading order={2}>
                Edit or unsubscribe from communications
            </SubHeading>
            <Text size="md" mb="md">
                If you would like to update your information or have your data removed from our records, you
                can contact us using the contact details below.
            </Text>
            <Text size="md" mb="md">
            <SubHeading order={2}>
                Disable Cookies
            </SubHeading>
            <Text size="md" mb="md">
                At the bottom of each email, you will find the option to update your information or
                unsubscribe.
            </Text>
            </Text>
            <SubHeading order={2}>
                Questions and feedback
            </SubHeading>
            <Text size="md" mb="md">
                We regularly check whether we comply with this privacy policy. If you have any questions
                about this privacy policy, you can contact us at:
            </Text>
           <div style={{ padding: '20px', backgroundColor: '#f9f9f9', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <Text size="md" weight={700} style={{ color: '#1a3d4c' }}>Brand Name: ResiRent</Text>
                
                <Text size="md" weight={700} style={{ color: '#1a3d4c' }}>Sustainable Small Accommodations Bulteel, CHE291 088 716</Text>
                
                <Text size="md" weight={700} style={{ color: '#1a3d4c' }}>Address: Schützenrütistrasse 10, 8044 Gockhausen, Switzerland</Text>
                
                <Text size="md" weight={700} style={{ color: '#1a3d4c' }}>Email:
                    <Anchor href="mailto:anjabulteel@me.com" style={{ textDecoration: 'none', color: '#1a73e8', fontWeight: 500 }}> anjabulteel@me.com</Anchor>
                </Text>
                
                <Text size="md" weight={700} style={{ color: '#1a3d4c' }}>Phone: +41 764 240 582</Text>
                
                <Text size="md" weight={700} style={{ color: '#1a3d4c' }}>Website: 
                    <Anchor href="https://resirent.org" target="_blank" style={{ textDecoration: 'none', color: '#1a73e8', fontWeight: 500 }}> ResiRent.org</Anchor>
                </Text>
            </div>

        </Container>
    );
};

export default PrivacyPage;

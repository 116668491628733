import React, { useState } from 'react';
import { Text, Box, SimpleGrid } from '@mantine/core';
import { createStyles } from '@mantine/core';
import { IconArrowDown, IconArrowUp } from '@tabler/icons-react';
import { IconHome } from '@tabler/icons-react';

const getGradientColors = (status1, status2, theme) => {
    const colorMap = {
        booked: theme.colors.red[2],
        inProcess: theme.colors.green[2],
        closed: theme.colors.gray[2],
        available: theme.colors.blue[2],
    };

    const color1 = colorMap[status1] || theme.colors.blue[2];
    const color2 = colorMap[status2] || theme.colors.blue[2];

    return {
        background: `linear-gradient(to left, ${color2} 50%, ${color1} 50%)`,
        border: `1px solid ${theme.colors.gray[0]}`,
        padding: theme.spacing.xs,
        borderRadius: theme.radius.md,
        cursor: 'pointer',
        align: 'center',
        position: 'relative',
        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? color1 : color1,
        },
    };
};


const useStyles = createStyles((theme) => ({
    bookedAvailable: {
        ...getGradientColors('booked', 'available', theme),
    },
    inProcessAvailable: {
        ...getGradientColors('inProcess', 'available', theme),
    },
    closedAvailable: {
        ...getGradientColors('closed', 'available', theme),
    },
    availableBooked: {
        ...getGradientColors('available', 'booked', theme),
    },
    availableInProcess: {
        ...getGradientColors('available', 'inProcess', theme),
    },
    availableClosed: {
        ...getGradientColors('available', 'closed', theme),
    },
    halfStartBooked: {
        ...getGradientColors('booked', 'inProcess', theme),
    },
    halfEndBooked: {
        ...getGradientColors('booked', 'closed', theme),
    },
    highSeason: {
        ...getGradientColors('booked', 'booked', theme),
    },
    lowSeason: {
        ...getGradientColors('inProcess', 'inProcess', theme),
    },
    cleaning: {
        ...getGradientColors('closed', 'closed', theme),
    },
    BookedInProcess: {
        ...getGradientColors('booked', 'inProcess', theme),
    },
    InProcessBooked: {
        ...getGradientColors('inProcess', 'booked', theme),
    },
    bookedClosed: {
        ...getGradientColors('booked', 'closed', theme),
    },
    closedBooked: {
        ...getGradientColors('closed', 'booked', theme),
    },
    InProcessClosed: {
        ...getGradientColors('inProcess', 'closed', theme),
    },
    closedInProcess: {
        ...getGradientColors('closed', 'inProcess', theme),
    },
    paper: {
        m: 'md',
        radius: 'md',
        align: 'center',
    },
    box: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.blue[2] : theme.colors.blue[2],
        padding: theme.spacing.xs,
        borderRadius: theme.radius.md,
        cursor: 'pointer',
        align: 'center',
        position: 'relative',
        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.blue[2] : theme.colors.blue[2],
        },
        border: `1px solid ${theme.colors.gray[0]}`,
    },
    boxWeekday: {
        backgroundColor: theme.colors.resirent[0],
        padding: theme.spacing.xs,
        borderRadius: theme.radius.md,
        cursor: 'pointer',
        align: 'center',
        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.pink[7] : theme.colors.pink[7],
        },
        border: `1px solid ${theme.colors.gray[0]}`,
    },
    boxEmpty: {
        backgroundColor: theme.colors.white,
        padding: theme.spacing.xs,
        borderRadius: theme.radius.md,
        cursor: 'pointer',
        align: 'center',
        border: `1px solid ${theme.colors.gray[0]}`,
    },
    boxSelected: {
        backgroundColor: theme.colors.yellow[5],
        padding: theme.spacing.xs,
        borderRadius: theme.radius.md,
        cursor: 'pointer',
        align: 'center',
        position: 'relative',
        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.yellow[5] : theme.colors.yellow[5],
        },
        border: `1px solid ${theme.colors.gray[0]}`,
    },
    checkOut: {
        position: 'absolute',
        top: 2,
        left: 2,
        color: theme.colors.blue[7],
        fontSize: '1.5rem', // Increase the size
        fontWeight: 700, // Increase the weight
    },
    checkIn: {
        position: 'absolute',
        top: 2,
        right: 2,
        color: theme.colors.red[7],
        fontSize: '2rem', // Increase the size
        fontWeight: 700, // Increase the weight
    },
}));

function sortBookings(bookings) {
    // Convert the bookings object to an array of entries
    const bookingsArray = Object.entries(bookings);

    // Sort the array based on the start date
    bookingsArray.sort((a, b) => {
        const startDateA = new Date(a[1].start);
        const startDateB = new Date(b[1].start);
        return startDateA - startDateB;
    });

    // Convert the sorted array back to an object
    const sortedBookings = Object.fromEntries(bookingsArray);

    return sortedBookings;
}

export const CalendarOverview = (inputForm, date, dateStates, selectedRange, setSelectedRange, itemDisplay, handleAccept, handleDecline, handleCancel, handleStop) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [modalClosedOpen, setModalClosedOpen] = useState(false);
    const [bookingInfoModalOpen, setBookingInfoModalOpen] = useState(false);
    const [currentRangeKey, setCurrentRangeKey] = useState(null);
    const [currentBookingInfo, setCurrentBookingInfo] = useState(null);

    dateStates = sortBookings(dateStates);

    const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    const { classes } = useStyles();
    const year = date.getFullYear();
    const month = date.getMonth();

    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    const daysInMonth = lastDay.getDate();
    const startingDay = (firstDay.getDay() + 6) % 7;

    // last date of the datestate (for boundaries conditions)
    let lastDate = null;
    let lastInfoBooked = "";

    const calendar = [];

    const headerRow = (
        <>
            {daysOfWeek.map((day) => (
                <Box className={classes.boxWeekday} key={day}>
                    <Text color="white" size="xs" weight={500} align="center">
                        {day}
                    </Text>
                </Box>
            ))}
        </>
    );
    calendar.push(headerRow);

    let currentRow = [];
    for (let i = 0; i < startingDay; i++) {
        currentRow.push(
            <Box className={classes.boxEmpty} key={`empty-${i}`} />
        );
    }

    const isSelected = (dateKey) => {
        if (selectedRange.start) {
            const startDate = new Date(selectedRange.start);
            const endDate = selectedRange.end ? new Date(selectedRange.end) : startDate;
            const currentDate = new Date(dateKey);
            return currentDate >= startDate && currentDate <= endDate;
        }
        return false;
    };

    const handleDateClick = (dateKey) => {

        const selectedDateRange = Object.entries(dateStates).find(([key, range]) =>
            new Date(range.start) <= new Date(dateKey) && new Date(range.end) >= new Date(dateKey)
        );

        if (selectedDateRange && (selectedDateRange[1].booked === 'inProcess')) {
            const { start, end } = selectedDateRange[1];
            const isBoundary = dateKey === start || dateKey === end;

            if (!isBoundary) {
                setCurrentRangeKey(selectedDateRange[0]);
                setCurrentBookingInfo(selectedDateRange[1]);
                setModalOpen(true);
                return;
            }
        }

        if (selectedDateRange && (selectedDateRange[1].booked === 'closed')) {
            const { start, end } = selectedDateRange[1];
            const isBoundary = dateKey === start || dateKey === end;

            if (!isBoundary) {
                setCurrentRangeKey(selectedDateRange[0]);
                setCurrentBookingInfo(selectedDateRange[1]);
                setModalClosedOpen(true);
                return;
            }
        }

        if (selectedDateRange && (selectedDateRange[1].booked === 'booked')) {
            const { start, end } = selectedDateRange[1];
            const isBoundary = dateKey === start || dateKey === end;

            if (!isBoundary) {
                setCurrentRangeKey(selectedDateRange[0]);
                setCurrentBookingInfo(selectedDateRange[1]);
                setBookingInfoModalOpen(true);
                return;
            }
        }

        if (selectedRange.start === null && selectedRange.end === null) {
            setSelectedRange({ start: dateKey, end: null });
        } else if (selectedRange.start != null && selectedRange.end === null) {
            if (new Date(dateKey) < new Date(selectedRange.start)) {
                setSelectedRange({ start: dateKey, end: selectedRange.start });
            } else {
                setSelectedRange({ ...selectedRange, end: dateKey });
            }
        } else {
            setSelectedRange({ start: dateKey, end: null });
        }
    };

    for (let day = 1; day <= daysInMonth; day++) {

        const dateKey = `${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;

        const dateStateInfo = Object.values(dateStates).find(range =>
            new Date(range.start) <= new Date(dateKey) && new Date(range.end) >= new Date(dateKey)
        );

        const dateStateInfos = Object.values(dateStates).filter(range =>
            new Date(range.start) <= new Date(dateKey) && new Date(range.end) >= new Date(dateKey)
        );

        const dateStateInfosLength = dateStateInfos.length;

        let boxClass = classes.box;
        let isClickable = true;
        let checkIn = false;
        let checkOut = false;

        const statusMap = {
            booked: 'booked',
            inProcess: 'inProcess',
            closed: 'closed',
            cleaning: 'cleaning',
        };

        const classMap = {
            booked_booked: classes.highSeason,
            inProcess_inProcess: classes.lowSeason,
            closed_closed: classes.cleaning,
            booked_inProcess: classes.BookedInProcess,
            inProcess_booked: classes.InProcessBooked,
            booked_closed: classes.bookedClosed,
            closed_booked: classes.closedBooked,
            inProcess_closed: classes.InProcessClosed,
            closed_inProcess: classes.closedInProcess,
            booked_available: classes.bookedAvailable,
            closed_available: classes.closedAvailable,
            inProcess_available: classes.inProcessAvailable,
            available_booked: classes.availableBooked,
            available_inProcess: classes.availableInProcess,
            available_closed: classes.availableClosed,
            booked_available: classes.bookedAvailable,
            inProcess_available: classes.inProcessAvailable,
            closed_available: classes.closedAvailable
        };

        // Overlapping day
        if (dateStateInfosLength == 2) {

            dateStateInfos.forEach((info, index) => {
                const { booked } = info;
                if (booked !== statusMap.closed && booked !== statusMap.cleaning) {
                    if (index === 0) checkOut = true;
                    if (index === 1) checkIn = true;
                }
            });

            const { booked: bookedDay1 } = dateStateInfos[0];
            const { booked: bookedDay2 } = dateStateInfos[1];
            const { start: start } = dateStateInfos[1];

            const classKey = `${bookedDay1}_${bookedDay2}`;
            boxClass = classMap[classKey] || '';

            if (isSelected(start)) {
                boxClass = classes.boxSelected;
            }

        } else if (dateStateInfo) {

            const { booked, start, end } = dateStateInfo;

            if (dateKey === start) {

                const classKey = `${'available'}_${booked}`;
                boxClass = classMap[classKey] || '';

                if (booked !== 'closed' && booked !== "cleaning") {
                    checkIn = true;
                }

            } else if (dateKey === end) {

                const classKey = `${booked}_${'available'}`;

                boxClass = classMap[classKey] || '';

                if (booked !== 'closed' && booked !== "cleaning") {
                    checkOut = true;
                }

            } else if (booked === 'booked') {
                boxClass = classes.highSeason;
                isClickable = true;
            } else if (booked === 'inProcess') {
                boxClass = classes.lowSeason;
                isClickable = true;
            } else if (booked === 'closed') {
                boxClass = classes.cleaning;
                isClickable = true;
            } else if (booked === 'cleaning') {
                boxClass = classes.cleaning;
                isClickable = true;
            }

            if (isSelected(dateKey)) {
                boxClass = classes.boxSelected;
            }

        } else if (isSelected(dateKey)) {

            boxClass = classes.boxSelected;

        }

        currentRow.push(
            <Box className={boxClass} onClick={() => isClickable && handleDateClick(dateKey)} key={dateKey} position="relative">
                <SimpleGrid columns={2} spacing="xs">
                    {day}
                </SimpleGrid>

                {itemDisplay === 'Booked' && dateStateInfo ?
                    (
                        <>
                            {checkIn && <IconArrowDown size={12} className={classes.checkIn} />}
                            {checkOut && <IconArrowUp size={12} className={classes.checkOut} />}
                            {dateStateInfosLength != 2 && <><Text size="xs" weight={500} align='center'>
                                {dateStateInfo.booked === "inProcess" ? dateStateInfo.booked === "cleaning" ? "Cleaning" : "request" : dateStateInfo.booked}
                            </Text>
                                <Text size="xs" weight={500} align='center'>
                                    &nbsp;
                                </Text>
                            </>
                            }
                            {dateStateInfosLength == 2 && <> <Text size="xs" weight={500} align='center'>
                                {dateStateInfos[0].booked === "inProcess" ? dateStateInfos[0].booked === "cleaning" ? "Cleaning" : "request" : dateStateInfos[0].booked}
                            </Text>
                                <Text size="xs" weight={500} align='center'>
                                    {dateStateInfos[1].booked === "inProcess" ? dateStateInfos[1].booked === "cleaning" ? "Cleaning" : "request" : dateStateInfos[1].booked}
                                </Text>
                            </>
                            }
                        </>
                    ) : (
                        <>
                            <Text size="xs" weight={500} align='center'>
                                available
                            </Text>
                            <Text size="xs" weight={500} align='center'>
                                &nbsp;
                            </Text>
                        </>
                    )}
            </Box>
        );

        if (currentRow.length === 31) {
            calendar.push(
                <React.Fragment key={`week-${day}`}>
                    {currentRow}
                </React.Fragment>
            );
            currentRow = [];
        }
    }

    while (currentRow.length < 7) {
        currentRow.push(
            <Box className={classes.boxEmpty} key={`empty-${currentRow.length}`} />
        );
    }

    calendar.push(
        <React.Fragment key="last-row">
            {currentRow}
        </React.Fragment>
    );

    return (
        <>
            {calendar}
        </>
    );
};

export default CalendarOverview;

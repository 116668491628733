import axios from 'axios';

const BASE_URL = "https://resirent-api-server-a34a467884e2.herokuapp.com/api/resirent"
// const BASE_URL = "http://localhost:5000/api/resirent"

export default axios.create({
    baseURL: BASE_URL
})

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
});
import React, { useEffect, useState } from "react";
import {
  createStyles,
  Group,
  Title,
  TextInput,
  Button,
  Card,
  Container,
  Image,
  Box,
  Grid,
  Text,
  Select,
  Textarea,
  Progress,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import blogPost from "../../assets/blogPosts/blogPage/blog_post.jpg";
import useAuth from "../../hooks/useAuth";
import axios from "../../api/axios";
import { useParams, useNavigate } from "react-router-dom";
import ReactQuill, { Quill } from "react-quill";
import cloudinaryConfig from "../../config/cloudinary.config";
import "react-quill/dist/quill.snow.css";

import QuillResizeImage from "quill-resize-image";

Quill.register("modules/resize", QuillResizeImage);

const useStyles = createStyles((theme) => ({
  label: {
    fontWeight: 600,
    textAlign: "left",
    marginRight: theme.spacing.sm,
  },
  card: {
    padding: theme.spacing.xl,
    maxWidth: "900px",
    margin: "0 auto",
  },
  editor: {
    minHeight: "200px",
    height: "300px",
    marginBottom: "80px",
  },
  loadingOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    color: "#fff",
    zIndex: 9999,
  },
}));

export const EditBlog = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { auth } = useAuth();
  const navigate = useNavigate();
  const { post_id } = useParams();

  const [form, setForm] = useState({
    title: "",
    author: "",
    designation: "",
    profileUrl: "",
    userdescription: "",
    usertitle: "",
    userImage: "",
    content: "",
    description: "",
    author_id: 0,
    language: "EN", // Default language
  });

  const [imagePreview, setImagePreview] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [intervalId, setIntervalId] = useState(null);

  // Function to simulate a smooth loading progress
  const startLoading = () => {
    setLoading(true);
    setProgress(0);
    let currentProgress = 0;

    const interval = setInterval(() => {
      currentProgress += Math.floor(Math.random() * 10) + 5;
      if (currentProgress >= 95) {
        clearInterval(interval);
      }
      setProgress(currentProgress);
    }, 200);

    setIntervalId(interval);
  };

  // Function to ensure progress reaches 100% before hiding
  const stopLoading = () => {
    if (intervalId) clearInterval(intervalId);
    setProgress(100);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const [userImageFile, setUserImageFile] = useState(null);
  const [userImagePreview, setUserImagePreview] = useState(null);

  const [user, setUser] = useState(null);

  // Fetch existing blog details
  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(`/blog/${post_id}`);
        const {
          title,
          content,
          authorUsername,
          language,
          description,
          imageUrl,
          author_id,
        } = response.data;
        setForm({
          title,
          content,
          description,
          author: authorUsername,
          language: language || "EN",
          author_id,
        });
        if (imageUrl) {
          setImagePreview(imageUrl);
        }

        const user_response = await axios.get(`/user/${authorUsername}`);

        setForm((prevForm) => ({
          ...prevForm,
          designation: user_response.data?.designation || "",
          profileUrl: user_response.data?.profileUrl || "",
          usertitle: user_response.data?.title || "",
          userdescription: user_response.data?.description || "",
        }));

        if (user_response.data?.imageUrl) {
          setUserImagePreview(user_response.data.imageUrl);
        }
      } catch (error) {
        console.error("Error fetching blog data:", error);
        alert("Failed to fetch blog data. Please try again later.");
      }
    };
    fetchBlog();
  }, [post_id]);

  // Validation
  const validate = () => {
    const newErrors = {};
    if (!form.title.trim()) {
      newErrors.title = "Title is required.";
    }
    if (!form.description.trim()) {
      newErrors.description = "Description is required.";
    }
    if (!form.usertitle.trim()) {
      newErrors.usertitle = "Autor Name is required.";
    }
    if (!form.designation.trim()) {
      newErrors.designation = "Designation is required.";
    }
    // if (!form.author.trim()) {
    //   newErrors.author = "Author is required.";
    // }
    if (!form.content.trim()) {
      newErrors.content = "Content is required.";
    }
    if (!form.language) {
      newErrors.language = "Language is required.";
    }
    return newErrors;
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
    if (errors[name])
      setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
  };

  const handleSelectChange = (value) => {
    setForm((prevForm) => ({ ...prevForm, language: value }));
    if (errors.language)
      setErrors((prevErrors) => ({ ...prevErrors, language: undefined }));
  };

  const handleEditorChange = (data) => {
    setForm((prevForm) => ({ ...prevForm, content: data }));
    if (errors.content)
      setErrors((prevErrors) => ({ ...prevErrors, content: undefined }));
  };

  // Handle image upload
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  // Remove selected image
  const removeImage = () => {
    setImageFile(null);
    setImagePreview(null);
  };

  const handleUserImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setUserImageFile(file);
      setUserImagePreview(URL.createObjectURL(file));
    }
  };

  const removeUserImage = () => {
    setUserImageFile(null);
    setUserImagePreview(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    setErrors(validationErrors);
  
    if (Object.keys(validationErrors).length === 0 && auth) {
      try {
        startLoading();
  
        let blogImageUrl = null;
        let authorImageUrl = null;
  
        const generateRandomString = (length = 10) => {
          const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
          return Array.from({ length }, () =>
            chars.charAt(Math.floor(Math.random() * chars.length))
          ).join("");
        };
  
        // ✅ Upload blog image
        if (imageFile) {
          const blogImgId = generateRandomString();
          const signRes = await axios.get("/signuploadform", {
            params: { folder: auth.username, publicID: blogImgId },
          });
  
          const blogUploadUrl =
            cloudinaryConfig.BASE_URL +
            signRes.data.cloudName +
            cloudinaryConfig.UPLOAD_SUFFIX;
  
          const blogFormData = new FormData();
          blogFormData.append("file", imageFile);
          blogFormData.append("api_key", signRes.data.apiKey);
          blogFormData.append("cloud_name", signRes.data.cloudName);
          blogFormData.append("folder", auth.username);
          blogFormData.append("public_id", blogImgId);
          blogFormData.append("signature", signRes.data.signature);
          blogFormData.append("timestamp", signRes.data.timestamp);
  
          const res = await axios.post(blogUploadUrl, blogFormData);
          blogImageUrl = res.data.secure_url;
        }
  
        // ✅ Upload author image
        if (userImageFile) {
          const authorImgId = generateRandomString();
          const signRes = await axios.get("/signuploadform", {
            params: { folder: auth.username, publicID: authorImgId },
          });
  
          const authorUploadUrl =
            cloudinaryConfig.BASE_URL +
            signRes.data.cloudName +
            cloudinaryConfig.UPLOAD_SUFFIX;
  
          const authorFormData = new FormData();
          authorFormData.append("file", userImageFile);
          authorFormData.append("api_key", signRes.data.apiKey);
          authorFormData.append("cloud_name", signRes.data.cloudName);
          authorFormData.append("folder", auth.username);
          authorFormData.append("public_id", authorImgId);
          authorFormData.append("signature", signRes.data.signature);
          authorFormData.append("timestamp", signRes.data.timestamp);
  
          const res = await axios.post(authorUploadUrl, authorFormData);
          authorImageUrl = res.data.secure_url;
        }
  
        // ✅ Now send form data to backend
        const formData = new FormData();
        formData.append("author_id", form.author_id);
        formData.append("title", form.title);
        formData.append("description", form.description);
        formData.append("content", form.content);
        formData.append("authorName", form.author);
        formData.append("language", form.language);
        formData.append("readingTime", "");
        formData.append("category", "");
        formData.append("authorUsername", form.author);
        formData.append("authorRole", form.usertitle);
        formData.append("designation", form.designation);
        formData.append("authorProfileUrl", form.profileUrl);
        formData.append("userdescription", form.userdescription);
        formData.append("href", "");
  
        if (blogImageUrl) formData.append("blogImage", blogImageUrl);
        if (authorImageUrl) formData.append("authorImageUrl", authorImageUrl);
  
        await axios.put(`/blog/${post_id}`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
          withCredentials: true,
        });
  
        stopLoading();
        alert("Blog updated successfully!");
        navigate("/blog");
      } catch (error) {
        stopLoading();
        console.error("Error updating blog:", error.response?.data || error.message);
        alert("Error updating blog. Please try again later.");
      }
    }
  };
  

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
    resize: {
      locale: {},
    },
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "clean",
  ];
  return (
    <Box>
      {/* Loading Screen */}
      {loading && (
        <div className={classes.loadingOverlay}>
          <Text size="xl" weight={700}>
            Saving...
          </Text>
          <Progress value={progress} size="xl" color="blue" mt="md" w="300px" />
          <Text size="lg" mt="sm">
            {progress}%
          </Text>
        </div>
      )}
      <Image src={blogPost} alt="Blog" height={400} />

      <Container size="lg" py={100}>
        <Group position="center">
          <Title order={4} weight={100} align="center" color="resirentPink">
            <h1>{t("Edit Blog")}</h1>
          </Title>
        </Group>

        <Container size="lg" mt="xl">
          <Card shadow="sm" className={classes.card} radius="md" withBorder>
            <form onSubmit={handleSubmit}>
              <Grid align="center" gutter="md">
                {/* Title Input */}
                <Grid.Col span={3}>
                  <Text className={classes.label}>{t("Title")}</Text>
                </Grid.Col>
                <Grid.Col span={9}>
                  <TextInput
                    placeholder={t("Enter blog title")}
                    value={form.title}
                    onChange={handleChange}
                    name="title"
                    error={errors.title}
                  />
                </Grid.Col>
                <Grid.Col span={3}>
                  <Text className={classes.label}>{t("Description")}</Text>
                </Grid.Col>
                <Grid.Col span={9}>
                  <TextInput
                    placeholder={t("Enter blog description")}
                    value={form.description}
                    onChange={handleChange}
                    name="description"
                    error={errors.description}
                  />
                </Grid.Col>
                {/* Image Upload */}
                <Grid.Col span={3}>
                  <Text className={classes.label}>
                    {t("Main Image Upload")}
                  </Text>
                </Grid.Col>
                <Grid.Col span={9}>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                  />
                  {imagePreview && (
                    <Box mt="md">
                      <Image src={imagePreview} alt="Preview" height={200} />
                      <Button color="red" mt="sm" onClick={removeImage}>
                        {t("Remove Image")}
                      </Button>
                    </Box>
                  )}
                </Grid.Col>

                {/* Content Editor */}
                <Grid.Col span={3}>
                  <Text className={classes.label}>{t("Content")}</Text>
                </Grid.Col>
                <Grid.Col span={9}>
                  <ReactQuill
                    value={form.content}
                    onChange={handleEditorChange}
                    modules={modules}
                    formats={formats}
                    className={classes.editor}
                  />
                </Grid.Col>

                <Grid.Col span={3}>
                  <Text className={classes.label}>{t("Author Name")}</Text>
                </Grid.Col>
                <Grid.Col span={9}>
                  <TextInput
                    placeholder={t("Enter Author Name")}
                    value={form.usertitle}
                    onChange={handleChange}
                    name="usertitle"
                    error={errors.usertitle}
                  />
                </Grid.Col>
                <Grid.Col span={3}>
                  <Text className={classes.label}>
                    {t("Author Designation")}
                  </Text>
                </Grid.Col>
                <Grid.Col span={9}>
                  <TextInput
                    placeholder={t("Enter Author Designation")}
                    value={form.designation}
                    onChange={handleChange}
                    name="designation"
                    error={errors.designation}
                  />
                </Grid.Col>
                <Grid.Col span={3}>
                  <Text className={classes.label}>
                    {t("Author Profile Url")}
                  </Text>
                </Grid.Col>
                <Grid.Col span={9}>
                  <TextInput
                    placeholder={t("Enter Author Profile Url")}
                    value={form.profileUrl}
                    onChange={handleChange}
                    name="profileUrl"
                    // error={errors.designation}
                  />
                </Grid.Col>

                <Grid.Col span={3}>
                  <Text className={classes.label}>{t("Author Image")}</Text>
                </Grid.Col>
                <Grid.Col span={9}>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleUserImageUpload}
                  />
                  {userImagePreview && (
                    <Box>
                      <Image
                        src={userImagePreview}
                        alt="Preview"
                        height={200}
                      />
                      <Button color="red" mt="sm" onClick={removeUserImage}>
                        {t("Remove Image")}
                      </Button>
                    </Box>
                  )}
                </Grid.Col>

                <Grid.Col span={3}>
                  <Text className={classes.label}>
                    {t("Author Description")}
                  </Text>
                </Grid.Col>
                <Grid.Col span={9}>
                  <Textarea
                    rows={6}
                    placeholder={t("Enter Author Description")}
                    value={form.userdescription}
                    onChange={handleChange}
                    name="userdescription"
                    // error={errors.designation}
                  />
                </Grid.Col>
              </Grid>

              <Group position="right" mt="lg">
                <Button type="submit" size="lg" disabled={loading}>
                  {t("Save Changes")}
                </Button>
              </Group>
            </form>
          </Card>
        </Container>
      </Container>
    </Box>
  );
};
